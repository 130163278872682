import React from 'react'
import { Formik, Form } from 'formik'
import {
  FormGroup, Button, Input, InputGroup, InputGroupAddon, InputGroupText, FormFeedback,
} from 'reactstrap'
import * as Yup from 'yup'
import { connect } from 'react-redux'
import { suppressionSettingAction } from 'actions'

// APIへ配信停止リクエストを送信する前の確認・編集画面を表示するコンポーネント
export const SuppressionEditForm = ({ text, handleSubmit, dispatch }) => {
  const initialValues = generateInitialValues(text)
  const checkBoxNames = initialValues.inputs.map(element => (element.checkBoxName))
  const validationSchema = generateValidationSchema(checkBoxNames)
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting }) => {
        const emails = values.inputs.filter(obj => obj.checkBox).map(obj => obj.email)
        dispatch(suppressionSettingAction.submitUpdateSuppressionSetting({ emails, setSubmitting, handleSubmit }))
      }}
    >
      {props => (
        <Form>
          <ValidationMessageGenerator errors={props.errors} />
          <InitialValidator validateForm={props.validateForm} />
          <SuppressionEditInputGroups {...props} />
          <div className="text-center">
            <Button color="primary" type="submit" size="lg" disabled={props.isSubmitting}>
              配信停止
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  )
}

const regex = RegExp(/^[\w+\-.]+@[a-z\d\-.]+\.[a-z]+$/, 'i')

// YupのValidationSchemaを動的に生成するための関数
const generateValidationSchema = (array) => {
  const isEveryFalsyCheckBoxs = generateIsEveryFalsyCheckBoxsSchema(array)
  return Yup.object().shape({
    inputs: Yup.array().of(
      Yup.object().shape({
        email: Yup.string().when('checkBox', {
          is: true,
          then: Yup.string()
            .required('メールアドレスを入力してください')
            .matches(regex, { message: '正しいフォーマットのメールアドレスを入力してください（例: example@example.co.jp）', excludeEmptyString: true }),
        }),
        checkBox: Yup.boolean(),
      }),
    ),
    isEveryFalsyCheckBoxs,
  })
}

const generateIsEveryFalsyCheckBoxsSchema = array => (
  Yup.mixed().when(array, {
    is: (...args) => (
      // すべてのcheckBoxの値がfalseであればバリデーションを実行
      args.every(arg => !arg)
    ),
    then: Yup.mixed().test({
      message: '最低でも一つは有効なメールアドレスを指定してください。',
      test: () => false,
    }),
  })
)

/**
 * フォームデータの初期値の定義
 * @param  {string}       改行区切りのテキストデータ
 * @return {object}       フォームで使用するデータ構造
 * example: {
 *            inputs: [
 *              {
 *                email: 'foo@foo.com',
 *                emailName: 'inputs[0].email',
 *                checkBox: true,
 *                checkBoxName: 'inputs[0].checkBox',
 *              }
 *            ],
 *            isEveryFalsyCheckBoxs: false,
 *          }
 */
const generateInitialValues = (string) => {
  const textLines = string.split(/\n/)
  const emails = excludeOnlySpaceOrBlankElement(textLines)
  const inputs = generateInputs(emails)
  return { inputs, isEveryFalsyCheckBoxs: false }
}

const excludeOnlySpaceOrBlankElement = (array) => {
  let ar = array.map(element => element.replace(/\s+/, ''))
  return ar.filter(element => Boolean(element))
}

const generateInputs = (array) => {
  const inputs = array.map((email, index) => {
    let emailName = `inputs[${index}].email`
    let checkBoxName = `inputs[${index}].checkBox`
    const datum = {
      email,
      emailName,
      checkBox: true,
      checkBoxName,
    }
    return datum
  })
  return inputs
}

// バリデーションエラーの有無に応じてヘッダー用のメッセージを生成するコンポーネント
const ValidationMessageGenerator = ({ errors }) => {
  let message
  if (Object.keys(errors).length) {
    message = (
      <div className="parsley-equalto">
        {
          errors.isEveryFalsyCheckBoxs ||
          '入力内容に誤りのある項目があります。該当箇所を修正するか、チェックボックスのチェックを外して配信対象から除外してください。'
        }
      </div>
    )
  } else {
    message = (
      <div>
        以下のメールアドレスを配信停止します。
        よろしければページ最下部の「配信停止」ボタンを押してください。
      </div>
    )
  }
  return (message)
}

// 初期描画時にバリデーションを作動させるために用意したコンポーネント
class InitialValidator extends React.Component {
  componentDidMount() {
    this.props.validateForm()
  }

  render() {
    return null
  }
}

// フォームの部品を動的に生成するコンポーネント
// keyを設定するために用意
const SuppressionEditInputGroups = ({ values, errors, ...props }) => {
  const inputGroups = values.inputs.map((input, index) => {
    const errorMessage = errors.inputs && errors.inputs[index] && errors.inputs[index].email
    return (
      <SuppressionEditInputGroup
        key={input.emailName}
        input={input}
        isError={Boolean(errorMessage)}
        errorMessage={errorMessage}
        {...props}
      />
    )
  })
  return (inputGroups)
}

const SuppressionEditInputGroup = ({
  input,
  handleChange,
  handleBlur,
  isError,
  errorMessage,
}) => (
  (
    <React.Fragment>
      <FormGroup>
        <InputGroup>
          <InputGroupAddon addonType="prepend">
            <InputGroupText>
              <Input
                addon
                type="checkbox"
                name={input.checkBoxName}
                onChange={(e) => {
                  let val
                  if ('checked' in e.target) {
                    val = e.target.checked
                  } else if (e.target.value === 'on' || e.target.value === 'true') {
                    val = true
                  } else if (e.target.value === 'off' || e.target.value === 'false') {
                    val = false
                  } else {
                    val = !!e.target.value
                  }
                  e.target.value = val
                  handleChange(e)
                }}
                onBlur={handleBlur}
                defaultChecked={input.checkBox}
              />
            </InputGroupText>
          </InputGroupAddon>
          <InputGroupAddon addonType="prepend">
            <InputGroupText>
              配信停止する
            </InputGroupText>
          </InputGroupAddon>
          <Input
            value={input.email}
            name={input.emailName}
            onChange={handleChange}
            onBlur={handleBlur}
            invalid={isError}
          />
          <FormFeedback>{errorMessage}</FormFeedback>
        </InputGroup>
      </FormGroup>
    </React.Fragment>
  )
)

export default connect()(SuppressionEditForm)
