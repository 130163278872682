import React, { useState } from 'react'
import moment from 'moment'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import {
  Table, Button,
  DropdownToggle, DropdownMenu, DropdownItem,
  UncontrolledDropdown,
  Card, CardBody, CardFooter,
  Badge, Modal, ModalBody,
} from 'reactstrap'
import { Pagination, ChangeMailAbTestStatusModal, Swal, Loading } from 'lib/components'
import { mailAbTestConstant, generalConstant } from 'config/constants'
import { arraysToObject } from 'lib/utils'
import { mailAbTestAction, mailAbTestCampaignMapAction } from 'actions'

export const MailAbTestList = ({
  mailAbTest, testType = 'all', deliveryStatus = 'all', showFinishedTest = 'false', limit = 6, offset = 0, dispatch,
}) => {
  const targetItems = [...mailAbTest.items].filter(mailAbTest => mailAbTestFilter(mailAbTest, testType, deliveryStatus, showFinishedTest))
  return (
    <Loading isLoading={mailAbTest.isFetching}>
      <Card>
        <CardBody>
          <Table responsive>
            <tbody className="no-thead">
              {
                targetItems.sort((a, b) => mailAbTestSort(a, b))
                // ページネーション用filter
                  .filter((mailAbTest, index) => (index >= offset && index < offset + limit))
                  .map(mailAbTest => (
                    <tr key={`mailAbTest_${mailAbTest.id}`} className={mailAbTest.enabled ? '' : 'inactive'}>
                      <td className="campaign-overview">
                        <h5>{mailAbTest.name}</h5>
                        <MailAbTestBadges mailAbTest={mailAbTest} />
                        <p className="mb-0">
                          <span className="ascii">{moment(mailAbTest.updatedAt).format('YYYY/MM/DD HH:mm')}</span>
  に更新
                        </p>
                      </td>
                      <td className="text-center">
                        <MailAbTestEditButton mailAbTest={mailAbTest} />
                      </td>
                      <td className="text-center">
                        <MailAbTestStageDropdownButton mailAbTest={mailAbTest} mailAbTestId={mailAbTest.id} dispatch={dispatch} />
                      </td>
                      <td className="text-center">
                        <MailAbTestFinishButton mailAbTest={mailAbTest} mailAbTestId={mailAbTest.id} dispatch={dispatch} />
                      </td>
                    </tr>
                  ))
              }
            </tbody>
          </Table>
        </CardBody>
        <CardFooter>
          <Pagination
            totalItemCount={targetItems.length}
            limit={limit}
          />
        </CardFooter>
      </Card>
    </Loading>
  )
}

const testType = arraysToObject(mailAbTestConstant.testTypes, 1, 0)

/**
 * ABテストの内容バッジを表示するComponent
 * @param {Object} mailAbTest
 */
const MailAbTestBadges = ({ mailAbTest }) => (
  <p className="mb-2">
    <span className="badge badge-purple">{testType[mailAbTest.testType]}</span>
  </p>
)

/**
 * ABテストの編集ボタンのComponent
 * ABテスト準備中のみ「編集」ラベルにする
 * それ以外は備考のみ編集可能として「設定を確認する」ラベルにする
 * @param {Object} mailAbTest
 */
const MailAbTestEditButton = ({ mailAbTest }) => {
  let buttonLabel
  if (mailAbTest.status === 'in_the_middle' || mailAbTest.status === 'finished') {
    buttonLabel = '設定を確認する'
  } else {
    buttonLabel = '編集'
  }
  return (
    <Button tag={Link} to={`/mail_ab_test_edit/${mailAbTest.id}`} color="secondary">{buttonLabel}</Button>
  )
}

/**
 * ABテストのステータス変更ボタンのComponent
 * @param {Object} mailAbTest
 * @param {[type]} dispatch
 */
const MailAbTestStageDropdownButton = ({ mailAbTest, dispatch }) => {
  const [isOpen, setIsOpen] = useState(false)
  let statusText; let actionText; let
    statusColor
  if (mailAbTest.status === 'finished') {
    return (<Badge variant="secondary">テスト終了</Badge>)
  }
  if (mailAbTest.status === 'in_preparation') {
    statusText = '準備中'
    statusColor = 'success'
    actionText = '開始する'
  }
  if (mailAbTest.status === 'in_the_middle' && mailAbTest.enabled) {
    statusText = '実行中'
    statusColor = 'info'
    actionText = '中断する'
  }
  if (mailAbTest.status === 'in_the_middle' && !mailAbTest.enabled) {
    statusText = '停止中'
    statusColor = 'warning'
    actionText = '再開する'
  }
  return (
    <>
      <UncontrolledDropdown>
        <DropdownToggle caret color={statusColor}>{statusText}</DropdownToggle>
        <DropdownMenu>
          <DropdownItem
            onClick={() => {
              dispatch(mailAbTestCampaignMapAction.fetchMailAbTestCampaignMaps(mailAbTest.id))
              setIsOpen(true)
            }}
          >
            {actionText}
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
      <Modal isOpen={isOpen} toggle={() => setIsOpen(false)}>
        <ModalBody>
          <ChangeMailAbTestStatusModal mailAbTestProps={{ mailAbTest, willFinished: false }} />
        </ModalBody>
      </Modal>
    </>
  )
}

/**
 * ABテストの終了・削除ボタンのComponent
 * ABテストが準備中 => 削除ボタンを表示
 * ABテストが配信・停止中 => 終了ボタンを表示
 * @param {Object} mailAbTest
 * @param {[type]} dispatch
 * @param {[boolean]} isFinished 操作が終了かどうか
 */
const MailAbTestFinishButton = ({ mailAbTest, dispatch }) => {
  const [isOpen, setIsOpen] = useState(false)
  let actionText
  let statusColor
  if (mailAbTest.status === 'in_the_middle') {
    statusColor = 'danger'
    actionText = '終了する'
    return (
      <>
        <Button
          color={statusColor}
          onClick={() => {
            dispatch(mailAbTestCampaignMapAction.fetchMailAbTestCampaignMaps(mailAbTest.id))
            setIsOpen(true)
          }}
        >
          {actionText}
        </Button>
        <Modal isOpen={isOpen} toggle={() => setIsOpen(false)}>
          <ModalBody>
            <ChangeMailAbTestStatusModal mailAbTestProps={{ mailAbTest, willFinished: true }} />
          </ModalBody>
        </Modal>
      </>
    )
  }
  if (mailAbTest.status === 'in_preparation' && !mailAbTest.enabled) {
    statusColor = 'danger'
    actionText = '削除する'
    return (
      <Swal
        options={generalConstant.deleteAbTestSwalOptions}
        color={statusColor}
        callback={(isConfirm) => { isConfirm && dispatch(mailAbTestAction.submitDeleteMailAbTest({ id: mailAbTest.id })) }}
      >
        {actionText}
      </Swal>
    )
  }
  return null
}

/**
 * ソート用関数
 * @param  {Object} a  比較対象ABテストA
 * @param  {Object} b  比較対象ABテストB
 * @return {Boolean} ソート判定結果
 */
// とりあえず、更新日順にしている
const mailAbTestSort = (a, b) => moment(b.updatedAt) - moment(a.updatedAt)


/**
 * ABテストの表示フィルタ
 * @param mailAbTest
 * @param testType テスト内容
 * @param deliveryStatus 配信状況
 * @param showfinishedTest 終了したABテストを表示するか否か
 * @returns {boolean}
 */
const mailAbTestFilter = (mailAbTest, testType, deliveryStatus, showfinishedTest) => {
  if (mailAbTest === undefined) { return false }
  if (testType !== 'all' && mailAbTest.testType !== testType) { return false }
  if (deliveryStatus !== 'all') {
    if (deliveryStatus === 'on' && (mailAbTest.status !== 'in_the_middle' || !mailAbTest.enabled)) { return false }
    if (deliveryStatus === 'off' && (mailAbTest.status === 'in_the_middle' && mailAbTest.enabled)) { return false }
  }
  if (showfinishedTest === 'false' && mailAbTest.status === 'finished') { return false }
  return true
}

const select = ({ mailAbTest }, ...ownProps) => ({ mailAbTest, ...ownProps })

export default connect(select)(MailAbTestList)
