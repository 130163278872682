import React from 'react'
import { connect } from 'react-redux'
import { Formik, Form } from 'formik'
import { Button, FormGroup, InputGroup } from 'reactstrap'
import * as Yup from 'yup'
import { Loading, Label, FieldSingle } from 'lib/components'
import { companyAction } from 'actions'
import { generalConstant } from 'config/constants'

const validationSchema = Yup.object().shape({
  name: Yup.string().required(),
  domain: Yup.string().matches(/([A-Za-z0-9][A-Za-z0-9-]{1,61}[A-Za-z0-9]\.)+[A-Za-z]+/).required(),
  siteName: Yup.string(),
  siteUrl: Yup.string().url(),
  phoneNumber: Yup.string(),
  postalCode: Yup.string(),
  prefecturalCode: Yup.string(),
  address1: Yup.string(),
  address2: Yup.string(),
})

export const CompanySettingForm = ({ company, dispatch }) => {
  const item = company.item
  return (
    <Loading isLoading={company.isFetching}>
      <Formik
        initialValues={{
          name: item.name,
          domain: item.domain,
          siteName: item.siteName,
          siteUrl: item.siteUrl,
          phoneNumber: item.phoneNumber,
          postalCode: item.postalCode,
          prefecturalCode: item.prefecturalCode,
          address1: item.address1,
          address2: item.address2,
        }}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          dispatch(companyAction.submitUpdateCompany(values))
        }}
      >
        {() => (
          <Form noValidate className="form-horizontal">
            <FormGroup>
              <Label className="col-sm-2">シリアル</Label>
              <InputGroup>
                <p>{company.item.serial}</p>
              </InputGroup>
            </FormGroup>
            <FormGroup>
              <Label require className="col-sm-2">企業名</Label>
              <FieldSingle name="name" type="text" placeholder="例）ナビプラス株式会社" disabled/>
            </FormGroup>
            <FormGroup>
              <Label require>ドメイン</Label>
              <FieldSingle name="domain" type="text" placeholder="例）naviplus.co.jp" disabled />
            </FormGroup>
            <FormGroup>
              <Label>導入サイト名</Label>
              <FieldSingle name="siteName" type="text" placeholder="例）ナビプラスネットショップ" />
            </FormGroup>
            <FormGroup>
              <Label>サイトURL</Label>
              <FieldSingle name="siteUrl" type="text" placeholder="例）http://www.naviplus.co.jp" />
            </FormGroup>
            <FormGroup>
              <Label>電話番号</Label>
              <FieldSingle name="phoneNumber" type="text" placeholder="例）03-5456-8017" />
            </FormGroup>
            <FormGroup>
              <Label>郵便番号</Label>
              <FieldSingle name="postalCode" type="text" placeholder="例）150-0021" />
            </FormGroup>
            <FormGroup>
              <Label>都道府県</Label>
              <FieldSingle type="select" name="prefecturalCode" options={generalConstant.prefectures} blank />
            </FormGroup>
            <FormGroup>
              <Label>住所１</Label>
              <FieldSingle name="address1" type="text" placeholder="例）渋谷区恵比寿西1-7-7" />
            </FormGroup>
            <FormGroup>
              <Label>住所２</Label>
              <FieldSingle name="address2" type="text" placeholder="例）EBSビル 8階" />
            </FormGroup>
            <div className="text-center"><Button color="primary" type="submit" size="lg">保存</Button></div>
          </Form>
        )}
      </Formik>
    </Loading>
  )
}

const select = ({ company }) => ({ company })

export default connect(select)(CompanySettingForm)
