import React from 'react'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { Button, FormGroup, FormText } from 'reactstrap'
import { FieldSingle, Label } from 'lib/components'

const SuppressionNewForm = ({ handleSubmit }) => (
  (
    <Formik
      initialValues={{ text: '' }}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        handleSubmit(values.text)
      }}
    >
      {() => (
        <Form>
          <FormGroup>
            <Label>配信停止したいメールアドレスを以下の入力欄に記入し、「確認画面へ」ボタンを押してください。</Label>
            <FormText>
              複数のメールアドレスを入力する場合は、それぞれのメールアドレスを改行で区切ってください。
            </FormText>
            <FieldSingle name="text" type="textarea" placeholder="ここに配信停止したいメールアドレスを入力してください" />
            <br />
            <div className="text-center">
              <Button color="primary" type="submit" size="lg">確認画面へ</Button>
            </div>
          </FormGroup>
        </Form>
      )}
    </Formik>
  )
)

const validationSchema = Yup.object().shape({
  text: Yup.string().required('未入力です。'),
})

export default SuppressionNewForm
