import React, { useState } from 'react'
import { connect } from 'react-redux'
import { ClipboardLink, Loading, ToggleButton } from 'lib/components'
import { Button, UncontrolledTooltip } from 'reactstrap'
import { trackingSettingAction } from 'actions'

export const CartTrackingCode = ({ company, trackingSetting, dispatch }) => {
  const cartTsJson = trackingSetting.items.find(item => item.eventType === 'cart')
  const cartTs = cartTsJson ? JSON.parse(cartTsJson.setting) : {}
  const defaultTs = {
    tags: {}, itemParams: {}, itemTag: { params: {} }, userTag: { tags: {} },
  }
  const ts = { ...defaultTs, ...cartTs }

  const [cartQty, setCartQty] = useState(ts.itemParams.cartQty)
  const [cartQtyPrice, setCartQtyPrice] = useState(ts.itemParams.cartQtyPrice)

  const [cartReturnUrl, setCartReturnUrl] = useState(ts.tags.cartReturnUrl)
  const [cartTotalPrice, setCartTotalPrice] = useState(ts.tags.cartTotalPrice)


  const [itemTagShow, setItemTagShow] = useState(ts.itemTag.show)

  const [itemName, setItemName] = useState(ts.itemTag.params.name)
  const [itemUrl1, setItemUrl1] = useState(ts.itemTag.params.url1)
  const [itemStatus, setItemStatus] = useState(ts.itemTag.params.status)
  const [itemImageUrl1, setItemImageUrl1] = useState(ts.itemTag.params.imageUrl1)
  const [itemCategory1, setItemCategory1] = useState(ts.itemTag.params.category1)
  const [itemDescription, setItemDescription] = useState(ts.itemTag.params.description)
  const [itemSaleStage, setItemSaleStage] = useState(ts.itemTag.params.saleStage)
  const [itemStockStatus, setItemStockStatus] = useState(ts.itemTag.params.stockStatus)
  const [itemStockCount, setItemStockCount] = useState(ts.itemTag.params.stockCount)
  const [itemContentDate, setItemContentDate] = useState(ts.itemTag.params.contentDate)
  const [itemPrice1, setItemPrice1] = useState(ts.itemTag.params.price1)
  const [itemPrice2, setItemPrice2] = useState(ts.itemTag.params.price2)
  const [itemCurrency, setItemCurrency] = useState(ts.itemTag.params.currency)
  const [itemDiscount, setItemDiscount] = useState(ts.itemTag.params.discount)


  const [userTagShow, setUserTagShow] = useState(ts.userTag.show)

  const [userEmail, setUserEmail] = useState(ts.userTag.tags.userEmail)
  const [userPurchasedCount, setUserPurchasedCount] = useState(ts.userTag.tags.userPurchasedCount)
  const [userName, setUserName] = useState(ts.userTag.tags.userName)
  const [userStatus, setUserStatus] = useState(ts.userTag.tags.userStatus)
  const [userGender, setUserGender] = useState(ts.userTag.tags.userGender)
  const [userPoint, setUserPoint] = useState(ts.userTag.tags.userPoint)
  const [userCustomerType, setUserCustomerType] = useState(ts.userTag.tags.userCustomerType)


  const codeText = generateCodeText({
    itemTagShow,
    userTagShow,
    cartQty,
    cartQtyPrice,
    cartReturnUrl,
    cartTotalPrice,
    itemName,
    itemUrl1,
    itemStatus,
    itemImageUrl1,
    itemCategory1,
    itemDescription,
    itemSaleStage,
    itemStockCount,
    itemStockStatus,
    itemContentDate,
    itemPrice1,
    itemPrice2,
    itemCurrency,
    itemDiscount,
    userEmail,
    userPurchasedCount,
    userName,
    userStatus,
    userGender,
    userPoint,
    userCustomerType,
  })

  const saveSetting = () => {
    const updateSetting = {
      itemParams: {
        cartQty,
        cartQtyPrice,
        code: true,
      },
      tags: {
        cartOrderCode: !!ts.tags.cartOrderCode,
        cartReturnUrl,
        cartTotalPrice,
      },
      itemTag: {
        params: {
          name: itemName,
          url1: itemUrl1,
          status: itemStatus,
          imageUrl1: itemImageUrl1,
          category1: itemCategory1,
          description: itemDescription,
          saleStage: itemSaleStage,
          stockCount: itemStockCount,
          stockStatus: itemStockStatus,
          contentDate: itemContentDate,
          price1: itemPrice1,
          price2: itemPrice2,
          currency: itemCurrency,
          discount: itemDiscount,
        },
        show: itemTagShow,
      },
      userTag: {
        tags: {
          userEmail,
          userPurchasedCount,
          userName,
          // 互換性のため、userFirstName, userLastNameも含める
          userFirstName: userName,
          userLastName: userName,
          userStatus,
          userGender,
          userPoint,
          userCustomerType,
          userRegister: !!ts.userTag.tags.userRegister,
        },
        show: userTagShow,
      },
    }
    if (cartTsJson) {
      return dispatch(trackingSettingAction.submitUpdateTrackingSetting({
        ...cartTsJson,
        setting: JSON.stringify(updateSetting),
      }))
    }
    dispatch(trackingSettingAction.submitCreateTrackingSetting({
      eventType: 'cart',
      setting: JSON.stringify(updateSetting),
    }))
  }
  return (
    <Loading isLoading={trackingSetting.isFetching}>
      <p>このイベントタグはショッピングカートのページに配置します。ショッピングカートの情報やカートに投入された商品の情報を収集します。</p>
      <div className="mb-3">
        <ToggleButton
          className="mr-1 mb-1"
          defaultActive={itemTagShow}
          onClick={() => setItemTagShow(!itemTagShow)}
        >商品情報をあわせて更新
        </ToggleButton>
        <ToggleButton
          className="mr-1 mb-1"
          defaultActive={userTagShow}
          onClick={() => setUserTagShow(!userTagShow)}
        >ユーザ情報をあわせて更新
        </ToggleButton>
      </div>
      <div className="mb-3">
        <h5>カートデータ</h5>
        <div className="mb-3">
          <p className="mb-0">任意パラメータ</p>
          <ToggleButton
            className="mr-1 mb-1"
            defaultActive={cartQty}
            onClick={() => setCartQty(!cartQty)}
            id="cartQty"
          >qty
          </ToggleButton>
          <ToggleButton
            className="mr-1 mb-1"
            defaultActive={cartQtyPrice}
            onClick={() => setCartQtyPrice(!cartQtyPrice)}
            id="cartQtyPrice"
          >qtyPrice
          </ToggleButton>
          <ToggleButton
            className="mr-1 mb-1"
            defaultActive={cartReturnUrl}
            onClick={() => setCartReturnUrl(!cartReturnUrl)}
            id="cartReturnUrl"
          >returnUrl
          </ToggleButton>
          <ToggleButton
            className="mr-1 mb-1"
            defaultActive={cartTotalPrice}
            onClick={() => setCartTotalPrice(!cartTotalPrice)}
            id="cartTotalPrice"
          >totalPrice
          </ToggleButton>
        </div>
        <UncontrolledTooltip target="cartQty">
          商品の投入個数
        </UncontrolledTooltip>
        <UncontrolledTooltip target="cartQtyPrice">
          アイテム毎小計金額
        </UncontrolledTooltip>
        <UncontrolledTooltip target="cartReturnUrl">
          ショッピングカートの内容を元通りに復旧できるURL。配信内容に含むとクリックしたユーザがカートページに遷移できます。URLの準備はお客様側のシステムで行う必要があります。
        </UncontrolledTooltip>
        <UncontrolledTooltip target="cartTotalPrice">
          投入金額合計
        </UncontrolledTooltip>
      </div>
      { itemTagShow
        && (
          <>
            <div className="mb-3">
              <h5>商品属性データ</h5>
              <div className="mb-3">
                <p className="mb-0">任意パラメータ</p>
                <ToggleButton
                  className="mr-1 mb-1"
                  defaultActive={itemName}
                  onClick={() => setItemName(!itemName)}
                  id="itemName"
                >name
                </ToggleButton>
                <ToggleButton
                  className="mr-1 mb-1"
                  defaultActive={itemUrl1}
                  onClick={() => setItemUrl1(!itemUrl1)}
                  id="itemUrl1"
                >url1
                </ToggleButton>
                <ToggleButton
                  className="mr-1 mb-1"
                  defaultActive={itemStatus}
                  onClick={() => setItemStatus(!itemStatus)}
                  id="itemStatus"
                >status
                </ToggleButton>
                <ToggleButton
                  className="mr-1 mb-1"
                  defaultActive={itemImageUrl1}
                  onClick={() => setItemImageUrl1(!itemImageUrl1)}
                  id="itemImageUrl1"
                >imageUrl1
                </ToggleButton>
                <ToggleButton
                  className="mr-1 mb-1"
                  defaultActive={itemCategory1}
                  onClick={() => setItemCategory1(!itemCategory1)}
                  id="itemCategory1"
                >category1
                </ToggleButton>
                <ToggleButton
                  className="mr-1 mb-1"
                  defaultActive={itemDescription}
                  onClick={() => setItemDescription(!itemDescription)}
                  id="itemDescription"
                >description
                </ToggleButton>
                <ToggleButton
                  className="mr-1 mb-1"
                  defaultActive={itemSaleStage}
                  onClick={() => setItemSaleStage(!itemSaleStage)}
                  id="itemSaleStage"
                >saleStage
                </ToggleButton>
                <ToggleButton
                  className="mr-1 mb-1"
                  defaultActive={itemStockStatus}
                  onClick={() => setItemStockStatus(!itemStockStatus)}
                  id="itemStockStatus"
                >stockStatus
                </ToggleButton>
                <ToggleButton
                  className="mr-1 mb-1"
                  defaultActive={itemStockCount}
                  onClick={() => setItemStockCount(!itemStockCount)}
                  id="itemStockCount"
                >stockCount
                </ToggleButton>
                <ToggleButton
                  className="mr-1 mb-1"
                  defaultActive={itemContentDate}
                  onClick={() => setItemContentDate(!itemContentDate)}
                  id="itemContentDate"
                >contentDate
                </ToggleButton>
                <ToggleButton
                  className="mr-1 mb-1"
                  defaultActive={itemPrice1}
                  onClick={() => setItemPrice1(!itemPrice1)}
                  id="itemPrice1"
                >price1
                </ToggleButton>
                <ToggleButton
                  className="mr-1 mb-1"
                  defaultActive={itemPrice2}
                  onClick={() => setItemPrice2(!itemPrice2)}
                  id="itemPrice2"
                >price2
                </ToggleButton>
                <ToggleButton
                  className="mr-1 mb-1"
                  defaultActive={itemCurrency}
                  onClick={() => setItemCurrency(!itemCurrency)}
                  id="itemCurrency"
                >currency
                </ToggleButton>
                <ToggleButton
                  className="mr-1 mb-1"
                  defaultActive={itemDiscount}
                  onClick={() => setItemDiscount(!itemDiscount)}
                  id="itemDiscount"
                >discount
                </ToggleButton>
              </div>
              <UncontrolledTooltip target="itemName">
              商品名
              </UncontrolledTooltip>
              <UncontrolledTooltip target="itemUrl1">
              商品URL
              </UncontrolledTooltip>
              <UncontrolledTooltip target="itemStatus">
              商品の有効／無効ステータス
              </UncontrolledTooltip>
              <UncontrolledTooltip target="itemImageUrl1">
              商品画像URL
              </UncontrolledTooltip>
              <UncontrolledTooltip target="itemCategory1">
              商品の属する第一階層のカテゴリ
              </UncontrolledTooltip>
              <UncontrolledTooltip target="itemDescription">
              商品の説明文
              </UncontrolledTooltip>
              <UncontrolledTooltip target="itemSaleStage">
              商品の販売上の取扱状態
              </UncontrolledTooltip>
              <UncontrolledTooltip target="itemStockStatus">
              商品の在庫状態
              </UncontrolledTooltip>
              <UncontrolledTooltip target="itemStockCount">
              商品の在庫数
              </UncontrolledTooltip>
              <UncontrolledTooltip target="itemContentDate">
              商品情報の更新日時。ブラウザキャッシュなどにより古い商品情報で更新されるのを防ぎます。
              </UncontrolledTooltip>
              <UncontrolledTooltip target="itemPrice1">
              商品の価格
              </UncontrolledTooltip>
              <UncontrolledTooltip target="itemPrice2">
              商品の推奨販売価格（値下げ前）
              </UncontrolledTooltip>
              <UncontrolledTooltip target="itemCurrency">
              商品の価格に関する記述する通貨
              </UncontrolledTooltip>
              <UncontrolledTooltip target="itemDiscount">
              商品の割引率
              </UncontrolledTooltip>
            </div>
          </>
        )
      }
      { userTagShow
        && (
          <>
            <div className="mb-3">
              <h5>ユーザ属性データ</h5>
              <div className="mb-3">
                <p className="mb-0">任意パラメータ</p>
                <ToggleButton
                  className="mr-1 mb-1"
                  defaultActive={userEmail}
                  onClick={() => setUserEmail(!userEmail)}
                  id="userEmail"
                >email
                </ToggleButton>
                <ToggleButton
                  className="mr-1 mb-1"
                  defaultActive={userPurchasedCount}
                  onClick={() => setUserPurchasedCount(!userPurchasedCount)}
                  id="userPurchasedCount"
                >purchasedCount
                </ToggleButton>
                <ToggleButton
                  className="mr-1 mb-1"
                  defaultActive={userName}
                  onClick={() => setUserName(!userName)}
                  id="userName"
                >firstName/lastName
                </ToggleButton>
                <ToggleButton
                  className="mr-1 mb-1"
                  defaultActive={userStatus}
                  onClick={() => setUserStatus(!userStatus)}
                  id="userStatus"
                >status
                </ToggleButton>
                <ToggleButton
                  className="mr-1 mb-1"
                  defaultActive={userGender}
                  onClick={() => setUserGender(!userGender)}
                  id="userGender"
                >gender
                </ToggleButton>
                <ToggleButton
                  className="mr-1 mb-1"
                  defaultActive={userPoint}
                  onClick={() => setUserPoint(!userPoint)}
                  id="userPoint"
                >point
                </ToggleButton>
                <ToggleButton
                  className="mr-1 mb-1"
                  defaultActive={userCustomerType}
                  onClick={() => setUserCustomerType(!userCustomerType)}
                  id="userCustomerType"
                >customerType
                </ToggleButton>
              </div>
              <UncontrolledTooltip target="userEmail">
              ユーザのメールアドレス
              </UncontrolledTooltip>
              <UncontrolledTooltip target="userPurchasedCount">
              ユーザの購入回数
              </UncontrolledTooltip>
              <UncontrolledTooltip target="userName">
              ユーザの名前
              </UncontrolledTooltip>
              <UncontrolledTooltip target="userStatus">
              ユーザの配信対象の有効／無効ステータス
              </UncontrolledTooltip>
              <UncontrolledTooltip target="userGender">
              ユーザの性別
              </UncontrolledTooltip>
              <UncontrolledTooltip target="userPoint">
              ユーザのポイント数
              </UncontrolledTooltip>
              <UncontrolledTooltip target="userCustomerType">
              ユーザの会員タイプ
              </UncontrolledTooltip>
            </div>
          </>
        )
      }
      <div className="mb-3">
        <Button
          color="primary"
          onClick={saveSetting}
          id="saveTrackingSetting"
        >設定を保存
        </Button>
      </div>
      <div>
        <ClipboardLink copyText={codeText} />
      </div>
      <pre className="bg-gray-lighter p-3 code-text">
        { codeText }
      </pre>
    </Loading>
  )
}

const generateCodeText = ({
  itemTagShow, userTagShow,
  cartQty, cartQtyPrice, cartReturnUrl, cartTotalPrice,
  itemName, itemUrl1, itemStatus, itemImageUrl1, itemCategory1, itemDescription, itemSaleStage,
  itemStockCount, itemStockStatus, itemContentDate, itemPrice1, itemPrice2, itemCurrency, itemDiscount,
  userEmail, userPurchasedCount, userName, userStatus, userGender, userPoint, userCustomerType,
}) => {
  const cartText = [
    cartReturnUrl && "_sna_dam.push(['returnUrl', '{戻り先URL}']);",
    cartTotalPrice && "_sna_dam.push(['totalPrice', '{投入金額合計}']);",
  ].filter(e => !!e).join('\n  ')

  const itemAttributeText = [
    itemName && "'name': '{商品名}'",
    itemUrl1 && "'url1': '{商品URL}'",
    itemStatus && "'status': '{商品の有効／無効ステータス}'",
    itemImageUrl1 && "'imageUrl1': '{商品画像URL}'",
    itemCategory1 && "'catgegory1': '{商品が属する第一階層カテゴリ}'",
    itemDescription && "'description': '{商品の説明文}'",
    itemSaleStage && "'saleStage': '{商品の販売上の取扱状態}'",
    itemStockStatus && "'stockStatus': '{商品の在庫状態}'",
    itemStockCount && "'stockCount': '{商品の在庫数}'",
    itemContentDate && "'contentDate': '{商品情報更新日時}'",
    itemPrice1 && "'price1': '{商品の価格}'",
    itemPrice2 && "'price2': '{商品の推奨販売価格（値下げ前）}'",
    itemCurrency && "'currency': '{商品の価格に記述する通貨}'",
    itemDiscount && "'discount': '{商品の割引率}'",
  ].filter(e => !!e).join(',\n      ')

  const itemText = [
    cartQty && "'qty': '{投入個数}'",
    cartQtyPrice && "'qtyPrice': '{アイテム毎小計金額}'",
    "'code': '{アイテムコード}'",
  ].filter(e => !!e).join(',\n    ')

  const userText = [
    userEmail && "_sna_dam.push(['email', '{ユーザのメールアドレス}']);",
    userPurchasedCount && "_sna_dam.push(['purchasedCount', '{ユーザの購入回数}']);",
    userName && "_sna_dam.push(['lastName', '{ユーザの名前（姓）}']);",
    userName && "_sna_dam.push(['firstName', '{ユーザの名前（名）}']);",
    userStatus && "_sna_dam.push(['status', '{ユーザの配信対象の有効／無効ステータス}']);",
    userGender && "_sna_dam.push(['gender', '{ユーザの性別}']);",
    userPoint && "_sna_dam.push(['point', '{ユーザのポイント数}']);",
    userCustomerType && "_sna_dam.push(['customerType', '{ユーザの会員タイプ}']);",
  ].filter(e => !!e).join('\n  ')

  const template = `
<script type='text/javascript'>
  _sna_dam.push(['behaviorType', 2]);
  _sna_dam.push(['userCode', '{お客様（一般ユーザ）を識別するためのID}']);
  // 投入アイテム分、_sna_dam.pushを繰り返します。
  _sna_dam.push(['item', {
    ${!itemAttributeText || !itemTagShow ? '' : `
    'attributes': {
      ${itemAttributeText}
    },`
}
    ${itemText}
  }]);
  ${cartText}
  ${userTagShow ? userText : ''}
</script>`
  return template.replace(/^\s*\n/gm, '')
}

const select = ({ company, trackingSetting }) => ({ company, trackingSetting })

export default connect(select)(CartTrackingCode)
