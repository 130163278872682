import { takeLatest, call, put } from 'redux-saga/effects'
import * as api from 'services/api'
import { customerAction } from 'actions'
import { toast } from 'react-toastify'

function* handleFindCustomer(action) {
  const { payload, meta } = yield call(api.findCustomer, action.payload)
  yield put(customerAction.findCustomerDone(payload, meta))
}

function* handleUpdateCustomer(action) {
  const { payload, error } = yield call(api.updateCustomer, action.payload)
  if (error) {
    toast.error(payload.message)
    return
  }

  yield put(customerAction.updateCustomerDone(action.payload))
  toast.success('会員種別を変更しました')
}

function* handleActivateCustomer(action) {
  const { payload, error } = yield call(api.activateCustomer, action.payload.customer)

  if (error) {
    yield put(customerAction.activateCustomerFailure())
    action.payload.setErrors({ email: payload.message })
  } else {
    yield put(customerAction.activateCustomerSuccess(action.payload.customer))
    action.payload.toggle()
    toast.success('動的タグのステータスをOKに変更しました')
  }
}

export const customerSaga = [
  takeLatest(customerAction.findCustomer, handleFindCustomer),
  takeLatest(customerAction.updateCustomer, handleUpdateCustomer),
  takeLatest(customerAction.activateCustomer, handleActivateCustomer),
]
