import React from 'react'
import { Button } from 'reactstrap'
import copy from 'clipboard-copy'
import { toast } from 'react-toastify'

const ClipboardLink = ({
  copyText, className, ...rest
}) => {
  return (
    <Button
      className={`${className || ''} pl-0`}
      color='link'
      onClick={() => {
        copy(copyText)
        toast(
          'クリップボードにコピーしました。',
          { type: 'success' }
        )
      }}
    >
      <em className="fa fa-copy mr-2"></em>
      クリップボードにコピー
    </Button>
  )
}

export default ClipboardLink
