import React from 'react'
import { connect } from 'react-redux'
import { Table, UncontrolledTooltip } from 'reactstrap'
import { HelpIcon, Loading } from 'lib/components'
import { shouldDisplaySummaryCost, valueOrError } from "lib/reportUtils"

export const TransitionReportSummaryTable = ({ transitionReport, condition }) => {
  const summary = transitionReport.item.summary
  const shouldDisplaySummaryCostCol = shouldDisplaySummaryCost(condition)
  return (
    <Loading isLoading={transitionReport.isFetching}>
      <Table bordered responsive>
        <thead>
          <tr>
            <th />
            <th colSpan="2" className="text-center">配信数</th>
            <th colSpan="10" className="text-center">成果指標</th>
            <th colSpan="3" className="text-center">リタゲ経由売上</th>
            <th colSpan="3" className="text-center">サイト状況</th>
            { shouldDisplaySummaryCostCol
              && (
                <th colSpan="2" className="text-center">
                  コスト
                </th>
              )
            }
          </tr>
          <tr style={{ wordBreak: 'keep-all' }}>
            <th>キャンペーン名</th>
            <th>
              配信数
              <HelpIcon id="processedCountTooltip" />
            </th>
            <th>
              到達数
              <HelpIcon id="deliveredCountTooltip" />
            </th>
            <th>
              開封数
              <HelpIcon id="openCountTooltip" />
            </th>
            <th>
              開封率
              <HelpIcon id="openRateTooltip" />
            </th>
            <th>
              クリック数
              <HelpIcon id="clickCountTooltip" />
            </th>
            <th>
              クリック率
              <HelpIcon id="clickRateTooltip" />
            </th>
            <th>
              完了数
              <HelpIcon id="conversionCountTooltip" />
            </th>
            <th>
              完了率
              <HelpIcon id="conversionRateTooltip" />
            </th>
            <th>
              メアド取得数
              <HelpIcon id="prospectCountTooltip" />
            </th>
            <th>
              メアド取得率
              <HelpIcon id="prospectRateTooltip" />
            </th>
            <th>
              会員化数
              <HelpIcon id="registerCountTooltip" />
            </th>
            <th>
              会員化率
              <HelpIcon id="registerRateTooltip" />
            </th>
            <th>
              キャンペーン受注単価
              <HelpIcon id="orderUnitPriceTooltip" />
            </th>
            <th>
              キャンペーン売上
              <HelpIcon id="priceSumTooltip" />
            </th>
            <th>
              リフトアップ
              <HelpIcon id="liftUpTooltip" />
            </th>
            <th>
              全体完了数
              <HelpIcon id="siteConversionCountTooltip" />
            </th>
            <th>
              全体売上
              <HelpIcon id="sitePriceSumTooltip" />
            </th>
            <th>
              全体会員化数
              <HelpIcon id="siteRegisterCountTooltip" />
            </th>
            { shouldDisplaySummaryCostCol
              && (
                <th>
                  コスト
                  <HelpIcon id="costTooltip" />
                </th>
              )
            }
            { shouldDisplaySummaryCostCol
              && (
                <th>
                  ROAS
                  <HelpIcon id="roasTooltip" />
                </th>
              )
            }
          </tr>
        </thead>
        <tbody>
          { summary
            && (
              <tr>
                <td>{summary.name}</td>
                <td>{summary.processedCount.toLocaleString()}</td>
                <td>{summary.deliveredCount.toLocaleString()}</td>
                <td>{summary.openCount.toLocaleString()}</td>
                <td>{Math.round(summary.openRate * 1000) / 10}%</td>
                <td>{summary.clickCount.toLocaleString()}</td>
                <td>{Math.round(summary.clickRate * 1000) / 10}%</td>
                <td>{summary.conversionCount.toLocaleString()}</td>
                <td>{Math.round(summary.conversionRate * 1000) / 10}%</td>
                <td>{summary.prospectCount.toLocaleString()}</td>
                <td>{Math.round(summary.prospectRate * 1000) / 10}%</td>
                <td>{summary.registerCount.toLocaleString()}</td>
                <td>{Math.round(summary.registerRate * 1000) / 10}%</td>
                <td>¥{Math.round(summary.orderUnitPrice).toLocaleString()}</td>
                <td>¥{summary.priceSum.toLocaleString()}</td>
                <td>{Math.round(summary.liftUp * 1000) / 10}%</td>
                <td>{summary.siteConversionCount.toLocaleString()}</td>
                <td>¥{summary.sitePriceSum.toLocaleString()}</td>
                <td>{summary.siteRegisterCount.toLocaleString()}</td>
                { shouldDisplaySummaryCostCol
                  && (
                    <td>{valueOrError(`¥${summary.cost.toLocaleString()}`, summary.errors)}</td>
                  )
                }
                { shouldDisplaySummaryCostCol
                  && (
                    <td>{valueOrError(`${Math.round(summary.roas * 1000) / 10}%`, summary.errors)}</td>
                  )
                }
              </tr>
            )
          }
        </tbody>
        <IndicatorTooltips shouldDisplaySummaryCostCol={shouldDisplaySummaryCostCol} />
      </Table>
    </Loading>
  )
}

const IndicatorTooltips = ({ shouldDisplaySummaryCostCol }) => (
  <>
    <UncontrolledTooltip target="processedCountTooltip" placement="bottom">
      メール/ポップアップ/LINEキャンペーンを配信した件数
    </UncontrolledTooltip>
    <UncontrolledTooltip target="clickCountTooltip" placement="left">
      メール/ポップアップ/LINEキャンペーン内リンクをクリックし、サイトへ送客した件数<br />
      （ポップアップ内の「メールで送る」は集計対象外）
    </UncontrolledTooltip>
    <UncontrolledTooltip target="conversionCountTooltip" placement="left">
      クリックから１日以内（ポップアップの場合）または７日以内（キャンペーン配信の場合）に購入完了した件数<br />
      （タグ内パラメータbehaviorTypeの値が「3」）
    </UncontrolledTooltip>
    <UncontrolledTooltip target="priceSumTooltip" placement="left">
      クリックから１日以内（ポップアップの場合）または７日以内（キャンペーン配信の場合）に購入完了した売上の合計額<br />
      （タグ内パラメータbehaviorTypeの値が「3」）
    </UncontrolledTooltip>
    { shouldDisplaySummaryCostCol
      && (
        <UncontrolledTooltip target="costTooltip" placement="left">
          配信数課金の期間：配信数と料金テーブルに基づいた額<br />
          クリック課金の期間：成果報酬額（CPC✕クリック数）。ただし最低チャージ額未満にはならない
        </UncontrolledTooltip>
      )
    }
    { shouldDisplaySummaryCostCol
      && (
        <UncontrolledTooltip target="roasTooltip" placement="bottom">
          コスト対効果
        </UncontrolledTooltip>
      )
    }
    <UncontrolledTooltip target="deliveredCountTooltip" placement="bottom">
      送信したメールのうちメールボックスに到達した件数
    </UncontrolledTooltip>
    <UncontrolledTooltip target="openCountTooltip" placement="bottom">
      開封された件数（テキストパートは集計対象外）
    </UncontrolledTooltip>
    <UncontrolledTooltip target="prospectCountTooltip" placement="bottom">
      ポップアップ内の「メールで送る」を選択後メールアドレスを入力した件数
    </UncontrolledTooltip>
    <UncontrolledTooltip target="registerCountTooltip" placement="left">
      クリックから１日以内（ポップアップの場合）または７日以内（メールの場合）に会員登録した件数<br />
      （タグ内パラメータregisterの値が「1」）
    </UncontrolledTooltip>
    <UncontrolledTooltip target="siteRegisterCountTooltip" placement="bottom">
      タグ経由で集計できたユニークなサイト全体の新規会員獲得の合計数
    </UncontrolledTooltip>
    <UncontrolledTooltip target="siteConversionCountTooltip" placement="bottom">
      タグ経由で集計した、サイト全体のユニークな受注の合計数
    </UncontrolledTooltip>
    <UncontrolledTooltip target="sitePriceSumTooltip" placement="bottom">
      タグ経由で集計した、サイト全体のユニークな売上の合計額
    </UncontrolledTooltip>
    <UncontrolledTooltip target="orderUnitPriceTooltip" placement="bottom">
      キャンペーン売上÷完了数
    </UncontrolledTooltip>
    <UncontrolledTooltip target="liftUpTooltip" placement="bottom">
      全体売上のうち、リタゲキャンペーンが貢献した売上の割合
    </UncontrolledTooltip>
    <UncontrolledTooltip target="openRateTooltip" placement="bottom">
      開封数÷到達数
    </UncontrolledTooltip>
    <UncontrolledTooltip target="clickRateTooltip" placement="left">
      クリック数÷到達数（メールの場合）<br />
      クリック数÷配信数（ポップアップの場合）
    </UncontrolledTooltip>
    <UncontrolledTooltip target="prospectRateTooltip" placement="bottom">
      メールアドレス取得数÷配信数
    </UncontrolledTooltip>
    <UncontrolledTooltip target="registerRateTooltip" placement="bottom">
      会員化数÷クリック数
    </UncontrolledTooltip>
    <UncontrolledTooltip target="conversionRateTooltip" placement="bottom">
      完了数÷クリック数
    </UncontrolledTooltip>
  </>
)

const select = ({ transitionReport }) => ({
  transitionReport,
})

export default connect(select)(TransitionReportSummaryTable)
