import { handleActions } from 'redux-actions'
import generateHandlerArgs, { preparedHandlers } from 'modules/reducerGenerator'
import { completedCampaignAction as action } from 'actions'

const overrideHandlers = {
  [action.submitSendCompletedCampaignTestMail]: (state, action) => ({
    ...state,
    isSendingTestMail: true,
  }),
  [action.submitSendCompletedCampaignTestMailDone]: (state, action) => ({
    ...state,
    isSendingTestMail: false,
    processResult: action.error ? action.payload : 'SUCCESS',
  }),
  [action.submitEnableCompletedCampaignDone]: preparedHandlers.submitUpdateObjectDone(true),
}

const overrideInitial = {
  isSendingTestMail: false,
}

const { handler, initial } = generateHandlerArgs(action, 'completedCampaign', overrideHandlers, overrideInitial)

export const completedCampaign = handleActions(handler, initial)
