import call from 'modules/apiCaller'

/**
 * APIのURL等を定義
 */

const API_ROOT = process.env.REACT_APP_API_ROOT

// 認証
export const fetchLogin = (email, password) => call(`${API_ROOT}/admins/sign_in`, 'POST', { admin: { email, password } })
export const submitLogout = () => call(`${API_ROOT}/admins/sign_out`, 'DELETE')

// カレントユーザ
export const fetchCurrentUser = () => call(`${API_ROOT}/api/admin_current_user`, 'GET')

// メールテンプレート
export const fetchCampaignTemplates = () => call(`${API_ROOT}/api/admin_campaign_templates`, 'GET')
export const submitCreateCampaignTemplate = values => call(`${API_ROOT}/api/admin_campaign_templates`, 'POST', { campaign_template: values })
export const submitUpdateCampaignTemplate = values => call(`${API_ROOT}/api/admin_campaign_templates/${values.id}`, 'PATCH', { campaign_template: values })
export const submitDeleteCampaignTemplate = values => call(`${API_ROOT}/api/admin_campaign_templates/${values.id}`, 'DELETE')

// テンプレートタグ
export const fetchTemplateTags = () => call(`${API_ROOT}/api/admin_template_tags`, 'GET')
export const submitCreateTemplateTag = values => call(`${API_ROOT}/api/admin_template_tags`, 'POST', { template_tag: values })
export const submitUpdateTemplateTag = values => call(`${API_ROOT}/api/admin_template_tags/${values.id}`, 'PATCH', { template_tag: values })
export const submitDeleteTemplateTag = values => call(`${API_ROOT}/api/admin_template_tags/${values.id}`, 'DELETE')

// 登録企業
export const fetchCompanies = () => call(`${API_ROOT}/api/admin_companies`, 'GET')
export const submitUpdateCompany = values => call(`${API_ROOT}/api/admin_companies/${values.companyId}`, 'PATCH', { company: values })

// 登録企業ステータス
export const fetchCompanyStatus = values => call(`${API_ROOT}/api/admin_companies/${values.companyId}/company_status`, 'GET')
export const submitUpdateCompanyStatus = values => call(`${API_ROOT}/api/admin_companies/${values.companyId}/company_status`, 'PATCH', { company_status: values })
export const submitTerminateCompanyStatus = values => call(`${API_ROOT}/api/admin_companies/${values.companyId}/company_status`, 'DELETE')

// 登録企業システム情報
export const fetchCompanySystemOrder = values => call(`${API_ROOT}/api/admin_companies/${values.companyId}/company_system_order`, 'GET')

// アイテムマスタ連携設定
export const fetchItemImportSetting = values => call(`${API_ROOT}/api/admin_companies/${values.companyId}/item_import_setting`, 'GET')
export const submitUpdateItemImportSetting = values => call(`${API_ROOT}/api/admin_companies/${values.companyId}/item_import_setting`, 'PUT', { item_import_setting: values })

// 宛先マスタ連携設定
export const fetchCustomerImportSetting = values => call(`${API_ROOT}/api/admin_companies/${values.companyId}/customer_import_setting`, 'GET')
export const submitUpdateCustomerImportSetting = values => call(`${API_ROOT}/api/admin_companies/${values.companyId}/customer_import_setting`, 'PUT', { customer_import_setting: values })

// レコメンドアカウント
export const fetchRecoAccounts = () => call(`${API_ROOT}/api/admin_reco_accounts`, 'GET')

// レビューアカウント
export const fetchReviewAccounts = () => call(`${API_ROOT}/api/admin_review_accounts`, 'GET')

// JavaScript互換設定
export const fetchCompanyTrackingOption = values => call(`${API_ROOT}/api/admin_companies/${values.companyId}/company_tracking_options`, 'GET')
export const submitUpdateCompanyTrackingOption = values => call(`${API_ROOT}/api/admin_companies/${values.companyId}/company_tracking_options`, 'PATCH', { company_tracking_option: values })

// 請求基本条件
export const fetchCompanyInvoiceTerm = values => call(`${API_ROOT}/api/admin_companies/${values.companyId}/company_invoice_terms/${values.id}`, 'GET')
export const fetchCompanyInvoiceTerms = values => call(`${API_ROOT}/api/admin_companies/${values.companyId}/company_invoice_terms`, 'GET')
export const submitCreateCompanyInvoiceTerm = values => call(`${API_ROOT}/api/admin_companies/${values.companyId}/company_invoice_terms`, 'POST', { company_invoice_term: values })
export const submitUpdateCompanyInvoiceTerm = values => call(`${API_ROOT}/api/admin_companies/${values.companyId}/company_invoice_terms/${values.id}`, 'PATCH', { company_invoice_term: values })
export const submitDeleteCompanyInvoiceTerm = values => call(`${API_ROOT}/api/admin_companies/${values.companyId}/company_invoice_terms/${values.id}`, 'DELETE')

// 価格設定
export const fetchCompanyUnitPrice = values => call(`${API_ROOT}/api/admin_companies/${values.companyId}/unit_prices/${values.id}`, 'GET')
export const fetchCompanyUnitPrices = values => call(`${API_ROOT}/api/admin_companies/${values.companyId}/unit_prices`, 'GET')
export const submitCreateCompanyUnitPrice = values => call(`${API_ROOT}/api/admin_companies/${values.companyId}/unit_prices`, 'POST', { unit_price: values })
export const submitUpdateCompanyUnitPrice = values => call(`${API_ROOT}/api/admin_companies/${values.companyId}/unit_prices/${values.id}`, 'PATCH', { unit_price: values })
export const submitDeleteCompanyUnitPrice = values => call(`${API_ROOT}/api/admin_companies/${values.companyId}/unit_prices/${values.id}`, 'DELETE')

// 許可IPアドレス
export const fetchCompanyAllowIpAddress = values => call(`${API_ROOT}/api/admin_companies/${values.companyId}/allow_ip_addresses/${values.id}`, 'GET')
export const fetchCompanyAllowIpAddresses = values => call(`${API_ROOT}/api/admin_companies/${values.companyId}/allow_ip_addresses`, 'GET')
export const submitCreateCompanyAllowIpAddress = values => call(`${API_ROOT}/api/admin_companies/${values.companyId}/allow_ip_addresses`, 'POST', { allow_ip_address: values })
export const submitUpdateCompanyAllowIpAddress = values => call(`${API_ROOT}/api/admin_companies/${values.companyId}/allow_ip_addresses/${values.id}`, 'PATCH', { allow_ip_address: values })
export const submitDeleteCompanyAllowIpAddress = values => call(`${API_ROOT}/api/admin_companies/${values.companyId}/allow_ip_addresses/${values.id}`, 'DELETE')
