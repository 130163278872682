import React from 'react'
import { connect } from 'react-redux'
import { Formik, Form } from 'formik'
import {
  Button, FormGroup, InputGroup, FormText,
} from 'reactstrap'
import * as Yup from 'yup'
import {
  Loading, FieldSingle, Label,
} from 'lib/components'
import { lineSettingAction } from 'actions'

const validationSchema = Yup.object().shape({
  loginClientId: Yup.string(),
  loginClientSecret: Yup.string(),
  messagingAccessToken: Yup.string(),
})

export const LineSettingEditForm = ({ lineSetting, customers, dispatch }) => {
  const item = lineSetting.item
  return (
    <Loading isLoading={lineSetting.isFetching || customers.isFetching}>
      <Formik
        initialValues={{
          loginClientId: item.loginClientId || '',
          loginClientSecret: item.loginClientSecret || '',
          messagingAccessToken: item.messagingAccessToken || '',
        }}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          dispatch(lineSettingAction.submitUpdateLineSetting(values))
        }}
      >
        <Form noValidate>
          <fieldset>
            <FormGroup>
              <Label>現在のLINE ID連携数</Label>
              <InputGroup>
                <p><strong>{customers.items.length}</strong> アカウント</p>
              </InputGroup>
            </FormGroup>
            <FormGroup>
              <Label>ログイン用コールバックURL</Label>
              <InputGroup>
                <p>{process.env.REACT_APP_BEAVER_API_ROOT}/api/v01/oauth/line/callback</p>
              </InputGroup>
              <FormText>LINE Developersのログインチャネル内「LINEログイン設定＞コールバックURL」に入力する</FormText>
            </FormGroup>
            <FormGroup>
              <Label>ログイン用チャネルID</Label>
              <FieldSingle name="loginClientId" type="text" />
              <FormText>LINE Developersのログインチャネル内「チャネル基本設定＞チャネルID」</FormText>
            </FormGroup>
            <FormGroup>
              <Label>ログイン用チャネルシークレット</Label>
              <FieldSingle name="loginClientSecret" type="text" />
              <FormText>LINE Developersのログインチャネル内「チャネル基本設定＞チャネルシークレット」</FormText>
            </FormGroup>
            <FormGroup>
              <Label>MessagingAPI用チャネルアクセストークン</Label>
              <FieldSingle name="messagingAccessToken" type="text" />
              <FormText>LINE DevelopersのMessagingAPIチャネル内「MessagingAPI設定＞チャネルアクセストークン（長期）」</FormText>
              <FormText>※表示されていない場合は「発行」をクリックして発行する</FormText>
            </FormGroup>
          </fieldset>
          <div className="text-center">
            <Button color="primary" type="submit" size="lg">保存</Button>
          </div>
        </Form>
      </Formik>
    </Loading>
  )
}

const select = ({ lineSetting, customers }) => ({ lineSetting, customers })

export default connect(select)(LineSettingEditForm)
