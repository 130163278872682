import React from 'react'
import { connect } from 'react-redux'
import ContentWrapper from 'components/layout/ContentWrapper'
import { Card, CardBody, CardHeader } from 'reactstrap'
import { useMountEffect } from 'lib/hooks'
import MailSenderInfoEditForm from 'components/parts/mailSenderInfo/MailSenderInfoEditForm'
import { mailSenderInfoAction } from 'actions'

export const CampaignCommonSettingPage = ({dispatch}) => {
  useMountEffect(() => {
    dispatch(mailSenderInfoAction.fetchMailSenderInfo())
  })

  return (
    <ContentWrapper>
      <div className="content-heading">
        <div>キャンペーン全体設定</div>
      </div>
      <Card className="card-default">
        <CardHeader>キャンペーン全体設定</CardHeader>
        <CardBody>
          <MailSenderInfoEditForm />
        </CardBody>
      </Card>
    </ContentWrapper>
  )
}

export default connect()(CampaignCommonSettingPage)
