import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'

import {
  Alert, Button, FormGroup, Row, Col, Table, Card, CardBody, CardHeader,
} from 'reactstrap'
import {
  CustomSelect, FieldSingle, Label, Loading, Swal,
} from 'lib/components'
import { mailAbTestConstant as ab, generalConstant as gc } from 'config/constants'
import { mailAbTestAction } from 'actions'

const validationSchema = Yup.object().shape({
  name: Yup.string().required(),
  testType: Yup.string().required(),
  remark: Yup.string(),
})

export const MailAbTestForm = ({
  mailAbTest, mailAbTestCampaignMap, item, submitAction, allFetchedCampaigns, dispatch,
}) => {
  const mappingCampaignsId = mailAbTestCampaignMap.items.map(x => x.campaignId)
  const defaultSelectedCampaigns = initializeSelectedCampaigns(allFetchedCampaigns.items, mappingCampaignsId)
  const editable = item.status !== 'in_the_middle' && item.status !== 'finished'
  return (
    <Loading isLoading={mailAbTest.isFetching || mailAbTestCampaignMap.isFetching}>
      <Formik
        // NOTICE:mailAbtest:{campaigns:[]}の入れ子でAPIへ送信するためvaluesにcampaignsを定義
        initialValues={{
          name: item.name || '',
          testType: item.testType || '',
          remark: item.remark || '',
          campaigns: defaultSelectedCampaigns || [],
          campaignType: initalizeCampaignType(defaultSelectedCampaigns),
        }}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          if (submitAction === 'update') {
            dispatch(mailAbTestAction.submitUpdateMailAbTest({ id: item.id, ...values }))
          } else {
            dispatch(mailAbTestAction.submitCreateMailAbTest({ ...values, enabled: false, status: 0 }))
          }
        }}
      >
        {({ values, errors, setFieldValue }) => (
          <Form noValidate>
            <div>
              { !editable &&
                <Alert color="warning">
                  実行・停止中または終了したABテストは備考のみ編集できます。
                </Alert>
              }
            </div>
            { mailAbTest.error && <Alert color="danger">{mailAbTest.error}</Alert> }
            <legend className="mb-3">ABテスト基本設定</legend>
            <fieldset>
              <FormGroup>
                <Label require>テスト名称</Label>
                <FieldSingle name="name" type="text" disabled={!editable} />
              </FormGroup>
              <FormGroup>
                <Label require>テスト内容</Label>
                <FieldSingle
                  type="select"
                  name="testType"
                  options={ab.testTypes}
                  disabled={!editable}
                />
              </FormGroup>
              <FormGroup>
                <Label>備考</Label>
                <FieldSingle name="remark" type="text" />
              </FormGroup>
            </fieldset>
            <fieldset>
              <TargetCampaignForm
                formCampaigns={values.campaigns}
                allFetchedCampaign={allFetchedCampaigns.items}
                setFormCampaigns={(cams) => { setFieldValue('campaigns', cams) }}
                editable={editable}
              />
            </fieldset>
            <div className="text-center"><Button color="primary" type="submit" size="lg" disabled={values.campaigns.length < 2}>保存</Button></div>
          </Form>
        )}
      </Formik>
    </Loading>
  )
}

/**
 * campaignsにキャンペーンの初期値を設定するメソッド
 * 編集経由の場合、すでに紐づいているキャンペーンを取得してセット
 * @param allFetchedCampaign 全キャンペーン
 * @param mappingCampaignsId ABテストに紐づく全キャンペーンのId
 * @returns {*}
 */
const initializeSelectedCampaigns = (allFetchedCampaign, mappingCampaignsId) => {
  let defaultSelectedCampaigns
  if (mappingCampaignsId.length !== 0) {
    defaultSelectedCampaigns = allFetchedCampaign.filter(x => isMappedCampaign(x, mappingCampaignsId))
  } else {
    defaultSelectedCampaigns = []
  }
  return defaultSelectedCampaigns
}

/**
 * FormのCampaignType初期値を設定するメソッド
 * 編集画面の場合、紐付くABテストに対応するキャンペーンタイプを設定する
 * @param defaultSelectedCampaigns
 * @returns {*}
 */
const initalizeCampaignType = (defaultSelectedCampaigns) => {
  let initialCampaignType
  if (defaultSelectedCampaigns.length === 0) {
    initialCampaignType = 'non_select'
  } else {
    initialCampaignType = defaultSelectedCampaigns[0]._camGroup
  }
  return initialCampaignType
}

/**
 * 対象のキャンペーンが紐づいているかどうかをチェックするメソッド
 * @param cam
 * @param mappingCampaignsId
 * @returns {boolean}
 */
const isMappedCampaign = (cam, mappingCampaignsId) => {
  if (mappingCampaignsId.indexOf(cam.id) < 0) { return false }
  return true
}

/**
 * ABテストをするキャンペーンを選択するコンポーネント
 */
const TargetCampaignForm = ({ formCampaigns, allFetchedCampaign, setFormCampaigns, editable }) => {
  const [campaignType, setCampaignType] = useState(formCampaigns.length > 0 ? formCampaigns[0]._camGroup : 'abandoned')
  const campaignOptions = allFetchedCampaign
    .filter((x) => (x._camGroup === campaignType))
    .filter((x) => (isSameTargetCampaign(x, formCampaigns)))
    .filter((x) => (!formCampaigns.includes(x)))
    .map((x) => ([x.name, x.id]))
  const [selectedCampaignId, setSelectedCampaignId] = useState(campaignOptions.length > 0 ? campaignOptions[0][1] : undefined)

  function addFormCampaign(){
    const selectedCampaign = allFetchedCampaign.find((x) => (
      x._camGroup === campaignType && x.id === Number(selectedCampaignId)
    ))
    if(!selectedCampaign){ return }
    setFormCampaigns([selectedCampaign, ...formCampaigns])
  }

  return (
    <Loading isLoading={allFetchedCampaign.isFetching}>
      <Card className="card-default">
        <CardHeader>
          <div>
            <legend className="mb-3">テストするキャンペーンを選ぶ</legend>
          </div>
          <div>
            <Alert color="dark">
              キャンペーンの配信割合はキャンペーン数で等分になります。
              <br />
              効果測定にはある程度の配信数が必要になりますので、２〜３つのキャンペーン設定を推奨します。
            </Alert>
          </div>
        </CardHeader>
        <CardBody>
          { (formCampaigns.length > 0 && formCampaigns[0]._camGroup !== campaignType) &&
            <Alert color="warning">
              すでに追加したキャンペーンと異なるキャンペーンタイプは追加することができません。
              <br />
              キャンペーンを選び直したい場合は「選び直す」ボタンを押してください。
            </Alert>
          }
          <Row>
            <Col md={4}>
              <FormGroup>
                <Label for="campaignType">キャンペーンタイプ</Label>
                <CustomSelect
                  type='select'
                  name='_campaignType'
                  value={campaignType}
                  onChange={(e) => { setCampaignType(e.target.value) }}
                  options={ab.campaignTypes}
                  disabled={!editable}
                />
              </FormGroup>
            </Col>
            <Col md={4}>
              <FormGroup>
                <Label for="campaignName">キャンペーン名</Label>
                <CustomSelect
                  type='select'
                  name='_selectedCampaignId'
                  onChange={(e) => { setSelectedCampaignId(e.target.value) }}
                  options={campaignOptions.length > 0 ? campaignOptions : [['', undefined]]}
                  disabled={!editable}
                />
              </FormGroup>
            </Col>
            <Col md={2}>
              <FormGroup>
                <Label>&nbsp;</Label>
                <div className="text-left">
                  <Button
                    color="primary"
                    size="lg"
                    disabled={!editable || (formCampaigns.length > 0 && formCampaigns[0]._camGroup !== campaignType)}
                    onClick={addFormCampaign}
                  >
                    追加する
                  </Button>
                </div>
              </FormGroup>
            </Col>
            <Col md={2}>
              <FormGroup>
                <Label>&nbsp;</Label>
                <div>
                  <Swal
                    color="warning"
                    size="lg"
                    disabled={!editable || formCampaigns.length === 0}
                    callback={(isConfirm) => { isConfirm && setFormCampaigns([]) }}
                    options={{
                      ...gc.deleteSwalOptions,
                      title: 'キャンペーンを選び直しますか？',
                      text: 'キャンペーンを選びなおすと、現在選択しているキャンペーンはリセットされます。',
                      buttons: ['キャンセル', '実行'],
                    }}
                  >
                    選び直す
                  </Swal>
                </div>
              </FormGroup>
            </Col>
          </Row>
        </CardBody>
        <CardBody>
          <FormGroup>
            <legend>テスト対象キャンペーン一覧</legend>
            <Table bordered responsive>
              <thead>
                <tr style={{ wordBreak: 'keep-all' }}>
                  <th>キャンペーン名</th>
                </tr>
              </thead>
              <tbody>
                { formCampaigns.map((cam, index) => (
                  <tr key={`${cam.campaignType}${cam.id}`}>
                    <td>{cam.name}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </FormGroup>
        </CardBody>
      </Card>
    </Loading>
  )
}

/**
 * 対象のキャンペーンがすでに追加しているキャンペーンと同じターゲットアクションおよび後続タイプか
 * チェックするメソッド
 * @param cam
 * @param selectedCampaign
 * @returns {boolean}
 */
const isSameTargetCampaign = (cam, selectedCampaign) => {
  if (selectedCampaign === undefined || selectedCampaign.length === 0) { return true }
  if (cam._camGroup !== selectedCampaign[0]._camGroup) { return false }
  switch (cam._camGroup) {
  case 'abandoned':
    if (cam.abandonedType !== selectedCampaign[0].abandonedType) { return false }
    if (cam.triggerCampaignId !== selectedCampaign[0].triggerCampaignId) { return false }
    break
  case 'completed':
    if (cam.campaignType !== selectedCampaign[0].campaignType) { return false }
    if (cam.triggerCampaignId !== selectedCampaign[0].triggerCampaignId) { return false }
    break
  case 'contextual':
    if (cam.targetAction !== selectedCampaign[0].targetAction) { return false }
    if (cam.triggerType !== selectedCampaign[0].triggerType) { return false }
    if (cam.triggerCampaignId !== selectedCampaign[0].triggerCampaignId) { return false }
    break
  default:
  }
  return true
}

const select = ({
  mailAbTest, mailAbTestCampaignMap, abandonedCampaign, completedCampaign, contextualCampaign,
}, { mailAbTestId, submitAction, ...ownProps }) => {
  let item
  let mailAbTestContentOverride
  const abanItems = abandonedCampaign.items.map((x) => { x._camGroup = 'abandoned'; return x })
  const compItems = completedCampaign.items.map((x) => { x._camGroup = 'completed'; return x })
  const contItems = contextualCampaign.items.map((x) => { x._camGroup = 'contextual'; return x })
  if (mailAbTestId) {
    mailAbTestContentOverride = mailAbTest.items.filter(obj => (obj.id === mailAbTestId))[0] || {}
  }
  item = { ...mailAbTestContentOverride }
  return {
    mailAbTest,
    mailAbTestCampaignMap,
    item,
    submitAction,
    allFetchedCampaigns: {
      items: [...abanItems, ...compItems, ...contItems],
      isFetching: abandonedCampaign.isFetching || completedCampaign.isFetching || contextualCampaign.isFetching || mailAbTest.isFetching || mailAbTestCampaignMap.isFetching,
    },
    ...ownProps,
  }
}

export default connect(select)(MailAbTestForm)
