export default {
  prefectures: [
    ['北海道', 1],
    ['青森県', 2],
    ['岩手県', 3],
    ['宮城県', 4],
    ['秋田県', 5],
    ['山形県', 6],
    ['福島県', 7],
    ['茨城県', 8],
    ['栃木県', 9],
    ['群馬県', 10],
    ['埼玉県', 11],
    ['千葉県', 12],
    ['東京都', 13],
    ['神奈川県', 14],
    ['新潟県', 15],
    ['富山県', 16],
    ['石川県', 17],
    ['福井県', 18],
    ['山梨県', 19],
    ['長野県', 20],
    ['岐阜県', 21],
    ['静岡県', 22],
    ['愛知県', 23],
    ['三重県', 24],
    ['滋賀県', 25],
    ['京都府', 26],
    ['大阪府', 27],
    ['兵庫県', 28],
    ['奈良県', 29],
    ['和歌山県', 30],
    ['鳥取県', 31],
    ['島根県', 32],
    ['岡山県', 33],
    ['広島県', 34],
    ['山口県', 35],
    ['徳島県', 36],
    ['香川県', 37],
    ['愛媛県', 38],
    ['高知県', 39],
    ['福岡県', 40],
    ['佐賀県', 41],
    ['長崎県', 42],
    ['熊本県', 43],
    ['大分県', 44],
    ['宮崎県', 45],
    ['鹿児島県', 46],
    ['沖縄県', 47],
  ],
  deleteSwalOptions: {
    title: '本当に削除しますか？',
    text: '削除したデータは復旧できません。',
    icon: 'warning',
    dangerMode: true,
    closeModal: true,
    closeOnClickOutside: false,
    closeOnEsc: true,
    buttons: ['キャンセル', '削除'],
  },
  deleteAbTestSwalOptions: {
    title: 'ABテストを削除します',
    text: 'ABテストを削除しますか？削除したデータは復旧できません。',
    icon: 'warning',
    dangerMode: true,
    closeModal: true,
    closeOnClickOutside: false,
    closeOnEsc: true,
    buttons: ['キャンセル', '実行'],
  },
  terminateCompanySwalOptions: {
    title: 'アカウント解約',
    text: 'アカウントの解約を行います。よろしいですか？',
    icon: 'warning',
    dangerMode: true,
    closeModal: true,
    closeOnClickOutside: false,
    closeOnEsc: true,
    buttons: ['キャンセル', '実行'],
  },
  deleteInvoiceSwalOptions: {
    title: '請求情報削除',
    text: '請求情報の削除を行います。よろしいですか？',
    icon: 'warning',
    dangerMode: true,
    closeModal: true,
    closeOnClickOutside: false,
    closeOnEsc: true,
    buttons: ['キャンセル', '実行'],
  },
  deleteAllowIpAddressSwalOptions: {
    title: '許可IPアドレスを削除します',
    text: '選択した許可IPアドレスを削除します。よろしいですか？',
    icon: 'warning',
    dangerMode: true,
    closeModal: true,
    closeOnClickOutside: false,
    closeOnEsc: true,
    buttons: ['キャンセル', '実行'],
  },
  finishCampaignSwalOptions: {
    title: '本当に終了しますか？',
    text: '終了したデータは復旧できません。',
    icon: 'warning',
    dangerMode: true,
    closeModal: true,
    closeOnClickOutside: false,
    closeOnEsc: true,
    buttons: ['キャンセル', '終了'],
  },
  campaignItemsLimit: 20,
  adminCampaignTemplateItemsLimit: 20,
  adminTemplateTagItemsLimit: 20,
  adminCompanyAllowIpAddressItemsLimit: 20,
  csrfCookieName: 'RTG-CSRF-TOKEN',
  csrfHeaderName: 'X-RTG-CSRF-TOKEN',
  apiRoot: process.env.REACT_APP_API_ROOT,
  trackigOptions: [
    { label: '互換を利用しない', value: 'no_use' },
    { label: '_sna_dam.pushされたデータをレコメンドに送信', value: 'rtg_to_reco' },
    { label: 'recoConstructerに渡されたデータをリタゲに送信', value: 'reco_to_rtg' },
  ],
  siteType: {
    ec: 'Eコマース',
    ec_over_20K: 'Eコマース（平均購買単価２万円以上）',
    digital_contents: 'デジタルコンテンツ',
    web_service: 'ウェブサービス',
    media_publishing: 'メディア出版',
    real_estate: '不動産',
    travel_leisure: '旅行レジャー',
    human_resources: '人材',
    finance_and_insurance: '金融保険',
    manufacture: '製造',
    education: '教育',
    other: 'その他',
  },
  systemType: {
    c21_sell_side_solution: '株式会社コマース21（Commerce21 SELL-SIDE SOLUTION）',
    c21_s5: '株式会社コマース21（Commerce21 S5）',
    ecbeing: '株式会社ecbeing（ecbeing）',
    si_web_shopping: '株式会社システムインテグレータ（SI Web Shopping）',
    ec_orange: '株式会社エスキュービズム・テクノロジー（EC-Orange）',
    ebisu_mart: '株式会社インターファクトリー（えびすマート）',
    future_shop2: '株式会社フューチャーショップ（FutureShop2）',
    kagolab: '株式会社アラタナ（カゴラボ）',
    ec_cube2: 'EC-Cube（Version 2.x）',
    ec_cube3: 'EC-Cube（Version 3.x）',
    other_system: 'その他のシステム',
  },
  sitePv: {
    pv_under_500k: '50万PV未満',
    pv_under_5m: '500万PV未満',
    pv_under_50m: '5,000万PV未満',
    pv_under_100m: '1億PV未満',
    pv_over_100m: '1億PV以上',
    pv_under_2500k: '250万PV未満',
    pv_over_2500k_under_5m: '500万PV未満',
    pv_over_5m_under_10m: '1,000万PV未満',
    pv_over_10m: '1,000万PV以上',
  },
  customerDataCount: {
    customer_under_10k: '1万件未満',
    customer_under_50k: '5万件未満',
    customer_under_100k: '10万件未満',
    customer_under_250k: '25万件未満',
    customer_under_500k: '50万件未満',
    customer_under_1000k: '100万件未満',
    customer_under_1500k: '150万件未満',
    customer_over_1500k: '150万件以上',
  },
  itemDataCount: {
    item_under_50k: '5万件未満',
    item_under_250k: '25万件未満',
    item_under_1m: '100万件未満',
    item_over_1m: '100万件以上',
  },
  chargeSystem: {
    pay_per_click: 'クリック課金',
    pay_per_performance: '成果報酬課金',
  },
  invoiceTerm: {
    minimumChargeAmount: '最低請求額',
    maxChargeAmount: '最高請求額',
    agencyRate: '代理店料率',
    salesStaffName: '営業担当者名',
    closingTiming: '支払い情報',
    paymentTiming: '支払い情報',
    invoiceTo: '請求先',
  },
  unitPrice: {
    chargeSystem: '課金方式',
    cartCampaignCpc: 'カゴ落ちCPC',
    browserCampaignCpc: 'ブラウザ放棄CPC',
    registeredCampaignCpc: '会員登録完了CPC',
    purchasedCampaignCpc: '購入完了CPC',
    popupCampaignCpc: 'ポップアップCPC',
    prospectCampaignCpc: 'ポップアップメールCPC',
    contextualCampaignCpc: '商品連動CPC',
  },
  companyStatuses: {
    under_examination: {
      class: 'warning',
      label: '審査中',
    },
    accepted: {
      class: 'primary',
      label: '契約中',
    },
    rejected: {
      class: 'danger',
      label: '審査NG',
    },
    terminated: {
      class: 'secondary',
      label: '解約',
    },
  },
  ipv4RegEx: /^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/,

  lineIdCheckUrl: `${process.env.REACT_APP_BEAVER_API_ROOT}/api/v01/oauth/rtg_line`,
}
