import React from 'react'
import ContentWrapper from 'components/layout/ContentWrapper'
import ManualLinkList from 'components/parts/manual/ManualList'


const ManualMailOperationPage = () => {
  const manuals = [
    {
      title: 'キャンペーン設定編',
      links: [
        {
          text: 'キャンペーン設定編',
          filename: 'mailope_mailcamp',
        },
      ],
    },
    {
      title: 'クリエイティブ編',
      links: [
        {
          text: 'クリエイティブ編',
          filename: 'mailope_creative',
        },
      ],
    },
    {
      title: 'ABテスト編',
      links: [
        {
          text: 'ABテスト編',
          filename: 'mailope_abtest',
        },
      ],
    },
    {
      title: '効果測定編',
      links: [
        {
          text: '効果測定編',
          filename: 'mailope_analytics',
        },
      ],
    },
    {
      title: '管理画面操作マニュアル',
      links: [
        {
          text: '管理画面操作マニュアル',
          filename: 'rtgmail_management_screen_manual',
        },
      ],
    },
  ]
  return (
    <ContentWrapper>
      <ManualLinkList manuals={manuals} />
    </ContentWrapper>
  )
}

export default ManualMailOperationPage
