import { Form, Formik } from 'formik'
import { connect } from 'react-redux'
import { Alert, Button, FormGroup } from 'reactstrap'
import React from 'react'
import * as Yup from 'yup'
import {
  FieldSingle, Label, Loading,
} from 'lib/components'
import { adminTemplateTagAction } from 'actions'

const validationSchema = Yup.object().shape({
  name: Yup.string().required(),
})

export const AdminTemplateTagForm = ({
  adminTemplateTag, item, submitAction, dispatch,
}) => (
  <Loading isLoading={adminTemplateTag.isFetching}>
    <Formik
      initialValues={{
        name: item.name || '',
      }}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        if (submitAction === 'update') {
          dispatch(adminTemplateTagAction.submitUpdateTemplateTag({ id: item.id, ...values }))
        } else {
          dispatch(adminTemplateTagAction.submitCreateTemplateTag({ ...values }))
        }
      }}
    >
      {({ values, errors }) => (
        <Form noValidate>
          { adminTemplateTag.error && <Alert color="danger">{adminTemplateTag.error}</Alert> }
          <fieldset className="mb-3">
            <FormGroup>
              <Label require>タグ名</Label>
              <FieldSingle name="name" type="text" />
            </FormGroup>
          </fieldset>
          <div className="text-center"><Button color="primary" type="submit" size="lg">更新</Button></div>
        </Form>
      )}
    </Formik>
  </Loading>
)

const select = ({
  adminTemplateTag,
}, { tagId }) => {
  let item = {}
  if (tagId) {
    item = adminTemplateTag.items.filter(obj => (obj.id === tagId))[0] || {}
  }
  return {
    adminTemplateTag, item,
  }
}

export default connect(select)(AdminTemplateTagForm)
