import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import ContentWrapper from 'components/layout/ContentWrapper'
import { Card, CardBody, CardHeader, UncontrolledTooltip } from 'reactstrap'
import { deserializeFromQueryString } from 'lib/utils'
import {
  HintIcon,
} from 'lib/components'

import { behaviorSummaryConstant as bc } from 'config/constants'
import { behaviorSummaryAction } from 'actions'

import BehaviorSummaryConditionForm from 'components/parts/behaviorSummary/BehaviorSummaryConditionForm'
import BehaviorSummaryGraph from 'components/parts/behaviorSummary/BehaviorSummaryGraph'
import BehaviorSummaryTable from 'components/parts/behaviorSummary/BehaviorSummaryTable'
import BehaviorSummaryDetailTable from 'components/parts/behaviorSummary/BehaviorSummaryDetailTable'
import BehaviorSummaryCsvDownloadButton from 'components/parts/behaviorSummary/BehaviorSummaryCsvDownloadButton'
import PageDescriptionCard from 'components/parts/common/PageDescriptionCard'

export const BehaviorSummaryPage = ({dispatch, location}) => {
  const queryObj = toQueryObj(location.search)

  function toQueryObj(queryString){
    let ret = deserializeFromQueryString(queryString) || {}
    for (let key in bc.defaultBehaviorSummaryCondition) {
      if ({}.hasOwnProperty.call(bc.defaultBehaviorSummaryCondition, key)) {
        ret[key] = ret[key] || bc.defaultBehaviorSummaryCondition[key]
        if (ret[key] === 'true' || ret[key] === 'false') {
          ret[key] = ret[key] === 'true'
        }
      }
    }
    return ret
  }

  useEffect(() => {
    dispatch(behaviorSummaryAction.fetchBehaviorSummary(queryObj))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search])

  return (
    <ContentWrapper>
      <div className="content-heading">
        <div>行動情報集計</div>
      </div>
      <PageDescriptionCard>
        サイトのアクセス状況から算出されたブラウザ放棄・カゴ落ちメールの配信対象ユーザ数と、それに関連する数値を閲覧できます。
        <HintIcon id="pagePointTooltip" />
      </PageDescriptionCard>
      <Card className="card-default">
        <CardBody>
          <BehaviorSummaryConditionForm queryObj={queryObj} />
        </CardBody>
      </Card>
      <Card className="card-default">
        <CardBody>
          <BehaviorSummaryGraph
            transition='daily'
            cart={queryObj['cart']}
            browser={queryObj['browser']}
          />
        </CardBody>
      </Card>
      <div className="text-right mb-3">
        <BehaviorSummaryCsvDownloadButton
          cart={queryObj['cart']}
          browser={queryObj['browser']}
        />
      </div>
      <Card className="card-default">
        <CardHeader>集計期間合計</CardHeader>
        <BehaviorSummaryTable
          cart={queryObj['cart']}
          browser={queryObj['browser']}
        />
      </Card>
      <Card className="card-default">
        <CardHeader>集計期間明細</CardHeader>
        <BehaviorSummaryDetailTable
          cart={queryObj['cart']}
          browser={queryObj['browser']}
        />
      </Card>
      <Tooltips />
    </ContentWrapper>
  )
}

const Tooltips = () => (
  <>
    <UncontrolledTooltip placement="bottom" target="pagePointTooltip">
      サイトのアクセス状況、配信可能な対象の増減はリタゲメール（特に放棄系メール）の配信に影響を及ぼす可能性があるため、定期的にチェックするのがおすすめです！
    </UncontrolledTooltip>
  </>
)


export default connect()(BehaviorSummaryPage)
