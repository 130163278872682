import React from 'react'
import { connect } from 'react-redux'
import { CSVLink } from 'react-csv'

const headers = [
  { label: '年月日', key: 'date' },
  { label: 'キャンペーン名', key: 'name' },
  { label: '配信数', key: 'processedCount' },
  { label: '到達数', key: 'deliveredCount' },
  { label: '開封数', key: 'openCount' },
  { label: 'クリック数', key: 'clickCount' },
  { label: 'メアド取得数', key: 'prospectCount' },
  { label: '会員化数', key: 'registerCount' },
  { label: '全体会員化数', key: 'siteRegisterCount' },
  { label: '完了数', key: 'conversionCount' },
  { label: '全体完了数', key: 'siteConversionCount' },
  { label: 'キャンペーン売上', key: 'priceSum' },
  { label: '全体売上', key: 'sitePriceSum' },
  { label: 'キャンペーン受注単価', key: 'orderUnitPrice' },
  { label: 'リフトアップ', key: 'liftUp' },
  { label: '開封率', key: 'openRate' },
  { label: 'クリック率', key: 'clickRate' },
  { label: 'メアド取得率', key: 'prospectRate' },
  { label: '完了率', key: 'conversionRate' },
]

export const MailAbTestReportCsvDownloadButton = ({ mailAbTestReport }) => {
  if (mailAbTestReport.items.length === 0) { return null }
  const details = []
  const summaries = []
  for (let report of mailAbTestReport.items) {
    report.summary.date = '合計'
    summaries.push(report.summary)
    for (let detail of report.detail) {
      details.push(detail)
    }
  }
  const data = [...details, ...summaries]
  return (
    <CSVLink
      className="btn btn-success"
      filename="ABテストレポート.csv"
      data={data}
      headers={headers}
    >
      CSVダウンロード
    </CSVLink>
  )
}

const select = ({ mailAbTestReport }) => ({
  mailAbTestReport,
})

export default connect(select)(MailAbTestReportCsvDownloadButton)
