import { takeLatest, call, put } from 'redux-saga/effects'
import * as api from 'services/api'
import { batchStatusCustomerImportAction } from 'actions'

function* handleFetchBatchStatusCustomerImports() {
  const { payload, meta } = yield call(api.fetchBatchStatusCustomerImports)
  yield put(batchStatusCustomerImportAction.fetchBatchStatusCustomerImportsDone(payload, meta))
}

export const batchStatusCustomerImportSaga = [
  takeLatest(batchStatusCustomerImportAction.fetchBatchStatusCustomerImports, handleFetchBatchStatusCustomerImports),
]
