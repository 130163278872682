import React from 'react'
import { Formik, Form } from 'formik'
import moment from 'moment'
import { connect } from 'react-redux'
import * as Yup from 'yup'
import {
  Col, Row, Button, FormGroup, Input,
} from 'reactstrap'
import { FieldSingle, FieldGroup, InputGroupAddon } from 'lib/components'

import { behaviorSummaryAction } from 'actions'
import { behaviorSummaryConstant as bc } from 'config/constants'

const validationSchema = Yup.object().shape({
  purchase_count: Yup.string(),
  behavior: Yup.string(),
  term: Yup.string(),
  dateFrom: Yup.string(),
  dateTo: Yup.string(),
})

const convertToSubmitValues = (values) => {
  const { dateFrom, dateTo, behavior, ...baseValues } = values
  const submitValues = values.term === 'free_period' ? values : baseValues
  submitValues.cart = behavior === 'cart'
  submitValues.browser = behavior === 'browser'
  return submitValues
}

export const BehaviorSummaryConditionForm = ({ queryObj, dispatch }) => (
  <Formik
    initialValues={{
      term: queryObj.term || bc.defaultBehaviorSummaryCondition.term,
      dateFrom: queryObj.dateFrom || moment().subtract(1, 'months').format('YYYY-MM-DD'),
      dateTo: queryObj.dateTo || moment().format('YYYY-MM-DD'),
      behavior: queryObj.cart ? 'cart' : 'browser',
      purchaseCount: 'any_purchased_count'
    }}
    validationSchema={validationSchema}
    onSubmit={(values) => {
      const submitValues = convertToSubmitValues(values)
      dispatch(behaviorSummaryAction.searchBehaviorSummary(submitValues))
    }}
  >
    {({ values }) => (
      <Form noValidate>
        <Row>
          <Col xs="6" md="6">
            <FormGroup className="mb-3">
              <FieldSingle type="select" name="behavior" options={bc.behaviors} />
            </FormGroup>
          </Col>
          <Col xs="6" md="6">
            <FormGroup className="mb-3">
              <FieldSingle type="select" name="purchaseCount" options={bc.purchaseCount} />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <FormGroup className="mb-3">
              <FieldSingle type="select" name="term" options={bc.terms} />
            </FormGroup>
          </Col>
        </Row>
        { values.term === 'free_period'
          && (
            <FormGroup className="mb-3">
              <FieldGroup>
                <Input name="dateFrom" type="date" />
                <InputGroupAddon addonType="middle">〜</InputGroupAddon>
                <Input name="dateTo" type="date" />
              </FieldGroup>
            </FormGroup>
          )
        }
        <div className="text-center"><Button color="primary" type="submit">レポートを更新</Button></div>
      </Form>
    )}
  </Formik>
)

export default connect()(BehaviorSummaryConditionForm)
