import {
  takeLatest, takeEvery, put, call,
} from 'redux-saga/effects'
import { replace } from 'connected-react-router'
import { serializeToQueryString } from 'lib/utils'
import { comparisonReportAction } from 'actions'
import * as api from 'services/api'
import { reportConstant as rc } from 'config/constants'
import { baseCampaignTypes } from 'lib/reportUtils'

function* handleSearchComparisonReport(action) {
  yield put(replace(`/comparison_report${serializeToQueryString(action.payload, { isNested: true })}`))
}

function* handleRemoveComparisonReport(action) {
  let queryObj = action.payload.queryObj
  const index = action.payload.index
  queryObj.report.splice(index, 1)
  yield put(replace(`/comparison_report${serializeToQueryString(queryObj, { isNested: true })}`))
}

function* handleFetchComparisonReport(action) {
  const [order, q] = action.payload
  const reqValues = fetchActionPayloadToRequest(q)
  let { payload, meta, error } = yield call(api.fetchTransitionReport, reqValues)
  if (!error) {
    payload = {
      ...payload,
      info: q,
      order: Number(order)
    }
  }
  yield put(comparisonReportAction.fetchComparisonReportDone(payload, meta))
}

const fetchActionPayloadToRequest = (actionPayload) => {
  let { campaign, ...reqValues } = actionPayload
  if (baseCampaignTypes.includes(campaign)) {
    reqValues.campaignType = campaign
  } else {
    const delimiterIndex = campaign.indexOf(rc.campaignTypeDelimiter)
    reqValues.campaignType = campaign.slice(0, delimiterIndex)
    reqValues.campaignId = campaign.slice(delimiterIndex + rc.campaignTypeDelimiter.length)
  }
  return reqValues
}

export const comparisonReportSaga = [
  takeLatest(comparisonReportAction.searchComparisonReport, handleSearchComparisonReport),
  takeLatest(comparisonReportAction.removeComparisonReport, handleRemoveComparisonReport),
  takeEvery(comparisonReportAction.fetchComparisonReport, handleFetchComparisonReport),
]
