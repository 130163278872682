import { call, put, takeLatest } from 'redux-saga/effects'
import * as api from 'services/adminApi'
import { adminCompanyTrackingOptionAction } from 'actions'

export function* handleFetchCompanyTrackingOption(action) {
  const { payload, meta } = yield call(api.fetchCompanyTrackingOption, action.payload)
  yield put(adminCompanyTrackingOptionAction.fetchCompanyTrackingOptionDone(payload, meta))
}

function* handleSubmitUpdateCompanyTrackingOption(action) {
  const { payload, meta } = yield call(api.submitUpdateCompanyTrackingOption, action.payload)
  yield put(adminCompanyTrackingOptionAction.submitUpdateCompanyTrackingOptionDone(payload.companyTrackingOption, meta))
}

export const adminCompanyTrackingOptionSaga = [
  takeLatest(adminCompanyTrackingOptionAction.fetchCompanyTrackingOption, handleFetchCompanyTrackingOption),
  takeLatest(adminCompanyTrackingOptionAction.submitUpdateCompanyTrackingOption, handleSubmitUpdateCompanyTrackingOption),
]
