import React from 'react'

const SignUpCompletePage = () => (
  <div className="block-center mt-4" style={{ width: '450px' }}>
    <div className="card card-flat">
      <div className="card-header text-center bg-dark">
        <img className="block-center rounded" src="img/naviplus-logo.png" alt="Logo" />
      </div>
      <div className="card-body">
        <h4>アカウント申請を受付けました。</h4>
        <p>ナビプラスの営業担当より連絡をいたしますのでしばらくお待ちください。</p>
      </div>
    </div>
    <div className="p-3 text-center">
      <span className="mr-2">&copy;</span>
      <span>2021</span>
      <span className="mx-2">-</span>
      <span>NaviPlus</span>
    </div>
  </div>
)

export default SignUpCompletePage
