import { createActions } from 'redux-actions'

const FETCH_COMPANIES = 'FETCH_COMPANIES'
const FETCH_COMPANIES_DONE = 'FETCH_COMPANIES_DONE'

const SUBMIT_UPDATE_COMPANY = 'SUBMIT_UPDATE_COMPANY'
const SUBMIT_UPDATE_COMPANY_DONE = 'SUBMIT_UPDATE_COMPANY_DONE'

export const adminCompanyAction = createActions(
  {
    [FETCH_COMPANIES_DONE]: [
      (...args) => args[0],
      (...args) => args[1],
    ],
    [SUBMIT_UPDATE_COMPANY_DONE]: [
      (...args) => args[0],
      (...args) => Object.assign({ notification: 'ALL_CASE' }, args[1]),
    ],
  },
  FETCH_COMPANIES,
  SUBMIT_UPDATE_COMPANY,
  {
    prefix: 'ADMIN',
  },
)
