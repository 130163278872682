export default {
  siteName: 'XXXXXXXXXXストア',
  companyName: '株式会社XXXXXXXXXX',
  siteUrl: 'https://example.com/top',

  shopLogoImageUrl: 'https://s3-ap-northeast-1.amazonaws.com/rtg-mail/mailtemplate/img/logo.jpg',
  shopMainImageUrl: 'https://s3-ap-northeast-1.amazonaws.com/rtg-mail/mailtemplate/img/images600x150.jpg',
  copyright: '©XXXXXXXXXX Co., Ltd. All rights reserved.',

  corporateSiteUrl: 'https://example.com/corporate',
  cartPageUrl: 'https://example.com/cart',
  contactUrl: 'https://example.com/contact',
  privacyPolicyUrl: 'https://example.com/privacy',
  myPageUrl: 'https://example.com/mypage',

  facebookUrl: 'https://example.com/facebook',
  twitterUrl: 'https://example.com/twitter',
  lineUrl: 'https://example.com/line',
  instagramUrl: 'https://example.com/instagram',
  pinterestUrl: 'https://example.com/pinterest',
  youtubeUrl: 'https://example.com/youtube',

  couponImageUrl: 'https://s3-ap-northeast-1.amazonaws.com/rtg-mail/mailtemplate/img/images600x150coupon.jpg',

  baseUrl: 'https://example.com/',
}
