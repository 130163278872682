import React from 'react'
import { connect } from 'react-redux'
import {
  Card, CardHeader, CardBody, CardTitle,
} from 'reactstrap'

export const PageDescriptionCard = ({ children }) => (
  <Card className="card-default">
    <CardHeader>
      <CardTitle tag="h3">
        <span>この画面について</span>
      </CardTitle>
    </CardHeader>
    <CardBody>
      { children }
    </CardBody>
  </Card>
)

export default connect()(PageDescriptionCard)
