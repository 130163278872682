import { createStore, applyMiddleware, combineReducers } from 'redux'
import { connectRouter, routerMiddleware } from 'connected-react-router'
import createSagaMiddleware from 'redux-saga'
import { createBrowserHistory } from 'history'
import * as reducers from 'reducers'
import rootSaga from 'sagas/rootSaga'
import { notificatorMiddleware, authWatcherMiddleware, googleAnalyticsMiddleware } from '../middlewares'

export const history = createBrowserHistory()

export default function configureStore(preloadedState) {
  const sagaMiddleware = createSagaMiddleware()
  const store = createStore(
    combineReducers({
      ...reducers,
      router: connectRouter(history),
    }),
    preloadedState,
    applyMiddleware(
      sagaMiddleware,
      routerMiddleware(history),
      notificatorMiddleware,
      authWatcherMiddleware,
      googleAnalyticsMiddleware,
    ),
  )

  sagaMiddleware.run(rootSaga)

  return store
}
