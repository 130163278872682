import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'

const UnauthRoute = ({ isLoggedIn, component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props => (
      !isLoggedIn
        ? <Component {...props} />
        : <Redirect to={{ pathname: '/dashboard', state: { from: props.location } }} />
    )}
  />
)

const mapStateToProps = state => ({
  isLoggedIn: state.auth.isLoggedIn,
})

export default connect(mapStateToProps)(UnauthRoute)
