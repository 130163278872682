import React from 'react'
import { connect } from 'react-redux'
import ContentWrapper from 'components/layout/ContentWrapper'
import {
  Card, CardHeader, CardTitle, CardBody, UncontrolledTooltip, FormGroup, Button, Input, UncontrolledCollapse,
} from 'reactstrap'
import { useMountEffect } from 'lib/hooks'
import CommonTrackingCode from 'components/parts/trackingCode/CommonTrackingCode'
import ItemTrackingCode from 'components/parts/trackingCode/ItemTrackingCode'
import CartTrackingCode from 'components/parts/trackingCode/CartTrackingCode'
import ConversionTrackingCode from 'components/parts/trackingCode/ConversionTrackingCode'
import RegistrationTrackingCode from 'components/parts/trackingCode/RegistrationTrackingCode'
import UnregistrationTrackingCode from 'components/parts/trackingCode/UnregistrationTrackingCode'
import FavoriteTrackingCode from 'components/parts/trackingCode/FavoriteTrackingCode'
import OtherTrackingCode from 'components/parts/trackingCode/OtherTrackingCode'
import PageDescriptionCard from 'components/parts/common/PageDescriptionCard'
import { trackingSettingAction } from 'actions'
import ManualLink from 'components/parts/manual/ManualLink'
import { Formik, Form } from 'formik'
import { deserializeFromQueryString, serializeToQueryString } from 'lib/utils'
import { FieldGroup } from 'lib/components'
import { useCookies } from 'react-cookie'

export const TrackingCodePage = ({ dispatch, history, location }) => {
  const [cookies, setCookie] = useCookies([
    'trackingCodeCampaigns',
  ])
  const queryObj = deserializeFromQueryString(cookies.trackingCodeCampaigns) || deserializeFromQueryString(location.search) || {}
  let campaigns
  if (queryObj.campaigns === undefined) {
    campaigns = []
  } else {
    campaigns = Array.isArray(queryObj.campaigns) ? queryObj.campaigns : [queryObj.campaigns]
  }

  const TrackingCodeCard = ({ id, title, Tag }) => (
    <Card className="card-default">
      <CardHeader
        id={`toggler${id}`}
        className="toggler"
      >
        <CardTitle tag="h3">
          <span>
            <em className="fa fa-plus mr-2 text-gray-dark" />
          </span>
          <span>{ title }</span>
        </CardTitle>
      </CardHeader>
      <UncontrolledCollapse toggler={`toggler${id}`}>
        <CardBody>
          <Tag />
        </CardBody>
      </UncontrolledCollapse>
    </Card>
  )
  const CommonTrackingCodeCard = TrackingCodeCard({ id: 1, title: '全ページ共通タグ', Tag: CommonTrackingCode })
  const ItemTrackingCodeCard = TrackingCodeCard({ id: 2, title: '商品詳細ページ用タグ', Tag: ItemTrackingCode })
  const CartTrackingCodeCard = TrackingCodeCard({ id: 3, title: 'カートページ用タグ', Tag: CartTrackingCode })
  const ConversionTrackingCodeCard = TrackingCodeCard({ id: 4, title: '購入完了ページ用タグ', Tag: ConversionTrackingCode })
  const RegistrationTrackingCodeCard = TrackingCodeCard({ id: 5, title: '会員登録完了ページ用タグ', Tag: RegistrationTrackingCode })
  const UnregistrationTrackingCodeCard = TrackingCodeCard({ id: 6, title: '退会完了ページ用タグ', Tag: UnregistrationTrackingCode })
  const FavoriteCodeCard = TrackingCodeCard({ id: 7, title: 'お気に入りページ用タグ', Tag: FavoriteTrackingCode })
  const OtherCodeCard = TrackingCodeCard({ id: 8, title: 'その他全ページ用タグ', Tag: OtherTrackingCode })

  const duplicate = (ary1, ary2) => [...ary1, ...ary2].filter(item => ary1.includes(item) && ary2.includes(item)).length > 0

  const requiredCodes = (campaigns) => {
    const codes = [
      CommonTrackingCodeCard,
      ItemTrackingCodeCard,
      CartTrackingCodeCard,
      ConversionTrackingCodeCard,
    ]

    if (duplicate(campaigns, ['completed_register'])) {
      codes.push(RegistrationTrackingCodeCard)
    }

    if (duplicate(campaigns, ['abandoned_cart', 'abandoned_browser', 'completed_register', 'completed_conversion', 'contextual_cart', 'contextual_favorite'])) {
      codes.push(UnregistrationTrackingCodeCard)
    }

    if (duplicate(campaigns, ['contextual_favorite'])) {
      codes.push(FavoriteCodeCard)
    }

    return codes
  }
  const optionalCodes = (campaigns) => {
    const codes = []

    // TODO
    if (!duplicate(campaigns, ['completed_register']) && duplicate(campaigns, ['abandoned_cart', 'abandoned_browser', 'completed_conversion', 'contextual_cart', 'contextual_favorite'])) {
      codes.push(RegistrationTrackingCodeCard)
    }
    if (duplicate(campaigns, ['abandoned_cart', 'abandoned_browser', 'completed_register', 'completed_conversion', 'contextual_cart', 'contextual_favorite'])) {
      codes.push(OtherCodeCard)
    }

    return codes
  }
  useMountEffect(() => {
    dispatch(trackingSettingAction.fetchTrackingSettings())
  })

  return (
    <ContentWrapper>
      <div className="content-heading">
        <div>動的タグ</div>
      </div>
      <PageDescriptionCard>
        <div className="mb-2">
          リタゲメールの導入に必要な動的タグを生成できます。<br />
          動的タグを設置することで、商品情報、会員情報、行動履歴をリタゲメールに連携することができます。<br />
          <br />
          使い方：<br />
          ①実施するキャンペーンを選択し、「検索」ボタンをクリックしてください<br />
          ②動的タグを設置するページの種別をクリックし、動的タグ生成パネルを展開してください<br />
          ③パネル内から連携したい属性を選択します。選択に応じて動的タグの内容が更新されます<br />
          ④更新された動的タグをコピーし、サイトに動的タグを設置してください<br />
        </div>
        <ManualLink filename="mansys_jstag">
          詳細はこちら
        </ManualLink>
        <span
          className="link-like ml-2"
          id="trackingCodeTooltip"
        >
          キャンペーン種別とタグ設置画面の対応表
        </span>
        <UncontrolledTooltip target="trackingCodeTooltip" placement="right">
          <img src="/img/tracking_code_tooltip.png" alt="tracking_code_tooltip" />
        </UncontrolledTooltip>
      </PageDescriptionCard>
      <Card className="card-default">
        <CardHeader>
          <CardTitle tag="h3">
            <span>キャンペーン選択</span>
          </CardTitle>
        </CardHeader>
        <CardBody>
          <Formik
            initialValues={{
              campaigns,
            }}
            onSubmit={(values) => {
              const query = serializeToQueryString(values)
              setCookie('trackingCodeCampaigns', query)
              history.push({
                pathname: location.pathname,
                search: query,
              })
            }}
          >
            {() => (
              <Form noValidate>
                <FormGroup>
                  <FieldGroup>
                    <Input name="campaigns" key="abandoned_cart" value="abandoned_cart" type="multicheckbox" option="カゴ落ちメール" />
                    <Input name="campaigns" key="abandoned_browser" value="abandoned_browser" type="multicheckbox" option="ブラウザ放棄メール" />
                    <Input name="campaigns" key="completed_register" value="completed_register" type="multicheckbox" option="会員登録メール" />
                    <Input name="campaigns" key="completed_conversion" value="completed_conversion" type="multicheckbox" option="購入完了メール" />
                    <Input name="campaigns" key="contextual_cart" value="contextual_cart" type="multicheckbox" option="商品連動メール（カート）" />
                    <Input name="campaigns" key="contextual_favorite" value="contextual_favorite" type="multicheckbox" option="商品連動メール（お気に入り）" />
                    <Input name="campaigns" key="prospect" value="prospect" type="multicheckbox" option="ポップアップメール" />
                  </FieldGroup>
                </FormGroup>
                <div className="text-center">
                  <Button color="primary" type="submit" size="lg">検索</Button>
                </div>
              </Form>
            )}
          </Formik>
        </CardBody>
      </Card>
      {
        campaigns.length > 0 && (
          <>
            <h4>動的タグの必須設置ページ</h4>
            { requiredCodes(campaigns).map(code => code)}
            <h4>動的タグの任意設置ページ</h4>
            { optionalCodes(campaigns).map(code => code)}
          </>
        )
      }
    </ContentWrapper>
  )
}

export default connect()(TrackingCodePage)
