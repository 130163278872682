import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import {
  Table, Button, CardBody, CardFooter,
} from 'reactstrap'
import {
  Loading, MailPreviewThumbnail, Pagination, Swal,
} from 'lib/components'
import { generalConstant as gc } from 'config/constants'
import { adminCampaignTemplateAction } from 'actions'

export const AdminCampaignTemplateList = ({
  adminCampaignTemplate, limit = gc.campaignItemsLimit, offset = 0, dispatch,
}) => {
  const targetItems = [...adminCampaignTemplate.items].filter((cam, index) => campaignTemplateFilter(cam))
  return (
    <Loading isLoading={adminCampaignTemplate.isFetching}>
      <CardBody>
        <Table responsive>
          <thead>
            <tr>
              <th>サムネイル</th>
              <th>テンプレート名</th>
              <th>表示順(昇順で表示されます)</th>
              <th>概要</th>
              <th>&nbsp;</th>
              <th>&nbsp;</th>
              <th>&nbsp;</th>
            </tr>
          </thead>
          <tbody className="no-thead">
            {
              adminCampaignTemplate.items
                .sort((a, b) => (a.displayOrder > b.displayOrder))
              // ページネーション用filter
                .filter((cam, index) => (index >= offset && index < offset + limit))
                .map(cam => (
                  <tr key={`campaignTempalte_${cam.id}`}>
                    <td>
                      <MailPreviewThumbnail type="campaign_templates" id={cam.id} />
                    </td>
                    <td className="campaign-overview">
                      <h5>{cam.title}</h5>
                      {cam.templateTags.map(tag => (
                        <span key={`tag_${tag.id}`} className="badge badge-purple">{tag.name}</span>
                      ))}
                    </td>
                    <td>
                      {cam.displayOrder}
                    </td>
                    <td>
                      {cam.description}
                    </td>
                    <td className="text-center">
                      <Button tag={Link} to={`/admin/campaign_template_edit/${cam.id}`} color="secondary">編集</Button>
                    </td>
                    <td className="text-center">
                      <Swal
                        options={gc.deleteSwalOptions}
                        color="danger"
                        callback={(isConfirm) => {
                          isConfirm && dispatch(adminCampaignTemplateAction.submitDeleteCampaignTemplate({ id: cam.id }))
                        }}
                      >
                    削除
                      </Swal>
                    </td>
                    <td className="text-center">
                      <label className="switch switch-lg">
                        <input
                          type="checkbox"
                          defaultChecked={cam.enabled}
                          onChange={() => {
                            dispatch(adminCampaignTemplateAction.submitUpdateCampaignTemplate({
                              ...cam,
                              enabled: !cam.enabled,
                              templateTagsAttributes: cam.templateTags,
                            }))
                          }}
                        />
                        <span />
                      </label>
                    </td>
                  </tr>
                ))
            }
          </tbody>
        </Table>
      </CardBody>
      <CardFooter>
        <Pagination
          totalItemCount={targetItems.length}
          limit={limit}
        />
      </CardFooter>
    </Loading>
  )
}

// 削除された要素(undefined)をスキップ
const campaignTemplateFilter = cam => !!cam

const select = ({ adminCampaignTemplate }) => ({ adminCampaignTemplate })

export default connect(select)(AdminCampaignTemplateList)
