import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'

import {
  Alert, Button, FormGroup, Input, FormText, UncontrolledCollapse, UncontrolledTooltip,
} from 'reactstrap'
import {
  Loading, FieldSingle, Label, FieldGroup, InputGroupAddon, MailPreviewButton, HelpIcon, HintIcon,
} from 'lib/components'
import { contextualCampaignConstant as ccc } from 'config/constants'
import { arraysToObject } from 'lib/utils'
import { contextualCampaignAction, mailPreviewAction } from 'actions'
import CampaignMailCodeHelp from 'components/parts/campaign/CampaignMailCodeHelp'
import CampaignHtmlFields from 'components/parts/campaign/CampaignHtmlFields'
import ManualLink from 'components/parts/manual/ManualLink'
import ValidateDomainButton from 'components/parts/common/ValidateDomainButton'

// constants
const contextualTypes = arraysToObject(ccc.contextualTypes, 2, 1)
const previousContextualTypes = [contextualTypes.priceReduced, contextualTypes.stockReduced, contextualTypes.restock]
const followingContextualTypes = [contextualTypes.priceReducedStep, contextualTypes.stockReducedStep, contextualTypes.restockStep]

const validationSchema = Yup.object().shape({
  name: Yup.string().required(),
  triggerType: Yup.number().required(),
  triggerCampaignId: Yup.number()
    .when('triggerType', {
      is: triggerType => (followingContextualTypes.includes(triggerType)),
      then: Yup.number().required('該当する先行キャンペーンが存在しません'),
    }),
  threshold: Yup.number()
    .when('triggerType', {
      is: triggerType => (
        [contextualTypes.priceReduced, contextualTypes.stockReduced].includes(triggerType)
      ),
      then: Yup.number().required(),
    }),
  targetAction: Yup.number(),
  purchaseHistory: Yup.string().required(),
  customerType: Yup.number().required(),
  gender: Yup.string().required(),
  schedule: Yup.number()
    .when('triggerType', {
      is: triggerType => (followingContextualTypes.includes(triggerType)),
      then: Yup.number().required(),
    }),
  stopIfAddCart: Yup.bool(),
  fromName: Yup.string().notIncludeAngleBrackets().required(),
  fromAddress: Yup.string().required(),
  subject: Yup.string().required(),
  previewText: Yup.string(),
  urlParam: Yup.string(),
  htmlBody: Yup.string(),
  textBody: Yup.string(),
  htmlHeader: Yup.string(),
  style: Yup.string(),
})

export const ContextualCampaignForm = ({
  contextualCampaign, company, companyCampaignTemplateValue, mailSenderInfo, item, submitAction, dispatch,
}) => {
  const priceReducedCampaignList = contextualCampaign.items
    .filter(x => (x.triggerType === contextualTypes.priceReduced && x.id !== item.id))
    .map(x => ([x.name, x.id]))
  const stockReducedCampaignList = contextualCampaign.items
    .filter(x => (x.triggerType === contextualTypes.stockReduced && x.id !== item.id))
    .map(x => ([x.name, x.id]))
  const restockCampaignList = contextualCampaign.items
    .filter(x => (x.triggerType === contextualTypes.restock && x.id !== item.id))
    .map(x => ([x.name, x.id]))
  let initialTargetAction
  if (item.addToCart) { initialTargetAction = 2 } else if (item.view) { initialTargetAction = 3 } else { initialTargetAction = 1 }
  return (
    <Loading isLoading={contextualCampaign.isFetching || company.isFetching || mailSenderInfo.isFetching}>
      <Formik
        initialValues={{
          name: item.name || '',
          triggerType: item.triggerType || 0,
          triggerCampaignId: item.triggerCampaignId || undefined,
          targetAction: initialTargetAction,
          threshold: item.threshold || 0,
          purchaseHistory: item.purchaseHistory || 'any_purchased_count',
          customerType: item.customerType || 0,
          gender: item.gender || 'all_gender',
          schedule: item.schedule || undefined,
          stopIfAddCart: item.stopIfAddCart || false,
          fromName: item.fromName || '',
          fromAddress: item.fromAddress || '',
          subject: item.subject || '',
          previewText: item.previewText || '',
          urlParam: item.urlParam || '',
          htmlBody: item.htmlBody || '',
          textBody: item.textBody || '',
          htmlHeader: item.htmlHeader || '',
          style: item.style || '',
          testAddress: '',
        }}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          const submitValues = convertToSubmitValues(values)
          if (submitAction === 'update') {
            dispatch(contextualCampaignAction.submitUpdateContextualCampaign({ id: item.id, ...submitValues }))
          } else {
            dispatch(contextualCampaignAction.submitCreateContextualCampaign({ ...submitValues, enabled: false }))
          }
        }}
      >
        {({ values, setFieldValue, errors }) => (
          <Form noValidate>
            { contextualCampaign.error && <Alert color="danger">{contextualCampaign.error}</Alert> }
            <fieldset>
              <FormGroup>
                <Label require>キャンペーン名称</Label>
                <FieldSingle name="name" type="text" />
              </FormGroup>
              <FormGroup>
                <Label require>ターゲット商品属性</Label>
                <FieldSingle
                  type="select"
                  name="triggerType"
                  options={ccc.contextualTypes}
                  value={() => defaultTargetAction(item.templateTags, values)}
                  disabled={item.stage === 'in_the_middle' || item.stage === 'finished'}
                />
                <FormText>
                  ステップメール（2通目以降）を作成する際は、（後続）が付いたアクションを選択してください。
                </FormText>
              </FormGroup>
              { [contextualTypes.priceReduced, contextualTypes.stockReduced].includes(Number(values.triggerType))
                && (
                  <FormGroup>
                    <Label require>しきい値</Label>
                    { Number(values.triggerType) === contextualTypes.priceReduced && (
                      <>
                        <HelpIcon id="thresholdPriceTooltip" className="ml-1" />
                        <UncontrolledTooltip placement="right" target="thresholdPriceTooltip">
                          メール送信条件となる値下げ率のしきい値を%単位で⼊⼒してください。<br />
                          （ 値下げ率が 1%未満でもメールを送信する場合は 0 を設定してください）
                        </UncontrolledTooltip>
                      </>
                    )}
                    { Number(values.triggerType) === contextualTypes.stockReduced && (
                      <>
                        <HelpIcon id="thresholdStockTooltip" className="ml-1" />
                        <UncontrolledTooltip placement="right" target="thresholdStockTooltip">
                          メール送信条件となる商品在庫の値を⼊⼒してください。
                        </UncontrolledTooltip>
                      </>
                    )}
                    <FieldSingle type="text" name="threshold" />
                  </FormGroup>
                )
              }
              { previousContextualTypes.includes(Number(values.triggerType))
                && (
                  <FormGroup>
                    <Label require>ターゲットアクション</Label>
                    <FieldSingle type="select" name="targetAction" options={ccc.targetActions} />
                  </FormGroup>
                )
              }
              { followingContextualTypes.includes(Number(values.triggerType))
                && (
                  <>
                    <FormGroup>
                      <Label require>先行キャンペーン</Label>
                      {(() => {
                        let triggerCampaignList
                        switch (Number(values.triggerType)) {
                        case contextualTypes.priceReducedStep:
                          triggerCampaignList = priceReducedCampaignList
                          break
                        case contextualTypes.stockReducedStep:
                          triggerCampaignList = stockReducedCampaignList
                          break
                        case contextualTypes.restockStep:
                          triggerCampaignList = restockCampaignList
                          break
                        default:
                          triggerCampaignList = []
                        }
                        if (triggerCampaignList.length < 1) {
                          triggerCampaignList.push(['', undefined])
                        }
                        return (<FieldSingle type="select" name="triggerCampaignId" options={triggerCampaignList} />)
                      })()}
                      <FormText>
                        ステップメールの対象となる1通⽬のキャンペーンを選択してください。
                      </FormText>
                    </FormGroup>
                    <FormGroup>
                      <Label require>1通目送信からの経過時間</Label>
                      <FieldSingle type="select" name="schedule" options={ccc.scheduleTimes} />
                    </FormGroup>
                  </>
                )
              }
            </fieldset>
            <legend className="mb-0">ターゲット顧客属性<HintIcon id="segmentPointTooltip" className="ml-1" style={{ 'font-size': '14px' }} /></legend>
            <UncontrolledTooltip placement="right" target="segmentPointTooltip">
              ️施策に適した傾向を持つ顧客を配信対象に指定することで、成果指標（開封率・クリック率・完了率）を引き上げる効果が期待できます！
            </UncontrolledTooltip>
            <FormText className="mb-3" tag="span">
              セグメント別配信の設定メニューです。
            </FormText>
            <fieldset>
              <FormGroup>
                <Label require>購入履歴</Label>
                <FieldSingle type="select" name="purchaseHistory" options={ccc.purchaseHistories} />
              </FormGroup>
              <FormGroup>
                <Label require>会員種別</Label>
                <FieldSingle type="select" name="customerType" options={ccc.customerTypes} />
              </FormGroup>
              <FormGroup>
                <Label require>性別</Label>
                <FieldSingle type="select" name="gender" options={ccc.gender} />
              </FormGroup>
              {
                [1, 3].includes(realTargetAction(
                  values.targetAction,
                  values.triggerType,
                  values.triggerCampaignId,
                  contextualCampaign.items,
                )) && (
                  <FormGroup check>
                    <FieldGroup>
                      <Input name="stopIfAddCart" type="checkbox" option="ターゲットアクション(後続メールの場合は1通目送信後)からメール配信時までにカート投入した場合メールを送信しない" />
                      <HelpIcon id="stopIfAddCartTooltip" className="ml-1" />
                      <UncontrolledTooltip placement="right" target="stopIfAddCartTooltip">
                        カートに⼊れたお客さまの場合、メールを送るか停⽌するかを設定します。
                      </UncontrolledTooltip>
                    </FieldGroup>
                  </FormGroup>
                )
              }
            </fieldset>
            <legend className="mb-3">メール設定</legend>
            <fieldset>
              <FormGroup>
                <Label require>差出人名</Label>
                <FieldSingle name="fromName" type="text" />
              </FormGroup>
              <FormGroup>
                <Label require>Reply-toアドレス</Label>
                <HelpIcon id="fromAddressTooltip" className="ml-1" />
                <UncontrolledTooltip placement="right" target="fromAddressTooltip">
                  SPFレコード設定が必須となります。「SPFチェック」ボタンを押すと、入力したアドレスのSPFレコードが正しく設定できているか確認することができます。
                </UncontrolledTooltip>
                <FieldGroup>
                  <Input name="fromAddress" type="text" />
                  <InputGroupAddon addonType="append">
                    <ValidateDomainButton email={values.fromAddress} />
                  </InputGroupAddon>
                </FieldGroup>
                <FormText>
                  お客様がリタゲメールに返信した場合の返信先メールアドレスです。
                  <Link to="/campaign_common_setting">全体の初期設定はこちら</Link>
                </FormText>
                <FormText>
                  <ManualLink filename="mansys_dns">
                    DNS設定について
                  </ManualLink>
                </FormText>
              </FormGroup>
              <FormGroup>
                <Label require>件名</Label>
                <HintIcon id="subjectPointTooltip" className="ml-1" />
                <UncontrolledTooltip placement="right" target="subjectPointTooltip">
                  15〜30文字がおすすめです。適切に設定することで開封率がアップする可能性があります！
                </UncontrolledTooltip>
                <FieldSingle name="subject" type="text" />
              </FormGroup>
              <FormGroup>
                <Label>プレビューテキスト</Label>
                <HelpIcon id="previewTextTooltip" className="ml-1" />
                <UncontrolledTooltip placement="right" target="previewTextTooltip">
                  メール本⽂の概要（ダイジェスト）として受信箱内に表⽰されるテキストです。<br />
                  ※設定されてない場合、メール本⽂のテキスト情報を先頭から順に読み取り表⽰します。
                </UncontrolledTooltip>
                <HintIcon id="previewTextPointTooltip" className="ml-1" />
                <UncontrolledTooltip placement="right" target="previewTextPointTooltip">
                  統⼀性をもたせ不信感を減らし開封率を引き上げる効果が期待できます！
                </UncontrolledTooltip>
                <FieldSingle name="previewText" type="text" />
              </FormGroup>
              <FormGroup>
                <Label>URLパラメータ</Label>
                <HelpIcon id="urlParamTooltip" className="ml-1" />
                <UncontrolledTooltip placement="right" target="urlParamTooltip">
                  キャンペーン内のリンクに自動で付与するURLパラメータを設定できます。<br />
                  外部のアクセス解析ソフトの計測用パラメータの設定などにご利用ください。
                </UncontrolledTooltip>
                <FieldSingle name="urlParam" type="text" placeholder="param1=value2&param2=value2" />
              </FormGroup>
            </fieldset>
            <legend className="mb-3">メール内容</legend>
            <fieldset>
              <CampaignHtmlFields
                company={company}
                companyCampaignTemplateValue={companyCampaignTemplateValue}
                formType="contextual"
                campaignType="contextual"
                submitAction={submitAction}
                values={values}
                setFieldValue={setFieldValue}
                dispatch={dispatch}
              />
              <FormGroup>
                <Label>テキストメール用本文</Label>
                <FieldSingle name="textBody" type="textarea" style={{ minHeight: 200 }} />
                <FormText>
                  <Link to="/campaign_common_setting">テキストパートの配信設定はこちら</Link>
                </FormText>
              </FormGroup>
              <div className="mb-5">
                <Button
                  color="secondary"
                  id="codeHelpToggler2"
                  onClick={() => {}}
                >
                  {' '}
コードのヘルプを表示する
                </Button>
              </div>
              <UncontrolledCollapse toggler="#codeHelpToggler2" className="mb-5">
                <CampaignMailCodeHelp campaignType="contextual" />
              </UncontrolledCollapse>
              <div className="mb-5">
                <MailPreviewButton
                  color="info"
                  onClickCallback={() => { dispatch(mailPreviewAction.submitCreateMailPreview({ ...values, _type: 'contextual' })) }}
                >
メールのプレビュー
                </MailPreviewButton>
              </div>
              <FormGroup>
                <Label>テストメール送信先アドレス</Label>
                <FieldGroup>
                  <Input name="testAddress" type="text" />
                  <InputGroupAddon addonType="append">
                    <Button
                      color="info"
                      onClick={(e) => { dispatch(contextualCampaignAction.submitSendContextualCampaignTestMail(values)) }}
                    >
テストメール送信
                    </Button>
                  </InputGroupAddon>
                </FieldGroup>
                <FormText>カンマ区切りで複数入力可能</FormText>
                <FormText>※メール文面に表示されるアイテム情報などは、ランダムに抽出されたものが表示されます。</FormText>
              </FormGroup>
            </fieldset>
            <div className="text-center"><Button color="primary" type="submit" size="lg">保存</Button></div>
          </Form>
        )}
      </Formik>
    </Loading>
  )
}

// 後続キャンペーンの場合、
// フォーム上のターゲットアクションが
// 実際のターゲットアクションと一致しない可能性があるため、
// 以下メソッドで実際のターゲットアクションを導く
const realTargetAction = (formTargetAction, triggerType, triggerCampaignId, allContextualCampaigns) => {
  // 後続キャンペーンでなければformの値を返す
  if (!followingContextualTypes.includes(Number(triggerType))) {
    return Number(formTargetAction)
  }

  const triggerCampaign = allContextualCampaigns.find(x => x.id === Number(triggerCampaignId))
  if (!triggerCampaign) {
    return Number(formTargetAction)
  }
  return triggerCampaign.targetAction
}

const convertToSubmitValues = (values) => {
  const { testAddress, ...restValues } = values
  return { ...restValues }
}

const defaultTargetAction = (templateTags, values) => {
  if (templateTags) {
    const mappingNames = ccc.tagMappingContextualTypes.map(m => m.name)
    const mappedTag = templateTags.filter(tag => mappingNames.includes(tag.name))[0]
    if (mappedTag) {
      const triggerType = ccc.tagMappingContextualTypes.filter(m => m.name === mappedTag.name)[0].value
      values.triggerType = triggerType
      return triggerType
    }
  }
  return values.triggerType
}

const select = ({
  contextualCampaign, company, companyCampaignTemplateValue, mailSenderInfo, campaignTemplate,
}, { campaignId, templateId, submitAction }) => {
  let item
  let mailContentOverride = {
    fromAddress: mailSenderInfo.item.replyToEmail,
    fromName: mailSenderInfo.item.fromName,
  }
  let campaignContentOverride
  if (templateId) {
    const template = campaignTemplate.items.filter(obj => (obj.id === templateId))[0] || {}
    campaignContentOverride = {
      subject: template.subject,
      htmlBody: template.htmlBody,
      textBody: template.textBody,
      htmlHeader: template.htmlHeader,
      style: template.style,
      templateTags: template.templateTags,
    }
  }
  if (campaignId) {
    campaignContentOverride = contextualCampaign.items.filter(obj => (obj.id === campaignId))[0] || {}
  }
  item = { ...mailContentOverride, ...campaignContentOverride }
  if (submitAction === 'create') {
    item = { ...item, enabled: false, stage: 'in_preparation' }
  }
  return {
    contextualCampaign, company, companyCampaignTemplateValue, mailSenderInfo, item, submitAction,
  }
}

export default connect(select)(ContextualCampaignForm)
