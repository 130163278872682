import { createActions } from 'redux-actions'

const FETCH_CAMPAIGN_TEMPLATES = 'FETCH_CAMPAIGN_TEMPLATES'
const FETCH_CAMPAIGN_TEMPLATES_DONE = 'FETCH_CAMPAIGN_TEMPLATES_DONE'

const SUBMIT_UPDATE_CAMPAIGN_TEMPLATE = 'SUBMIT_UPDATE_CAMPAIGN_TEMPLATE'
const SUBMIT_UPDATE_CAMPAIGN_TEMPLATE_DONE = 'SUBMIT_UPDATE_CAMPAIGN_TEMPLATE_DONE'

const SUBMIT_CREATE_CAMPAIGN_TEMPLATE = 'SUBMIT_CREATE_CAMPAIGN_TEMPLATE'
const SUBMIT_CREATE_CAMPAIGN_TEMPLATE_DONE = 'SUBMIT_CREATE_CAMPAIGN_TEMPLATE_DONE'

const SUBMIT_DELETE_CAMPAIGN_TEMPLATE = 'SUBMIT_DELETE_CAMPAIGN_TEMPLATE'
const SUBMIT_DELETE_CAMPAIGN_TEMPLATE_DONE = 'SUBMIT_DELETE_CAMPAIGN_TEMPLATE_DONE'

export const adminCampaignTemplateAction = createActions(
  {
    [FETCH_CAMPAIGN_TEMPLATES_DONE]: [
      (...args) => args[0],
      (...args) => args[1],
    ],
    [SUBMIT_UPDATE_CAMPAIGN_TEMPLATE_DONE]: [
      (...args) => args[0],
      (...args) => Object.assign({ notification: 'ALL_CASE' }, args[1]),
    ],
    [SUBMIT_CREATE_CAMPAIGN_TEMPLATE_DONE]: [
      (...args) => args[0],
      (...args) => Object.assign({ notification: 'ALL_CASE' }, args[1]),
    ],
    [SUBMIT_DELETE_CAMPAIGN_TEMPLATE_DONE]: [
      (...args) => args[0],
      (...args) => Object.assign({ notification: 'ALL_CASE' }, args[1]),
    ],
  },
  FETCH_CAMPAIGN_TEMPLATES,
  SUBMIT_UPDATE_CAMPAIGN_TEMPLATE,
  SUBMIT_CREATE_CAMPAIGN_TEMPLATE,
  SUBMIT_DELETE_CAMPAIGN_TEMPLATE,
  {
    prefix: 'ADMIN',
  },
)
