import React, { Fragment } from 'react'
import { generalConstant as gc } from 'config/constants'

const CompanyStatusBadge = ({ status }) => {
  if (!status) {
    return <Fragment />
  }
  const statusProp = gc.companyStatuses[status]
  return (
    <span className={`badge badge-${statusProp.class}`}>
      { statusProp.label }
    </span>
  )
}

export default CompanyStatusBadge
