import { createActions } from 'redux-actions'

const SUBMIT_UPDATE_SUPPRESSION_SETTING = 'SUBMIT_UPDATE_SUPPRESSION_SETTING'
const SUBMIT_UPDATE_SUPPRESSION_SETTING_DONE = 'SUBMIT_UPDATE_SUPPRESSION_SETTING_DONE'

export const suppressionSettingAction = createActions(
  {
    [SUBMIT_UPDATE_SUPPRESSION_SETTING_DONE]: [
      (...args) => args[0],
      (...args) => Object.assign({ notification: 'ALL_CASE' }, args[1]),
    ],
  },
  SUBMIT_UPDATE_SUPPRESSION_SETTING,
)
