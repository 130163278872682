import { createActions } from 'redux-actions'

const FETCH_BATCH_STATUS_CUSTOMER_IMPORTS = 'FETCH_BATCH_STATUS_CUSTOMER_IMPORTS'
const FETCH_BATCH_STATUS_CUSTOMER_IMPORTS_DONE = 'FETCH_BATCH_STATUS_CUSTOMER_IMPORTS_DONE'

export const batchStatusCustomerImportAction = createActions(
  {
    [FETCH_BATCH_STATUS_CUSTOMER_IMPORTS_DONE]: [
      (...args) => args[0],
      (...args) => args[1],
    ],
  },
  FETCH_BATCH_STATUS_CUSTOMER_IMPORTS,
)
