import React from 'react'

const PasswordStrengthBadge = ({ password, minLength }) => {
  let strength = 0
  if (password.length >= minLength) {
    strength += 1
    // 英字の大文字と小文字を含んでいれば+1
    if (password.match(/([a-z].*[A-Z])|([A-Z].*[a-z])/)) strength += 1
    // 英字と数字を含んでいれば+1
    if (password.match(/([a-zA-Z])/) && password.match(/([0-9])/)) strength += 1
    // 記号を含んでいれば+1
    if (password.match(/([!,%,&,@,#,$,^,*,?,_,~])/)) strength += 1
    // 記号を2つ含んでいれば+1
    if (password.match(/(.*[!,%,&,@,#,$,^,*,?,_,~].*[!,%,&,@,#,$,^,*,?,_,~])/)) strength += 1
  }

  const strengthSet = [
    { color: 'inverse', message: '文字数不足' },
    { color: 'danger', message: '非常に弱い' },
    { color: 'warning', message: '弱い' },
    { color: 'info', message: '普通' },
    { color: 'green', message: '強い' },
    { color: 'success', message: '非常に強い' },
  ]

  const { color, message } = strengthSet[strength]

  return (
    <div>
      <span className={`badge badge-${color}`}>{message}</span>
    </div>
  )
}

export default PasswordStrengthBadge
