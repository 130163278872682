import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { adminCustomerImportSettingAction } from 'actions'

const CustomerImportSettingRow = ({ adminCustomerImportSetting, dispatch }) => {
  if (Object.keys(adminCustomerImportSetting.item).length === 0) {
    return <Fragment />
  }
  const item = { ...adminCustomerImportSetting.item }
  return (
    <tr>
      <th>
        <h6>宛先マスタ連携設定</h6>
        <span className={`badge badge-${item.hideSetting ? 'warning' : 'primary'}`}>
          設定画面を顧客に表示して{item.hideSetting ? 'いません' : 'います'}
        </span>
      </th>
      <td>
        <label className="switch switch-lg">
          <input
            type="checkbox"
            defaultChecked={item.hideSetting}
            value={item.hideSetting}
            onChange={() => {
              dispatch(adminCustomerImportSettingAction.submitUpdateCustomerImportSetting({
                ...item,
                hideSetting: !item.hideSetting,
              }))
            }}
          />
          <span />
        </label>
      </td>
    </tr>
  )
}

const select = ({ adminCustomerImportSetting }) => ({ adminCustomerImportSetting })

export default connect(select)(CustomerImportSettingRow)
