import React from 'react'
import { connect } from 'react-redux'
import ContentWrapper from 'components/layout/ContentWrapper'
import {
  Card, CardBody, CardHeader,
} from 'reactstrap'
import { useMountEffect } from 'lib/hooks'
import CompanyTable from 'components/parts/company/CompanyTable'
import CustomerImportSettingRow from 'components/parts/company/CustomerImportSettingRow'
import ItemImportSettingRow from 'components/parts/company/ItemImportSettingRow'
import StatusTable from 'components/parts/company/StatusTable'
import SystemOrderTable from 'components/parts/company/SystemOrderTable'
import TerminateCompanyCard from 'components/parts/company/TerminateCompanyCard'
import CompanyEditForm from 'components/parts/company/CompanyEditForm'
import {
  adminCompanyAction, adminCompanyStatusAction, adminCompanySystemOrderAction, adminRecoAccountAction,
  adminCompanyTrackingOptionAction, adminCustomerImportSettingAction, adminItemImportSettingAction,
} from 'actions'

const companyDispatchActions = [
  adminCompanySystemOrderAction.fetchCompanySystemOrder,
  adminItemImportSettingAction.fetchItemImportSetting,
  adminCustomerImportSettingAction.fetchCustomerImportSetting,
  adminCompanyTrackingOptionAction.fetchCompanyTrackingOption,
  adminCompanyStatusAction.fetchCompanyStatus,
  adminRecoAccountAction.fetchRecoAccounts,
]

const AdminCompanyDetailPage = ({match, dispatch}) => {
  const companyId = Number(match.params.id)

  useMountEffect(() => {
    dispatch(adminCompanyAction.fetchCompanies())
    companyDispatchActions.forEach((action) => {
      dispatch(action({ companyId }))
    })
  })

  return (
    <ContentWrapper>
      <div className="content-heading">
        <div>登録企業詳細</div>
      </div>
      <Card className="card-default">
        <CompanyTable companyId={companyId}/>
      </Card>
      <Card className="card-default">
        <SystemOrderTable/>
      </Card>
      <Card className="card-default">
        <CompanyEditForm companyId={companyId}/>
      </Card>
      <Card className="card-default">
        <StatusTable  companyId={companyId}/>
      </Card>
      <Card className="card-default">
        <CardHeader>マスタ連携設定画面非表示設定</CardHeader>
        <CardBody>
          <table>
            <tbody>
              <ItemImportSettingRow companyId={companyId}/>
              <CustomerImportSettingRow companyId={companyId}/>
            </tbody>
          </table>
        </CardBody>
      </Card>
      <TerminateCompanyCard />
    </ContentWrapper>
  )
}

export default connect()(AdminCompanyDetailPage)
