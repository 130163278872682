import {
  call, fork, join, put, select, take,
} from 'redux-saga/effects'
import { adminAuthAction } from 'actions'
import * as api from 'services/adminApi'
import { push } from 'connected-react-router'
import { handleFetchCurrentUser } from './userSaga'

const authSelector = state => state.adminAuth
const userSelector = state => state.adminUser

function* watchSubmitLogout() {
  while (true) {
    yield take(adminAuthAction.submitLogout)
    const authState = yield select(authSelector)
    if (authState.isLoggedIn) {
      yield fork(handleSubmitLogout)
    }
  }
}

function* handleSubmitLogout() {
  const { payload, meta, error } = yield call(api.submitLogout)
  yield put(adminAuthAction.submitLogoutDone(payload, meta))
  if (!error) yield put(push('/admin/login'))
}

function* watchFetchLogin() {
  while (true) {
    const { payload } = yield take(adminAuthAction.fetchLogin)
    yield fork(handleFetchLogin, payload.email, payload.password)
  }
}

function* handleFetchLogin(email, password) {
  const { payload, meta, error } = yield call(api.fetchLogin, email, password)
  yield put(adminAuthAction.fetchLoginDone(payload, meta))
  if (!error) yield fork(handleFetchCurrentUser)
}

function* watchCheckLogin() {
  while (true) {
    yield take(adminAuthAction.checkLogin)
    yield fork(handleCheckLogin)
  }
}

function* handleCheckLogin() {
  const task = yield fork(handleFetchCurrentUser)

  yield join(task)

  const userState = yield select(userSelector)

  if (userState.processResult === 'SUCCESS') {
    yield put(adminAuthAction.checkLoginDone(true))
  } else {
    yield put(adminAuthAction.checkLoginDone(new Error()))
  }
}

export const adminAuthSaga = [
  fork(watchCheckLogin),
  fork(watchFetchLogin),
  fork(watchSubmitLogout),
]
