import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Input, Row, Col } from 'reactstrap'

const API_ROOT = process.env.REACT_APP_API_ROOT

export const MailPreview = ({ mailPreview, source = 'src', type, id }) => {
  const [device, setDevice] = useState('pc')
  const [part, setPart] = useState('html')
  return (
    <>
      <Row className="mb-3">
        <Col md={3}>
          <Input
            type="select"
            name="device"
            value={device}
            onChange={(e) => setDevice(e.target.value)}
          >
            <option value="pc">PCサイズ</option>
            <option value="sp">スマホサイズ</option>
          </Input>
        </Col>
        <Col md={3}>
          <Input
            type="select"
            name="device"
            value={part}
            onChange={(e) => setPart(e.target.value)}
          >
            <option value="html">HTMLパート</option>
            <option value="text">テキストパート</option>
          </Input>
        </Col>
      </Row>
      <div className={`${device}-preview`}>
        { (part === 'html' && source === 'src')
          && (
            <iframe
              title="MailPreview"
              frameBorder="0"
              sandbox="allow-scripts"
              src={`${API_ROOT}/api/${type}/${id}/preview`}
            />
          )
        }
        { (part === 'html' && source === 'doc')
          && (
            <iframe
              title="MailPreview"
              frameBorder="0"
              sandbox="allow-scripts"
              srcDoc={mailPreview.item.html}
            />
          )
        }
        { (part === 'text' && source === 'src')
          && (
            <iframe
              title="MailPreview"
              frameBorder="0"
              sandbox="allow-scripts"
              src={`${API_ROOT}/api/${type}/${id}/preview.text`}
            />
          )
        }
        { (part === 'text' && source === 'doc')
          && (
            <iframe
              title="MailPreview"
              frameBorder="0"
              sandbox="allow-scripts"
              srcDoc={previewText(mailPreview.item.text)}
            />
          )
        }
      </div>
      <p className="mt-1 mb-0">
        <small>
          ※配信されるメールとは表示が異なる場合があります。配信前にテストメールを送信し、メールアプリで確認することをお勧めします。
          <br />
          ※メール文面に表示されるアイテム情報などは、ランダムに抽出されたものが表示されます。
        </small>
      </p>
    </>
  )
}

const previewText = text => `
<pre>
${text}
</pre>
`

const select = ({ mailPreview }, ownProps) => ({ mailPreview, ...ownProps })

export default connect(select)(MailPreview)
