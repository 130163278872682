import { createActions } from 'redux-actions'

const FETCH_PAGE_RESOURCES = 'FETCH_PAGE_RESOURCES'
const FETCH_PAGE_RESOURCES_DONE = 'FETCH_PAGE_RESOURCES_DONE'

export const pageResourceAction = createActions(
  {
    [FETCH_PAGE_RESOURCES_DONE]: [
      (...args) => args[0],
      (...args) => args[1],
    ],
  },
  FETCH_PAGE_RESOURCES,
)
