import { createActions } from 'redux-actions'

const FETCH_TRACKING_SETTINGS = 'FETCH_TRACKING_SETTINGS'
const FETCH_TRACKING_SETTINGS_DONE = 'FETCH_TRACKING_SETTINGS_DONE'

const SUBMIT_UPDATE_TRACKING_SETTING = 'SUBMIT_UPDATE_TRACKING_SETTING'
const SUBMIT_UPDATE_TRACKING_SETTING_DONE = 'SUBMIT_UPDATE_TRACKING_SETTING_DONE'

const SUBMIT_CREATE_TRACKING_SETTING = 'SUBMIT_CREATE_TRACKING_SETTING'
const SUBMIT_CREATE_TRACKING_SETTING_DONE = 'SUBMIT_CREATE_TRACKING_SETTING_DONE'

export const trackingSettingAction = createActions(
  {
    [FETCH_TRACKING_SETTINGS_DONE]: [
      (...args) => args[0],
      (...args) => args[1],
    ],
    [SUBMIT_UPDATE_TRACKING_SETTING_DONE]: [
      (...args) => args[0],
      (...args) => Object.assign({ notification: 'ALL_CASE' }, args[1]),
    ],
    [SUBMIT_CREATE_TRACKING_SETTING_DONE]: [
      (...args) => args[0],
      (...args) => Object.assign({ notification: 'ALL_CASE' }, args[1]),
    ],
  },

  FETCH_TRACKING_SETTINGS,

  SUBMIT_UPDATE_TRACKING_SETTING,
  SUBMIT_CREATE_TRACKING_SETTING,

)
