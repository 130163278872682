import React from 'react'
import { connect } from 'react-redux'
import { Formik, Form } from 'formik'
import { Button, UncontrolledTooltip, FormGroup } from 'reactstrap'
import * as Yup from 'yup'
import { Loading, FieldSingle, HintIcon } from 'lib/components'
import { reportMailSettingAction } from 'actions'


const validationSchema = Yup.object().shape({
  enabled: Yup.string().required(),
})

export const ReportMailSettingForm = ({ reportMailSetting, dispatch }) => (
  <Loading isLoading={reportMailSetting.isFetching}>
    <div className="mb-4" style={{ fontSize: '110%' }}>
      <p>
          以下にチェックを入れると、メインユーザに
        <span className="link-like" id="reportMailSettingTooltip">週次・月次レポートメール</span>
          が送信されます。
        <HintIcon id="mainUserTooltip" />
      </p>
      <UncontrolledTooltip target="mainUserTooltip">
        メインユーザは1名のみ登録可能です。
      </UncontrolledTooltip>
      <UncontrolledTooltip placement="right" target="reportMailSettingTooltip">
        <img src="img/weekly_report_mail.png" alt="weekly report" />
      </UncontrolledTooltip>
    </div>
    <Formik
      initialValues={{
        enabled: reportMailSetting.item.enabled,
      }}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        dispatch(reportMailSettingAction.submitUpdateReportMailSetting(values))
      }}
    >
      {() => (
        <Form noValidate className="form-horizontal">
          <FormGroup check>
            <FieldSingle name="enabled" type="checkbox" option="定期レポートメールを送信する" />
          </FormGroup>
          <div className="mt-3 text-center"><Button color="primary" type="submit" size="lg">保存</Button></div>
        </Form>
      )}
    </Formik>
  </Loading>
)

const select = ({ reportMailSetting }) => ({ reportMailSetting })

export default connect(select)(ReportMailSettingForm)
