import React from 'react'
import { connect } from 'react-redux'
import ContentWrapper from 'components/layout/ContentWrapper'
import { Card, CardBody } from 'reactstrap'
import { useMountEffect } from 'lib/hooks'
import CustomerImportSettingEditFormWrapper from 'components/parts/customerImport/CustomerImportSettingEditFormWrapper'
import { customerImportSettingAction, apiTokenAction, companyAction } from 'actions'

export const CustomerImportSettingEditPage = ({dispatch}) => {
  useMountEffect(() => {
    dispatch(customerImportSettingAction.fetchCustomerImportSetting())
    dispatch(apiTokenAction.fetchApiTokens())
    dispatch(companyAction.fetchCompany())
  })

  return (
    <ContentWrapper>
      <div className="content-heading">
        <div>宛先データ取り込み設定</div>
      </div>
      <Card className="card-default">
        <CardBody>
          <CustomerImportSettingEditFormWrapper />
        </CardBody>
      </Card>
    </ContentWrapper>
  )
}

export default connect()(CustomerImportSettingEditPage)
