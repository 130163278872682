import { createActions } from 'redux-actions'

const FETCH_CONTEXTUAL_CAMPAIGNS = 'FETCH_CONTEXTUAL_CAMPAIGNS'
const FETCH_CONTEXTUAL_CAMPAIGNS_DONE = 'FETCH_CONTEXTUAL_CAMPAIGNS_DONE'

const SUBMIT_UPDATE_CONTEXTUAL_CAMPAIGN = 'SUBMIT_UPDATE_CONTEXTUAL_CAMPAIGN'
const SUBMIT_UPDATE_CONTEXTUAL_CAMPAIGN_DONE = 'SUBMIT_UPDATE_CONTEXTUAL_CAMPAIGN_DONE'

const SUBMIT_ENABLE_CONTEXTUAL_CAMPAIGN = 'SUBMIT_ENABLE_CONTEXTUAL_CAMPAIGN'
const SUBMIT_ENABLE_CONTEXTUAL_CAMPAIGN_DONE = 'SUBMIT_ENABLE_CONTEXTUAL_CAMPAIGN_DONE'

const SUBMIT_CREATE_CONTEXTUAL_CAMPAIGN = 'SUBMIT_CREATE_CONTEXTUAL_CAMPAIGN'
const SUBMIT_CREATE_CONTEXTUAL_CAMPAIGN_DONE = 'SUBMIT_CREATE_CONTEXTUAL_CAMPAIGN_DONE'

const SUBMIT_DELETE_CONTEXTUAL_CAMPAIGN = 'SUBMIT_DELETE_CONTEXTUAL_CAMPAIGN'
const SUBMIT_DELETE_CONTEXTUAL_CAMPAIGN_DONE = 'SUBMIT_DELETE_CONTEXTUAL_CAMPAIGN_DONE'

const SUBMIT_SEND_CONTEXTUAL_CAMPAIGN_TEST_MAIL = 'SUBMIT_SEND_CONTEXTUAL_CAMPAIGN_TEST_MAIL'
const SUBMIT_SEND_CONTEXTUAL_CAMPAIGN_TEST_MAIL_DONE = 'SUBMIT_SEND_CONTEXTUAL_CAMPAIGN_TEST_MAIL_DONE'


export const contextualCampaignAction = createActions(
  {
    [FETCH_CONTEXTUAL_CAMPAIGNS_DONE]: [
      (...args) => args[0],
      (...args) => args[1],
    ],
    [SUBMIT_UPDATE_CONTEXTUAL_CAMPAIGN_DONE]: [
      (...args) => args[0],
      (...args) => Object.assign({ notification: 'ALL_CASE' }, args[1]),
    ],
    [SUBMIT_ENABLE_CONTEXTUAL_CAMPAIGN_DONE]: [
      (...args) => args[0],
      (...args) => Object.assign({ notification: 'ALL_CASE' }, args[1]),
    ],
    [SUBMIT_CREATE_CONTEXTUAL_CAMPAIGN_DONE]: [
      (...args) => args[0],
      (...args) => Object.assign({ notification: 'ALL_CASE' }, args[1]),
    ],
    [SUBMIT_DELETE_CONTEXTUAL_CAMPAIGN_DONE]: [
      (...args) => args[0],
      (...args) => Object.assign({ notification: 'ALL_CASE', succMess: '終了しました。' }, args[1]),
    ],
    [SUBMIT_SEND_CONTEXTUAL_CAMPAIGN_TEST_MAIL_DONE]: [
      (...args) => args[0],
      (...args) => Object.assign(
        { notification: 'ALL_CASE', succMess: 'メール送信しました。', failMess: 'メール送信に失敗しました。' },
        args[1],
      ),
    ],
  },

  FETCH_CONTEXTUAL_CAMPAIGNS,
  SUBMIT_UPDATE_CONTEXTUAL_CAMPAIGN,
  SUBMIT_ENABLE_CONTEXTUAL_CAMPAIGN,
  SUBMIT_CREATE_CONTEXTUAL_CAMPAIGN,
  SUBMIT_DELETE_CONTEXTUAL_CAMPAIGN,
  SUBMIT_SEND_CONTEXTUAL_CAMPAIGN_TEST_MAIL,
)
