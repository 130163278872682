import { handleActions } from 'redux-actions'
import generateHandlerArgs, { preparedHandlers } from 'modules/reducerGenerator'
import { popupAction as action } from 'actions'

const overrideHandlers = {
  [action.submitEnablePopupDone]: preparedHandlers.submitUpdateObjectDone(true),
}

const { handler, initial } = generateHandlerArgs(action, 'popup', overrideHandlers)

export const popup = handleActions(handler, initial)
