import React from 'react'
import { connect } from 'react-redux'
import ContentWrapper from 'components/layout/ContentWrapper'
import {
  Row, Col, Card, CardBody,
} from 'reactstrap'

import { useMountEffect } from 'lib/hooks'
import { popupAction, pageResourceAction } from 'actions'
import PopupForm from 'components/parts/popup/PopupForm'
import ProspectSettingLinkCard from 'components/parts/popup/ProspectSettingLinkCard'
import { deserializeFromQueryString } from 'lib/utils'

export const PopupNewPage = ({ dispatch, location }) => {
  const queryObj = deserializeFromQueryString(location.search) || {}
  const popupId = 'original' in queryObj && Number(queryObj.original)

  useMountEffect(() => {
    if (popupId) {
      dispatch(popupAction.fetchPopups())
    }
    dispatch(pageResourceAction.fetchPageResources({ enabled: true }))
  })

  return (
    <ContentWrapper>
      <div className="content-heading">
        <div>ポップアップ新規作成</div>
      </div>
      <Row>
        <Col xl={12}>
          <Card className="card-default">
            <CardBody>
              <PopupForm
                popupId={popupId}
                submitAction="new"
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col xl={12}>
          <ProspectSettingLinkCard />
        </Col>
      </Row>
    </ContentWrapper>
  )
}

export default connect()(PopupNewPage)
