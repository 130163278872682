/* eslint global-require: 0 */

let exp
if (process.env.REACT_APP_ENV === 'production') {
  exp = require('./configureStore.prod')
} else {
  exp = require('./configureStore.dev')
}
export const history = exp.history
export default exp.default
