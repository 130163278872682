import { Loading } from 'lib/components'
import React from 'react'
import { connect } from 'react-redux'
import { Table } from 'reactstrap'

export const BehaviorSummaryDetailTable = ({ behaviorSummary, cart, browser }) => {
  const details = behaviorSummary.item.detail || []
  return (
    <Loading isLoading={behaviorSummary.isFetching}>
      <Table bordered responsive>
        <thead>
          <tr style={{ wordBreak: 'keep-all' }}>
            <th>年月日</th>
            { cart &&
              <>
                <th>カートUB数</th>
                <th>カゴ落ちUB数</th>
                <th>カゴ落ち率</th>
              </>
            }
            { browser &&
              <>
                <th>アイテムUB数</th>
                <th>ブラウザ放棄UB数</th>
                <th>ブラウザ放棄率</th>
              </>
            }
            <th>配信対象ユーザ数</th>
            <th>抽出率</th>
            <th>受注単価</th>
            <th>ポテンシャル売上</th>
            <th>ヒット率５％</th>
          </tr>
        </thead>
        <tbody>
          { details.map(detail => (
            <tr key={`${detail.name}_${detail.date}`}>
              <td>{detail.date}</td>
              { cart &&
                <>
                  <td>{detail.cartUbCount.toLocaleString()}</td>
                  <td>{detail.abandonedCartCount.toLocaleString()}</td>
                  <td>{Math.round(detail.abandonedCartRate * 1000) / 10}%</td>
                </>
              }
              { browser &&
                <>
                  <td>{detail.viewUbCount.toLocaleString()}</td>
                  <td>{detail.abandonedBrowserCount.toLocaleString()}</td>
                  <td>{Math.round(detail.abandonedBrowserRate * 1000) / 10}%</td>
                </>
              }
              <td>{detail.deliverableCount.toLocaleString()}</td>
              <td>{Math.round(detail.matchingRate * 1000) / 10}%</td>
              <td>¥{detail.averageOrderPrice.toLocaleString()}</td>
              <td>¥{detail.potentialPrice.toLocaleString()}</td>
              <td>¥{Math.round(detail.hitRate5).toLocaleString()}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Loading>
  )
}

const select = ({ behaviorSummary }) => ({ behaviorSummary })

export default connect(select)(BehaviorSummaryDetailTable)
