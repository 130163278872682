import { handleActions } from 'redux-actions'
import generateHandlerArgs from 'modules/reducerGenerator'
import { userAction as action } from 'actions'

const overrideHandlers = {
  [action.submitUpdatePassword]: (state, action) => ({
    ...state,
    isSubmitting: true,
  }),
  [action.submitUpdatePasswordDone]: (state, action) => ({
    ...state,
    isSubmitting: false,
    processResult: action.error ? action.payload : 'SUCCESS',
  }),
}

const { handler, initial } = generateHandlerArgs(action, 'currentUser', overrideHandlers)

export const user = handleActions(handler, initial)
