import { createActions } from 'redux-actions'

const FETCH_COMPANY_TRACKING_OPTION = 'FETCH_COMPANY_TRACKING_OPTION'
const FETCH_COMPANY_TRACKING_OPTION_DONE = 'FETCH_COMPANY_TRACKING_OPTION_DONE'

const SUBMIT_UPDATE_COMPANY_TRACKING_OPTION = 'SUBMIT_UPDATE_COMPANY_TRACKING_OPTION'
const SUBMIT_UPDATE_COMPANY_TRACKING_OPTION_DONE = 'SUBMIT_UPDATE_COMPANY_TRACKING_OPTION_DONE'

export const adminCompanyTrackingOptionAction = createActions(
  {
    [FETCH_COMPANY_TRACKING_OPTION_DONE]: [
      (...args) => args[0],
      (...args) => args[1],
    ],
    [SUBMIT_UPDATE_COMPANY_TRACKING_OPTION_DONE]: [
      (...args) => args[0],
      (...args) => Object.assign({ notification: 'ALL_CASE' }, args[1]),
    ],
  },
  FETCH_COMPANY_TRACKING_OPTION,
  SUBMIT_UPDATE_COMPANY_TRACKING_OPTION,
  {
    prefix: 'ADMIN',
  },
)
