export default {
  terms: [
    ['今週', 'this_week'],
    ['今月', 'this_month'],
    ['先週', 'last_week'],
    ['先月', 'last_month'],
    ['期間を指定', 'free_period'],
  ],
  purchaseCount: [
    ['すべての顧客', 'any_purchased_count'],
    ['購入履歴のない顧客', 'not_purchased'],
    ['購入履歴が１回の顧客', 'purchased_once'],
    ['購入履歴が２回以上の顧客', 'purchased_more_than_once'],
  ],
  behaviors: [
    ['カゴ落ちデータ', 'cart'],
    ['ブラウザ放棄データ', 'browser'],
  ],
  defaultBehaviorSummaryCondition: {
    term: 'this_month',
    purchaseCount: 'any_purchased_count',
    cart: true,
    browser: false,
  },
  cartDisplayIndexes: [
    ['cartUbCount', 'カートUB数', '#5ab1ef'],
    ['abandonedCartCount', 'カゴ落ちUB数', '#f5994e'],
    ['deliverableCount', '配信対象ユーザ数', '#d87a80'],
  ],
  browserDisplayIndexes: [
    ['viewUbCount', 'アイテムUB数', '#5ab1ef'],
    ['abandonedBrowserCount', 'ブラウザ放棄UB数', '#f5994e'],
    ['deliverableCount', '配信対象ユーザ数', '#d87a80'],
  ],
}
