import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import ContentWrapper from 'components/layout/ContentWrapper'
import { Card, CardBody } from 'reactstrap'
import { deserializeFromQueryString } from 'lib/utils'
import ItemList from 'components/parts/item/ItemList'
import ItemSearchConditionForm from 'components/parts/item/ItemSearchConditionForm'
import { itemAction } from "actions"

export const ItemListPage = ({ location, dispatch, history }) => {
  useEffect(() => {
    const queryObj = deserializeFromQueryString(location.search) || {}
    dispatch(itemAction.fetchItems(queryObj))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])
  return (
    <ContentWrapper>
      <div className="content-heading">
        <div>商品一覧</div>
      </div>
      <Card className="card-default">
        <CardBody>
          <ItemSearchConditionForm location={location} history={history} />
        </CardBody>
      </Card>
      <Card className="card-default">
        <ItemList />
      </Card>
    </ContentWrapper>
  )
}

export default connect()(ItemListPage)
