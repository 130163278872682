import {
  call, fork, put, takeEvery, takeLatest,
} from 'redux-saga/effects'
import { push } from 'connected-react-router'
import * as api from 'services/api'
import { contextualCampaignAction } from 'actions'
import { serializeToQueryString } from 'lib/utils'
import { handleNoticeChangeCampaign, handleNoticeEnableCampaign } from './userAccessTrackingSaga'

function* handleFetchContextualCampaigns(action) {
  const { payload, meta } = yield call(api.fetchContextualCampaigns, action.payload)
  yield put(contextualCampaignAction.fetchContextualCampaignsDone(payload, meta))
}

function* handleSubmitUpdateContextualCampaign(action) {
  let { payload, meta, error } = yield call(api.submitUpdateContextualCampaign, action.payload)
  if (!error && ('contextualCampaign' in payload)) { payload = payload.contextualCampaign }
  yield put(contextualCampaignAction.submitUpdateContextualCampaignDone(payload, meta))
  if (!error) {
    yield fork(handleNoticeChangeCampaign, payload)
    yield put(push(`/campaign_list${window.location.search}`))
  }
}

function* handleSubmitEnableContextualCampaign(action) {
  let { payload, meta, error } = yield call(api.submitUpdateContextualCampaign, action.payload)
  if (!error && ('contextualCampaign' in payload)) { payload = payload.contextualCampaign }
  yield put(contextualCampaignAction.submitEnableContextualCampaignDone(payload, meta))
  if (!error) {
    yield fork(handleNoticeEnableCampaign, payload)
  }
}

function* handleSubmitCreateContextualCampaign(action) {
  let { payload, meta, error } = yield call(api.submitCreateContextualCampaign, action.payload)
  yield put(contextualCampaignAction.submitCreateContextualCampaignDone(payload, meta))
  if (!error) yield put(push(`/campaign_list${serializeToQueryString({ deliveryStatus: 'all' })}`))
}

function* handleSubmitDeleteContextualCampaign(action) {
  let { payload, meta, error } = yield call(api.submitDeleteContextualCampaign, {id: action.payload.contextualCampaign.id})
  if (!error) {
    action.payload.contextualCampaign['enable'] = false
    action.payload.contextualCampaign['stage'] = 'finished'
    payload = action.payload
  }
  yield put(contextualCampaignAction.submitDeleteContextualCampaignDone(payload, meta))
}

function* handleSubmitSendContextualCampaignTestMail(action) {
  let payload = {}
  let meta = {}
  if (action.payload.fromAddress && action.payload.testAddress) {
    ({ payload, meta } = yield call(api.submitSendContextualCampaignTestMail, action.payload))
  } else {
    payload = new Error()
    meta.failMess = '「Reply-toアドレス」「テストメール送信先アドレス」を入力してください'
  }
  yield put(contextualCampaignAction.submitSendContextualCampaignTestMailDone(payload, meta))
}

export const contextualCampaignSaga = [
  takeLatest(contextualCampaignAction.fetchContextualCampaigns, handleFetchContextualCampaigns),
  takeLatest(contextualCampaignAction.submitUpdateContextualCampaign, handleSubmitUpdateContextualCampaign),
  takeEvery(contextualCampaignAction.submitEnableContextualCampaign, handleSubmitEnableContextualCampaign),
  takeLatest(contextualCampaignAction.submitCreateContextualCampaign, handleSubmitCreateContextualCampaign),
  takeLatest(contextualCampaignAction.submitDeleteContextualCampaign, handleSubmitDeleteContextualCampaign),
  takeLatest(contextualCampaignAction.submitSendContextualCampaignTestMail, handleSubmitSendContextualCampaignTestMail),
]
