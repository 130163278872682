import React from 'react'
import { HelpIcon } from 'lib/components'

export const HintIcon = ({ ...attr }) => {
  return (
    <HelpIcon
      icon="exclamation-circle"
      {...attr}
    >
      ヒント
    </HelpIcon>
  )
}

export default HintIcon
