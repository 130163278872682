import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import { withRouter } from 'react-router'
import { fromToMap, deserializeFromQueryString, serializeToQueryString } from 'lib/utils'
import { Pagination as RSPagination, PaginationItem, PaginationLink } from 'reactstrap'

export const Pagination = ({
  totalItemCount, limit, paginationItemLength = 5, pageQueryString = 'page', dispatch, location,
}) => {
  const basePath = location.pathname
  const queryObj = deserializeFromQueryString(location.search) || {}
  const nowPage = Number(queryObj[pageQueryString]) || 1

  const pageLinkPath = (page) => {
    const queryString = serializeToQueryString({
      ...queryObj,
      [pageQueryString]: page,
    })
    return `${basePath}${queryString}`
  }
  const lastPage = Math.ceil(totalItemCount / limit)

  const nearEnd = lastPage - nowPage > nowPage - 1 ? 'first' : 'last'
  let from; let
    to
  if (nearEnd === 'first') {
    from = nowPage - Math.floor(paginationItemLength / 2) < 1 ? 1 : nowPage - Math.floor(paginationItemLength / 2)
    to = from + paginationItemLength - 1 > lastPage ? lastPage : from + paginationItemLength - 1
  }
  if (nearEnd === 'last') {
    to = nowPage + Math.floor(paginationItemLength / 2) > lastPage ? lastPage : nowPage + Math.floor(paginationItemLength / 2)
    from = to - paginationItemLength + 1 < 1 ? 1 : to - paginationItemLength + 1
  }


  return (
    <RSPagination className="pagination-sm">
      <PaginationItem disabled={nowPage === 1 || lastPage <= 1}>
        <PaginationLink previous onClick={() => { dispatch(push(pageLinkPath(nowPage - 1))) }} />
      </PaginationItem>
      { fromToMap(from, to, page => (
        <PaginationItem active={nowPage === page} key={`page_${page}`}>
          <PaginationLink onClick={() => { dispatch(push(pageLinkPath(page))) }}>
            {page}
          </PaginationLink>
        </PaginationItem>
      )).filter(x => (x))}
      <PaginationItem disabled={nowPage === lastPage || lastPage <= 1}>
        <PaginationLink next onClick={() => { dispatch(push(pageLinkPath(nowPage + 1))) }} />
      </PaginationItem>
    </RSPagination>
  )
}

const select = (state, ownProps) => ({ ...ownProps })

export default compose(
  withRouter,
  connect(select),
)(Pagination)
