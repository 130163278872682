import {
  take, call, put, fork, join, select, takeLatest,
} from 'redux-saga/effects'
import { push } from 'connected-react-router'
import { authAction, emailUniquenessAction } from 'actions'
import * as api from 'services/api'
import { handleFetchCurrentUser } from './userSaga'
import { handleNoticeLogin } from './userAccessTrackingSaga'

const authSelector = state => state.auth
const userSelector = state => state.user

function* watchSubmitLogout() {
  while (true) {
    yield take(authAction.submitLogout)
    const authState = yield select(authSelector)
    if (authState.isLoggedIn) {
      yield fork(handleSubmitLogout)
    }
  }
}

function* handleSubmitLogout() {
  const { payload, meta, error } = yield call(api.submitLogout)

  if (payload && payload.redirectPath) {
    window.location.href = payload.redirectPath
  }

  yield put(authAction.submitLogoutDone(payload, meta))
  if (!error) yield put(push('/login'))
}

function* watchFetchLogin() {
  while (true) {
    const { payload } = yield take(authAction.fetchLogin)
    yield fork(handleFetchLogin, payload.email, payload.password)
  }
}

function* handleFetchLogin(email, password) {
  const { payload, meta, error } = yield call(api.fetchLogin, email, password)
  yield put(authAction.fetchLoginDone(payload, meta))
  if (!error) {
    yield fork(handleFetchCurrentUser)
    yield fork(handleNoticeLogin)
  }
}

function* watchCheckLogin() {
  while (true) {
    yield take(authAction.checkLogin)
    yield fork(handleCheckLogin)
  }
}

function* handleCheckLogin() {
  const task = yield fork(handleFetchCurrentUser)

  yield join(task)

  const userState = yield select(userSelector)

  if (userState.processResult === 'SUCCESS') {
    yield put(authAction.checkLoginDone(true))
  } else {
    yield put(authAction.checkLoginDone(new Error()))
  }
}

function* handleSubmitSignUp(action) {
  let { payload, meta, error } = yield call(api.checkEmailUniqueness, { email: action.payload.user.email })
  if (!payload.status) { payload = new Error() }
  yield put(emailUniquenessAction.checkEmailUniquenessDone(payload, meta))
  if (error || payload instanceof Error) { return }

  const res = yield call(api.submitSignUp, action.payload)
  yield put(authAction.submitSignUpDone(res.payload, res.meta))
  if (!res.error) yield put(push('/sign_up_complete'))
}

export const authSaga = [
  fork(watchCheckLogin),
  fork(watchFetchLogin),
  fork(watchSubmitLogout),
  takeLatest(authAction.submitSignUp, handleSubmitSignUp),
]
