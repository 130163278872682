import { createActions } from 'redux-actions'

const FETCH_ELASTICSEARCH_CUSTOMERS = 'FETCH_ELASTICSEARCH_CUSTOMERS'
const FETCH_ELASTICSEARCH_CUSTOMERS_DONE = 'FETCH_ELASTICSEARCH_CUSTOMERS_DONE'

export const elasticsearchCustomersAction = createActions(
  {
    [FETCH_ELASTICSEARCH_CUSTOMERS_DONE]: [
      (...args) => args[0],
      (...args) => args[1],
    ],
  },
  FETCH_ELASTICSEARCH_CUSTOMERS,
)
