import ReactGA from 'react-ga'

const googleAnalyticsMiddleware = store => next => (action) => {
  if (process.env.REACT_APP_ENV !== 'production') return next(action)
  if (action.type !== '@@router/LOCATION_CHANGE') return next(action)

  const isAdmin = !!store.getState().adminPermission.item.status
  const company = isAdmin ? 'admin' : store.getState().company.item
  if (company.name) ReactGA.set({dimension2: company.name})

  ReactGA.pageview(action.payload.location.pathname)

  return next(action)
}

export default googleAnalyticsMiddleware
