import { createActions } from 'redux-actions'

const FETCH_CAMPAIGN_COPY = 'FETCH_CAMPAIGN_COPY'
const FETCH_CAMPAIGN_COPY_DONE = 'FETCH_CAMPAIGN_COPY_DONE'

export const campaignCopyAction = createActions(
  {
    [FETCH_CAMPAIGN_COPY_DONE]: [
      (...args) => args[0],
      (...args) => Object.assign({ notification: 'ALL_CASE', succMess: 'コピーしました。', failMess: 'コピーに失敗しました。' }, args[1]),
    ],
  },

  FETCH_CAMPAIGN_COPY,
)
