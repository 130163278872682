import { call, put, takeLatest } from 'redux-saga/effects'
import * as api from 'services/adminApi'
import { adminReviewAccountAction } from 'actions'

export function* handleFetchReviewAccounts(action) {
  const { payload, meta } = yield call(api.fetchReviewAccounts)
  yield put(adminReviewAccountAction.fetchReviewAccountsDone(payload, meta))
}

export const adminReviewAccountSaga = [
  takeLatest(adminReviewAccountAction.fetchReviewAccounts, handleFetchReviewAccounts),
]
