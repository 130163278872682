import React from 'react'

const Loading = ({ isLoading, children, ...rest }) => {
  if (isLoading) {
    return (
      <div className="whirl double-up" style={{ minHeight: '150px' }} {...rest} />
    )
  }
  return (children)
}

export default Loading
