import React, { useState } from 'react'
import { Modal, ModalBody } from 'reactstrap'
import { MailPreview } from 'lib/components'

const API_ROOT = process.env.REACT_APP_API_ROOT

const MailPreviewThumbnail = ({ type, id }) => {
  const [isOpen, setIsOpen] = useState(false)
  return (
    <div className="thumbnail-container">
      <div className="thumbnail" onClick={() => setIsOpen(true)}>
        <iframe
          title="MailPreview"
          frameBorder="0"
          sandbox="allow-scripts"
          src={`${API_ROOT}/api/${type}/${id}/preview`}
        />
      </div>
      <Modal modalClassName="preview-modal" isOpen={isOpen} toggle={() => setIsOpen(false)} >
        <ModalBody>
          <MailPreview source="src" type={type} id={id} />
        </ModalBody>
      </Modal>
    </div>
  )
}

export default MailPreviewThumbnail
