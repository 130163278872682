import React from 'react'
import { connect } from 'react-redux'
import { CardBody, CardFooter, DropdownItem, DropdownMenu, DropdownToggle, Table, UncontrolledDropdown } from 'reactstrap'
import { Loading, Pagination } from "lib/components"
import { itemAction } from 'actions'

export const ItemList = ({ item, dispatch }) => {
  if (!item.items.data) return null
  const items = item.items.data && [...item.items.data]
  return (
    <Loading isLoading={item.isFetching}>
      <CardBody>
        <Table bordered striped responsive>
          <thead>
            <tr style={{ wordBreak: 'keep-all' }}>
              <th className="w-20">商品イメージ</th>
              <th className="w-20">商品名</th>
              <th className="w-20">商品コード</th>
              <th className="w-20">価格</th>
              <th className="w-20">在庫数</th>
              <th>配信状態</th>
            </tr>
          </thead>
          <tbody>
            { items && items
              .map(item => (
                <tr key={`${item.id}`}>
                  <td>
                    <a href={item.url1} target="_blank" rel="noopener noreferrer">
                      <img className="thumbnail-container" src={item.imageUrl1} alt={item.name}/>
                    </a>
                  </td>
                  <td>{item.name}</td>
                  <td>{item.code}</td>
                  <td>{item.price1}</td>
                  <td>{item.stockCount}</td>
                  <td>
                    <StopDeliveryDropdownButton item={item} dispatch={dispatch} />
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
      </CardBody>
      <CardFooter>
        <Pagination
          totalItemCount={item.items.pagination.totalCount}
          limit={20}
        />
      </CardFooter>
    </Loading>
  )
}


const StopDeliveryDropdownButton = ({ item, dispatch }) => {
  let statusText
  let actionText
  let statusColor
  let status
  let forceStop
  if (item.forceStop) {
    statusText = '強制停止'
    actionText = '配信開始'
    statusColor = 'danger'
    status = true
    forceStop = false
  } else {
    if (item.status) {
      statusText = '配信可能'
      actionText = '強制停止'
      statusColor = 'info'
      status = false
      forceStop = true
    } else {
      statusText = '配信不可'
      actionText = '配信開始'
      statusColor = 'warning'
      status = true
      forceStop = false
    }
  }

  const handleClick = (e) => {
    dispatch(itemAction.submitUpdateItem({ ...item, forceStop, status}))
  }
  return (
    <UncontrolledDropdown>
      <DropdownToggle caret color={statusColor}>{statusText}</DropdownToggle>
      <DropdownMenu>
        <DropdownItem onClick={handleClick}>{actionText}</DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  )
}

const select = ({ item }) => ({ item })

export default connect(select)(ItemList)
