import { takeLatest, call, put } from 'redux-saga/effects'
import * as api from 'services/api'
import { companyAction } from 'actions'

function* handleFetchCompany() {
  const { payload, meta } = yield call(api.fetchCompany)
  yield put(companyAction.fetchCompanyDone(payload, meta))
}

function* handleSubmitUpdateCompany(action) {
  const { payload, meta, error } = yield call(api.submitUpdateCompany, action.payload)
  yield put(companyAction.submitUpdateCompanyDone(payload, meta))
  if (!error) yield put(companyAction.fetchCompany())
}

export const companySaga = [
  takeLatest(companyAction.fetchCompany, handleFetchCompany),
  takeLatest(companyAction.submitUpdateCompany, handleSubmitUpdateCompany),
]
