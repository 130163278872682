import { handleActions } from 'redux-actions'
import { mailAbTestReportAction as action } from 'actions'

const initial = {
  isFetching: false,
  items: [],
  processResult: null,
}

export const mailAbTestReport = handleActions({
  [action.fetchMailAbTestReport]: (state, action) => ({
    ...state,
    isFetching: true,
  }),
  [action.fetchMailAbTestReportDone]: (state, action) => ({
    ...state,
    isFetching: false,
    items: action.error ? state.items : [
      ...state.items,
      action.payload,
    ],
    processResult: action.error ? action.payload : 'SUCCESS',
  }),
  [action.clearMailAbTestReports]: (state, action) => ({
    ...state,
    items: [],
  }),
}, initial)
