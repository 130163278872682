import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Collapse, Badge } from 'reactstrap';
import SidebarRun from './Sidebar.run';
import { permissions } from 'config/menu'

import config from 'react-global-configuration';

import { connect } from 'react-redux';
import { Loading } from 'lib/components'

/** Component to display headings on sidebar */
const SidebarItemHeader = ({ item }) => (
  <li className="nav-heading">
    <span>{item.heading}</span>
  </li>
)

/** Normal items for the sidebar */
const SidebarItem = ({ item, isActive }) => (
  <li className={isActive ? 'active' : ''}>
    <SidebarItemLink item={item} isActive={isActive}>
      {item.label && <Badge tag="div" className="pull-right" color={item.label.color}>{item.label.value}</Badge>}
      {item.icon && <em className={item.icon}></em>}
      <span>{item.displayName}</span>
    </SidebarItemLink>
  </li>
)

/** Build a sub menu with items inside and attach collapse behavior */
const SidebarSubItem = ({ item, isActive, handler, children, isOpen }) => (
  <li className={isActive ? 'active' : ''}>
    <div className="nav-item" onClick={handler}>
      {item.label && <Badge tag="div" className="pull-right" color={item.label.color}>{item.label.value}</Badge>}
      {item.icon && <em className={item.icon}></em>}
      <span>{item.displayName}</span>
    </div>
    <Collapse isOpen={isOpen}>
      <ul id={item.path} className="sidebar-nav sidebar-subnav">
        {children}
      </ul>
    </Collapse>
  </li>
)

const SidebarItemLink = ({ item, isActive, children }) => {
  if (item.path.startsWith('/')) {
    return (
      <Link to={item.path} title={item.name} className={isActive ? 'disabled' : ''}>
        {children}
      </Link>
    )
  }

  return (
    <a href={item.path} target="_blank" rel="noopener noreferrer">
      {children}
    </a>
  )
}

/** Component used to display a header on menu when using collapsed/hover mode */
const SidebarSubHeader = ({ item }) => (
  <li className="sidebar-subnav-header">{item.displayName}</li>
)

class Sidebar extends Component {
  state = {
    collapse: {},
    menu: config.get('MENU')[this.props.isAdmin ? 'admin' : 'user']
  }

  componentDidMount() {
    // pass navigator to access router api
    SidebarRun(this.navigator.bind(this));
    // prepare the flags to handle menu collapsed states
    this.buildCollapseList()
  }

  /** prepare initial state of collapse menus. Doesnt allow same route names */
  buildCollapseList = () => {
    let collapse = {};
    this.state.menu
      .filter(({ heading }) => !heading)
      .forEach(({ name, path, submenu }) => {
        collapse[name] = this.routeActive(submenu ? submenu.map(({ path }) => path) : path)
      })
    this.setState({ collapse });
  }

  navigator(route) {
    this.props.history.push(route);
  }

  routeActive(paths) {
    paths = Array.isArray(paths) ? paths : [paths];
    if (paths.indexOf(this.props.location.pathname) > -1)
      return true;
    return false;
  }

  toggleItemCollapse(stateName) {
    for (let c in this.state.collapse) {
      if (this.state.collapse[c] === true && c !== stateName)
        this.setState({
          collapse: {
            [c]: false
          }
        });
    }
    this.setState({
      collapse: {
        [stateName]: !this.state.collapse[stateName]
      }
    });
  }

  getSubRoutes = item => item.submenu.map(({ path }) => path)

  /** map menu config to string to determine what elemetn to render */
  itemType = item => {
    if (item.heading) return 'heading';
    if (!item.submenu) return 'menu';
    if (item.submenu) return 'submenu';
  }

  render() {
    return (
      <Loading isLoading={this.props.adminPermission.isFetching || this.props.company.isFetching}>
        <aside className='aside-container'>
          { /* START Sidebar (left) */}
          <div className="aside-inner">
            <nav data-sidebar-anyclick-close="" className="sidebar">
              { /* START sidebar nav */}
              <ul className="sidebar-nav mt-2">
                { /* Iterates over all sidebar items */}
                {
                  this.state.menu.map((item, i) => {
                    // heading
                    if (this.itemType(item) === 'heading')
                      return (
                        <SidebarItemHeader item={item} key={i} />
                      )
                    else {
                      if (this.itemType(item) === 'menu')
                        return (
                          <SidebarItem isActive={this.routeActive(item.path)} item={item} key={i} />
                        )
                      if (this.itemType(item) === 'submenu')
                        return [
                          <SidebarSubItem item={item} isOpen={this.state.collapse[item.name]} handler={this.toggleItemCollapse.bind(this, item.name)} isActive={this.routeActive(this.getSubRoutes(item))} key={i}>
                            <SidebarSubHeader item={item} key={i} />
                            {
                              item.submenu.map((subitem, i) => {
                                // HACK: メニューの表示制御専用のAPIを用意した方が綺麗かも
                                if (subitem.permission === permissions.ALL) {
                                  return <SidebarItem key={i} item={subitem} isActive={this.routeActive(subitem.path)} />
                                } else if (subitem.permission === permissions.ADMIN && this.props.adminPermission.item.status === 'OK') {
                                  return <SidebarItem key={i} item={subitem} isActive={this.routeActive(subitem.path)} />
                                } else if (subitem.permission === permissions.PUBLISH_LIMIT_DELIVERY_FEATURE && this.props.company.item.publishLimitDeliveryFeature) {
                                  return <SidebarItem key={i} item={subitem} isActive={this.routeActive(subitem.path)} />
                                }
                                return null
                              }).filter(Boolean)
                            }
                          </SidebarSubItem>
                        ]
                    }
                    return null; // unrecognized item
                  })
                }
              </ul>
              { /* END sidebar nav */}
            </nav>
          </div>
          { /* END Sidebar (left) */}
        </aside>
      </Loading>
    );
  }
}

const select = ({ adminPermission, company }) => ({ adminPermission, company })

export default withRouter(connect(select)(Sidebar));
