import React from 'react'
import { connect } from 'react-redux'
import { CSVLink } from 'react-csv'
import { arraysToObject } from 'lib/utils'
import { reportConstant as rc } from 'config/constants'

const terms = arraysToObject(rc.terms, 1, 0)
const baseCampaignTypes = arraysToObject(rc.campaignTypes, 1, 0)

const headers = [
  { label: 'キャンペーン名', key: 'campaignName' },
  { label: '期間', key: 'termName' },
  { label: '配信数', key: 'processedCount' },
  { label: '到達数', key: 'deliveredCount' },
  { label: '開封数', key: 'openCount' },
  { label: '開封率', key: 'openRate' },
  { label: 'クリック数', key: 'clickCount' },
  { label: 'クリック率', key: 'clickRate' },
  { label: '完了数', key: 'conversionCount' },
  { label: '完了率', key: 'conversionRate' },
  { label: 'キャンペーン売上', key: 'priceSum' },
  { label: 'リフトアップ', key: 'liftUp' },
  { label: 'メアド取得数', key: 'prospectCount' },
  { label: '会員化数', key: 'registerCount' },
  { label: '会員化率', key: 'registerRate' },
]

export const ComparisonReportCsvDownloadButton = ({ campaign, comparisonReport }) => {
  if (!comparisonReport.items) {
    return (<div />)
  }
  const data = comparisonReport.items
    .sort((a, b) => (a.order - b.order))
    .map(el => ({
      ...el.summary,
      ...el.info,
      campaignName: convertCampaignName(el.info.campaign, campaign.items),
      termName: terms[el.info.term],
    }))
  return (
    <CSVLink
      className="btn btn-success"
      filename="比較レポート.csv"
      data={data}
      headers={headers}
    >
CSVダウンロード
    </CSVLink>
  )
}

const convertCampaignName = (campaignStr, campaignItems) => {
  if (baseCampaignTypes[campaignStr]) {
    return baseCampaignTypes[campaignStr]
  }
  const delimiterIndex = campaignStr.indexOf(rc.campaignTypeDelimiter)
  const campaignType = campaignStr.slice(0, delimiterIndex)
  const campaignId = Number(campaignStr.slice(delimiterIndex + rc.campaignTypeDelimiter.length))
  const campaign = campaignItems.find(item => item._camGroup === campaignType && item.id === campaignId)
  return campaign !== undefined ? campaign.name : ''
}

const select = ({
  abandonedCampaign, completedCampaign, contextualCampaign, popup, prospectsCampaign, comparisonReport
}) => {
  const abanItems = abandonedCampaign.items.map((x) => { x._camGroup = 'abandoned'; return x })
  const compItems = completedCampaign.items.map((x) => { x._camGroup = 'completed'; return x })
  const contItems = contextualCampaign.items.map((x) => { x._camGroup = 'contextual'; return x })
  const popItems = popup.items.map((x) => { x._camGroup = 'popup'; return x })
  const prosItems = prospectsCampaign.items.map((x) => { x._camGroup = 'prospects'; return x })
  return {
    campaign: {
      items: [...abanItems, ...compItems, ...contItems, ...popItems, ...prosItems],
    },
    comparisonReport,
  }
}

export default connect(select)(ComparisonReportCsvDownloadButton)
