import React from 'react'
import {
  Card, CardBody, Col, Row,
} from 'reactstrap'
import ContentWrapper from 'components/layout/ContentWrapper'
import { connect } from 'react-redux'
import AdminCampaignTemplateForm from 'components/parts/campaignTemplate/AdminCampaignTemplateForm'

export const AdminCampaignTemplateNewPage = () => {
  return (
    <ContentWrapper>
      <div className="content-heading">
        <div>
          メールテンプレート作成
        </div>
      </div>
      <Row>
        <Col xl={12}>
          <Card className="card-default">
            <CardBody>
              <AdminCampaignTemplateForm
                submitAction="create"
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </ContentWrapper>
  )
}

export default connect()(AdminCampaignTemplateNewPage)
