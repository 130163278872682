import { createActions } from 'redux-actions'

const FETCH_COMPANY_UNIT_PRICE = 'FETCH_COMPANY_UNIT_PRICE'
const FETCH_COMPANY_UNIT_PRICE_DONE = 'FETCH_COMPANY_UNIT_PRICE_DONE'

const FETCH_COMPANY_UNIT_PRICES = 'FETCH_COMPANY_UNIT_PRICES'
const FETCH_COMPANY_UNIT_PRICES_DONE = 'FETCH_COMPANY_UNIT_PRICES_DONE'

const SUBMIT_UPDATE_COMPANY_UNIT_PRICE = 'SUBMIT_UPDATE_COMPANY_UNIT_PRICE'
const SUBMIT_UPDATE_COMPANY_UNIT_PRICE_DONE = 'SUBMIT_UPDATE_COMPANY_UNIT_PRICE_DONE'

const SUBMIT_CREATE_COMPANY_UNIT_PRICE = 'SUBMIT_CREATE_COMPANY_UNIT_PRICE'
const SUBMIT_CREATE_COMPANY_UNIT_PRICE_DONE = 'SUBMIT_CREATE_COMPANY_UNIT_PRICE_DONE'

const SUBMIT_DELETE_COMPANY_UNIT_PRICE = 'SUBMIT_DELETE_COMPANY_UNIT_PRICE'
const SUBMIT_DELETE_COMPANY_UNIT_PRICE_DONE = 'SUBMIT_DELETE_COMPANY_UNIT_PRICE_DONE'

export const adminCompanyUnitPriceAction = createActions(
  {
    [FETCH_COMPANY_UNIT_PRICE_DONE]: [
      (...args) => args[0],
      (...args) => args[1],
    ],
    [FETCH_COMPANY_UNIT_PRICES_DONE]: [
      (...args) => args[0],
      (...args) => args[1],
    ],
    [SUBMIT_UPDATE_COMPANY_UNIT_PRICE_DONE]: [
      (...args) => args[0],
      (...args) => Object.assign({ notification: 'ALL_CASE' }, args[1]),
    ],
    [SUBMIT_DELETE_COMPANY_UNIT_PRICE_DONE]: [
      (...args) => args[0],
      (...args) => Object.assign({ notification: 'ALL_CASE' }, args[1]),
    ],
    [SUBMIT_CREATE_COMPANY_UNIT_PRICE_DONE]: [
      (...args) => args[0],
      (...args) => Object.assign({ notification: 'ALL_CASE' }, args[1]),
    ],
  },
  FETCH_COMPANY_UNIT_PRICE,
  FETCH_COMPANY_UNIT_PRICES,
  SUBMIT_UPDATE_COMPANY_UNIT_PRICE,
  SUBMIT_CREATE_COMPANY_UNIT_PRICE,
  SUBMIT_DELETE_COMPANY_UNIT_PRICE,
  {
    prefix: 'ADMIN',
  },
)
