import { createActions } from 'redux-actions'

const FETCH_ITEM_IMPORT_SETTING = 'FETCH_ITEM_IMPORT_SETTING'
const FETCH_ITEM_IMPORT_SETTING_DONE = 'FETCH_ITEM_IMPORT_SETTING_DONE'

const SUBMIT_UPDATE_ITEM_IMPORT_SETTING = 'SUBMIT_UPDATE_ITEM_IMPORT_SETTING'
const SUBMIT_UPDATE_ITEM_IMPORT_SETTING_DONE = 'SUBMIT_UPDATE_ITEM_IMPORT_SETTING_DONE'

export const itemImportSettingAction = createActions(
  {
    [FETCH_ITEM_IMPORT_SETTING_DONE]: [
      (...args) => args[0],
      (...args) => Object.assign({ ignoreUnauth: true }, args[1]),
    ],
    [SUBMIT_UPDATE_ITEM_IMPORT_SETTING_DONE]: [
      (...args) => args[0],
      (...args) => Object.assign({ notification: 'ALL_CASE' }, args[1]),
    ],
  },
  FETCH_ITEM_IMPORT_SETTING,
  SUBMIT_UPDATE_ITEM_IMPORT_SETTING,
)
