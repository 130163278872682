import React from 'react'
import { connect } from 'react-redux'
import ContentWrapper from 'components/layout/ContentWrapper'
import LimitDeliverySettingForm from 'components/parts/limitDelivery/LimitDeliverySettingForm'
import { Card, CardBody, CardHeader, CardTitle } from 'reactstrap'
import { useMountEffect } from 'lib/hooks'
import { limitDeliverySettingAction } from 'actions'
import ManualLink from 'components/parts/manual/ManualLink'

export const LimitDeliverySettingPage = ({ dispatch }) => {
  useMountEffect(() => {
    dispatch(limitDeliverySettingAction.fetchLimitDeliverySetting())
  })

  return (
    <ContentWrapper>
      <div className="content-heading">
        <div>メール配信数制限</div>
      </div>
      <Card className="card-default">
        <CardHeader>
          <CardTitle tag="h3">
            <span>メール配信数制限機能とは</span>
          </CardTitle>
        </CardHeader>
        <CardBody>
          <div className="mb-2">当月のメール配信数が設定された配信制限通数に達すると、メール配信が自動的に停止されます。</div>
          <ManualLink filename="document/limit_delivery_setting.pdf">
            詳細はこちら
          </ManualLink>
        </CardBody>
      </Card>
      <Card className="card-default">
        <CardBody>
          <LimitDeliverySettingForm />
        </CardBody>
      </Card>
    </ContentWrapper>
  )
}

export default connect()(LimitDeliverySettingPage)
