import React, { useState } from 'react'
import { connect } from 'react-redux'
import { campaignConstant } from 'config/constants'
import { HelpIcon } from 'lib/components'
import { Loading } from 'lib/components'
import { customerAction, sendgridAction } from 'actions'
import { toast } from 'react-toastify'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import {
  Card,
  CardBody,
  UncontrolledTooltip,
  FormGroup,
  Label,
  Input,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from 'reactstrap'

export const OptoutSettings = ({ dispatch, sendgrid, customer }) => {
  const [modal, setModal] = useState(false)
  const [sendGridModal, setSendGridModal] = useState(false)
  const toggle = () => setModal(!modal)
  const toggleSendGridModal = () => setSendGridModal(!sendGridModal)

  if (Object.keys(customer.item).length === 0) return null

  const isSendGridOptin = Object.keys(sendgrid.item).length === 0
  const customerStatus = customer.item.status
  const isOptin = isSendGridOptin && customerStatus
  const customerEmail = customer.item.email
  const customerType = customer.item.customerType || 0
  const optionItems = campaignConstant.customerTypes.map(type => {
    const index = type[1]
    const name = index === 0 ? '会員種別未設定' : type[0]
    return <option value={index} key={index}>{ name }</option>
  })
  const isLoading = sendgrid.isFetching || customer.isFetching
  const selectStyle = { width: '100px' }

  function updateSendGridStatus() {
    if (customerEmail) {
      isSendGridOptin ? dispatch(sendgridAction.createSuppressions([customerEmail])) : dispatch(sendgridAction.removeSuppression(customerEmail))
    } else {
      toast.error('ユーザのメールアドレスがありません')
    }
    toggleSendGridModal()
  }

  function optoutSendGridStatus() {
    if (isSendGridOptin) {
      dispatch(sendgridAction.createSuppressions([customerEmail]))
    } else {
      toast.success('メール配信システムのステータスはすでにNGです')
    }    
    toggle()
  }

  function activateCustomer(values, { setErrors }) {
    const params = Object.assign({ id: customer.item.id, status: true }, values)
    dispatch(customerAction.activateCustomer(params, toggle, setErrors))
  }

  function updateCustomerType(e) {
    const type = e.target.value === '0' ? '' : e.target.value
    dispatch(customerAction.updateCustomer({ id: customer.item.id, customerType: type }))
  }

  return (
    <Loading isLoading={isLoading}>
      <Card className="card-default">
        <CardBody>
          <p className="h4">当該ユーザにおける現在の<strong>メール受信可否状況</strong>です。</p>
          <p>・<strong>受信ステータス</strong>：「受信可能」の場合にリタゲメールが配信可能となります。</p>
          <p>※受信ステータスは下記の「メール配信システム」および「動的タグ」の両方が「OK」となっている場合のみ「受信可能」となります。</p>
          <div className="row mb-2">
            <div className="col-1 align-self-center font-weight-bold">受信ステータス</div>
            <div className="col-1 align-self-center">
              <span className={`${isOptin ? 'bg-primary' : 'bg-danger'} d-inline-block font-weight-bold text-white px-3 py-2 rounded`}>{ isOptin ? '受信可能' : '受信不可' }</span>
            </div>
            <div className="col-4 align-self-center">
              <div className="row bg-gray py-2 rounded">
                <div className="col-7 d-flex">
                  <div className="align-self-center">メール配信システム</div>
                  <HelpIcon id="sendGridDesc" className="mr-2" />
                  <UncontrolledTooltip placement="top" target="sendGridDesc">
                    メール内のオプトアウトリンクもしくは管理画面&gt;キャンペーン(会員)&gt;配信停止設定 で配信停止にした場合、NGになります
                  </UncontrolledTooltip>
                  <Input type="select" style={selectStyle} value={isSendGridOptin} onChange={toggleSendGridModal}>
                    <option value="true">OK</option>
                    <option value="false">NG</option>
                  </Input>
                  <Modal isOpen={sendGridModal} toggle={toggleSendGridModal}>
                    <ModalHeader>メール配信システムのステータス変更</ModalHeader>
                    <ModalBody>
                      <p>ステータスを変更してよろしいですか？</p>
                    </ModalBody>
                    <ModalFooter>
                      <Button color="primary" onClick={updateSendGridStatus}>変更する</Button>
                      <Button color="secondary" onClick={toggleSendGridModal}>キャンセル</Button>
                    </ModalFooter>
                  </Modal>
                </div>
                <div className="col-5 d-flex">
                  <div className="align-self-center">動的タグ</div>
                  <HelpIcon id="customerDesc" className="mr-2" />
                  <UncontrolledTooltip placement="top" target="customerDesc">
                    サイト上に設置された動的タグもしくは連携ファイルにて「status」パラメータに「0」を連携した場合、NGになります
                  </UncontrolledTooltip>
                  <Input type="select" style={selectStyle} value={customerStatus} onChange={toggle}>
                    <option value="true">OK</option>
                    <option value="false">NG</option>
                  </Input>
                  <Modal isOpen={modal} toggle={toggle}>
                    <ModalHeader>動的タグのステータス変更</ModalHeader>
                    <CustomerModalBody
                      customerStatus={customerStatus}
                      handleClick={optoutSendGridStatus}
                      handleSubmit={activateCustomer}
                      toggle={toggle}
                    />
                  </Modal>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-1 align-self-center font-weight-bold">会員種別</div>
            <div className="col-2 align-self-center">
              <Input type="select" className="w-75" value={customerType} onChange={updateCustomerType}>
                { optionItems }
              </Input>
            </div>
          </div>
        </CardBody>
      </Card>
    </Loading>
  )
}

const CustomerSchema = Yup.object().shape({
  email: Yup.string().email().required(),
  firstName: Yup.string(),
  lastName: Yup.string(),
  lineId: Yup.string(),
})

function CustomerModalBody({ customerStatus, handleClick, handleSubmit, toggle }) {
  if (customerStatus) {
    return (
      <>
        <ModalBody>
          <p>
            仕組み上「動的タグ」をNGとしても、サイト上のタグ実装もしくはファイル連携により再度OKに上書きされる可能性があります。<br />
            誤配信を防ぐため、この操作では「メール配信システム」のみをNGに変更します。
          </p>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleClick}>変更する</Button>
          <Button color="secondary" onClick={toggle}>キャンセル</Button>
        </ModalFooter>
      </>
    )
  }
  return (
    <Formik
      initialValues={{
        email: '',
        lastName: '',
        firstName: '',
        lineId: '',
      }}
      validationSchema={CustomerSchema}
      onSubmit={handleSubmit}
    >
      <Form>
        <ModalBody>
          <p>OKに変更するにはユーザ情報を入力してください。</p>
          <FormGroup>
            <Label for="email" required>メールアドレス（必須）</Label>
            <Input tag={Field} type="email" name="email" id="email" placeholder="test@example.com" required />
            <ErrorMessage name="email">{msg => <div className='text-danger'>{msg}</div>}</ErrorMessage>
          </FormGroup>
          <FormGroup>
            <Label for="lastName">姓</Label>
            <Input tag={Field} name="lastName" id="lastName" placeholder="山田" />
          </FormGroup>
          <FormGroup>
            <Label for="firstName">名</Label>
            <Input tag={Field} name="firstName" id="firstName" placeholder="太郎" />
          </FormGroup>
          <FormGroup>
            <Label for="lineId">LINE ID</Label>
            <Input tag={Field} name="lineId" id="lineId" />
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" type="submit">変更する</Button>
          <Button color="secondary" onClick={toggle}>キャンセル</Button>
        </ModalFooter>
      </Form>
    </Formik>
  )
}

const select = ({ sendgrid, customer }) => ({ sendgrid, customer })

export default connect(select)(OptoutSettings)
