export default {
  campaignPolicyOptions: [
    ['ブラウザ放棄', 'view'],
    ['かご落ち', 'cart'],
    ['再購入促進', 'repeatPurchasing'],
    ['レビューリクエスト', 'reviewRequest'],
    ['初回購入促進', 'firstPurchasing'],
    ['在庫あとわずかお知らせ（カート）', 'littleStockInCart'],
    ['再入荷お知らせ（カート）', 'restockInCart'],
    ['値下げお知らせ（カート）', 'discountInCart'],
    ['在庫あとわずかお知らせ（お気に入り）', 'littleStockInFav'],
    ['再入荷お知らせ（お気に入り）', 'restockInFav'],
    ['値下げお知らせ（お気に入り）', 'discountInFav'],
    ['会員登録促進（ポップアップ）', 'register'],
    ['マイページ(顧客管理)', 'customerManage'],
    ['退会処理', 'customerUnsubscribe'],
  ],
  termOptions: [
    ['最新', 0],
    ['昨日', 1],
    ['2日前', 2],
    ['3日前', 3],
    ['4日前', 4],
    ['5日前', 5],
    ['6日前', 6],
    ['１週間前', 7],
  ],
  defaultTagParameterCondition: {
    campaignPolicy: 'cart',
    term: 0,
    behaviorType: 2,
  },
  logTypeOptions: [
    ['行動履歴', 'behaviors'],
    ['商品情報', 'customers'],
    ['会員情報', 'items'],
  ],
  cooperated: '○',
  invalid: '×',
  behaviorsForItemDetailParameterDataTemplate: [
    {
      name: 'behaviorType', required: '必須', coopCondition: '-', abstract: '行動履歴IDです。ブラウザ放棄の場合は数字の1 を連携します。',
    },
    {
      name: 'userCode', required: '', coopCondition: '-', abstract: '会員IDです。cookieで連携できるのは一度ログインした会員に限りますのでご注意ください。',
    },
    {
      name: 'itemCode', required: '必須', coopCondition: '-', abstract: '商品IDです。',
    },
  ],
  behaviorsForCartDataTemplate: [
    {
      name: 'behaviorType', required: '必須', coopCondition: '-', abstract: '行動履歴IDです。かご落ち、カート内値下げ・在庫あと僅か・再入荷および、ポップアップを利用する場合は数字の2 を連携してください。',
    },
    {
      name: 'userCode', required: '必須', coopCondition: '-', abstract: '会員IDです。',
    },
    {
      name: 'itemCode', required: '必須', coopCondition: '-', abstract: '商品IDです。',
    },
  ],
  behaviorsForPurchasedDataTemplate: [
    {
      name: 'behaviorType', required: '必須', coopCondition: '-', abstract: '行動履歴IDです。購入完了・レビューリクエストの場合は数字の3 を連携します。',
    },
    {
      name: 'userCode', required: '必須', coopCondition: '-', abstract: '会員IDです。',
    },
    {
      name: 'itemCode', required: '必須', coopCondition: '-', abstract: '商品IDです。',
    },
    {
      name: 'itemQty', required: '', coopCondition: '-', abstract: '商品数小計です。',
    },
    {
      name: 'totalPrice', required: '必須', coopCondition: '-', abstract: '購入金額合計です。成果集計に必要なパラメータです。',
    },
  ],
  behaviorsForFavoriteParameterDataTemplate: [
    {
      name: 'behaviorType', required: '必須', coopCondition: '-', abstract: '行動履歴IDです。お気に入りの場合は数字の14 を連携します。',
    },
    {
      name: 'userCode', required: '', coopCondition: '-', abstract: '会員IDです。',
    },
    {
      name: 'itemCode', required: '', coopCondition: '-', abstract: '商品IDです。',
    },
  ],
  itemsParameterDataTemplateDefault: [
    {
      name: 'itemCode', required: '', coopCondition: '-', abstract: '商品IDです。',
    },
    {
      name: 'itemName', required: '', coopCondition: '-', abstract: '商品名です。',
    },
    {
      name: 'url1', required: '', coopCondition: '-', abstract: '商品詳細画面のURLです。',
    },
    {
      name: 'price1', required: '', coopCondition: '-', abstract: '商品価格です。',
    },
    {
      name: 'imageUrl1', required: '', coopCondition: '-', abstract: '商品詳細画像のURLです。',
    },
    {
      name: 'category1', required: '', coopCondition: '-', abstract: '商品カテゴリーです。',
    },
    {
      name: 'status', required: '', coopCondition: '-', abstract: '商品表示フラグです。配信内容に在庫のない商品を載せたくない場合は数字の0 を連携します。',
    },
    {
      name: 'stockCount', required: '', coopCondition: '-', abstract: '商品在庫数です。',
    },
    {
      name: 'stockStatus', required: '', coopCondition: '-', abstract: '商品状態です。',
    },
    {
      name: 'contentDate', required: '', coopCondition: '-', abstract: '商品の更新日時です。',
    },
  ],
  itemsForItemDetailParameterDataTemplate: [
    {
      name: 'itemCode', required: '必須', coopCondition: '-', abstract: '商品IDです。',
    },
    {
      name: 'itemName', required: '', coopCondition: '-', abstract: '商品名です。',
    },
    {
      name: 'url1', required: '', coopCondition: '-', abstract: '商品詳細画面のURLです。',
    },
    {
      name: 'price1', required: '', coopCondition: '-', abstract: '商品価格です。',
    },
    {
      name: 'imageUrl1', required: '', coopCondition: '-', abstract: '商品詳細画像のURLです。',
    },
    {
      name: 'category1', required: '', coopCondition: '-', abstract: '商品カテゴリーです。',
    },
    {
      name: 'status', required: '', coopCondition: '-', abstract: '商品表示フラグです。配信内容に在庫のない商品を載せたくない場合は数字の0 を連携します。',
    },
    {
      name: 'stockCount', required: '', coopCondition: '-', abstract: '商品在庫数です。',
    },
    {
      name: 'stockStatus', required: '', coopCondition: '-', abstract: '商品状態です。',
    },
    {
      name: 'contentDate', required: '', coopCondition: '-', abstract: '商品の更新日時です。',
    },
  ],
  itemsForPurchasedParameterDataTemplate: [
    {
      name: 'itemCode', required: '必須', coopCondition: '-', abstract: '商品IDです。',
    },
    {
      name: 'status', required: '', coopCondition: '-', abstract: '商品表示フラグです。配信内容に在庫のない商品を載せたくない場合は数字の0 を連携します。',
    },
    {
      name: 'stockCount', required: '', coopCondition: '-', abstract: '商品在庫数です。',
    },
    {
      name: 'contentDate', required: '', coopCondition: '-', abstract: '商品の更新日時です。',
    },
  ],
  itemsForDiscountContextualParameterDataTemplate: [
    {
      name: 'itemCode', required: '必須', coopCondition: '-', abstract: '商品IDです。',
    },
    {
      name: 'itemName', required: '', coopCondition: '-', abstract: '商品名です。',
    },
    {
      name: 'url1', required: '', coopCondition: '-', abstract: '商品詳細画面のURLです。',
    },
    {
      name: 'price1', required: '必須', coopCondition: '-', abstract: '商品価格です。値下げキャンペーンをする場合は必須です。',
    },
    {
      name: 'imageUrl1', required: '', coopCondition: '-', abstract: '商品詳細画像のURLです。',
    },
    {
      name: 'category1', required: '', coopCondition: '-', abstract: '商品カテゴリーです。',
    },
    {
      name: 'status', required: '', coopCondition: '-', abstract: '商品表示フラグです。配信内容に在庫のない商品を載せたくない場合は数字の 0を連携します。',
    },
    {
      name: 'stockCount', required: '', coopCondition: '-', abstract: '商品在庫数です。',
    },
    {
      name: 'stockStatus', required: '', coopCondition: '-', abstract: '商品状態です。',
    },
    {
      name: 'contentDate', required: '必須', coopCondition: '-', abstract: '商品の更新日時です。値下げキャンペーンをする場合は必須です。',
    },
  ],
  itemsForStockContextualParameterDataTemplate: [
    {
      name: 'itemCode', required: '必須', coopCondition: '-', abstract: '商品IDです。',
    },
    {
      name: 'itemName', required: '', coopCondition: '-', abstract: '商品名です。',
    },
    {
      name: 'url1', required: '', coopCondition: '-', abstract: '商品詳細画面のURLです。',
    },
    {
      name: 'price1', required: '', coopCondition: '-', abstract: '商品価格です。',
    },
    {
      name: 'imageUrl1', required: '', coopCondition: '-', abstract: '商品詳細画像のURLです。',
    },
    {
      name: 'category1', required: '', coopCondition: '-', abstract: '商品カテゴリーです。',
    },
    {
      name: 'status', required: '', coopCondition: '-', abstract: '商品表示フラグです。配信内容に在庫のない商品を載せたくない場合は数字の 0を連携します。',
    },
    {
      name: 'stockCount', required: '必須', coopCondition: '-', abstract: '商品在庫数です。在庫変動・再入荷キャンペーンをする場合は必須です。',
    },
    {
      name: 'stockStatus', required: '', coopCondition: '-', abstract: '商品状態です。',
    },
    {
      name: 'contentDate', required: '必須', coopCondition: '-', abstract: '商品の更新日時です。在庫変動・再入荷キャンペーンをする場合は必須です。',
    },
  ],
  // 商品詳細・カート・マイページのCustomer連携状況
  customersForGeneralParameterDataTemplate: [
    {
      name: 'userCode', required: '必須', coopCondition: '-', abstract: '会員IDです。',
    },
    {
      name: 'email', required: '', coopCondition: '-', abstract: '会員メールアドレスです。',
    },
    {
      name: 'status', required: '', coopCondition: '-', abstract: '会員の配信可否フラグです。ファイル連携を行っておらず、タグ連携不可の場合、全会員が配信対象となります。',
    },
  ],
  // 退会完了ページのCustomer連携状況
  customersForUnsubscribeParameterDataTemplate: [
    {
      name: 'userCode', required: '必須', coopCondition: '-', abstract: '会員IDです。',
    },
    {
      name: 'email', required: '', coopCondition: '-', abstract: '会員メールアドレスです。',
    },
    {
      name: 'status', required: '必須', coopCondition: '-', abstract: '会員の配信可否フラグです。退会時に数字の0 を連携し配信停止します。',
    },

  ],
  // 会員登録完了ページのCustomer連携状況
  customersForRegisteredParameterDataTemplate: [
    {
      name: 'userCode', required: '必須', coopCondition: '-', abstract: '会員IDです。',
    },
    {
      name: 'email', required: '必須', coopCondition: '-', abstract: '会員メールアドレスです。',
    },
    {
      name: 'status', required: '', coopCondition: '-', abstract: '会員のメール配信可否フラグです。ファイル連携を行っておらず、タグ連携不可の場合、全会員が配信対象となります。',
    },
    {
      name: 'register', required: '必須', coopCondition: '-', abstract: '新規会員登録フラグです。',
    },
  ],
  behaviorsHeaders: [
    { key: 'behaviorType', label: 'behaviorType' },
    { key: 'userCode', label: 'userCode' },
    { key: 'itemCode', label: 'itemCode' },
    { key: 'itemQty', label: 'itemQty' },
    { key: 'totalPrice', label: 'totalPrice' },
  ],
  itemsHeaders: [
    { key: 'itemCode', label: 'itemCode' },
    { key: 'itemName', label: 'itemName' },
    { key: 'url1', label: 'url1' },
    { key: 'price1', label: 'price1' },
    { key: 'imageUrl1', label: 'imageUrl1' },
    { key: 'category1', label: 'category1' },
    { key: 'status', label: 'status' },
    { key: 'stockCount', label: 'stockCount' },
    { key: 'stockStatus', label: 'stockStatus' },
    { key: 'contentDate', label: 'contentDate' },
  ],
  customersHeaders: [
    { key: 'userCode', label: 'userCode' },
    { key: 'email', label: 'email' },
    { key: 'status', label: 'status' },
    { key: 'register', label: 'register' },
  ],
}
