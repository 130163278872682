import { createActions } from 'redux-actions'

const FETCH_REGISTERED_USERS = 'FETCH_REGISTERED_USERS'
const FETCH_REGISTERED_USERS_DONE = 'FETCH_REGISTERED_USERS_DONE'

const SUBMIT_CREATE_REGISTERED_USER = 'SUBMIT_CREATE_REGISTERED_USER'
const SUBMIT_CREATE_REGISTERED_USER_DONE = 'SUBMIT_CREATE_REGISTERED_USER_DONE'

const SUBMIT_DELETE_REGISTERED_USER = 'SUBMIT_DELETE_REGISTERED_USER'
const SUBMIT_DELETE_REGISTERED_USER_DONE = 'SUBMIT_DELETE_REGISTERED_USER_DONE'

export const registeredUserAction = createActions(
  {
    [FETCH_REGISTERED_USERS_DONE]: [
      (...args) => args[0],
      (...args) => args[1],
    ],
    [SUBMIT_CREATE_REGISTERED_USER_DONE]: [
      (...args) => args[0],
      (...args) => Object.assign({ notification: 'ALL_CASE', succMess: '登録が完了しました。' }, args[1]),
    ],
    [SUBMIT_DELETE_REGISTERED_USER_DONE]: [
      (...args) => args[0],
      (...args) => Object.assign({ notification: 'ALL_CASE', succMess: '削除しました。' }, args[1]),
    ],
  },

  FETCH_REGISTERED_USERS,
  SUBMIT_CREATE_REGISTERED_USER,
  SUBMIT_DELETE_REGISTERED_USER,

)
