import { createActions } from 'redux-actions'

const FETCH_PROSPECTS_CAMPAIGNS = 'FETCH_PROSPECTS_CAMPAIGNS'
const FETCH_PROSPECTS_CAMPAIGNS_DONE = 'FETCH_PROSPECTS_CAMPAIGNS_DONE'

const SUBMIT_UPDATE_PROSPECTS_CAMPAIGN = 'SUBMIT_UPDATE_PROSPECTS_CAMPAIGN'
const SUBMIT_UPDATE_PROSPECTS_CAMPAIGN_DONE = 'SUBMIT_UPDATE_PROSPECTS_CAMPAIGN_DONE'

const SUBMIT_ENABLE_PROSPECTS_CAMPAIGN = 'SUBMIT_ENABLE_PROSPECTS_CAMPAIGN'
const SUBMIT_ENABLE_PROSPECTS_CAMPAIGN_DONE = 'SUBMIT_ENABLE_PROSPECTS_CAMPAIGN_DONE'

const SUBMIT_CREATE_PROSPECTS_CAMPAIGN = 'SUBMIT_CREATE_PROSPECTS_CAMPAIGN'
const SUBMIT_CREATE_PROSPECTS_CAMPAIGN_DONE = 'SUBMIT_CREATE_PROSPECTS_CAMPAIGN_DONE'

const SUBMIT_SEND_PROSPECTS_CAMPAIGN_TEST_MAIL = 'SUBMIT_SEND_PROSPECTS_CAMPAIGN_TEST_MAIL'
const SUBMIT_SEND_PROSPECTS_CAMPAIGN_TEST_MAIL_DONE = 'SUBMIT_SEND_PROSPECTS_CAMPAIGN_TEST_MAIL_DONE'

export const prospectsCampaignAction = createActions(
  {
    [FETCH_PROSPECTS_CAMPAIGNS_DONE]: [
      (...args) => args[0],
      (...args) => args[1],
    ],
    [SUBMIT_UPDATE_PROSPECTS_CAMPAIGN_DONE]: [
      (...args) => args[0],
      (...args) => Object.assign({ notification: 'ALL_CASE' }, args[1]),
    ],
    [SUBMIT_ENABLE_PROSPECTS_CAMPAIGN_DONE]: [
      (...args) => args[0],
      (...args) => Object.assign({ notification: 'ALL_CASE' }, args[1]),
    ],
    [SUBMIT_CREATE_PROSPECTS_CAMPAIGN_DONE]: [
      (...args) => args[0],
      (...args) => Object.assign({ notification: 'ALL_CASE' }, args[1]),
    ],
    [SUBMIT_SEND_PROSPECTS_CAMPAIGN_TEST_MAIL_DONE]: [
      (...args) => args[0],
      (...args) => Object.assign(
        { notification: 'ALL_CASE', succMess: 'メール送信しました。', failMess: 'メール送信に失敗しました。' },
        args[1],
      ),
    ],
  },

  FETCH_PROSPECTS_CAMPAIGNS,
  SUBMIT_UPDATE_PROSPECTS_CAMPAIGN,
  SUBMIT_ENABLE_PROSPECTS_CAMPAIGN,
  SUBMIT_CREATE_PROSPECTS_CAMPAIGN,
  SUBMIT_SEND_PROSPECTS_CAMPAIGN_TEST_MAIL,

)
