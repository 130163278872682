import React from 'react'
import { connect } from 'react-redux'
import ContentWrapper from 'components/layout/ContentWrapper'
import {
  Card, CardBody, CardHeader, Row, Col,
} from 'reactstrap'
import { useMountEffect } from 'lib/hooks'
import ItemImportSetting from 'components/parts/itemImport/ItemImportSetting'
import BatchStatusItemImportList from 'components/parts/batchStatusItemImport/BatchStatusItemImportList'
import { itemImportSettingAction, batchStatusItemImportAction } from 'actions'

export const ItemImportPage = ({dispatch}) => {
  useMountEffect(() => {
    dispatch(itemImportSettingAction.fetchItemImportSetting())
    dispatch(batchStatusItemImportAction.fetchBatchStatusItemImports())
  })

  return (
    <ContentWrapper>
      <div className="content-heading">
        <div>商品データ取り込み</div>
      </div>
      <Row>
        <Col xl={8} md={6}>
          <Card className="card-default">
            <CardHeader>商品データ取り込み履歴</CardHeader>
            <CardBody>
              <BatchStatusItemImportList />
            </CardBody>
          </Card>
        </Col>
        <Col xl={4} md={6}>
          <Card className="card-default">
            <CardHeader>商品データ取り込み設定</CardHeader>
            <CardBody>
              <ItemImportSetting />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </ContentWrapper>
  )
}

export default connect()(ItemImportPage)
