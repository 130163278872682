import { takeLatest, call, put } from 'redux-saga/effects'
import * as api from 'services/api'
import { reportMailSettingAction } from 'actions'

function* handleFetchReportMailSetting() {
  const { payload, meta } = yield call(api.fetchReportMailSetting)
  yield put(reportMailSettingAction.fetchReportMailSettingDone(payload, meta))
}

function* handleSubmitUpdateReportMailSetting(action) {
  const { payload, meta } = yield call(api.submitUpdateReportMailSetting, action.payload)
  yield put(reportMailSettingAction.submitUpdateReportMailSettingDone(payload, meta))
}

export const reportMailSettingSaga = [
  takeLatest(reportMailSettingAction.fetchReportMailSetting, handleFetchReportMailSetting),
  takeLatest(reportMailSettingAction.submitUpdateReportMailSetting, handleSubmitUpdateReportMailSetting),
]
