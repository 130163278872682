import React from 'react'
import { useField } from 'formik'
import { InputGroup } from 'reactstrap'
import { UnControlled as CodeMirror } from 'react-codemirror2'
import 'codemirror/lib/codemirror.css'

require('codemirror/lib/codemirror')
require('codemirror/mode/htmlmixed/htmlmixed')
require('codemirror/mode/css/css')

const defaultExtraKeys = { Tab: 'autocomplete', 'Ctrl-Space': 'autocomplete' }

/**
 * codemirrorを使用したinputフォームのcomponent
 */

// NOTICE: validationには非対応
const FieldCode = ({ name, mode, options, height, ...props }) => {
  const [field] = useField(name)
  return (
    <InputGroup className="codemirror-wrapper">
      <CodeMirror
        options={{
          lineWrapping: true,
          autoRefresh: true,
          lineNumbers: true,
          mode,
          extraKeys: defaultExtraKeys,
          ...options,
        }}
        autoScroll={false}
        autoCursor={false}
        className={height === 'auto' && 'default-height'}
        onChange={(editor, data, value) => { field.onChange({ target: { name: name, value: value } }) }}
        value={field.value}
        {...props}
      />
    </InputGroup>
  )
}

export default FieldCode
