import React from 'react'
import { connect } from 'react-redux'
import { CSVLink } from 'react-csv'
import { tagParameterConstant as gc } from 'config/constants'

export const TagParameterItemsDataCsvDownloadButton = ({ itemsData, queryObj }) => {
  if (itemsData.items.length === 0) { return null }
  const headers = gc.itemsHeaders
  const data = [...itemsData.items]
  return (
    <CSVLink
      className="btn btn-success"
      filename={`商品情報ログ_${queryObj.confirmationIndexTerm}.csv`}
      data={data}
      headers={headers}
    >
      {queryObj.confirmationIndexTerm}
の商品情報ログをダウンロードする（10件）
    </CSVLink>
  )
}


const select = ({
  elasticsearchItems, queryObj,
}) => {
  const items = elasticsearchItems.items
  return {
    itemsData: {
      items: [...items],
    },
    _isLoading: elasticsearchItems.isFetching,
    ...queryObj,
  }
}

export default connect(select)(TagParameterItemsDataCsvDownloadButton)
