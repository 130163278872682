import React from 'react'
import { connect } from 'react-redux'
import ContentWrapper from 'components/layout/ContentWrapper'
import {
  Card, CardBody, CardHeader, Row, Col,
} from 'reactstrap'
import { useMountEffect } from 'lib/hooks'
import CustomerImportSetting from 'components/parts/customerImport/CustomerImportSetting'
import BatchStatusCustomerImportList from 'components/parts/batchStatusCustomerImport/BatchStatusCustomerImportList'
import { customerImportSettingAction, batchStatusCustomerImportAction } from 'actions'

export const CustomerImportPage = ({dispatch}) => {
  useMountEffect(() => {
    dispatch(customerImportSettingAction.fetchCustomerImportSetting())
    dispatch(batchStatusCustomerImportAction.fetchBatchStatusCustomerImports())
  })

  return (
    <ContentWrapper>
      <div className="content-heading">
        <div>宛先データ取り込み</div>
      </div>
      <Row>
        <Col xl={8} md={6}>
          <Card className="card-default">
            <CardHeader>宛先データ取り込み履歴</CardHeader>
            <CardBody>
              <BatchStatusCustomerImportList />
            </CardBody>
          </Card>
        </Col>
        <Col xl={4} md={6}>
          <Card className="card-default">
            <CardHeader>宛先データ取り込み設定</CardHeader>
            <CardBody>
              <CustomerImportSetting />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </ContentWrapper>
  )
}

export default connect()(CustomerImportPage)
