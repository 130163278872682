import { takeLatest, put, call } from 'redux-saga/effects'
import { replace } from 'connected-react-router'
import { serializeToQueryString } from 'lib/utils'
import { behaviorSummaryAction } from 'actions'
import * as api from 'services/api'

function* handleSearchBehaviorSummary(action) {
  yield put(replace(`/behavior_summary${serializeToQueryString(action.payload)}`))
}

function* handleFetchBehaviorSummary(action) {
  const reqValues = { ...action.payload, advanced: true}
  const { payload, meta } = yield call(api.fetchBehaviorSummary, reqValues)
  yield put(behaviorSummaryAction.fetchBehaviorSummaryDone(payload, meta))
}

export const behaviorSummarySaga = [
  takeLatest(behaviorSummaryAction.searchBehaviorSummary, handleSearchBehaviorSummary),
  takeLatest(behaviorSummaryAction.fetchBehaviorSummary, handleFetchBehaviorSummary),
]
