import { handleActions } from 'redux-actions'
import { adminAuthAction as action } from 'actions'

const initial = {
  isLoggedIn: undefined,
  isFetching: false,
  isChecked: false,
  isSubmitting: false,
  isUnAuthInProgress: false,
  error: undefined,
}

export const adminAuth = handleActions({
  [action.checkLogin]: (state, action) => ({
    ...state,
    isChecked: false,
  }),
  [action.checkLoginDone]: (state, action) => ({
    ...state,
    isChecked: true,
    isLoggedIn: !action.error,
  }),
  [action.fetchLogin]: (state, action) => ({
    ...state,
    isFetching: true,
  }),
  [action.fetchLoginDone]: (state, action) => ({
    ...state,
    isFetching: false,
    isLoggedIn: !action.error,
    isUnAuthInProgress: action.error ? state.isUnAuthInProgress : false,
    error: action.error && action.payload.message,
  }),
  [action.submitLogout]: (state, action) => ({
    ...state,
    isSubmitting: true,
  }),
  [action.submitLogoutDone]: (state, action) => ({
    ...state,
    isSubmitting: false,
    isLoggedIn: !!action.error,
    error: action.error && action.payload.message,
  }),
  [action.setUnauthInProgress]: (state, action) => ({
    ...state,
    isUnAuthInProgress: true,
  }),
}, initial)
