import { takeLatest, call, put } from 'redux-saga/effects'
import * as api from 'services/api'
import { trackingSettingAction } from 'actions'

function* handleFetchTrackingSettings() {
  const { payload, meta } = yield call(api.fetchTrackingSettings)
  yield put(trackingSettingAction.fetchTrackingSettingsDone(payload, meta))
}

function* handleSubmitUpdateTrackingSetting(action) {
  const { payload, meta, error } = yield call(api.submitUpdateTrackingSetting, action.payload)
  yield put(trackingSettingAction.submitUpdateTrackingSettingDone(payload, meta))
  if (!error) yield put(trackingSettingAction.fetchTrackingSettings())
}

function* handleSubmitCreateTrackingSetting(action) {
  const { payload, meta, error } = yield call(api.submitCreateTrackingSetting, action.payload)
  yield put(trackingSettingAction.submitCreateTrackingSettingDone(payload, meta))
  if (!error) yield put(trackingSettingAction.fetchTrackingSettings())
}

export const trackingSettingSaga = [
  takeLatest(trackingSettingAction.fetchTrackingSettings, handleFetchTrackingSettings),
  takeLatest(trackingSettingAction.submitUpdateTrackingSetting, handleSubmitUpdateTrackingSetting),
  takeLatest(trackingSettingAction.submitCreateTrackingSetting, handleSubmitCreateTrackingSetting),
]
