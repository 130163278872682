import { takeLatest, call, put } from 'redux-saga/effects'
import * as api from 'services/api'
import { campaignTemplateAction } from 'actions'

function* handleFetchCampaignTemplates() {
  const { payload, meta } = yield call(api.fetchCampaignTemplates)
  yield put(campaignTemplateAction.fetchCampaignTemplatesDone(payload, meta))
}

export const campaignTemplateSaga = [
  takeLatest(campaignTemplateAction.fetchCampaignTemplates, handleFetchCampaignTemplates),
]
