import React, { useState } from 'react'
import { connect } from 'react-redux'
import { ClipboardLink, ToggleButton, Loading } from 'lib/components'
import { Button, UncontrolledTooltip } from 'reactstrap'
import { trackingSettingAction } from 'actions'

export const FavoriteTrackingCode = ({ company, trackingSetting, dispatch }) => {
  const favoriteTsJson = trackingSetting.items.find(item => item.eventType === 'favorite')
  const favoriteTs = favoriteTsJson ? JSON.parse(favoriteTsJson.setting) : {}
  const defaultTs = {
    itemTag: { params: {} },
  }
  const ts = { ...defaultTs, ...favoriteTs }

  const [itemTagShow, setItemTagShow] = useState(ts.itemTag.show)

  const [itemName, setItemName] = useState(ts.itemTag.params.name)
  const [itemUrl1, setItemUrl1] = useState(ts.itemTag.params.url1)
  const [itemStatus, setItemStatus] = useState(ts.itemTag.params.status)
  const [itemImageUrl1, setItemImageUrl1] = useState(ts.itemTag.params.imageUrl1)
  const [itemCategory1, setItemCategory1] = useState(ts.itemTag.params.category1)
  const [itemDescription, setItemDescription] = useState(ts.itemTag.params.description)
  const [itemSaleStage, setItemSaleStage] = useState(ts.itemTag.params.saleStage)
  const [itemStockStatus, setItemStockStatus] = useState(ts.itemTag.params.stockStatus)
  const [itemStockCount, setItemStockCount] = useState(ts.itemTag.params.stockCount)
  const [itemContentDate, setItemContentDate] = useState(ts.itemTag.params.contentDate)
  const [itemPrice1, setItemPrice1] = useState(ts.itemTag.params.price1)
  const [itemPrice2, setItemPrice2] = useState(ts.itemTag.params.price2)
  const [itemCurrency, setItemCurrency] = useState(ts.itemTag.params.currency)
  const [itemDiscount, setItemDiscount] = useState(ts.itemTag.params.discount)


  const codeText = generateCodeText({
    itemTagShow,
    itemName,
    itemUrl1,
    itemStatus,
    itemImageUrl1,
    itemCategory1,
    itemDescription,
    itemSaleStage,
    itemStockCount,
    itemStockStatus,
    itemContentDate,
    itemPrice1,
    itemPrice2,
    itemCurrency,
    itemDiscount,
  })

  const saveSetting = () => {
    const updateSetting = {
      itemTag: {
        params: {
          name: itemName,
          url1: itemUrl1,
          status: itemStatus,
          imageUrl1: itemImageUrl1,
          category1: itemCategory1,
          description: itemDescription,
          saleStage: itemSaleStage,
          stockCount: itemStockCount,
          stockStatus: itemStockStatus,
          contentDate: itemContentDate,
          price1: itemPrice1,
          price2: itemPrice2,
          currency: itemCurrency,
          discount: itemDiscount,
        },
        show: itemTagShow,
      },
    }
    if (favoriteTsJson) {
      return dispatch(trackingSettingAction.submitUpdateTrackingSetting({
        ...favoriteTsJson,
        setting: JSON.stringify(updateSetting),
      }))
    }
    dispatch(trackingSettingAction.submitCreateTrackingSetting({
      eventType: 'favorite',
      setting: JSON.stringify(updateSetting),
    }))
  }
  return (
    <Loading isLoading={trackingSetting.isFetching}>
      <p>このイベントタグはお気に入り一覧ページに配置します。お気に入りに追加された商品の情報を収集します。</p>
      <div className="mb-3">
        <ToggleButton
          className="mr-1 mb-1"
          defaultActive={itemTagShow}
          onClick={() => setItemTagShow(!itemTagShow)}
        >商品情報をあわせて更新
        </ToggleButton>
      </div>
      { itemTagShow
        && (
          <>
            <div className="mb-3">
              <h5>商品属性データ</h5>
              <div className="mb-3">
                <p className="mb-0">任意パラメータ</p>
                <ToggleButton
                  className="mr-1 mb-1"
                  defaultActive={itemName}
                  onClick={() => setItemName(!itemName)}
                  id="itemName"
                >name
                </ToggleButton>
                <ToggleButton
                  className="mr-1 mb-1"
                  defaultActive={itemUrl1}
                  onClick={() => setItemUrl1(!itemUrl1)}
                  id="itemUrl1"
                >url1
                </ToggleButton>
                <ToggleButton
                  className="mr-1 mb-1"
                  defaultActive={itemStatus}
                  onClick={() => setItemStatus(!itemStatus)}
                  id="itemStatus"
                >status
                </ToggleButton>
                <ToggleButton
                  className="mr-1 mb-1"
                  defaultActive={itemImageUrl1}
                  onClick={() => setItemImageUrl1(!itemImageUrl1)}
                  id="itemImageUrl1"
                >imageUrl1
                </ToggleButton>
                <ToggleButton
                  className="mr-1 mb-1"
                  defaultActive={itemCategory1}
                  onClick={() => setItemCategory1(!itemCategory1)}
                  id="itemCategory1"
                >category1
                </ToggleButton>
                <ToggleButton
                  className="mr-1 mb-1"
                  defaultActive={itemDescription}
                  onClick={() => setItemDescription(!itemDescription)}
                  id="itemDescription"
                >description
                </ToggleButton>
                <ToggleButton
                  className="mr-1 mb-1"
                  defaultActive={itemSaleStage}
                  onClick={() => setItemSaleStage(!itemSaleStage)}
                  id="itemSaleStage"
                >saleStage
                </ToggleButton>
                <ToggleButton
                  className="mr-1 mb-1"
                  defaultActive={itemStockStatus}
                  onClick={() => setItemStockStatus(!itemStockStatus)}
                  id="itemStockStatus"
                >stockStatus
                </ToggleButton>
                <ToggleButton
                  className="mr-1 mb-1"
                  defaultActive={itemStockCount}
                  onClick={() => setItemStockCount(!itemStockCount)}
                  id="itemStockCount"
                >stockCount
                </ToggleButton>
                <ToggleButton
                  className="mr-1 mb-1"
                  defaultActive={itemContentDate}
                  onClick={() => setItemContentDate(!itemContentDate)}
                  id="itemContentDate"
                >contentDate
                </ToggleButton>
                <ToggleButton
                  className="mr-1 mb-1"
                  defaultActive={itemPrice1}
                  onClick={() => setItemPrice1(!itemPrice1)}
                  id="itemPrice1"
                >price1
                </ToggleButton>
                <ToggleButton
                  className="mr-1 mb-1"
                  defaultActive={itemPrice2}
                  onClick={() => setItemPrice2(!itemPrice2)}
                  id="itemPrice2"
                >price2
                </ToggleButton>
                <ToggleButton
                  className="mr-1 mb-1"
                  defaultActive={itemCurrency}
                  onClick={() => setItemCurrency(!itemCurrency)}
                  id="itemCurrency"
                >currency
                </ToggleButton>
                <ToggleButton
                  className="mr-1 mb-1"
                  defaultActive={itemDiscount}
                  onClick={() => setItemDiscount(!itemDiscount)}
                  id="itemDiscount"
                >discount
                </ToggleButton>
              </div>
              <UncontrolledTooltip target="itemName">
              商品名
              </UncontrolledTooltip>
              <UncontrolledTooltip target="itemUrl1">
              商品URL
              </UncontrolledTooltip>
              <UncontrolledTooltip target="itemStatus">
              商品の有効／無効ステータス
              </UncontrolledTooltip>
              <UncontrolledTooltip target="itemImageUrl1">
              商品画像URL
              </UncontrolledTooltip>
              <UncontrolledTooltip target="itemCategory1">
              商品の属する第一階層のカテゴリ
              </UncontrolledTooltip>
              <UncontrolledTooltip target="itemDescription">
              商品の説明文
              </UncontrolledTooltip>
              <UncontrolledTooltip target="itemSaleStage">
              商品の販売上の取扱状態
              </UncontrolledTooltip>
              <UncontrolledTooltip target="itemStockStatus">
              商品の在庫状態
              </UncontrolledTooltip>
              <UncontrolledTooltip target="itemStockCount">
              商品の在庫数
              </UncontrolledTooltip>
              <UncontrolledTooltip target="itemContentDate">
              商品情報の更新日時。ブラウザキャッシュなどにより古い商品情報で更新されるのを防ぎます。
              </UncontrolledTooltip>
              <UncontrolledTooltip target="itemPrice1">
              商品の価格
              </UncontrolledTooltip>
              <UncontrolledTooltip target="itemPrice2">
              商品の推奨販売価格（値下げ前）
              </UncontrolledTooltip>
              <UncontrolledTooltip target="itemCurrency">
              商品の価格に関する記述する通貨
              </UncontrolledTooltip>
              <UncontrolledTooltip target="itemDiscount">
              商品の割引率
              </UncontrolledTooltip>
            </div>
          </>
        )
      }
      <div className="mb-3">
        <Button
          color="primary"
          onClick={saveSetting}
          id="saveTrackingSetting"
        >設定を保存
        </Button>
      </div>
      <div>
        <ClipboardLink copyText={codeText} />
      </div>
      <pre className="bg-gray-lighter p-3 code-text">
        { codeText }
      </pre>
    </Loading>
  )
}

const generateCodeText = ({
  itemTagShow,
  itemName, itemUrl1, itemStatus, itemImageUrl1, itemCategory1, itemDescription, itemSaleStage,
  itemStockCount, itemStockStatus, itemContentDate, itemPrice1, itemPrice2, itemCurrency, itemDiscount,
}) => {
  const itemAttributeText = [
    itemName && "'name': '{商品名}'",
    itemUrl1 && "'url1': '{商品URL}'",
    itemStatus && "'status': '{商品の有効／無効ステータス}'",
    itemImageUrl1 && "'imageUrl1': '{商品画像URL}'",
    itemCategory1 && "'catgegory1': '{商品が属する第一階層カテゴリ}'",
    itemDescription && "'description': '{商品の説明文}'",
    itemSaleStage && "'saleStage': '{商品の販売上の取扱状態}'",
    itemStockStatus && "'stockStatus': '{商品の在庫状態}'",
    itemStockCount && "'stockCount': '{商品の在庫数}'",
    itemContentDate && "'contentDate': '{商品情報更新日時}'",
    itemPrice1 && "'price1': '{商品の価格}'",
    itemPrice2 && "'price2': '{商品の推奨販売価格（値下げ前）}'",
    itemCurrency && "'currency': '{商品の価格に記述する通貨}'",
    itemDiscount && "'discount': '{商品の割引率}'",
  ].filter(e => !!e).join(',\n      ')

  const template = `
<script type='text/javascript'>
  _sna_dam.push(['behaviorType', 14]);
  _sna_dam.push(['userCode', '{お客様（一般ユーザ）を識別するためのID}']);
  // 投入アイテム分、_sna_dam.pushを繰り返します。
  _sna_dam.push(['item', {
    ${!itemAttributeText || !itemTagShow ? '' : `
    'attributes': {
      ${itemAttributeText}
    },`
}
    'code': '{アイテムコード}'
  }]);
</script>`
  return template.replace(/^\s*\n/gm, '')
}

const select = ({ company, trackingSetting }) => ({ company, trackingSetting })

export default connect(select)(FavoriteTrackingCode)
