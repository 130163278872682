import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap'
import swal from 'sweetalert';

/**
 * Wrapper component for sweetalert plugin
 */
const Swal = ({ callback, children, options, ...rest }) => {
    const handleClick = e => {
        e.preventDefault();
        swal(options).then(callback)
    }

    return (
        <Button {...rest} onClick={handleClick}>
            {children}
        </Button>
    )
}

Swal.propType = {
    /** swal options object */
    options: PropTypes.object.isRequired,
    /** callback function for swal response */
    callback: PropTypes.func
}

Swal.defaultProps = {
    callback: () => {}
}

export default Swal;
