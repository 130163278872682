import { call, put, takeLatest } from 'redux-saga/effects'
import * as api from 'services/adminApi'
import { adminCompanyStatusAction } from 'actions'

function* handleFetchCompanyStatus(action) {
  const { payload, meta } = yield call(api.fetchCompanyStatus, action.payload)
  yield put(adminCompanyStatusAction.fetchCompanyStatusDone(payload, meta))
}

export function* handleSubmitUpdateCompanyStatus(action) {
  const { payload, meta } = yield call(api.submitUpdateCompanyStatus, action.payload)
  yield put(adminCompanyStatusAction.submitUpdateCompanyStatusDone(payload.companyStatus, meta))
}

export function* handleSubmitTerminateCompanyStatus(action) {
  const { payload, meta } = yield call(api.submitTerminateCompanyStatus, action.payload)
  yield put(adminCompanyStatusAction.submitTerminateCompanyStatusDone(payload, meta))
}

export const adminCompanyStatusSaga = [
  takeLatest(adminCompanyStatusAction.fetchCompanyStatus, handleFetchCompanyStatus),
  takeLatest(adminCompanyStatusAction.submitUpdateCompanyStatus, handleSubmitUpdateCompanyStatus),
  takeLatest(adminCompanyStatusAction.submitTerminateCompanyStatus, handleSubmitTerminateCompanyStatus),
]
