import {
  Button, FormGroup, FormText, UncontrolledCollapse,
} from 'reactstrap'
import JsBeautify from 'js-beautify'
import React, { useState } from 'react'
import { UnControlled as CodeMirror } from 'react-codemirror2'
import { mailPreviewAction } from 'actions'
import { FieldCode, Label, MailPreviewButton } from 'lib/components'
import { campaignConstant as cc, companyCampaignTemplateValueConstant as cctvc } from 'config/constants'
import CampaignMailCodeHelp from 'components/parts/campaign/CampaignMailCodeHelp'
import ManualLink from 'components/parts/manual/ManualLink'

export const CampaignHtmlFields = ({
  company, companyCampaignTemplateValue, formType, campaignType, submitAction, values, setFieldValue, dispatch,
}) => {
  const [codemirrorEditors, setCodemirrorEditors] = useState([])
  const [editorWindow, setEditorWindow] = useState(null)

  const isForHtmlEditor = values.htmlBody !== undefined && values.htmlBody.startsWith('<!-- FOR-HTML-EDITOR -->')
  if (submitAction === 'create' && isForHtmlEditor) {
    let htmlBody = values.htmlBody

    const replaceList = [
      [cctvc.siteName, company.item.siteName],
      [cctvc.companyName, company.item.name],
      [cctvc.siteUrl, company.item.siteUrl],

      [cctvc.shopLogoImageUrl, companyCampaignTemplateValue.item.shopLogoImageUrl],
      [cctvc.shopMainImageUrl, companyCampaignTemplateValue.item.shopMainImageUrl],
      [cctvc.copyright, companyCampaignTemplateValue.item.copyright],

      [cctvc.corporateSiteUrl, companyCampaignTemplateValue.item.corporateSiteUrl],
      [cctvc.cartPageUrl, companyCampaignTemplateValue.item.cartPageUrl],
      [cctvc.contactUrl, companyCampaignTemplateValue.item.contactUrl],
      [cctvc.privacyPolicyUrl, companyCampaignTemplateValue.item.privacyPolicyUrl],
      [cctvc.myPageUrl, companyCampaignTemplateValue.item.myPageUrl],

      [cctvc.facebookUrl, companyCampaignTemplateValue.item.facebookUrl],
      [cctvc.twitterUrl, companyCampaignTemplateValue.item.twitterUrl],
      [cctvc.lineUrl, companyCampaignTemplateValue.item.lineUrl],
      [cctvc.instagramUrl, companyCampaignTemplateValue.item.instagramUrl],
      [cctvc.pinterestUrl, companyCampaignTemplateValue.item.pinterestUrl],
      [cctvc.youtubeUrl, companyCampaignTemplateValue.item.youtubeUrl],
    ]

    replaceList.forEach(([target, replace]) => {
      if (htmlBody.indexOf(target) !== -1 && replace !== null && replace !== '' && replace !== undefined) {
        htmlBody = values.htmlBody.replaceAll(target, replace)
      }
    })

    if (htmlBody !== values.htmlBody) {
      setFieldValue('htmlBody', htmlBody)
    }
  }
  return (
    <>
      <Label>HTML編集</Label>
      <div className="mb-3">
        <div className="form-group form-inline mb-0">
          <Button
            color="secondary"
            id="editCodeToggler"
            className="mr-3"
            onClick={() => {
              codemirrorEditors.forEach(codemirrorEditor => codemirrorEditor.refresh())
            }}
          >
            HTMLコードを編集する
          </Button>
          {
            isForHtmlEditor && (
              <Button
                color="info"
                onClick={() => {
                  if (editorWindow !== null && editorWindow.closed === false) {
                    // 2つ目は開けないようにする
                    editorWindow.focus()
                    return
                  }
                  window.campaign = values
                  window.campaignType = campaignType
                  const width = 900
                  const height = 700
                  const top = (window.screen.height / 2) - (height / 2)
                  const left = (window.screen.width / 2) - (width / 2)
                  const newWindow = window.open(
                    '/campaign_html_editor',
                    '_blank',
                    `width=${width},height=${height},top=${top},left=${left}`,
                  )
                  newWindow.onunload = (e) => {
                    if (e.target.URL === 'about:blank') {
                      return
                    }
                    setFieldValue('htmlBody', JsBeautify.html(window.campaign.htmlBody, { indent_size: 2, wrap_line_length: 0 }))
                    setFieldValue('style', JsBeautify.css(window.campaign.style, { indent_size: 2 }))
                  }
                  setEditorWindow(newWindow)
                }}
              >HTMLエディタを開く
              </Button>
            )
          }
        </div>
        {
          isForHtmlEditor && (
            <>
              <FormText>コードを直接編集された場合、HTMLエディタが正常に動作しなくなる可能性があります。</FormText>
              <FormText>
                <ManualLink filename="mailope_creative/#43-html">
                  HTMLエディタについて
                </ManualLink>
              </FormText>
            </>
          )
        }
      </div>
      <UncontrolledCollapse toggler="#editCodeToggler" className="mb-5">
        <FormGroup>
          <Label>HTMLヘッダ</Label>
          <CodeMirror
            options={{
              lineWrapping: true,
              autoRefresh: true,
              lineNumbers: true,
              mode: 'htmlmixed',
              readOnly: true,
            }}
            value={cc.defaultHtmlMailHeader}
            className="default-height transparent-linenumbers"
            editorDidMount={(codemirrorEditor) => {
              setCodemirrorEditors(prevCodemirrorEditors => [...prevCodemirrorEditors, codemirrorEditor])
            }}
          />
          <FieldCode
            name="htmlHeader"
            mode="htmlmixed"
            editorDidMount={(codemirrorEditor) => {
              setCodemirrorEditors(prevCodemirrorEditors => [...prevCodemirrorEditors, codemirrorEditor])
            }}
          />
        </FormGroup>
        <FormGroup>
          <Label require>HTML本文</Label>
          <FieldCode
            name="htmlBody"
            mode="htmlmixed"
            editorDidMount={(codemirrorEditor) => {
              setCodemirrorEditors(prevCodemirrorEditors => [...prevCodemirrorEditors, codemirrorEditor])
            }}
          />
          <FormText>bodyタグの内側を記入してください</FormText>
        </FormGroup>
        <div className="mb-5">
          <Button
            color="secondary"
            id="codeHelpToggler1"
            onClick={() => {
            }}
          >
            コードのヘルプを表示する
          </Button>
        </div>
        <UncontrolledCollapse toggler="#codeHelpToggler1" className="mb-5">
          <CampaignMailCodeHelp
            campaignType={campaignType}
          />
        </UncontrolledCollapse>
        <FormGroup>
          <Label>HTML用スタイルシート</Label>
          <FieldCode
            name="style"
            mode="css"
            editorDidMount={(codemirrorEditor) => {
              setCodemirrorEditors(prevCodemirrorEditors => [...prevCodemirrorEditors, codemirrorEditor])
            }}
          />
          <FormText>メール送信時にキャンペーンメール本文の要素に自動的にインライン展開されます</FormText>
        </FormGroup>
        <div className="mb-5">
          <MailPreviewButton
            color="info"
            onClickCallback={() => {
              dispatch(mailPreviewAction.submitCreateMailPreview({
                ...values,
                _type: formType,
              }))
            }}
          >
            メールのプレビュー
          </MailPreviewButton>
        </div>
      </UncontrolledCollapse>
    </>
  )
}

export default CampaignHtmlFields
