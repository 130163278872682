import { createActions } from 'redux-actions'

const CHECK_EMAIL_UNIQUENESS_DONE = 'CHECK_EMAIL_UNIQUENESS_DONE'

export const emailUniquenessAction = createActions(
  {
    [CHECK_EMAIL_UNIQUENESS_DONE]: [
      (...args) => args[0],
      (...args) => Object.assign({ notification: 'FAILURE', failMess: 'すでに存在するメールアドレスです。' }, args[1]),
    ],
  },

)
