const generateHtmlEditorMessages = () => (editor) => {
  editor.I18n.addMessages({
    ja: {
      domComponents: {
        names: {
          wrapper: '本文',
          text: 'テキスト',
          image: '画像',
          link: 'リンク',
          ul: 'リスト',
          li: 'リスト項目',

          area: 'エリア',
          items: '商品',
          snsIcons: 'SNSアイコン',
          linkButton: 'ボタン',
        },
      },
      styleManager: {
        properties: {
          'max-width': '横幅',
          'padding-top': '間隔(上)',
          'padding-bottom': '間隔(下)',
          'background-color': '背景色',
          'text-align': 'テキスト配置',
        },
      },
      traitManager: {
        traits: {
          labels: {
            // title要素のツールチップが邪魔なので、divを閉じて無理やり別ブロックにする
            src: "</div><div class='gjs-label'>画像URL<i class='fa fa-question link-like gjs-tooltip' data-tooltip='絶対パスで指定してください'></i>",
            href: 'リンク先',
            'item-num': "</div><div class='gjs-label'>商品枠数<i class='fa fa-question link-like gjs-tooltip' data-tooltip='商品の最大表示数'></i>",
          },
          attributes: {
            src: { placeholder: 'https://google.com' },
            href: { placeholder: 'https://google.com' },
          },
        },
      },
      selectorManager: {
        selected: '選択中',
      },
      panels: {
        buttons: {
          titles: {
            'open-layers': '配置済みのパーツを見る',
            'open-blocks': '新しくパーツを配置する',
          },
        },
      },
    },
  })
}

export default generateHtmlEditorMessages
