import { createActions } from 'redux-actions'

const FETCH_COMPANY_INVOICE_TERM = 'FETCH_COMPANY_INVOICE_TERM'
const FETCH_COMPANY_INVOICE_TERM_DONE = 'FETCH_COMPANY_INVOICE_TERM_DONE'

const FETCH_COMPANY_INVOICE_TERMS = 'FETCH_COMPANY_INVOICE_TERMS'
const FETCH_COMPANY_INVOICE_TERMS_DONE = 'FETCH_COMPANY_INVOICE_TERMS_DONE'

const SUBMIT_UPDATE_COMPANY_INVOICE_TERM = 'SUBMIT_UPDATE_COMPANY_INVOICE_TERM'
const SUBMIT_UPDATE_COMPANY_INVOICE_TERM_DONE = 'SUBMIT_UPDATE_COMPANY_INVOICE_TERM_DONE'

const SUBMIT_CREATE_COMPANY_INVOICE_TERM = 'SUBMIT_CREATE_COMPANY_INVOICE_TERM'
const SUBMIT_CREATE_COMPANY_INVOICE_TERM_DONE = 'SUBMIT_CREATE_COMPANY_INVOICE_TERM_DONE'

const SUBMIT_DELETE_COMPANY_INVOICE_TERM = 'SUBMIT_DELETE_COMPANY_INVOICE_TERM'
const SUBMIT_DELETE_COMPANY_INVOICE_TERM_DONE = 'SUBMIT_DELETE_COMPANY_INVOICE_TERM_DONE'

export const adminCompanyInvoiceTermAction = createActions(
  {
    [FETCH_COMPANY_INVOICE_TERM_DONE]: [
      (...args) => args[0],
      (...args) => args[1],
    ],
    [FETCH_COMPANY_INVOICE_TERMS_DONE]: [
      (...args) => args[0],
      (...args) => args[1],
    ],
    [SUBMIT_UPDATE_COMPANY_INVOICE_TERM_DONE]: [
      (...args) => args[0],
      (...args) => Object.assign({ notification: 'ALL_CASE' }, args[1]),
    ],
    [SUBMIT_CREATE_COMPANY_INVOICE_TERM_DONE]: [
      (...args) => args[0],
      (...args) => Object.assign({ notification: 'ALL_CASE' }, args[1]),
    ],
    [SUBMIT_DELETE_COMPANY_INVOICE_TERM_DONE]: [
      (...args) => args[0],
      (...args) => Object.assign({ notification: 'ALL_CASE' }, args[1]),
    ],
  },
  FETCH_COMPANY_INVOICE_TERM,
  FETCH_COMPANY_INVOICE_TERMS,
  SUBMIT_UPDATE_COMPANY_INVOICE_TERM,
  SUBMIT_CREATE_COMPANY_INVOICE_TERM,
  SUBMIT_DELETE_COMPANY_INVOICE_TERM,
  {
    prefix: 'ADMIN',
  },
)
