import React from 'react'
import ContentWrapper from 'components/layout/ContentWrapper'
import ManualLinkList from 'components/parts/manual/ManualList'

const ManualIntroductionPage = () => {
  const manuals = [
    {
      title: 'リタゲメールASP導入ガイド',
      links: [
        {
          text: 'リタゲメールASP導入ガイド',
          filename: 'rtg_guidance',
        },
      ],
    },
    {
      title: '導入時チェックリスト',
      links: [
        {
          text: 'リタゲメール導入時チェックリスト',
          filename: 'rtg_checklist_for_introduction',
        },
      ],
    },
    {
      title: '動的タグ設定編',
      links: [
        {
          text: '動的タグ設定編',
          filename: 'mansys_jstag',
        },
      ],
    },
    {
      title: 'DNS設定編',
      links: [
        {
          text: 'DNS設定編',
          filename: 'mansys_dns',
        },
      ],
    },
    {
      title: 'ファイル連携編',
      links: [
        {
          text: 'ファイル連携編',
          filename: 'mansys_file',
        },
        {
          text: '定期自動ファイルインポート編',
          filename: 'mansys_fileimport',
        },
        {
          text: '手動ファイルアップロード編',
          filename: 'mansys_fileupload',
        },
      ],
    },
    {
      title: 'LINE連携編',
      links: [
        {
          text: 'LINE連携編',
          filename: 'mansys_line_setting',
        },
      ],
    },
  ]
  return (
    <ContentWrapper>
      <ManualLinkList manuals={manuals} />
    </ContentWrapper>
  )
}

export default ManualIntroductionPage
