import { call, put, takeLatest } from 'redux-saga/effects'
import * as api from 'services/adminApi'
import { adminRecoAccountAction } from 'actions'

export function* handleFetchRecoAccounts(action) {
  const { payload, meta } = yield call(api.fetchRecoAccounts)
  yield put(adminRecoAccountAction.fetchRecoAccountsDone(payload, meta))
}

export const adminRecoAccountSaga = [
  takeLatest(adminRecoAccountAction.fetchRecoAccounts, handleFetchRecoAccounts),
]
