import React from 'react'
import { compose } from 'redux'
import { withRouter } from 'react-router'
import { push } from 'connected-react-router'
import { Formik, Form } from 'formik'
import { connect } from 'react-redux'
import { Button, FormGroup, CardBody } from 'reactstrap'
import { deserializeFromQueryString, serializeToQueryString } from 'lib/utils'
import { FieldSingle, Label } from 'lib/components'

import { campaignConstant as cc } from 'config/constants'

export const CampaignRefineSearchForm = ({ location, dispatch, cookies, setCookie }) => {
  const queryObj = deserializeFromQueryString(location.search) || deserializeFromQueryString(cookies.campaignListFilterQuery) || {}
  return (
    <Formik
      initialValues={{
        campaign: queryObj.campaign || 'all',
        deliveryStatus: queryObj.deliveryStatus || 'all',
      }}
      onSubmit={(values) => {
        const queryStr = serializeToQueryString({ ...queryObj, ...values })
        setCookie('campaignListFilterQuery', queryStr)
        dispatch(push(`${location.pathname}${queryStr}`))
      }}
    >
      <CardBody>
        <Form noValidate>
          <FormGroup className="mb-3">
            <Label>キャンペーンタイプ</Label>
            <FieldSingle type="select" name="campaign" options={cc.campaignTypeConditions} />
          </FormGroup>
          <FormGroup className="mb-3">
            <Label>配信状態</Label>
            <FieldSingle type="select" name="deliveryStatus" options={cc.deliveryStatus} />
          </FormGroup>
          <div className="text-center mt-4"><Button color="primary" type="submit">絞り込み</Button></div>
        </Form>
      </CardBody>
    </Formik>
  )
}

export default compose(
  connect(),
  withRouter,
)(CampaignRefineSearchForm)
