import { handleActions } from 'redux-actions'
import { abandonedCampaignAction as action } from 'actions'

const initialState = {
  items: [],
  isFetching: false,
  isSubmitting: false,
  isSendingTestMail: false,
}

export const abandonedCampaign = handleActions({
  [action.fetchAbandonedCampaigns]: (state) => ({
    ...state,
    isFetching: true,
  }),
  [action.fetchAbandonedCampaignsDone]: (state, action) => ({
    ...state,
    isFetching: false,
    items: action.error ? state.items : action.payload,
    processResult: action.error ? action.payload : 'SUCCESS',
  }),
  [action.submitCreateAbandonedCampaign]: (state) => ({
    ...state,
    isSubmitting: true,
  }),
  [action.submitCreateAbandonedCampaignDone]: (state, action) => ({
    ...state,
    isSubmitting: false,
    processResult: action.error ? action.payload : 'SUCCESS',
  }),
  [action.submitUpdateAbandonedCampaign]: (state) => ({
    ...state,
    isSubmitting: true,
  }),
  [action.submitUpdateAbandonedCampaignDone]: (state, action) => {
    if (action.error) {
      return {
        ...state,
        isSubmitting: false,
        processResult: action.payload,
      }
    }
    const items = state.items.map(obj => (obj.id === action.payload.id ? action.payload : obj))
    return {
      ...state,
      isSubmitting: false,
      items,
      processResult: action.error ? action.payload : 'SUCCESS',
    }
  },
  [action.submitDeleteAbandonedCampaign]: (state) => ({
    ...state,
    isSubmitting: true,
  }),
  [action.submitDeleteAbandonedCampaignDone]: (state, action) => {
    if (action.error) {
      return {
        ...state,
        isSubmitting: false,
        processResult: action.payload,
      }
    }
    const items = state.items.filter(obj => obj.id !== action.payload.id)
    return {
      ...state,
      isSubmitting: false,
      items,
      processResult: action.error ? action.payload : 'SUCCESS',
    }
  },
  [action.submitSendAbandonedCampaignTestMail]: (state) => ({
    ...state,
    isSendingTestMail: true,
  }),
  [action.submitSendAbandonedCampaignTestMailDone]: (state, action) => ({
    ...state,
    isSendingTestMail: false,
    processResult: action.error ? action.payload : 'SUCCESS',
  }),
  [action.submitEnableAbandonedCampaignDone]: (state, action) => {
    if (action.error) {
      return {
        ...state,
        isSubmitting: false,
        processResult: action.payload,
      }
    }
    const items = state.items.map(obj => (obj.id === action.payload.id ? action.payload : obj))
    return {
      ...state,
      isSubmitting: false,
      items,
      processResult: action.error ? action.payload : 'SUCCESS',
    }
  },
  [action.searchAbandonedCampaigns]: (state) => ({
    ...state,
    isFetching: true,
  }),
  [action.searchAbandonedCampaignsSuccess]: (state, action) => ({
    ...state,
    isFetching: false,
    items: action.payload,
    processResult: 'SUCCESS',
  }),
  [action.searchAbandonedCampaignsFailure]: (state, action) => ({
    ...state,
    isFetching: false,
    processResult: action.payload,
  }),
}, initialState)
