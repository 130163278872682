import React from 'react'
import { compose } from 'redux'
import { withRouter } from 'react-router'
import { push } from 'connected-react-router'
import { connect } from 'react-redux'
import Select from 'react-select'
import { serializeToQueryString, uniq } from 'lib/utils'
import { Loading } from 'lib/components'

export const CampaignTemplateSearchForm = ({
  campaignType, searchTags, campaignTemplate, location, dispatch,
}) => {
  const uniqTags = getUniqTags(campaignTemplate)
  const nestedTags = uniqTags.map(tag => ({ value: tag.id, label: tag.name }))
  if (campaignTemplate.items.length > 0 && searchTags === undefined) {
    const ids = uniqTags.filter(tag => defaultSelectTagName(campaignType).includes(tag.name)).map(tag => tag.id)
    pushSearchQuery(location, dispatch, ids)
    return <></>
  }
  const defaultValue = nestedTags.filter(tag => (searchTags.includes(tag.value)))
  return (
    <Loading isLoading={campaignTemplate.isFetching}>
      <div style={{ padding: '10px' }}>
        <Select
          defaultValue={defaultValue}
          name="templateTags"
          isMulti
          options={nestedTags}
          placeholder="タグを指定してテンプレートの絞り込みができます"
          onChange={(value) => {
            const ids = value.map(v => (v.value))
            pushSearchQuery(location, dispatch, ids)
          }}
        />
      </div>
    </Loading>
  )
}

const getUniqTags = campaignTemplate => uniq(
  Array.prototype.concat.apply(
    [], campaignTemplate.items.map(item => item.templateTags),
  ),
  tag => tag.id,
)

const pushSearchQuery = (location, dispatch, ids) => {
  dispatch(push(`${location.pathname}${serializeToQueryString({ tags: ids }, { isNested: true })}`))
}

const defaultSelectTagName = (campaignType) => {
  switch (campaignType) {
  case 'abandoned':
    return ['HTMLエディタ', '放棄系']
  case 'completed':
    return ['HTMLエディタ', '完了系']
  case 'contextual':
    return ['HTMLエディタ', '商品連動系']
  case 'prospects':
    return ['HTMLエディタ', 'ポップアップ&メール']
  default:
    return []
  }
}

const select = ({ campaignTemplate }) => ({ campaignTemplate })

export default compose(
  connect(select),
  withRouter,
)(CampaignTemplateSearchForm)
