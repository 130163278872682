import { createActions } from 'redux-actions'

const FETCH_ITEMS = 'FETCH_ITEMS'
const FETCH_ITEMS_DONE = 'FETCH_ITEMS_DONE'

const SUBMIT_UPDATE_ITEM = 'SUBMIT_UPDATE_ITEM'
const SUBMIT_UPDATE_ITEM_DONE = 'SUBMIT_UPDATE_ITEM_DONE'

export const itemAction = createActions(
  {
    [FETCH_ITEMS_DONE]: [
      (...args) => args[0],
      (...args) => args[1],
    ],
    [SUBMIT_UPDATE_ITEM_DONE]: [
      (...args) => args[0],
      (...args) => Object.assign({ notification: 'ALL_CASE' }, args[1]),
    ],
  },
  FETCH_ITEMS,
  SUBMIT_UPDATE_ITEM,
)
