import React from 'react'
import { connect } from 'react-redux'
import { companyAction, adminPermissionAction } from 'actions'
import { useMountEffect } from 'lib/hooks'

const Authorized = ({isAdmin, dispatch}) => {
  useMountEffect(() => {
    isAdmin ?
      dispatch(adminPermissionAction.fetchAdminPermission())
      : dispatch(companyAction.fetchCompany())
  })

  return (<React.Fragment/>)
}

export default connect()(Authorized)
