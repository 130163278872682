import { arraysToArray } from "lib/utils"
import { reportConstant as rc } from "config/constants"

const EXCEED_MAX_TABLE_ERROR = 'Report::ExceedMaxTableError'

export const baseCampaignTypes = arraysToArray(rc.campaignTypes, 1)

export const isGroupCampaign = condition => baseCampaignTypes.includes(condition.campaign)

export const shouldDisplaySummaryCost = (condition) => {
  // グループ以外を指定した場合は集計できない
  if (!isGroupCampaign(condition)) {
    return false
  }

  // 「今週or先週or期間指定」×「月次」でもシステム的にはコスト算出可能だが、混乱のもとになるのであえて出さない
  return ['this_month', 'last_month'].includes(condition.term)
}

export const shouldDisplayDetailCost = (condition) => {
  return shouldDisplaySummaryCost(condition) && condition.transition === 'monthly'
}

export const valueOrError = (value, errors) => {
  if (errors.includes(EXCEED_MAX_TABLE_ERROR)) {
    return '【エラー】テーブル上限を超えています'
  }
  return value
}
