import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import ContentWrapper from 'components/layout/ContentWrapper'
import { Card, Button } from 'reactstrap'

import { useMountEffect } from 'lib/hooks'
import ProspectsCampaignList from 'components/parts/campaign/ProspectsCampaignList'
import { prospectsCampaignAction } from 'actions'

export const ProspectsCampaignListPage = ({ dispatch }) => {
  useMountEffect(() => {
    dispatch(prospectsCampaignAction.fetchProspectsCampaigns({ show_finished: true }))
  })

  return (
    <ContentWrapper>
      <div className="content-heading">
        <div>メール設定一覧</div>
        <div className="ml-3">
          <Button tag={Link} to="/campaign_template_list/prospects" color="secondary">新規作成</Button>
        </div>
      </div>
      <Card className="card-default">
        <ProspectsCampaignList />
      </Card>
    </ContentWrapper>
  )
}

export default connect()(ProspectsCampaignListPage)
