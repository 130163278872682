import React from 'react'
import { connect } from 'react-redux'
import ContentWrapper from 'components/layout/ContentWrapper'
import { Card, CardBody, CardFooter } from 'reactstrap'

import { deserializeFromQueryString } from 'lib/utils'
import { Pagination } from 'lib/components'
import BehaviorHistorySearchConditionForm from 'components/parts/behaviorHistory/BehaviorHistorySearchConditionForm'
import BehaviorHistoryList from 'components/parts/behaviorHistory/BehaviorHistoryList'
import OptoutSettings from 'components/parts/behaviorHistory/OptoutSettings'


export const BehaviorHistoryPage = ({location, behaviorHistory}) => {
  const queryObj = deserializeFromQueryString(location.search) || {}
  const page = Number(queryObj.page) || 1
  return (
    <ContentWrapper>
      <div className="content-heading">
        <div>行動履歴検索</div>
      </div>
      <Card className="card-default">
        <CardBody>
          <BehaviorHistorySearchConditionForm />
        </CardBody>
      </Card>
      <OptoutSettings />
      <Card className="card-default">
        <CardBody>
          <BehaviorHistoryList limit={20} offset={(page - 1) * 20} />
        </CardBody>
        <CardFooter>
          <Pagination
            totalItemCount={behaviorHistory.items.length}
            limit={20}
          />
        </CardFooter>
      </Card>
    </ContentWrapper>
  )
}

const select = ({ behaviorHistory }) => ({ behaviorHistory })

export default connect(select)(BehaviorHistoryPage)
