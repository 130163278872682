import React from 'react'
import {
  Card, CardBody, CardHeader, CardTitle,
} from 'reactstrap'
import ManualLink from 'components/parts/manual/ManualLink'

const ManualList = ({ manuals }) => manuals.map(manual => (
  <Card className="card-default">
    <CardHeader>
      <CardTitle tag="h3">{manual.title}</CardTitle>
    </CardHeader>
    <CardBody>
      <ul>
        {manual.links.map(link => (
          <>
            <li>
              <ManualLink filename={link.filename}>
                {link.text}
              </ManualLink>
            </li>
            {
              link.children && (
                <ul>
                  {link.children.map(child => (
                    <li>
                      <ManualLink filename={child.filename}>
                        {child.text}
                      </ManualLink>
                    </li>
                  ))}
                </ul>
              )
            }
          </>
        ))}
      </ul>
    </CardBody>
  </Card>
))

export default ManualList
