import React from 'react'
import { connect } from 'react-redux'
import {
  CardHeader, CardBody,
} from 'reactstrap'
import { generalConstant as gc } from 'config/constants'
import { Loading } from 'lib/components'

const SystemOrderTable = ({ adminCompanySystemOrder }) => (
  <Loading isLoading={adminCompanySystemOrder.isFetching}>
    <CardHeader>システム情報</CardHeader>
    <CardBody>
      <table>
        <tbody>
          <tr>
            <th>サイトタイプ</th>
            <td>{gc.siteType[adminCompanySystemOrder.item.siteType]}</td>
          </tr>
          <tr>
            <th>システムタイプ</th>
            <td>{gc.systemType[adminCompanySystemOrder.item.systemType]}</td>
          </tr>
          <tr>
            <th>サイト月刊PV数</th>
            <td>{gc.sitePv[adminCompanySystemOrder.item.sitePv]}</td>
          </tr>
          <tr>
            <th>保有会員データ件数</th>
            <td>{gc.customerDataCount[adminCompanySystemOrder.item.customerDataCount]}</td>
          </tr>
          <tr>
            <th>保有アイテムデータ数</th>
            <td>{gc.itemDataCount[adminCompanySystemOrder.item.itemDataCount]}</td>
          </tr>
          <tr>
            <th>自由記入欄</th>
            <td>{adminCompanySystemOrder.item.freeField}</td>
          </tr>
        </tbody>
      </table>
    </CardBody>
  </Loading>
)

const select = ({ adminCompanySystemOrder }) => ({ adminCompanySystemOrder })

export default connect(select)(SystemOrderTable)
