import React from 'react'
import { connect } from 'react-redux'
import { Formik, Form } from 'formik'
import { Button, FormGroup } from 'reactstrap'
import * as Yup from 'yup'
import * as customYupValidation from 'lib/yupValidation'
import { FieldSingle, Label } from 'lib/components'
import { userAction } from 'actions'

Yup.addMethod(Yup.string, 'equalTo', customYupValidation.equalTo)

const validationSchema = Yup.object().shape({
  currentPassword: Yup.string().required(),
  password: Yup.string().min(8).required(),
  passwordConfirmation: Yup.string().equalTo(Yup.ref('password'), 'パスワードが一致していません').required(),
})

export const PasswordSettingForm = ({ dispatch }) => (
  <Formik
    initialValues={{
      currentPassword: '',
      password: '',
      passwordConfirmation: '',
    }}
    validationSchema={validationSchema}
    onSubmit={(values) => {
      dispatch(userAction.submitUpdatePassword(values))
    }}
  >
    {() => (
      <Form noValidate className="form-horizontal">
        <fieldset>
          <FormGroup>
            <Label>現在のパスワード</Label>
            <FieldSingle name="currentPassword" type="password" />
          </FormGroup>
        </fieldset>
        <fieldset>
          <FormGroup>
            <Label>新しいパスワード</Label>
            <FieldSingle name="password" type="password" />
          </FormGroup>
          <FormGroup>
            <Label>新しいパスワード（確認入力）</Label>
            <FieldSingle name="passwordConfirmation" type="password" />
          </FormGroup>
        </fieldset>
        <div className="text-center"><Button color="primary" type="submit" size="lg">更新</Button></div>
      </Form>
    )}
  </Formik>
)


export default connect()(PasswordSettingForm)
