import React from 'react'
import { ClipboardLink } from 'lib/components'

export const UnregistrationTrackingCode = () => {
  const codeText = generateCodeText()
  return (
    <>
      <p>退会完了ページでユーザをリタゲメール対象外に変更するためのタグです。</p>
      <div>
        <ClipboardLink copyText={codeText} />
      </div>
      <pre className="bg-gray-lighter p-3 code-text">
        { codeText }
      </pre>
    </>
  )
}

const generateCodeText = () => {
  const template = `
<script type='text/javascript'>
  _sna_dam.push(['userCode', '{お客様（一般ユーザ）を識別するためのID}']);
  _sna_dam.push(['status', '0']);
</script>`
  return template.replace(/^\s*\n/gm, '')
}

export default UnregistrationTrackingCode
