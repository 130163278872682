import { createActions } from 'redux-actions'

const FETCH_POPUPS = 'FETCH_POPUPS'
const FETCH_POPUPS_DONE = 'FETCH_POPUPS_DONE'

const SUBMIT_UPDATE_POPUP = 'SUBMIT_UPDATE_POPUP'
const SUBMIT_UPDATE_POPUP_DONE = 'SUBMIT_UPDATE_POPUP_DONE'

const SUBMIT_ENABLE_POPUP = 'SUBMIT_ENABLE_POPUP'
const SUBMIT_ENABLE_POPUP_DONE = 'SUBMIT_ENABLE_POPUP_DONE'

const SUBMIT_CREATE_POPUP = 'SUBMIT_CREATE_POPUP'
const SUBMIT_CREATE_POPUP_DONE = 'SUBMIT_CREATE_POPUP_DONE'

export const popupAction = createActions(
  {
    [FETCH_POPUPS_DONE]: [
      (...args) => args[0],
      (...args) => args[1],
    ],
    [SUBMIT_UPDATE_POPUP_DONE]: [
      (...args) => args[0],
      (...args) => Object.assign({ notification: 'ALL_CASE' }, args[1]),
    ],
    [SUBMIT_ENABLE_POPUP_DONE]: [
      (...args) => args[0],
      (...args) => Object.assign({ notification: 'ALL_CASE' }, args[1]),
    ],
    [SUBMIT_CREATE_POPUP_DONE]: [
      (...args) => args[0],
      (...args) => Object.assign({ notification: 'ALL_CASE' }, args[1]),
    ],
  },

  FETCH_POPUPS,
  SUBMIT_UPDATE_POPUP,
  SUBMIT_ENABLE_POPUP,
  SUBMIT_CREATE_POPUP,
)
