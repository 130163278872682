export default {
  batchStatuses: [
    ['processing', 1],
    ['finished', 2],
    ['error', 3],
    ['file_imported', 4],
    ['data_imported', 5],
    ['file_unchanged', 6],
  ],
  progressPercentage: [
    [30, 1],
    [100, 2],
    [null, 3],
    [50, 4],
    [80, 5],
    [100, 6],
  ],
}
