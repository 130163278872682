import { createActions } from 'redux-actions'

const FETCH_NEWS_RELEASES = 'FETCH_NEWS_RELEASES'
const FETCH_NEWS_RELEASES_DONE = 'FETCH_NEWS_RELEASES_DONE'

export const newsReleaseAction = createActions(
  {
    [FETCH_NEWS_RELEASES_DONE]: [
      (...args) => args[0],
      (...args) => args[1],
    ],
  },
  FETCH_NEWS_RELEASES,
)
