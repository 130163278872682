import { handleActions } from 'redux-actions'
import { mailPreviewAction as action } from 'actions'

const initial = {
  isSubmitting: false,
  item: {},
  error: undefined,
  processResult: null,
}

export const mailPreview = handleActions({
  [action.submitCreateMailPreview]: (state, action) => ({
    ...state,
    isSubmitting: true,
  }),
  [action.submitCreateMailPreviewDone]: (state, action) => ({
    ...state,
    isSubmitting: false,
    item: action.error ? {} : action.payload,
    processResult: action.error ? action.payload : 'SUCCESS',
  }),
}, initial)
