import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import ContentWrapper from 'components/layout/ContentWrapper'
import {
  Row, Col, Card, Button,
} from 'reactstrap'

import { useMountEffect } from 'lib/hooks'
import { deserializeFromQueryString } from 'lib/utils'
import MailAbTestList from 'components/parts/mailAbTest/MailAbTestList'
import MailAbTestRefineSearchForm from 'components/parts/mailAbTest/MailAbTestRefineSearchForm'
import PageDescriptionCard from 'components/parts/common/PageDescriptionCard'
import {
  mailAbTestAction, abandonedCampaignAction, completedCampaignAction, contextualCampaignAction,
} from 'actions'
import ManualLink from 'components/parts/manual/ManualLink'

export const MailAbTestListPage = ({ dispatch, location }) => {
  useMountEffect(() => {
    dispatch(mailAbTestAction.fetchMailAbTests())
    dispatch(abandonedCampaignAction.fetchAbandonedCampaigns({ show_finished: false }))
    dispatch(completedCampaignAction.fetchCompletedCampaigns({ show_finished: false }))
    dispatch(contextualCampaignAction.fetchContextualCampaigns({ show_finished: false }))
  })

  const queryObj = deserializeFromQueryString(location.search) || {}
  const page = Number(queryObj.page) || 1
  return (
    <ContentWrapper>
      <div className="content-heading">
        <div>ABテスト一覧</div>
        <div className="ml-3">
          <Button tag={Link} to="/mail_ab_test_new" color="secondary">新規作成</Button>
        </div>
      </div>
      <PageDescriptionCard>
        <div className="mb-2">設定の異なるキャンペーンを同期間に均等に配信し、成果を比較することができます。</div>
        <ManualLink filename="mailope_abtest">
          詳細はこちら
        </ManualLink>
      </PageDescriptionCard>
      <Row>
        <Col xl={9}>
          <Card className="card-default">
            <MailAbTestList
              limit={6}
              offset={(page - 1) * 6}
              testType={queryObj.testType}
              deliveryStatus={queryObj.deliveryStatus}
              showFinishedTest={queryObj.showFinishedTest}
            />
          </Card>
        </Col>
        <Col xl={3}>
          <Card className="card-default">
            <MailAbTestRefineSearchForm />
          </Card>
        </Col>
      </Row>
    </ContentWrapper>
  )
}

export default connect()(MailAbTestListPage)
