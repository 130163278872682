import { call, put, takeLatest } from 'redux-saga/effects'
import * as api from 'services/adminApi'
import { adminCompanyInvoiceTermAction } from 'actions'
import { push } from 'connected-react-router'

export function* handleFetchCompanyInvoiceTerm(action) {
  const { payload, meta } = yield call(api.fetchCompanyInvoiceTerm, action.payload)
  yield put(adminCompanyInvoiceTermAction.fetchCompanyInvoiceTermDone(payload, meta))
}

export function* handleFetchCompanyInvoiceTerms(action) {
  const { payload, meta } = yield call(api.fetchCompanyInvoiceTerms, action.payload)
  yield put(adminCompanyInvoiceTermAction.fetchCompanyInvoiceTermsDone(payload, meta))
}

function* handleSubmitUpdateCompanyInvoiceTerm(action) {
  const { payload, meta, error } = yield call(api.submitUpdateCompanyInvoiceTerm, action.payload)
  yield put(adminCompanyInvoiceTermAction.submitUpdateCompanyInvoiceTermDone(payload, meta))
  if (!error) yield put(push(`/admin/companies/${action.payload.companyId}/invoice_list`))
}

function* handleSubmitCreateCompanyInvoiceTerm(action) {
  const { payload, meta, error } = yield call(api.submitCreateCompanyInvoiceTerm, action.payload)
  yield put(adminCompanyInvoiceTermAction.submitCreateCompanyInvoiceTermDone(payload, meta))
  if (!error) yield put(push(`/admin/companies/${action.payload.companyId}/invoice_list`))
}

function* handleSubmitDeleteCompanyInvoiceTerm(action) {
  let { payload, meta, error } = yield call(api.submitDeleteCompanyInvoiceTerm, action.payload)
  if (!error) { payload = action.payload }
  yield put(adminCompanyInvoiceTermAction.submitDeleteCompanyInvoiceTermDone(payload, meta))
}

export const adminCompanyInvoiceTermSaga = [
  takeLatest(adminCompanyInvoiceTermAction.fetchCompanyInvoiceTerm, handleFetchCompanyInvoiceTerm),
  takeLatest(adminCompanyInvoiceTermAction.fetchCompanyInvoiceTerms, handleFetchCompanyInvoiceTerms),
  takeLatest(adminCompanyInvoiceTermAction.submitUpdateCompanyInvoiceTerm, handleSubmitUpdateCompanyInvoiceTerm),
  takeLatest(adminCompanyInvoiceTermAction.submitCreateCompanyInvoiceTerm, handleSubmitCreateCompanyInvoiceTerm),
  takeLatest(adminCompanyInvoiceTermAction.submitDeleteCompanyInvoiceTerm, handleSubmitDeleteCompanyInvoiceTerm),
]
