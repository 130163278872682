import React from 'react'
import { connect } from 'react-redux'
import {
  UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem,
} from 'reactstrap'
import { authAction, adminAuthAction } from 'actions'

export const AuthDropdown = ({
  user, company, isAdmin, dispatch,
}) => (
  <UncontrolledDropdown nav inNavbar>
    <DropdownToggle nav caret>
      <span>{company.item.name}{' < '}{user.item.email}{' >'}</span>
    </DropdownToggle>
    <DropdownMenu right className="dropdown-menu-right">
      <DropdownItem
        onClick={
          e => dispatch(isAdmin ? adminAuthAction.submitLogout() : authAction.submitLogout())
        }
      >
        <em className="fa fa-sign-out mr-2" />
          ログアウト
      </DropdownItem>
    </DropdownMenu>
  </UncontrolledDropdown>
)

const select = ({ user, company }) => ({ user, company })


export default connect(select)(AuthDropdown)
