import React from 'react'
import { connect } from 'react-redux'
import { CSVLink } from 'react-csv'
import { tagParameterConstant as gc } from 'config/constants'

export const TagParameterBehaviorsDataCsvDownloadButton = ({ behaviorsData, queryObj }) => {
  if (queryObj.campaignPolicy === 'firstPurchasing' || queryObj.campaignPolicy === 'customerManage'
      || behaviorsData.items.length === 0) { return null }
  const headers = gc.behaviorsHeaders
  const data = [...behaviorsData.items]
  return (
    <CSVLink
      className="btn btn-success"
      filename={`行動履歴ログ_${queryObj.confirmationIndexTerm}.csv`}
      data={data}
      headers={headers}
    >
      {queryObj.confirmationIndexTerm}
の行動履歴ログをダウンロードする（10件）
    </CSVLink>
  )
}


const select = ({
  elasticsearchBehaviors, queryObj,
}) => {
  const behaviors = elasticsearchBehaviors.items
  return {
    behaviorsData: {
      items: [...behaviors],
    },
    _isLoading: elasticsearchBehaviors.isFetching,
    ...queryObj,
  }
}

export default connect(select)(TagParameterBehaviorsDataCsvDownloadButton)
