import { takeLatest, call, put } from 'redux-saga/effects'
import * as api from 'services/api'
import { lineSettingAction } from 'actions'

function* handleFetchLineSetting() {
  const { payload, meta } = yield call(api.fetchLineSetting)
  yield put(lineSettingAction.fetchLineSettingDone(payload, meta))
}

function* handleSubmitUpdateLineSetting(action) {
  const { payload, meta, error } = yield call(api.submitUpdateLineSetting, action.payload)
  console.log(payload)
  yield put(lineSettingAction.submitUpdateLineSettingDone(payload, meta))
  if (!error) yield put(lineSettingAction.fetchLineSetting())
}

export const lineSettingSaga = [
  takeLatest(lineSettingAction.fetchLineSetting, handleFetchLineSetting),
  takeLatest(lineSettingAction.submitUpdateLineSetting, handleSubmitUpdateLineSetting),
]
