import { call } from 'redux-saga/effects'
import * as api from 'services/api'

export function* handleNoticeLogin() {
  yield handleSubmitCreateUserAccessTracking({ user_action: 'login' })
}

export function* handleNoticeChangeCampaign(campaign) {
  yield handleSubmitCreateUserAccessTracking({ user_action: 'campaign_change', object: campaign })
}

export function* handleNoticeEnableCampaign(campaign) {
  yield handleSubmitCreateUserAccessTracking({ user_action: 'campaign_enable', object: campaign })
}

export function* handleSubmitCreateUserAccessTracking(params) {
  yield call(api.submitUserAccessTrackings, params)
}
