import React from 'react'
import { connect } from 'react-redux'
import { Row, Col, Button, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap'
import { Loading, SwalDropdownItem } from 'lib/components'
import { diffObjectAttributes } from 'lib/utils'
import { Link } from 'react-router-dom'
import { push } from 'connected-react-router'
import { generalConstant as gc } from 'config/constants'
import { adminCompanyInvoiceTermAction } from 'actions'

export const InvoiceTermList = ({ adminCompanyInvoiceTerm, dispatch }) => {
  const invoiceTerms = adminCompanyInvoiceTerm.items
    .filter(e => !!e)
    .map((invoiceTerm, i) => {
      const nextInvoiceTerm = adminCompanyInvoiceTerm.items[i + 1]
      return {
        ...invoiceTerm,
        diffs: diffObjectAttributes(invoiceTerm, nextInvoiceTerm, ['id', 'startMonth'])
      }
    })
    .sort((a,b) => (a.startMonth < b.startMonth ? 1 : -1))

  return (
    <Loading isLoading={adminCompanyInvoiceTerm.isFetching}>
      { invoiceTerms.map((invoiceTerm, index) => (
        <div key={invoiceTerm.id} className='invoice-term mb-4'>
          <div className="lead bb mb-3 clearfix">{invoiceTerm.startMonth} 〜
            <div style={{float: 'right'}} className='mb-1'>
              <DropdownActionButton
                invoiceTerm={invoiceTerm}
                dispatch={dispatch}
                disableDelete={invoiceTerms.length <= 1}
              />
            </div>
          </div>
          { invoiceTerm.diffs.length > 0 &&
            <h5>
              ※全設定からの変更箇所：
              { invoiceTerm.diffs.map(diff => gc.invoiceTerm[diff]).join() }
            </h5>
          }
          <Row className='mb-2'>
            <Col xs={3}>最低請求額： {invoiceTerm.minimumChargeAmount}円</Col>
            <Col xs={3}>最高請求額： {
              invoiceTerm.maxChargeAmount === null ? '設定なし' : `${invoiceTerm.maxChargeAmount}円`
            }
            </Col>
          </Row>
          <Row>
            <Col xs={3}>代理店料率： {invoiceTerm.agencyRate}</Col>
            <Col xs={3}>営業担当者名： {invoiceTerm.salesStaffName}</Col>
            <Col xs={3}>支払い情報： {invoiceTerm.closingTiming}締め{invoiceTerm.paymentTiming}払い</Col>
            <Col xs={3}>請求先： {invoiceTerm.invoiceTo}</Col>
          </Row>
        </div>
      ))}
      { invoiceTerms.length === 0 &&
        <div className='m-3'>
          <h3>請求基本情報が存在しません</h3>
        </div>
      }
    </Loading>
  )
}

const DropdownActionButton = ({invoiceTerm, dispatch, disableDelete}) => {
  const invoiceTermEditPath = `/admin/companies/${invoiceTerm.companyId}/invoice_term_edit/${invoiceTerm.id}`
  const invoiceTermNewPath = `/admin/companies/${invoiceTerm.companyId}/invoice_term_new`
  const handlePushCopyPage = () => {
    dispatch(push(`${invoiceTermNewPath}?original=${invoiceTerm.id}`))
  }
  const deleteCallback = (isConfirm) => {
    isConfirm && dispatch(
      adminCompanyInvoiceTermAction.submitDeleteCompanyInvoiceTerm(
        { id: invoiceTerm.id, companyId: invoiceTerm.companyId }
      )
    )
  }

  return (
    <UncontrolledDropdown className="split-dropdown">
      <Button
        tag={Link}
        to={invoiceTermEditPath}
        color="secondary"
      > 編集
      </Button>
      <DropdownToggle caret color="secondary" />
      <DropdownMenu>
        <DropdownItem onClick={handlePushCopyPage}>
          コピーして新規作成
        </DropdownItem>
        { !disableDelete &&
          <SwalDropdownItem
            options={gc.deleteSwalOptions}
            callback={deleteCallback}
          >削除
          </SwalDropdownItem>
        }
      </DropdownMenu>
    </UncontrolledDropdown>
  )
}


const select = ({ adminCompanyInvoiceTerm }) => ({ adminCompanyInvoiceTerm })

export default connect(select)(InvoiceTermList)
