import React from 'react'
import { connect } from 'react-redux'
import { FieldGroup, InputGroupAddon, Label, Loading } from 'lib/components'
import { Formik, Field, Form } from 'formik'
import { Button, FormGroup, FormText, Input } from 'reactstrap'
import { limitDeliverySettingAction } from 'actions'
import * as Yup from 'yup'
import swal from 'sweetalert'

const limitDeliverySettingRadioValues = {
  on: 'on',
  off: 'off',
}

export const LimitDeliverySettingForm = ({ limitDeliverySetting, dispatch }) => {
  const item = limitDeliverySetting.item
  const existed = Object.keys(item).length !== 0
  const enabledLimitDeliverySetting = existed && item.maxCount !== null
  const stopped = enabledLimitDeliverySetting && item.currentProcessedCount >= item.maxCount

  const validationSchema = Yup.object().shape({
    enabled: Yup.string(),
    maxCount: Yup.number()
      .transform((value) => Number.isNaN(value) ? null : value )
      .min(item.currentProcessedCount, `現在の配信数（${item.currentProcessedCount}）以上の数値を入力してください`)
      .nullable()
      .when('enabled', {
        is: enabled => enabled === limitDeliverySettingRadioValues.on,
        then: Yup.number().required('配信制限通数を入力してください'),
      }),
  })

  return (
    <Loading isLoading={limitDeliverySetting.isFetching}>
      <Formik
        initialValues={{
          enabled: enabledLimitDeliverySetting ? limitDeliverySettingRadioValues.on : limitDeliverySettingRadioValues.off,
          maxCount: enabledLimitDeliverySetting ? item.maxCount : null,
        }}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          let changedContent = ''
          if (values.enabled === limitDeliverySettingRadioValues.on) {
            changedContent = `${values.maxCount}通`
          } else {
            changedContent = '上限なし'
          }
          swal({
            buttons: ['キャンセル', '変更'],
            closeModal: true,
            closeOnClickOutside: true,
            closeOnEsc: true,
            dangerMode: true,
            icon: 'warning',
            content: {
              element: 'div',
              attributes: {
                innerHTML: `メール配信数の上限を<span class='text-danger'>${changedContent}</span>に変更します。<br />よろしいですか？`
              },
            },
          }).then((isConfirm) => {
            if (!isConfirm) return
            if (values.enabled === limitDeliverySettingRadioValues.off) {
              values.maxCount = null
            }
            dispatch(limitDeliverySettingAction.submitUpdateLimitDeliverySetting(values))
          })
        }}
      >
        {({ values }) => (
          <Form noValidate>
            <FormGroup>
              <Label>月間のメール配信上限数をご設定ください。</Label>
              <FormText className="ml-3" tag="span">
                <ul className="list-unstyled">
                  <li>※メール配信数のみが制限されます（ポップアップ機能、LINE連携機能の配信数は対象外です）。</li>
                  <li>※<span className="text-bold">申込書の金額を確認した上で</span>ご設定ください。</li>
                  <li>※毎月1日の0:00にメール配信数のカウントがリセットされます。配信制限通数は最後に設定された通数のままになります。</li>
                </ul>
              </FormText>
              <Label>
                <Field name="enabled" type="radio" value={limitDeliverySettingRadioValues.off} />メール配信数上限なし
              </Label><br />
              <Label>
                <Field name="enabled" type="radio" value={limitDeliverySettingRadioValues.on} />メール配信数を制限する
              </Label>
              {
                values.enabled === limitDeliverySettingRadioValues.on && (
                  <div className='row mb-3'>
                    <FieldGroup className="col-sm-2">
                      <Input type="text" name="maxCount" /><InputGroupAddon addonType="append">通</InputGroupAddon>
                    </FieldGroup>
                    {stopped && (
                      <>
                        <div className="col-sm-1 bg-danger-dark d-flex align-items-center justify-content-center text-bold h4 m-0">配信停止中</div>
                        <span className="d-flex text-danger align-items-center ml-2">配信を再開したい場合は、配信制限通数を増やしてください。</span>
                      </>
                    )}
                  </div>
                )
              }
              <div className="text-center">
                <Button color="primary" type="submit" size="lg">更新</Button>
              </div>
            </FormGroup>
          </Form>
        )}
      </Formik>
    </Loading>
  )
}

const select = ({ limitDeliverySetting }) => ({ limitDeliverySetting })

export default connect(select)(LimitDeliverySettingForm)
