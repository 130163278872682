import { createActions } from 'redux-actions'

const SUBMIT_CREATE_MAIL_PREVIEW = 'SUBMIT_CREATE_MAIL_PREVIEW'
const SUBMIT_CREATE_MAIL_PREVIEW_DONE = 'SUBMIT_CREATE_MAIL_PREVIEW_DONE'

export const mailPreviewAction = createActions(
  {
    [SUBMIT_CREATE_MAIL_PREVIEW_DONE]: [
      (...args) => args[0],
      (...args) => args[1],
    ],
  },
  SUBMIT_CREATE_MAIL_PREVIEW,
)
