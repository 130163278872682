import { FormGroup, FormText } from 'reactstrap'
import { FieldSingle, Label } from 'lib/components'
import { importSettingConstant as isc } from 'config/constants'
import React from 'react'

export const FileFormatFields = () => (
  <>
    <legend className="mb-3 mt-4">ファイル形式</legend>
    <fieldset>
      <FormGroup>
        <Label>圧縮方式</Label>
        <FieldSingle type="select" name="compressType" options={isc.compressTypes} />
      </FormGroup>
      <FormGroup>
        <Label>ファイルタイプ</Label>
        <FieldSingle type="select" name="fileType" options={isc.fileTypes} />
      </FormGroup>
      <FormGroup>
        <Label>エンコーディング形式</Label>
        <FieldSingle type="select" name="encoding" options={isc.encodings} />
        <FormText>BOM付きのファイルは取り込みできません。</FormText>
      </FormGroup>
    </fieldset>
  </>
)

export default FileFormatFields
