import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import {
  Table, Button, CardBody, CardFooter,
} from 'reactstrap'
import { Loading, Pagination, Swal } from 'lib/components'
import { generalConstant as gc } from 'config/constants'
import moment from 'moment'
import { adminCompanyAllowIpAddressAction } from 'actions'

export const AdminCompanyAllowIpAddressList = ({
  adminCompanyAllowIpAddress, limit = gc.adminCompanyAllowIpAddressItemsLimit, offset = 0, dispatch,
}) => {
  const targetItems = [...adminCompanyAllowIpAddress.items].filter(allowIpAddressFilter)
  return (
    <Fragment>
      <Loading isLoading={adminCompanyAllowIpAddress.isFetching}>
        <CardBody>
          <Table responsive>
            <thead>
              <tr>
                <th>IPアドレス</th>
                <th>作成日時</th>
                <th>更新日時</th>
                <th>&nbsp;</th>
              </tr>
            </thead>
            <tbody className="no-thead">
              {
                targetItems
                  .sort((a, b) => allowIpAddressSort(a, b))
                  // ページネーション用filter
                  .filter((cam, index) => (index >= offset && index < offset + limit))
                  .map(allowIpAddress => (
                    <tr key={`${allowIpAddress.id}`}>
                      <td>
                        {allowIpAddress.ipAddress}
                      </td>
                      <td>
                        {moment(allowIpAddress.createdAt).format('YYYY/MM/DD HH:mm')}
                      </td>
                      <td>
                        {moment(allowIpAddress.updatedAt).format('YYYY/MM/DD HH:mm')}
                      </td>
                      <td className="text-center">
                        <Button href={`/admin/companies/${allowIpAddress.companyId}/allow_ip_address_edit/${allowIpAddress.id}`} color="success">
                          編集
                        </Button>
                        <Swal
                          options={gc.deleteAllowIpAddressSwalOptions}
                          color="danger"
                          callback={(isConfirm) => {
                            isConfirm
                            && dispatch(
                              adminCompanyAllowIpAddressAction.submitDeleteCompanyAllowIpAddress(
                                { id: allowIpAddress.id, companyId: allowIpAddress.companyId },
                              ),
                            )
                          }}
                        >
                          削除
                        </Swal>
                      </td>
                    </tr>
                  ))
              }
            </tbody>
          </Table>
        </CardBody>
        <CardFooter>
          <Pagination
            totalItemCount={targetItems.length}
            limit={limit}
          />
        </CardFooter>
      </Loading>
    </Fragment>
  )
}

const allowIpAddressSort = (a, b, condition) => moment(b.updatedAt) - moment(a.updatedAt)

// 削除された要素(empty)をスキップ
const allowIpAddressFilter = ip => !!ip

const select = ({ adminCompanyAllowIpAddress }) => ({ adminCompanyAllowIpAddress })

export default connect(select)(AdminCompanyAllowIpAddressList)
