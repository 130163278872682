import { call, put, takeLatest } from 'redux-saga/effects'
import * as api from 'services/adminApi'
import { adminCustomerImportSettingAction } from 'actions'

export function* handleFetchCustomerImportSetting(action) {
  const { payload, meta } = yield call(api.fetchCustomerImportSetting, action.payload)
  yield put(adminCustomerImportSettingAction.fetchCustomerImportSettingDone(payload, meta))
}

function* handleSubmitUpdateCustomerImportSetting(action) {
  const { payload, meta } = yield call(api.submitUpdateCustomerImportSetting, action.payload)
  yield put(adminCustomerImportSettingAction.submitUpdateCustomerImportSettingDone(payload.customerImportSetting, meta))
}

export const adminCustomerImportSettingSaga = [
  takeLatest(adminCustomerImportSettingAction.fetchCustomerImportSetting, handleFetchCustomerImportSetting),
  takeLatest(adminCustomerImportSettingAction.submitUpdateCustomerImportSetting, handleSubmitUpdateCustomerImportSetting),
]
