import React from 'react'
import { Formik, Form } from 'formik'
import moment from 'moment'
import { connect } from 'react-redux'
import {
  Col, Row, Button, FormGroup, Input, Alert,
} from 'reactstrap'
import { FieldSingle, FieldGroup, InputGroupAddon, Loading } from 'lib/components'

import { mailAbTestReportAction } from 'actions'
import { reportConstant as rc } from 'config/constants'

export const MailAbTestReportConditionForm = ({ dispatch, mailAbTest, queryObj }) => {
  const mailAbTestOption = mailAbTest.items.map(ab => ([ab.name, ab.id]))
  let recievedQuery = {}
  if (queryObj.length !== 0) { recievedQuery = queryObj[0] }
  return (
    <Loading isLoading={mailAbTest.isFetching}>
      <Formik
        initialValues={{
          mailAbTest: recievedQuery.mailAbTest || '',
          term: recievedQuery.term || rc.defaultTransitionReportCondition.term,
          transition: recievedQuery.transition || rc.defaultTransitionReportCondition.transition,
          termFrom: recievedQuery.termFrom || moment().subtract(1, 'months').format('YYYY-MM-DD'),
          termTo: recievedQuery.termTo || moment().format('YYYY-MM-DD'),
          segmentCondition: recievedQuery.segmentCondition || rc.defaultMailAbTestSegmentCondition,
        }}
        onSubmit={(values) => {
          dispatch(mailAbTestReportAction.searchMailAbTestReport(values))
        }}
        validate={(values) => {
          if (!values.mailAbTest) {
            return { message: 'ABテストを選択して下さい。' }
          }
          return {}
        }}
      >
        {({ values, errors }) => (
          <Form noValidate>
            <Row>
              <Col>
                <FormGroup className="mb-3">
                  <label>対象ABテスト</label>
                  <FieldSingle type="select" name="mailAbTest" options={mailAbTestOption} blank />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col xs="8" md="10">
                <FormGroup className="mb-3">
                  <label>集計期間</label>
                  <FieldSingle type="select" name="term" options={rc.terms} />
                </FormGroup>
              </Col>
              <Col xs="4" md="2">
                <FormGroup className="mb-3">
                  <label>推移</label>
                  <FieldSingle type="select" name="transition" options={rc.transitions} />
                </FormGroup>
              </Col>
            </Row>
            { values.term === 'free_period'
              && (
                <FormGroup className="mb-3">
                  <FieldGroup>
                    <Input name="termFrom" type="date" />
                    <InputGroupAddon addonType="middle">〜</InputGroupAddon>
                    <Input name="termTo" type="date" />
                  </FieldGroup>
                </FormGroup>
              )
            }
            <Row>
              <Col>
                <FormGroup className="mb-3">
                  <label>確認する指標</label>
                  <FieldSingle type="select" name="segmentCondition" options={rc.mailAbTestReportSegmentCondition} />
                </FormGroup>
              </Col>
            </Row>
            { errors.message
              && <Alert color="warning">{errors.message}</Alert>
            }
            <div className="text-center"><Button color="primary" type="submit">レポートを更新</Button></div>
          </Form>
        )}
      </Formik>
    </Loading>
  )
}

const select = ({ mailAbTest }) => ({ mailAbTest })

export default connect(select)(MailAbTestReportConditionForm)
