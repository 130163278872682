const snsIconsFullHTML = (props = {}) => {
  const color = props.color
  const facebookHref = props['facebook-href']
  const twitterHref = props['twitter-href']
  const lineHref = props['line-href']
  const instagramHref = props['instagram-href']
  const pinterestHref = props['pinterest-href']
  const youtubeHref = props['youtube-href']
  return `
<div data-gjs-type="snsIcons" data-gjs-color="${color}" data-gjs-facebook-href="${facebookHref}" data-gjs-twitter-href="${twitterHref}" data-gjs-line-href="${lineHref}" data-gjs-instagram-href="${instagramHref}" data-gjs-pinterest-href="${pinterestHref}" data-gjs-youtube-href="${youtubeHref}">
  <div data-gjs-type="unLayerable">
    ${snsIconsInnerHTML(props)}
  </div>
</div>
`
}

const snsIconsInnerHTML = (props) => {
  const color = props.color
  const facebookHref = props['facebook-href']
  const twitterHref = props['twitter-href']
  const lineHref = props['line-href']
  const instagramHref = props['instagram-href']
  const pinterestHref = props['pinterest-href']
  const youtubeHref = props['youtube-href']

  const facebookIcon = buildSnSIcon('facebook', facebookHref, color)
  const twitterIcon = buildSnSIcon('twitter', twitterHref, color)
  const lineIcon = buildSnSIcon('line', lineHref, color)
  const instagramIcon = buildSnSIcon('instagram', instagramHref, color)
  const pinterestIcon = buildSnSIcon('pinterest', pinterestHref, color)
  const youtubeIcon = buildSnSIcon('youtube', youtubeHref, color)
  return `
<table align="center" class="center">
    <tbody>
    <!-- アイコン ここから -->
    <tr>
        ${facebookIcon}
        
        ${twitterIcon}
        
        ${lineIcon}
        
        ${instagramIcon}
        
        ${pinterestIcon}
        
        ${youtubeIcon}
    </tr>
    <!-- アイコン ここまで -->
    </tbody>
</table>
`
}

const buildSnSIcon = (type, href, color) => {
  if (href === undefined || href === '') {
    return ''
  }
  return `
<td class="footericon" align="center" valign="middle">
    <!--[if mso]><table width="32" align="left" bgcolor="transparent" cellpadding="0" cellspacing="0" border="0"><tr><td valign="top" align="left" width="32" height="*"><![endif]-->
    <a href="${href}" title="${type}" target="_blank">
        <img class="fullwidth" src="https://s3-ap-northeast-1.amazonaws.com/rtg-mail/mailtemplate/img/icon_${type}_${color}.png" alt="${type}" title="${type}" border="0" width="32" height="32">
    </a>
    <!--[if mso]></td></tr></table><![endif]-->
</td>
    `
}

const generateSnsIcons = () => ({
  model: {
    defaults: {
      droppable: false,
      traits: [
        {
          type: 'select',
          name: 'color',
          label: 'アイコンカラー',
          options: [
            { id: 'white', name: '白' },
            { id: 'white2', name: '白抜き' },
            { id: 'black', name: '黒' },
            { id: 'black2', name: '黒抜き' },
            { id: 'grey', name: 'グレー' },
            { id: 'grey_dark', name: '濃いグレー' },
            { id: 'color', name: 'カラー' },
          ],
          changeProp: true,
        },
        {
          type: 'text',
          name: 'facebook-href',
          label: 'Facebook',
          placeholder: 'https://www.facebook.com/xxxxxxx',
          changeProp: true,
        },
        {
          type: 'text',
          name: 'twitter-href',
          label: 'Twitter',
          placeholder: 'https://twitter.com/xxxxxxx',
          changeProp: true,
        },
        {
          type: 'text',
          name: 'line-href',
          label: 'LINE',
          placeholder: 'http://line.me/xxxxxxx',
          changeProp: true,
        },
        {
          type: 'text',
          name: 'instagram-href',
          label: 'Instagram',
          placeholder: 'https://www.instagram.com/xxxxxxx',
          changeProp: true,
        },
        {
          type: 'text',
          name: 'pinterest-href',
          label: 'Pinterest',
          placeholder: 'https://www.pinterest.jp/xxxxxxx',
          changeProp: true,
        },
        {
          type: 'text',
          name: 'youtube-href',
          label: 'YouTube',
          placeholder: 'https://www.youtube.com/user/xxxxxxx',
          changeProp: true,
        },
      ],
    },
    init() {
      this.on('change', this.handleChange)
    },
    handleChange() {
      this.view.renderElement()
    },
    toHTML() {
      const html = snsIconsFullHTML(this.props())
      return html
    },
  },
  view: {
    renderElement() {
      this.el.innerHTML = snsIconsInnerHTML(this.model.props())
    },
    onRender({ el }) {
      this.renderElement()
    },
  },
})

export default generateSnsIcons
