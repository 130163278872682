import React from 'react'

import AdminLoginForm from 'components/parts/auth/AdminLoginForm'

const LoginPage = () => (
  <div className="block-center mt-4 wd-xl">
    <div className="card card-flat">
      <div className="card-header text-center bg-dark">
        <img className="block-center rounded" src="/img/naviplus-logo.png" alt="Logo" />
      </div>
      <div className="card-body">
        <AdminLoginForm />
      </div>
    </div>
    <div className="p-3 text-center">
      <span className="mr-2">&copy;</span>
      <span>2021</span>
      <span className="mx-2">-</span>
      <span>NaviPlus</span>
    </div>
  </div>
)

export default LoginPage
