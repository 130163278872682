import React from 'react'
import { connect } from 'react-redux'
import { Button } from 'reactstrap'
import { sendgridAction } from 'actions'

export const ValidateDomainButton = ({ dispatch, email }) => (
  <Button
    color="info"
    onClick={() => { dispatch(sendgridAction.validateDomainAuthentication(email)) }}
  >
    SPFチェック
  </Button>
)

export default connect()(ValidateDomainButton)
