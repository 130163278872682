import React, { useState } from 'react'
import moment from 'moment'
import { connect } from 'react-redux'
import { Table, Button, Modal, ModalBody } from 'reactstrap'
import { batchStatusConstant as bsc } from 'config/constants'
import { arraysToObject } from 'lib/utils'
import { Loading } from 'lib/components'

const batchStatus = arraysToObject(bsc.batchStatuses, 1, 0)

export const BatchStatusCustomerImportList = ({ batchStatusCustomerImport }) => {
  const items = [...batchStatusCustomerImport.items].reverse().splice(0, 10)
  const [isOpen, setIsOpen] = useState(false)
  return (
    <Loading isLoading={batchStatusCustomerImport.isFetching}>
      <Table bordered striped responsive>
        <thead>
          <tr style={{ wordBreak: 'keep-all' }}>
            <th>&nbsp;</th>
            <th>日時</th>
            <th>ファイル内件数</th>
            <th>取込後有効件数</th>
            <th>データエラー件数</th>
            <th>&nbsp;</th>
          </tr>
        </thead>
        <tbody>
          { items.map((item) => {
            const status = batchStatus[item.status]
            const errorObj = item.errorJson ? JSON.parse(item.errorJson) : {}
            return (
              <tr key={`batchStatus_${item.id}`}>
                <td style={{ textAlign: 'center' }}>
                  { (status === 'finished' && !item.errorJson)
                    && <i className="fa fa-check text-success" />
                  }
                  { (status === 'finished' && item.errorJson)
                    && <i className="fa fa-warning text-warning" />
                  }
                  { (status === 'error')
                    && <i className="fa fa-times text-danger" />
                  }
                  { (['processing', 'file_imported', 'data_imported'].includes(status) && !item.errorJson)
                    && <i className="fa fa-angle-double-right text-info" />
                  }
                </td>
                <td>
                  <strong>{moment(item.processStartedAt).format('YYYY/MM/DD')}</strong>
                  <br />
                  {moment(item.processStartedAt).format('HH:mm:ss')}
                  {' '}
  ~
                  {moment(item.processFinishedAt).format('HH:mm:ss')}
                </td>
                { status === 'finished'
                  && (
                    <>
                      <td>
                        {item.totalCount}
  件
                      </td>
                      <td>{item.activeCount === null ? '-' : `${item.activeCount}件`}</td>
                      <td>
                        {item.errorCount}
  件
                      </td>
                    </>
                  )
                }
                { ['processing', 'file_imported', 'data_imported'].includes(status)
                  && <td colSpan={3}>取込中です...</td>
                }
                { status === 'file_unchanged'
                  && (
                    <td colSpan={3}>
                    データの更新がなかったため、マスタ更新処理をスキップしました。
                      <br />
                    前回取込分データを利用しメール配信します。
                    </td>
                  )
                }
                { status === 'error'
                  && (
                    <td colSpan={3}>
                      {errorObj.exception}
                    </td>
                  )
                }
                <td style={{ textAlign: 'center' }}>
                  { item.errorJson
                    && <Button onClick={() => setIsOpen(true)}>詳細</Button>
                  }
                </td>
                <Modal isOpen={isOpen} toggle={() => setIsOpen(false)}>
                  <ModalBody>
                    <ModalContent status={status} errorObj={errorObj} />
                  </ModalBody>
                </Modal>
              </tr>
            )
          })}
        </tbody>
      </Table>
    </Loading>
  )
}

const ModalContent = ({ status, errorObj }) => (
  <>
    { status === 'finished'
      && (
        <h4 className="mb-4">
          <i className="fa fa-warning text-warning mr-2" />
複数行取込みに失敗したレコードが存在します。
        </h4>
      )
    }
    { status === 'error'
      && (
        <h4 className="mb-4">
          <i className="fa fa-times text-danger mr-2" />
          {errorObj.exception}
        </h4>
      )
    }
    { (errorObj.errors && errorObj.errors.length > 0)
      && (
        <>
          <Table responsive striped>
            <tbody>
              { errorObj.errors.map(err => (
                <tr key={`error_${err[0]}`}>
                  <td>
                    {err[0]}
行目
                  </td>
                  <td>{err[1]}</td>
                </tr>
              ))}
            </tbody>
          </Table>
          <p>※全エラー中先頭100行</p>
        </>
      )
    }
  </>
)

const select = ({ batchStatusCustomerImport }) => ({ batchStatusCustomerImport })

export default connect(select)(BatchStatusCustomerImportList)
