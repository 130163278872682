import { takeLatest, call, put } from 'redux-saga/effects'
import { push } from 'connected-react-router'
import * as api from 'services/api'
import { registeredUserAction, emailUniquenessAction } from 'actions'

function* handleFetchRegisteredUsers(action) {
  const { payload, meta } = yield call(api.fetchRegisteredUsers)
  yield put(registeredUserAction.fetchRegisteredUsersDone(payload, meta))
}

function* handleSubmitCreateRegisteredUser(action) {
  // 本当は別でactionを発火させる方が望ましい
  let { payload, meta, error } = yield call(api.checkEmailUniqueness, { email: action.payload.email })
  if (!payload.status) { payload = new Error() }
  yield put(emailUniquenessAction.checkEmailUniquenessDone(payload, meta))
  if (error || payload instanceof Error) { return }

  const res = yield call(api.submitCreateRegisteredUser, action.payload)
  yield put(registeredUserAction.submitCreateRegisteredUserDone(res.payload, res.meta))
  if (!res.error) yield put(push('/registered_user_management'))
}

function* handleSubmitDeleteRegisteredUser(action) {
  let { payload, meta, error } = yield call(api.submitDeleteRegisteredUser, action.payload)
  if (!error) { payload = action.payload }
  yield put(registeredUserAction.submitDeleteRegisteredUserDone(payload, meta))
}

export const registeredUserSaga = [
  takeLatest(registeredUserAction.fetchRegisteredUsers, handleFetchRegisteredUsers),
  takeLatest(registeredUserAction.submitCreateRegisteredUser, handleSubmitCreateRegisteredUser),
  takeLatest(registeredUserAction.submitDeleteRegisteredUser, handleSubmitDeleteRegisteredUser),
]
