import { createActions } from 'redux-actions'

const FETCH_TEMPLATE_TAGS = 'FETCH_TEMPLATE_TAGS'
const FETCH_TEMPLATE_TAGS_DONE = 'FETCH_TEMPLATE_TAGS_DONE'

const SUBMIT_UPDATE_TEMPLATE_TAG = 'SUBMIT_UPDATE_TEMPLATE_TAG'
const SUBMIT_UPDATE_TEMPLATE_TAG_DONE = 'SUBMIT_UPDATE_TEMPLATE_TAG_DONE'

const SUBMIT_CREATE_TEMPLATE_TAG = 'SUBMIT_CREATE_TEMPLATE_TAG'
const SUBMIT_CREATE_TEMPLATE_TAG_DONE = 'SUBMIT_CREATE_TEMPLATE_TAG_DONE'

const SUBMIT_DELETE_TEMPLATE_TAG = 'SUBMIT_DELETE_TEMPLATE_TAG'
const SUBMIT_DELETE_TEMPLATE_TAG_DONE = 'SUBMIT_DELETE_TEMPLATE_TAG_DONE'

export const adminTemplateTagAction = createActions(
  {
    [FETCH_TEMPLATE_TAGS_DONE]: [
      (...args) => args[0],
      (...args) => args[1],
    ],
    [SUBMIT_UPDATE_TEMPLATE_TAG_DONE]: [
      (...args) => args[0],
      (...args) => Object.assign({ notification: 'ALL_CASE' }, args[1]),
    ],
    [SUBMIT_CREATE_TEMPLATE_TAG_DONE]: [
      (...args) => args[0],
      (...args) => Object.assign({ notification: 'ALL_CASE' }, args[1]),
    ],
    [SUBMIT_DELETE_TEMPLATE_TAG_DONE]: [
      (...args) => args[0],
      (...args) => Object.assign({ notification: 'ALL_CASE' }, args[1]),
    ],
  },
  FETCH_TEMPLATE_TAGS,
  SUBMIT_UPDATE_TEMPLATE_TAG,
  SUBMIT_CREATE_TEMPLATE_TAG,
  SUBMIT_DELETE_TEMPLATE_TAG,
  {
    prefix: 'ADMIN',
  },
)
