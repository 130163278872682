import React from 'react'
import { connect } from 'react-redux'
import ContentWrapper from 'components/layout/ContentWrapper'
import { Card, CardBody } from 'reactstrap'
import RegisteredUserNewForm from 'components/parts/registeredUser/RegisteredUserNewForm'

export const RegisteredUserNewPage = () => {
  return (
    <ContentWrapper>
      <div className="content-heading">
        <div>新規ユーザ登録</div>
      </div>
      <Card className="card-default">
        <CardBody>
          <RegisteredUserNewForm />
        </CardBody>
      </Card>
    </ContentWrapper>
  )
}

export default connect()(RegisteredUserNewPage)
