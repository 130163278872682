import React from 'react'
import { connect } from 'react-redux'
import { useMountEffect } from 'lib/hooks'
import MainUserSettingForm from 'components/parts/mainUser/MainUserSettingForm'
import { registeredUserAction, adminPermissionAction, userAction } from 'actions'

export const MainUserSettingPage = ({ dispatch }) => {
  useMountEffect(() => {
    dispatch(registeredUserAction.fetchRegisteredUsers())
    dispatch(adminPermissionAction.fetchAdminPermission())
    dispatch(userAction.fetchCurrentUser())
  })
  return (
    <div>
      <MainUserSettingForm />
    </div>
  )
}

export default connect()(MainUserSettingPage)
