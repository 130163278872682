import { createActions } from 'redux-actions'

const CHECK_LOGIN = 'CHECK_LOGIN'
const CHECK_LOGIN_DONE = 'CHECK_LOGIN_DONE'

const FETCH_LOGIN = 'FETCH_LOGIN'
const FETCH_LOGIN_DONE = 'FETCH_LOGIN_DONE'

const SUBMIT_LOGOUT = 'SUBMIT_LOGOUT'
const SUBMIT_LOGOUT_DONE = 'SUBMIT_LOGOUT_DONE'

const SUBMIT_SIGN_UP = 'SUBMIT_SIGN_UP'
const SUBMIT_SIGN_UP_DONE = 'SUBMIT_SIGN_UP_DONE'

const SET_UNAUTH_IN_PROGRESS = 'SET_UNAUTH_IN_PROGRESS'

export const authAction = createActions(
  {
    [CHECK_LOGIN_DONE]: [(...args) => args[0], (...args) => args[1]],
    [FETCH_LOGIN]: (...args) => ({ email: args[0], password: args[1] }),
    [FETCH_LOGIN_DONE]: [
      (...args) => args[0],
      (...args) => Object.assign({ notification: 'SUCCESS', succMess: 'ログインしました', ignoreUnauth: true }, args[1]),
    ],
    [SUBMIT_SIGN_UP_DONE]: [
      (...args) => args[0],
      (...args) => Object.assign({ notification: 'ALL_CASE', succMess: 'アカウントを作成しました。', ignoreUnauth: true }, args[1]),
    ],
    [SUBMIT_LOGOUT_DONE]: [(...args) => args[0], (...args) => args[1]],
  },

  CHECK_LOGIN,

  SUBMIT_LOGOUT,

  SUBMIT_SIGN_UP,

  SET_UNAUTH_IN_PROGRESS,
)
