import { takeLatest, call, put } from 'redux-saga/effects'
import * as api from 'services/api'
import { behaviorHistoryAction } from 'actions'

function* handleFetchBehaviorHistories(action) {
  const { payload, meta } = yield call(api.fetchBehaviorHistories, action.payload)
  yield put(behaviorHistoryAction.fetchBehaviorHistoriesDone(payload, meta))
}

export const behaviorHistorySaga = [
  takeLatest(behaviorHistoryAction.fetchBehaviorHistories, handleFetchBehaviorHistories),
]
