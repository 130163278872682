import React from 'react'
import {
  Card, CardBody, Col, Row,
} from 'reactstrap'
import ContentWrapper from 'components/layout/ContentWrapper'
import { connect } from 'react-redux'
import { useMountEffect } from 'lib/hooks'
import AdminTemplateTagForm from 'components/parts/template_tag/AdminTemplateTagForm'
import { adminTemplateTagAction } from 'actions'

export const AdminTemplateTagEditPage = ({match, dispatch}) => {
  const tagId = Number(match.params.id)

  useMountEffect(() => {
    dispatch(adminTemplateTagAction.fetchTemplateTags())
  })

  return (
    <ContentWrapper>
      <div className="content-heading">
        <div>
          テンプレートのタグ編集
        </div>
      </div>
      <Row>
        <Col xl={12}>
          <Card className="card-default">
            <CardBody>
              <AdminTemplateTagForm
                tagId={tagId}
                submitAction="update"
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </ContentWrapper>
  )
}

export default connect()(AdminTemplateTagEditPage)
