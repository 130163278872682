import React from 'react'
import * as Yup from 'yup'
import { Formik, Form } from 'formik'
import { connect } from 'react-redux'
import { Button, Col, FormGroup, Input, Row } from 'reactstrap'
import {
  FieldSingle, FieldGroup, Label,
} from 'lib/components'
import { itemConstant as cc } from "config/constants"
import {serializeToQueryString} from "lib/utils"

const validationSchema = Yup.object().shape({
  code: Yup.string(),
  status: Yup.string(),
})

export const ItemSearchConditionForm = ({ location, history }) => (
  <Formik
    initialValues={{
      code: '',
      status: 'all',
    }}
    validationSchema={validationSchema}
    onSubmit={(values) => {
      if (values.status) {
        if (values.status === 'all') {
          delete values.status
        } else {
          values = {...values, status: values.status === 'enable', forceStop: values.status === 'force_stop' }
        }
      }
      history.push({
        pathname: location.pathname,
        search: serializeToQueryString(values)
      })
    }}
  >
    <Form noValidate className="form-horizontal">
      <Row>
        <Col>
          <FormGroup>
            <Label>商品コード</Label>
            <FieldGroup>
              <Input name="code" type="text" className="mr-1" />
              <Input name="code_operator" type="select" options={cc.itemCodeCondition}/>
            </FieldGroup>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col>
          <FormGroup>
            <Label>配信状態</Label>
            <FieldSingle type="select" name="status" options={cc.stopDeliveryStatus} />
          </FormGroup>
        </Col>
      </Row>
      <div className="text-center"><Button color="primary" type="submit">検索</Button></div>
    </Form>
  </Formik>
)

export default connect()(ItemSearchConditionForm)
