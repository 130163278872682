import generateItems from 'lib/grapesjs/components/items'
import generateSnsIcons from 'lib/grapesjs/components/snsIcons'
import generateArea from 'lib/grapesjs/components/area'
import generateLinkButton from 'lib/grapesjs/components/linkButton'
import generateUnLayerable from 'lib/grapesjs/components/unLayerable'

const generateHtmlEditorComponentTypes = ({ campaignType, triggerType, recoTemplates }) => (editor) => {
  // 既存コンポーネントのtraitsを上書き
  editor.DomComponents.addType('link', {
    model: {
      defaults: {
        traits: ['href'],
      },
    },
  })
  editor.DomComponents.addType('image', {
    model: {
      defaults: {
        resizable: false,
        traits: [
          {
            type: 'text',
            name: 'src',
            changeProp: true,
          },
        ],
      },
    },
  })
  editor.DomComponents.addType('text', {
    model: {
      defaults: {
        highlightable: false,
        traits: [],
      },
    },
  })
  editor.DomComponents.addType('default', {
    model: {
      defaults: {
        traits: [],
      },
    },
  })

  // オリジナルのコンポーネントを追加
  editor.DomComponents.addType('snsIcons', generateSnsIcons())
  editor.DomComponents.addType('area', generateArea())
  editor.DomComponents.addType('linkButton', generateLinkButton())
  editor.DomComponents.addType('items', generateItems({ campaignType, triggerType, recoTemplates }))
  editor.DomComponents.addType('unLayerable', generateUnLayerable())
}

export default generateHtmlEditorComponentTypes
