import { handleActions } from 'redux-actions'
import generateHandlerArgs, { preparedHandlers } from 'modules/reducerGenerator'
import { contextualCampaignAction as action } from 'actions'

const overrideHandlers = {
  [action.submitSendContextualCampaignTestMail]: (state, action) => ({
    ...state,
    isSendingTestMail: true,
  }),
  [action.submitSendContextualCampaignTestMailDone]: (state, action) => ({
    ...state,
    isSendingTestMail: false,
    processResult: action.error ? action.payload : 'SUCCESS',
  }),
  [action.submitEnableContextualCampaignDone]: preparedHandlers.submitUpdateObjectDone(true),
}

const overrideInitial = {
  isSendingTestMail: false,
}

const { handler, initial } = generateHandlerArgs(action, 'contextualCampaign', overrideHandlers, overrideInitial)

export const contextualCampaign = handleActions(handler, initial)
