import React from 'react'

export const ManualLink = ({ filename, children }) => (
  <a
    href={`${process.env.REACT_APP_API_ROOT}/api/docs/${filename}${filename.includes('#') ? '' : '/'}` }
    target="_blank"
    rel="noopener noreferrer"
  >
    {children}
  </a>
)

export default ManualLink
