import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Formik, Form } from 'formik'
import { Button, FormGroup, Row, Modal, ModalBody } from 'reactstrap'
import * as Yup from 'yup'
import { arraysToObject } from 'lib/utils'
import { FieldSingle, Label, BatchStatusModal } from 'lib/components'
import { itemImportSettingAction, connectionTestAction } from 'actions'
import { importSettingConstant as isc } from 'config/constants'
import { FileFormatFields } from 'components/parts/fileImport/FileFormatFields'

const protocolsValName = arraysToObject(isc.protocolValues, 1, 2)
const protocolsNameVal = arraysToObject(isc.protocolValues, 2, 1)

const validationSchema = Yup.object().shape({
  url: Yup.string().url('URLの形式で入力してください').required(),
  authMethod: Yup.number(),
  username: Yup.string()
    .when('authMethod', {
      is: authMethod => (Number(authMethod) !== 0),
      then: Yup.string().required(),
    }),
  password: Yup.string(),
  compressType: Yup.number().required(),
  fileType: Yup.number().required(),
  encoding: Yup.number().required(),
})

export const HttpSettingEditForm = ({ dispatch, itemImportSetting }) => {
  const item = itemImportSetting.item
  const [isOpen, setIsOpen] = useState(false)
  let initialUrl = ''
  if (item.protocol) {
    const protocol = protocolsValName[item.protocol]
    const port = item.port
        && ((protocol === 'http' && item.port !== 80)
        || (protocol === 'https' && item.port !== 443))
      ? `:${item.port}` : ''
    initialUrl = `${protocol}://${[`${item.host}${port}`, item.path, item.filename].filter(e => !!e).join('/')}`
  }
  return (
    <Formik
      initialValues={{
        url: initialUrl,
        authMethod: item.authMethod,
        username: item.username || '',
        password: item.password || '',
        compressType: item.compressType,
        fileType: item.fileType,
        encoding: item.encoding,
      }}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        const parsedVal = parseUrl(values.url)
        const submitValues = { ...values, ...parsedVal }
        submitValues.protocol = protocolsNameVal[submitValues.protocol]
        submitValues.url = null
        dispatch(itemImportSettingAction.submitUpdateItemImportSetting(submitValues))
      }}
    >
      {({ values }) => (
        <Form noValidate>
          <fieldset>
            <FormGroup>
              <Label require>連携ファイルURL</Label>
              <FieldSingle name="url" type="text" />
            </FormGroup>
            <FormGroup>
              <Label>認証</Label>
              <FieldSingle type="select" name="authMethod" options={isc.authMethod} />
            </FormGroup>
            { Number(values.authMethod) !== 0
              && (
                <Row>
                  <FormGroup className="col-sm-6">
                    <Label require>ユーザ名</Label>
                    <FieldSingle name="username" type="text" />
                  </FormGroup>
                  <FormGroup className="col-sm-6">
                    <Label>パスワード</Label>
                    <FieldSingle name="password" type="password" />
                  </FormGroup>
                </Row>
              )
            }
          </fieldset>
          <FileFormatFields />
          <div className="text-center">
            <Button
              color="warning"
              size="lg"
              className="mr-5"
              onClick={() => {
                const parsedVal = parseUrl(values.url)
                const submitValues = { ...values, ...parsedVal }
                submitValues.protocol = protocolsNameVal[submitValues.protocol]
                submitValues.url = null
                dispatch(connectionTestAction.submitCreateConnectionTest({ ...submitValues, _type: 'item' }))
                setIsOpen(true)
              }}
            >
      接続テスト
            </Button>
            <Modal isOpen={isOpen} toggle={() => setIsOpen(false)}>
              <ModalBody>
                <BatchStatusModal type="item" />
              </ModalBody>
            </Modal>
            <Button color="primary" type="submit" size="lg">保存</Button>
          </div>
        </Form>
      )}
    </Formik>
  )
}

const parseUrl = (url) => {
  const parser = global.document.createElement('a')
  parser.href = url
  const protocol = parser.protocol.replace(/:$/, '')
  let port = parser.port
  port = !port && protocol === 'http' ? 80 : port
  port = !port && protocol === 'https' ? 443 : port

  const matched = parser.pathname.match(/^\/?(.*)\/(.*)/) || [null, '', '']

  return {
    protocol,
    host: parser.hostname,
    port,
    path: matched[1],
    filename: matched[2]
  }
}

const select = ({ itemImportSetting }) => ({ itemImportSetting })

export default connect(select)(HttpSettingEditForm)
