import { createActions } from 'redux-actions'

const FETCH_COMPANY_ALLOW_IP_ADDRESS = 'FETCH_COMPANY_ALLOW_IP_ADDRESS'
const FETCH_COMPANY_ALLOW_IP_ADDRESS_DONE = 'FETCH_COMPANY_ALLOW_IP_ADDRESS_DONE'

const FETCH_COMPANY_ALLOW_IP_ADDRESSES = 'FETCH_COMPANY_ALLOW_IP_ADDRESSES'
const FETCH_COMPANY_ALLOW_IP_ADDRESSES_DONE = 'FETCH_COMPANY_ALLOW_IP_ADDRESSES_DONE'

const SUBMIT_UPDATE_COMPANY_ALLOW_IP_ADDRESS = 'SUBMIT_UPDATE_COMPANY_ALLOW_IP_ADDRESS'
const SUBMIT_UPDATE_COMPANY_ALLOW_IP_ADDRESS_DONE = 'SUBMIT_UPDATE_COMPANY_ALLOW_IP_ADDRESS_DONE'

const SUBMIT_CREATE_COMPANY_ALLOW_IP_ADDRESS = 'SUBMIT_CREATE_COMPANY_ALLOW_IP_ADDRESS'
const SUBMIT_CREATE_COMPANY_ALLOW_IP_ADDRESS_DONE = 'SUBMIT_CREATE_COMPANY_ALLOW_IP_ADDRESS_DONE'

const SUBMIT_DELETE_COMPANY_ALLOW_IP_ADDRESS = 'SUBMIT_DELETE_COMPANY_ALLOW_IP_ADDRESS'
const SUBMIT_DELETE_COMPANY_ALLOW_IP_ADDRESS_DONE = 'SUBMIT_DELETE_COMPANY_ALLOW_IP_ADDRESS_DONE'

export const adminCompanyAllowIpAddressAction = createActions(
  {
    [FETCH_COMPANY_ALLOW_IP_ADDRESS_DONE]: [
      (...args) => args[0],
      (...args) => args[1],
    ],
    [FETCH_COMPANY_ALLOW_IP_ADDRESSES_DONE]: [
      (...args) => args[0],
      (...args) => args[1],
    ],
    [SUBMIT_UPDATE_COMPANY_ALLOW_IP_ADDRESS_DONE]: [
      (...args) => args[0],
      (...args) => Object.assign({ notification: 'ALL_CASE' }, args[1]),
    ],
    [SUBMIT_CREATE_COMPANY_ALLOW_IP_ADDRESS_DONE]: [
      (...args) => args[0],
      (...args) => Object.assign({ notification: 'ALL_CASE' }, args[1]),
    ],
    [SUBMIT_DELETE_COMPANY_ALLOW_IP_ADDRESS_DONE]: [
      (...args) => args[0],
      (...args) => Object.assign({ notification: 'ALL_CASE' }, args[1]),
    ],
  },
  FETCH_COMPANY_ALLOW_IP_ADDRESS,
  FETCH_COMPANY_ALLOW_IP_ADDRESSES,
  SUBMIT_UPDATE_COMPANY_ALLOW_IP_ADDRESS,
  SUBMIT_CREATE_COMPANY_ALLOW_IP_ADDRESS,
  SUBMIT_DELETE_COMPANY_ALLOW_IP_ADDRESS,
  {
    prefix: 'ADMIN',
  },
)
