import React from 'react'
import { connect } from 'react-redux'
import { Formik, Form } from 'formik'
import { Button, FormGroup } from 'reactstrap'
import * as Yup from 'yup'
import { Loading, Label, FieldSingle } from 'lib/components'
import { companyCampaignTemplateValueAction } from 'actions'

const validationSchema = Yup.object().shape({
  shopLogoImageUrl: Yup.string().url(),
  shopMainImageUrl: Yup.string().url(),
  copyright: Yup.string(),
  corporateSiteUrl: Yup.string().url(),
  cartPageUrl: Yup.string().url(),
  contactUrl: Yup.string().url(),
  privacyPolicyUrl: Yup.string().url(),
  myPageUrl: Yup.string().url(),
  facebookUrl: Yup.string().url(),
  twitterUrl: Yup.string().url(),
  lineUrl: Yup.string().url(),
  instagramUrl: Yup.string().url(),
  pinterestUrl: Yup.string().url(),
  youtubeUrl: Yup.string().url(),
})

export const CompanyCampaignTemplateValueForm = ({ companyCampaignTemplateValue, dispatch }) => {
  const item = companyCampaignTemplateValue.item
  return (
    <Loading isLoading={companyCampaignTemplateValue.isFetching}>
      <Formik
        initialValues={{
          shopLogoImageUrl: item.shopLogoImageUrl,
          shopMainImageUrl: item.shopMainImageUrl,
          copyright: item.copyright,
          corporateSiteUrl: item.corporateSiteUrl,
          cartPageUrl: item.cartPageUrl,
          contactUrl: item.contactUrl,
          privacyPolicyUrl: item.privacyPolicyUrl,
          myPageUrl: item.myPageUrl,
          facebookUrl: item.facebookUrl,
          twitterUrl: item.twitterUrl,
          lineUrl: item.lineUrl,
          instagramUrl: item.instagramUrl,
          pinterestUrl: item.pinterestUrl,
          youtubeUrl: item.youtubeUrl,
        }}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          dispatch(companyCampaignTemplateValueAction.submitUpdateCompanyCampaignTemplateValue(values))
        }}
      >
        {() => (
          <Form noValidate className="form-horizontal">
            <legend className="mb-3">パーツ情報</legend>
            <fieldset>
              <FormGroup>
                <Label>ショップロゴ画像</Label>
                <FieldSingle name="shopLogoImageUrl" type="text" placeholder="例）https://naviplus.co.jp/logo.png" />
              </FormGroup>
              <FormGroup>
                <Label>ショップメイン画像</Label>
                <FieldSingle name="shopMainImageUrl" type="text" placeholder="例）https://naviplus.co.jp/main.png" />
              </FormGroup>
              <FormGroup>
                <Label>コピーライト</Label>
                <FieldSingle name="copyright" type="text" placeholder="例）©xxxxxxxxx Co., Ltd. All rights reserved." />
              </FormGroup>
            </fieldset>
            <legend className="mb-3">関連URL情報</legend>
            <fieldset>
              <FormGroup>
                <Label>企業サイトURL</Label>
                <FieldSingle name="corporateSiteUrl" type="text" placeholder="例）https://www.naviplus.co.jp/" />
              </FormGroup>
              <FormGroup>
                <Label>カートページURL</Label>
                <FieldSingle name="cartPageUrl" type="text" placeholder="例）https://www.naviplus.co.jp/cart" />
              </FormGroup>
              <FormGroup>
                <Label>問い合わせURL</Label>
                <FieldSingle name="contactUrl" type="text" placeholder="例）https://www.naviplus.co.jp/contact" />
              </FormGroup>
              <FormGroup>
                <Label>プライバシーポリシーURL</Label>
                <FieldSingle name="privacyPolicyUrl" type="text" placeholder="例）https://www.naviplus.co.jp/privacy" />
              </FormGroup>
              <FormGroup>
                <Label>マイページURL</Label>
                <FieldSingle name="myPageUrl" type="text" placeholder="例）https://www.naviplus.co.jp/mypage" />
              </FormGroup>
            </fieldset>
            <legend className="mb-3">SNS情報</legend>
            <fieldset>
              <FormGroup>
                <Label>Facebook</Label>
                <FieldSingle name="facebookUrl" type="text" placeholder="例）https://www.facebook.com/xxxxxxx" />
              </FormGroup>
              <FormGroup>
                <Label>Twitter</Label>
                <FieldSingle name="twitterUrl" type="text" placeholder="例）https://twitter.com/xxxxxxx" />
              </FormGroup>
              <FormGroup>
                <Label>LINE</Label>
                <FieldSingle name="lineUrl" type="text" placeholder="例）http://line.me/xxxxxxx" />
              </FormGroup>
              <FormGroup>
                <Label>Instagram</Label>
                <FieldSingle name="instagramUrl" type="text" placeholder="例）https://www.instagram.com/xxxxxxx" />
              </FormGroup>
              <FormGroup>
                <Label>Pinterest</Label>
                <FieldSingle name="pinterestUrl" type="text" placeholder="例）https://www.pinterest.jp/xxxxxxx" />
              </FormGroup>
              <FormGroup>
                <Label>YouTube</Label>
                <FieldSingle name="youtubeUrl" type="text" placeholder="例）https://www.youtube.com/user/xxxxxxx" />
              </FormGroup>
            </fieldset>
            <div className="text-center"><Button color="primary" type="submit" size="lg">保存</Button></div>
          </Form>
        )}
      </Formik>
    </Loading>
  )
}

const select = ({ companyCampaignTemplateValue }) => ({ companyCampaignTemplateValue })

export default connect(select)(CompanyCampaignTemplateValueForm)
