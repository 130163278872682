import React, { useState } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import { XYPlot, XAxis, YAxis, LineMarkSeries, Crosshair, makeWidthFlexible } from 'react-vis'
import { formatDateTick, thinOutDateTicks } from 'lib/utils'
import { Loading } from 'lib/components'

const FlexibleXYPlot = makeWidthFlexible(XYPlot)

const extractGraphData = (details, queryObj) => {
  if (queryObj.length === 0 || !details || details.length === 0) { return [] }
  const segmentCondition = queryObj[0].segmentCondition
  return details.filter(x => x.detail.length > 0).map((displayIndex) => {
    return displayIndex.detail.map((d, i) => (
      {
        x: new Date(d.date).getTime(),
        y: determineDisplayData(segmentCondition, d)
      }
    ))
  })
}

/**
 * 選択肢の指標にあったレポートデータを返す
 * @param segmentCondition
 * @param detail
 * @returns {*}
 */
const determineDisplayData = (segmentCondition, detail) => {
  switch (segmentCondition) {
  case 'processedCount':
    return detail.processedCount
  case 'clickCount':
    return detail.clickCount
  case 'clickRate':
    return (detail.clickRate * 100).toFixed(1)
  case 'conversionCount':
    return detail.conversionCount
  case 'conversionRate':
    return (detail.conversionRate * 100).toFixed(1)
  case 'priceSum':
    return detail.priceSum
  case 'liftUp':
    return (detail.liftUp * 100).toFixed(1)
  case 'Roas':
    return (detail.roas * 100).toFixed(1)
  case 'Cost':
    return detail.cost
  default:
    return null
  }
}

export const MailAbTestReportGraph = ({ mailAbTestReport, graphHeight = 300, queryObj }) => {
  const [crosshairValues, setCrosshairValues] = useState([])
  const graphData = extractGraphData(mailAbTestReport.items, queryObj)
  if(graphData.length === 0) {
    return (
      <Loading isLoading={mailAbTestReport.isFetching}>
        <div
          style={{
            height: graphHeight,
            backgroundColor: 'rgba(0, 0, 0, 0.03)',
            textAlign: 'center'
          }}
        >
          <h3 style={{lineHeight: `${graphHeight}px`}}>該当期間にデータが存在しません</h3>
        </div>
      </Loading>
    )
  }
  const max = Math.max(
    ...graphData.map(
      (data) => (data.reduce((acc, row) => (Math.max(acc, row.y)), -Infinity))
    )
  )
  return (
    <Loading isLoading={mailAbTestReport.isFetching}>
      <FlexibleXYPlot
        height={graphHeight}
        xType='time'
        yDomain={[0, max]}
        onMouseLeave={() => {setCrosshairValues([])}}
      >
        <XAxis
          tickFormat={(v, i) => (formatDateTick(v, i, queryObj[0].transition))}
          tickValues={thinOutDateTicks(graphData[0].map((data) => (data.x)), queryObj[0].transition)}
        />
        <YAxis/>
        {
          graphData.map((data, i) => (
            i === 0 ?
              <LineMarkSeries
                data={data}
                onNearestX={(value, {index}) => { setCrosshairValues(graphData.map(d => d[index])) }}
                key={`${i}`}
              /> :
              <LineMarkSeries
                data={data}
                key={`${i}`}
              />
          ))
        }
        <Crosshair
          values={crosshairValues}
          titleFormat={(d) => ({title: '日付', value: moment.utc(d[0].x).format('YYYY/MM/DD')})}
          itemsFormat={(d) => (
            d.map((v, i) => ({title: mailAbTestReport.items[i].summary.name, value: d[i].y}))
          )}
        />
      </FlexibleXYPlot>
    </Loading>
  )
}

const select = ({ mailAbTestReport, queryObj }) => ({ mailAbTestReport, ...queryObj })

export default connect(select)(MailAbTestReportGraph)
