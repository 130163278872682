import React from 'react'
import { connect } from 'react-redux'
import { Formik, Form } from 'formik'
import {
  Button, FormGroup
} from 'reactstrap'
import * as Yup from 'yup'
import { FieldSingle, Label, Loading } from 'lib/components'
import { recoTemplateAction } from 'actions'

const validationSchema = Yup.object().shape({
  recoTemplateId: Yup.number().required(),
  aliasName: Yup.string().max(30).required(),
  memo: Yup.string().max(255),
  useCategory: Yup.boolean(),
})

export const RecoTemplateForm = ({ _isLoading, itemsExceptSelf, item, dispatch, submitAction }) => (
  <Loading isLoading={_isLoading}>
    <Formik
      initialValues={{
        recoTemplateId: item.recoTemplateId || '',
        aliasName: item.aliasName || '',
        memo: item.memo || '',
        useCategory: item.useCategory || false
      }}
      validationSchema={validationSchema}
      validate={(values) => {
        let mess = {}
        if(itemsExceptSelf.some(i => i.recoTemplateId === Number(values.recoTemplateId))){
          mess.recoTemplateId = 'テンプレート番号は一意に設定してください'
        }
        if(itemsExceptSelf.some(i => i.aliasName === values.aliasName)){
          mess.aliasName = 'テンプレート名称は一意に設定してください'
        }
        return mess
      }}
      onSubmit={(values) => {
        if(submitAction === 'create'){
          return dispatch(recoTemplateAction.submitCreateRecoTemplate(values))
        }
        if(submitAction === 'update'){
          return dispatch(recoTemplateAction.submitUpdateRecoTemplate({ id: item.id, ...values }))
        }
      }}
    >
      {({ values }) => (
        <Form noValidate className='form-horizontal'>
          <FormGroup>
            <Label require>テンプレート番号</Label>
            <FieldSingle name="recoTemplateId" type="number"/>
          </FormGroup>
          <FormGroup>
            <Label require>テンプレート名称</Label>
            <FieldSingle name="aliasName" type="text"/>
          </FormGroup>
          <FormGroup>
            <Label>メモ</Label>
            <FieldSingle name="memo" type="text"/>
          </FormGroup>
          <FormGroup>
            <Label>リクエスト形式</Label>
            <FieldSingle
              name="useCategory"
              type="checkbox"
              option="categoryをリクエストに追加する"
            />
          </FormGroup>
          <div className="text-center"><Button color="primary" type="submit" size="lg">保存</Button></div>
        </Form>
      )}
    </Formik>
  </Loading>
)

const select = ({recoTemplate}, {rId, submitAction}) => {
  let item = {}
  if(rId) {
    item = recoTemplate.items.find(obj => (
      obj.id === rId
    )) || {}
  }
  const itemsExceptSelf = recoTemplate.items.filter(r => r.id !== rId)
  return { itemsExceptSelf, item, submitAction, _isLoading: recoTemplate.isFetching }
}

export default connect(select)(RecoTemplateForm)
