import React from 'react'
import moment from 'moment'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { push } from 'connected-react-router'
import {
  Table, Button, DropdownToggle, DropdownMenu, DropdownItem, UncontrolledDropdown,
} from 'reactstrap'
import { Loading, MailPreviewThumbnail } from 'lib/components'
import { prospectsCampaignAction } from 'actions'

export const ProspectsCampaignList = ({
  prospectsCampaign, limit = 10, offset = 0, dispatch,
}) => (
  <Loading isLoading={prospectsCampaign.isFetching}>
    <Table responsive>
      <tbody className="no-thead">
        {
          prospectsCampaign.items
            .sort((a, b) => campaignSort(a, b))
            // ページネーション用filter
            .filter((cam, index) => (index >= offset && index < offset + limit))
            .map(cam => (
              <tr key={`prospects_${cam.id}`} className={cam.enabled ? '' : 'inactive'}>
                <td>
                  <MailPreviewThumbnail type="prospects_campaigns" id={cam.id} />
                </td>
                <td className="campaign-overview">
                  <h5>{cam.name}</h5>
                  <p className="mb-0">
                    <span className="ascii">{moment(cam.updatedAt).format('YYYY/MM/DD HH:mm')}</span>
に更新
                  </p>
                </td>
                <td className="text-center">
                  <UncontrolledDropdown className="split-dropdown">
                    <Button tag={Link} to={`/campaign_edit/prospects/${cam.id}`} color="secondary">編集</Button>
                    <DropdownToggle caret color="secondary" />
                    <DropdownMenu>
                      <DropdownItem onClick={(e) => { dispatch(push(`/campaign_new/prospects?original=${cam.id}`)) }}>コピーして新規作成</DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </td>
                <td className="text-center">
                  <PopupStageDropdownButton campaign={cam} dispatch={dispatch} />
                </td>
              </tr>
            ))
        }
      </tbody>
    </Table>
  </Loading>
)

/**
 * ポップアップのステータス変更ボタンのComponent
 * @param {Object} popup
 * @param {[type]} dispatch
 */

const PopupStageDropdownButton = ({ campaign, dispatch }) => {
  let statusText; let actionText; let
    statusColor
  if (campaign.stage === 'in_preparation') {
    statusText = '準備中'
    statusColor = 'success'
    actionText = '開始する'
  }
  if (campaign.stage === 'in_the_middle' && campaign.enabled) {
    statusText = '配信中'
    statusColor = 'info'
    actionText = '停止する'
  }
  if (campaign.stage === 'in_the_middle' && !campaign.enabled) {
    statusText = '停止中'
    statusColor = 'warning'
    actionText = '開始する'
  }
  if (campaign.stage === 'finished') {
    statusText = '配信終了'
    statusColor = 'danger'
    actionText = '開始する'
  }
  const handleClick = (e) => {
    dispatch(prospectsCampaignAction.submitEnableProspectsCampaign({ ...campaign, stage: campaign.stage === 'in_preparation' ? 'in_the_middle' : campaign.stage, enabled: !campaign.enabled }))
  }
  return (
    <UncontrolledDropdown>
      <DropdownToggle caret color={statusColor}>{statusText}</DropdownToggle>
      <DropdownMenu>
        <DropdownItem onClick={handleClick}>{actionText}</DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  )
}

/**
 * ソート用関数
 * @param  {Object} a         比較対象キャンペーンA
 * @param  {Object} b         比較対象キャンペーンB
 * @param  {Object} condition ソート条件
 * @return {Boolean}          ソート判定結果
 */
// とりあえず、更新日順にしている
const campaignSort = (a, b, condition) => moment(b.updatedAt) - moment(a.updatedAt)


const select = ({ prospectsCampaign }) => ({ prospectsCampaign })

export default connect(select)(ProspectsCampaignList)
