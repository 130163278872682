import { call, put, takeLatest } from 'redux-saga/effects'
import * as api from 'services/adminApi'
import { adminTemplateTagAction } from 'actions'
import { push } from 'connected-react-router'

export function* handleFetchTemplateTags() {
  const { payload, meta } = yield call(api.fetchTemplateTags)
  yield put(adminTemplateTagAction.fetchTemplateTagsDone(payload, meta))
}
function* handleSubmitUpdateTemplateTag(action) {
  const { payload, meta, error } = yield call(api.submitUpdateTemplateTag, action.payload)
  yield put(adminTemplateTagAction.submitUpdateTemplateTagDone(payload, meta))
  if (!error) yield put(push('/admin/template_tag_list'))
}

function* handleSubmitCreateTemplateTag(action) {
  const { payload, meta, error } = yield call(api.submitCreateTemplateTag, action.payload)
  yield put(adminTemplateTagAction.submitCreateTemplateTagDone(payload, meta))
  if (!error) yield put(push('/admin/template_tag_list'))
}

function* handleSubmitDeleteTemplateTag(action) {
  let { payload, meta, error } = yield call(api.submitDeleteTemplateTag, action.payload)
  if (!error) { payload = action.payload }
  yield put(adminTemplateTagAction.submitDeleteTemplateTagDone(payload, meta))
  if (!error) yield put(push('/admin/template_tag_list'))
}

export const adminTemplateTagSaga = [
  takeLatest(adminTemplateTagAction.fetchTemplateTags, handleFetchTemplateTags),
  takeLatest(adminTemplateTagAction.submitUpdateTemplateTag, handleSubmitUpdateTemplateTag),
  takeLatest(adminTemplateTagAction.submitCreateTemplateTag, handleSubmitCreateTemplateTag),
  takeLatest(adminTemplateTagAction.submitDeleteTemplateTag, handleSubmitDeleteTemplateTag),
]
