import { createActions } from 'redux-actions'

const FETCH_EVENTS = 'FETCH_EVENTS'
const FETCH_EVENTS_DONE = 'FETCH_EVENTS_DONE'

export const eventAction = createActions(
  {
    [FETCH_EVENTS_DONE]: [
      (...args) => args[0],
      (...args) => args[1],
    ],
  },
  FETCH_EVENTS,
)
