import React from 'react'
import { connect } from 'react-redux'
import queryString from 'query-string'
import { ClipboardLink, Loading } from 'lib/components'

export const CommonTrackingCode = ({ company }) => {
  const codeText = generateCodeText({
    serial: company.item.serial,
    recoAccountDomain: company.item.recoAccountDomain,
    recoAccountSerial: company.item.recoAccountSerial,
    recoAccountCluster: company.item.recoAccountCluster,
    companyTrackingOptionDirection: company.item.companyTrackingOptionDirection,
  })

  return (
    <Loading isLoading={company.isFetching}>
      <p>全ページの{'</head>'}の直前に貼り付けてください。</p>
      <div>
        <ClipboardLink copyText={codeText} />
      </div>
      <pre className="bg-gray-lighter p-3 code-text">
        { codeText }
      </pre>
    </Loading>
  )
}

const generateCodeText = ({
  serial, recoAccountDomain, recoAccountSerial, recoAccountCluster, companyTrackingOptionDirection,
}) => {
  const hasReco = !!recoAccountDomain
  let q = {
    serial,
  }
  if (hasReco) {
    q = {
      ...q,
      reco_k: recoAccountSerial,
      reco_sna_host: recoAccountCluster,
    }
  }
  if (companyTrackingOptionDirection === 'rtg_to_reco') {
    q.reco_compatible = true
  }
  if (companyTrackingOptionDirection === 'reco_to_rtg') {
    q.rtg_compatible = true
  }
  const qs = queryString.stringify(q)
  const template = `
<script type='text/javascript'>
  (function() {
    var s = document.createElement('script');
    s.type = 'text/javascript';
    s.async = true;
    s.src = "${process.env.REACT_APP_BEAVER_JS_ROOT}/beaver.js?${qs}";
    var x = document.getElementsByTagName('script')[0];
    ${!hasReco ? '' : `
    window.recoConstructer = function (data) {
      window._sna_dam = window._sna_dam || [];
      if (window.__snahost != null) {
        window._sna_dam.push(['reco:snaHost', window.__snahost]);
      }
      window._sna_dam.push(["reco:recoConstructerArgs", data]);
    };
    window.apiSetCtr = function (item_code, tmpl, link, k) {
      if (window.__snahost != null) {
        window._sna_dam.push(['reco:snaHost', window.__snahost]);
      }
      window._sna_dam.push(["reco:apiSetCtrArgs", {
        itemCode: item_code,
        tmpl: tmpl,
        link: link.href
      }]);
    };
    `}
    x.parentNode.insertBefore(s, x);
  })();
  var _sna_dam = _sna_dam || [];
</script>`
  return template.replace(/^\s*\n/gm, '')
}

const select = ({ company }) => ({ company })

export default connect(select)(CommonTrackingCode)
