import React from 'react'

const ImportSettingNotAuthorized = () => (
  <>
    <h2>現在このアカウントではオーダーメイドによる取込設定を行っております</h2>
    <div className='mt-3'>
      ご変更・ご要望がございましたら、お手数ですがsupport@naviplus.co.jpまでご連絡をお願い致します。
      <br/>
      データファイル連携の作業を実施する際はサポート窓口までご連絡ください。作業内容・連携方式の詳細・サービス側への反映時間取り決めなど、データファイル連携に必要となる各種支援をいたします。
    </div>
  </>
)

export default ImportSettingNotAuthorized
