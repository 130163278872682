import { call, put, takeLatest } from 'redux-saga/effects'
import * as api from 'services/adminApi'
import { adminCompanyAllowIpAddressAction } from 'actions'
import { push } from 'connected-react-router'

export function* handleFetchCompanyAllowIpAddress(action) {
  const { payload, meta } = yield call(api.fetchCompanyAllowIpAddress, action.payload)
  yield put(adminCompanyAllowIpAddressAction.fetchCompanyAllowIpAddressDone(payload, meta))
}

export function* handleFetchCompanyAllowIpAddresses(action) {
  const { payload, meta } = yield call(api.fetchCompanyAllowIpAddresses, action.payload)
  yield put(adminCompanyAllowIpAddressAction.fetchCompanyAllowIpAddressesDone(payload, meta))
}

function* handleSubmitUpdateCompanyAllowIpAddress(action) {
  const { payload, meta, error } = yield call(api.submitUpdateCompanyAllowIpAddress, action.payload)
  yield put(adminCompanyAllowIpAddressAction.submitUpdateCompanyAllowIpAddressDone(payload, meta))
  if (!error) yield put(push(`/admin/companies/${action.payload.companyId}/allow_ip_address_list`))
}

function* handleSubmitCreateCompanyAllowIpAddress(action) {
  const { payload, meta, error } = yield call(api.submitCreateCompanyAllowIpAddress, action.payload)
  yield put(adminCompanyAllowIpAddressAction.submitCreateCompanyAllowIpAddressDone(payload, meta))
  if (!error) yield put(push(`/admin/companies/${action.payload.companyId}/allow_ip_address_list`))
}

function* handleSubmitDeleteCompanyAllowIpAddress(action) {
  let { payload, meta, error } = yield call(api.submitDeleteCompanyAllowIpAddress, action.payload)
  if (!error) { payload = action.payload }
  yield put(adminCompanyAllowIpAddressAction.submitDeleteCompanyAllowIpAddressDone(payload, meta))
}

export const adminCompanyAllowIpAddressSaga = [
  takeLatest(adminCompanyAllowIpAddressAction.fetchCompanyAllowIpAddress, handleFetchCompanyAllowIpAddress),
  takeLatest(adminCompanyAllowIpAddressAction.fetchCompanyAllowIpAddresses, handleFetchCompanyAllowIpAddresses),
  takeLatest(adminCompanyAllowIpAddressAction.submitUpdateCompanyAllowIpAddress, handleSubmitUpdateCompanyAllowIpAddress),
  takeLatest(adminCompanyAllowIpAddressAction.submitCreateCompanyAllowIpAddress, handleSubmitCreateCompanyAllowIpAddress),
  takeLatest(adminCompanyAllowIpAddressAction.submitDeleteCompanyAllowIpAddress, handleSubmitDeleteCompanyAllowIpAddress),
]
