import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import ContentWrapper from 'components/layout/ContentWrapper'
import { Card, Button } from 'reactstrap'
import { useMountEffect } from 'lib/hooks'
import PopupList from 'components/parts/popup/PopupList'
import { popupAction } from 'actions'

export const PopupListPage = ({ dispatch }) => {
  useMountEffect(() => {
    dispatch(popupAction.fetchPopups())
  })

  return (
    <ContentWrapper>
      <div className="content-heading">
        <div>ポップアップ一覧</div>
        <div className="ml-3">
          <Button tag={Link} to="/popup_new" color="secondary">新規作成</Button>
        </div>
      </div>
      <Card className="card-default">
        <PopupList />
      </Card>
    </ContentWrapper>
  )
}

export default connect()(PopupListPage)
