import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import ContentWrapper from 'components/layout/ContentWrapper'
import { Button, Card, CardBody } from 'reactstrap'
import { useMountEffect } from 'lib/hooks'
import RecoTemplateList from 'components/parts/recoTemplate/RecoTemplateList'
import { companyAction, recoTemplateAction } from 'actions'

export const RecoTemplateSettingPage = ({company, dispatch}) => {
  useMountEffect(() => {
    dispatch(companyAction.fetchCompany())
    dispatch(recoTemplateAction.fetchRecoTemplates())
  })
  return (
    <ContentWrapper>
      <div className="content-heading">
        <div>レコメンド連携設定</div>
        { company.item.recoAccountDomain &&
          <div className="ml-3">
            <Button tag={Link} to="/reco_template_new" color="secondary">新規作成</Button>
          </div>
        }
      </div>
      <Card className="card-default">
        <CardBody>
          <RecoTemplateList />
        </CardBody>
      </Card>
    </ContentWrapper>
  )
}

const select = ({company}) => ({company})

export default connect(select)(RecoTemplateSettingPage)
