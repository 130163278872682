import React, { useState } from 'react'
import { Form, Formik } from 'formik'
import { connect } from 'react-redux'
import moment from 'moment'
import { FieldSingle } from 'lib/components'
import { Button } from 'reactstrap'
import { adminCompanyStatusAction } from 'actions'
import * as Yup from 'yup'

const validationSchema = Yup.object().shape({
  billingStartAt: Yup.date().required(),
})

export const BillingStartAtRow = ({ adminCompanyStatus, dispatch }) => {
  const [isEditing, setIsEditing] = useState(false)

  if (isEditing) {
    return (
      <tr>
        <th>
          <h6>有料開始日</h6>
        </th>
        <td>
          <Formik
            initialValues={{
              billingStartAt: moment(adminCompanyStatus.item.billingStartAt).format('YYYY-MM-DD') || '',
            }}
            validationSchema={validationSchema}
            onSubmit={(values) => {
              dispatch(adminCompanyStatusAction.submitUpdateCompanyStatus({
                ...adminCompanyStatus.item,
                billingStartAt: values.billingStartAt,
              }))
              setIsEditing(false)
            }}
          >
            {({ values, errors }) => (
              <Form noValidate>
                <FieldSingle name="billingStartAt" type="date" />
                <Button
                  color="primary"
                  type="submit"
                  size="lg"
                >
                  更新
                </Button>
              </Form>
            )}
          </Formik>
        </td>
      </tr>
    )
  }

  return (
    <tr>
      <th>
        <h6>有料開始日</h6>
        { adminCompanyStatus.item.billingStartAt &&
          moment(adminCompanyStatus.item.billingStartAt).format('YYYY-MM-DD')
        }
      </th>
      <td>
        <Button
          color="primary"
          type="button"
          size="lg"
          onClick={() => { setIsEditing(true) }}
        >
          編集
        </Button>
      </td>
    </tr>
  )
}

const select = ({ adminCompanyStatus }) => ({ adminCompanyStatus })

export default connect(select)(BillingStartAtRow)
