import React from 'react'
import { connect } from 'react-redux'
import ContentWrapper from 'components/layout/ContentWrapper'
import { Card } from 'reactstrap'
import { useMountEffect } from 'lib/hooks'
import { deserializeFromQueryString } from 'lib/utils'
import CampaignTemplateList from 'components/parts/campaignTemplate/CampaignTemplateList'
import CampaignTemplateSearchForm from 'components/parts/campaignTemplate/CampaignTemplateSearchForm'
import { campaignTemplateAction } from 'actions'

export const CampaignTemplateListPage = ({ dispatch, location, match }) => {
  const queryObj = deserializeFromQueryString(location.search, { isNested: true }) || {}
  const searchTags = queryObj.tags
  const campaignType = match.params.campaign_type

  useMountEffect(() => {
    dispatch(campaignTemplateAction.fetchCampaignTemplates())
  })

  return (
    <ContentWrapper>
      <div className="content-heading">
        <div>キャンペーンテンプレート一覧</div>
      </div>
      <Card className="card-default">
        <CampaignTemplateSearchForm campaignType={campaignType} searchTags={searchTags} />
      </Card>
      <Card className="card-default">
        <CampaignTemplateList campaignType={campaignType} searchTags={searchTags} />
      </Card>
    </ContentWrapper>
  )
}

export default connect()(CampaignTemplateListPage)
