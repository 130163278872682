import { createActions } from 'redux-actions'

const FETCH_LIMIT_DELIVERY_SETTING = 'FETCH_LIMIT_DELIVERY_SETTING'
const FETCH_LIMIT_DELIVERY_SETTING_DONE = 'FETCH_LIMIT_DELIVERY_SETTING_DONE'

const SUBMIT_UPDATE_LIMIT_DELIVERY_SETTING = 'SUBMIT_UPDATE_LIMIT_DELIVERY_SETTING'
const SUBMIT_UPDATE_LIMIT_DELIVERY_SETTING_DONE = 'SUBMIT_UPDATE_LIMIT_DELIVERY_SETTING_DONE'

export const limitDeliverySettingAction = createActions(
  {
    [FETCH_LIMIT_DELIVERY_SETTING_DONE]: [
      (...args) => args[0],
      (...args) => args[1],
    ],
    [SUBMIT_UPDATE_LIMIT_DELIVERY_SETTING_DONE]: [
      (...args) => args[0],
      (...args) => Object.assign({ notification: 'ALL_CASE' }, args[1]),
    ],
  },
  FETCH_LIMIT_DELIVERY_SETTING,
  SUBMIT_UPDATE_LIMIT_DELIVERY_SETTING,
)
