import { call, put, takeLatest } from 'redux-saga/effects'
import * as api from 'services/adminApi'
import { adminCompanySystemOrderAction } from 'actions'

export function* handleFetchCompanySystemOrder(action) {
  const { payload, meta } = yield call(api.fetchCompanySystemOrder, action.payload)
  yield put(adminCompanySystemOrderAction.fetchCompanySystemOrderDone(payload, meta))
}

export const adminCompanySystemOrderSaga = [
  takeLatest(adminCompanySystemOrderAction.fetchCompanySystemOrder, handleFetchCompanySystemOrder),
]
