import React, { useState } from 'react'
import ContentWrapper from 'components/layout/ContentWrapper'
import { Card, CardBody, CardHeader } from 'reactstrap'
import SuppressionNewForm from 'components/parts/suppression/SuppressionNewForm'
import SuppressionEditForm from 'components/parts/suppression/SuppressionEditForm'

// ページの大枠を定義するコンポーネント
// 現在のstateに応じたpartsを選択する
const SuppressionSettingPage = () => {
  const [text, setText] = useState("")

  const handleSubmit = (t) => {
    setText(t)
  }

  const title = text ? '確認画面' : 'メールアドレス入力画面'
  let parts
  if (text) {
    parts = <SuppressionEditForm text={text} handleSubmit={handleSubmit} />
  } else {
    parts = <SuppressionNewForm handleSubmit={handleSubmit} />
  }
  return (
    <ContentWrapper>
      <div className="content-heading">
        <div>配信停止設定</div>
      </div>
      <Card className="card-default">
        <CardHeader>{ title }</CardHeader>
        <CardBody>
          { parts }
        </CardBody>
      </Card>
    </ContentWrapper>
  )
}

export default SuppressionSettingPage
