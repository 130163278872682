import React from 'react'
import {
  Card, CardBody, Col, Row,
} from 'reactstrap'
import ContentWrapper from 'components/layout/ContentWrapper'
import { connect } from 'react-redux'
import AdminCompanyAllowIpAddressForm from 'components/parts/allowIpAddress/AdminCompanyAllowIpAddressForm'

export const AdminCompanyAllowIpAddressNewPage = ({match}) => {
  return (
    <ContentWrapper>
      <div className="content-heading">
        <div>
          許可IPアドレス作成
        </div>
      </div>
      <Row>
        <Col xl={12}>
          <Card className="card-default">
            <CardBody>
              <AdminCompanyAllowIpAddressForm
                submitAction="create"
                companyId={match.params.id}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </ContentWrapper>
  )
}

export default connect()(AdminCompanyAllowIpAddressNewPage)
