import React from 'react'
import moment from 'moment'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { push } from 'connected-react-router'
import {
  Table, Button, DropdownToggle, DropdownMenu, DropdownItem, UncontrolledDropdown,
} from 'reactstrap'
import { Loading } from 'lib/components'
import { popupAction } from 'actions'

export const PopupList = ({
  popup, limit = 10, offset = 0, dispatch,
}) => (
  <Loading isLoading={popup.isFetching}>
    <Table responsive>
      <tbody className="no-thead">
        {
          popup.items
            .sort((a, b) => popupSort(a, b))
            // ページネーション用filter
            .filter((pop, index) => (index >= offset && index < offset + limit))
            .map(pop => (
              <tr key={`popup_${pop.id}`} className={pop.enabled ? '' : 'inactive'}>
                <td className="campaign-overview">
                  <h5>{pop.name}</h5>
                  <p className="mb-0">
                    <span className="ascii">{moment(pop.updatedAt).format('YYYY/MM/DD HH:mm')}</span>
に更新
                  </p>
                </td>
                <td className="text-center">
                  <UncontrolledDropdown className="split-dropdown">
                    <Button tag={Link} to={`/popup_edit/${pop.id}`} color="secondary">編集</Button>
                    <DropdownToggle caret color="secondary" />
                    <DropdownMenu>
                      <DropdownItem onClick={(e) => { dispatch(push(`/popup_new?original=${pop.id}`)) }}>コピーして新規作成</DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </td>
                <td className="text-center">
                  <PopupStageDropdownButton popup={pop} dispatch={dispatch} />
                </td>
              </tr>
            ))
        }
      </tbody>
    </Table>
  </Loading>
)

/**
 * ポップアップのステータス変更ボタンのComponent
 * @param {Object} popup
 * @param {[type]} dispatch
 */

const PopupStageDropdownButton = ({ popup, dispatch }) => {
  let statusText; let actionText; let
    statusColor
  if (popup.enabled) {
    statusText = '配信中'
    statusColor = 'info'
    actionText = '停止する'
  } else {
    statusText = '停止中'
    statusColor = 'warning'
    actionText = '開始する'
  }
  const handleClick = (e) => { dispatch(popupAction.submitEnablePopup({ ...popup, enabled: !popup.enabled })) }
  return (
    <UncontrolledDropdown>
      <DropdownToggle caret color={statusColor}>{statusText}</DropdownToggle>
      <DropdownMenu>
        <DropdownItem onClick={handleClick}>{actionText}</DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  )
}

/**
 * ソート用関数
 * @param  {Object} a         比較対象キャンペーンA
 * @param  {Object} b         比較対象キャンペーンB
 * @param  {Object} condition ソート条件
 * @return {Boolean}          ソート判定結果
 */
// とりあえず、更新日順にしている
const popupSort = (a, b, condition) => moment(b.updatedAt) - moment(a.updatedAt)


const select = ({ popup }) => ({ popup })

export default connect(select)(PopupList)
