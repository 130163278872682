import { capitalize } from 'lib/utils'
import pluralize from 'pluralize'

/**
 * reducerを自動生成するモジュール。
 * reactの起動時に、actionの名前から必要なreducerを生成する、
 */

/**
 * API呼び出しに通常使用するreducerを定義している。
 */

export const preparedHandlers = {
  fetchObject: isMultiple => (state, action) => ({
    ...state,
    isFetching: true,
  }),
  fetchObjectDone: (isMultiple) => {
    if (isMultiple) {
      return (state, action) => {
        if (action.error) {
          return {
            ...state,
            isFetching: false,
            processResult: action.payload,
          }
        }
        let replaceObjIndex = null
        const replaceObj = state.items.filter((obj, index) => {
          if (obj.id === action.payload.id) {
            replaceObjIndex = index
            return true
          }
          return false
        })[0]
        const items = state.items
        if (replaceObj) {
          items[replaceObjIndex] = action.payload
        }
        else {
          items.push(action.payload)
        }
        return {
          ...state,
          isFetching: false,
          items,
          processResult: action.error ? action.payload : 'SUCCESS',
        }
      }
    }
    return (state, action) => ({
      ...state,
      isFetching: false,
      item: action.error ? state.item : action.payload,
      processResult: action.error ? action.payload : 'SUCCESS',
    })
  },
  fetchObjects: isMultiple => (state, action) => ({
    ...state,
    isFetching: true,
  }),
  fetchObjectsDone: isMultiple => (state, action) => ({
    ...state,
    isFetching: false,
    items: action.error ? state.items : action.payload,
    processResult: action.error ? action.payload : 'SUCCESS',
  }),
  submitUpdateObject: isMultiple => (state, action) => ({
    ...state,
    isSubmitting: true,
  }),
  submitUpdateObjectDone: (isMultiple) => {
    if (isMultiple) {
      return (state, action) => {
        if (action.error) {
          return {
            ...state,
            isSubmitting: false,
            processResult: action.payload,
          }
        }
        let replaceObjIndex = null
        const replaceObj = state.items.filter((obj, index) => {
          // idで識別して更新する
          if (obj.id === action.payload.id) {
            replaceObjIndex = index
            return true
          }
          return false
        })[0]
        const items = state.items
        if (replaceObj) items[replaceObjIndex] = { ...replaceObj, ...action.payload }
        return {
          ...state,
          isSubmitting: false,
          items,
          processResult: action.error ? action.payload : 'SUCCESS',
        }
      }
    }
    return (state, action) => ({
      ...state,
      isSubmitting: false,
      item: action.error ? state.item : { ...state.item, ...action.payload },
      processResult: action.error ? action.payload : 'SUCCESS',
    })
  },
  submitCreateObject: isMultiple => (state, action) => ({
    ...state,
    isSubmitting: true,
  }),
  submitCreateObjectDone: isMultiple => (state, action) => ({
    ...state,
    isSubmitting: false,
    processResult: action.error ? action.payload : 'SUCCESS',
  }),
  submitDeleteObject: isMultiple => (state, action) => ({
    ...state,
    isSubmitting: true,
  }),
  submitDeleteObjectDone: (isMultiple) => {
    if (isMultiple) {
      return (state, action) => {
        if (action.error) {
          return {
            ...state,
            isSubmitting: false,
            processResult: action.payload,
          }
        }
        let replaceObjIndex = null
        const replaceObj = state.items.filter((obj, index) => {
          // idで識別して削除する
          if (obj.id === action.payload.id) {
            replaceObjIndex = index
            return true
          }
          return false
        })[0]
        const items = state.items
        if (replaceObj) delete items[replaceObjIndex]
        return {
          ...state,
          isSubmitting: false,
          items,
          processResult: action.error ? action.payload : 'SUCCESS',
        }
      }
    }
    return (state, action) => ({
      ...state,
      isSubmitting: false,
      item: action.error ? state.item : {},
      processResult: action.error ? action.payload : 'SUCCESS',
    })
  },
}

/**
 * 初期状態（保存されるstateの初期値）を定義。
 * @param  {Boolean} isMultiple         １アカウントに対してhas_manyなプロパティか
 * @param  {Boolean} isUseApi           APIを使用して取得するプロパティか
 * @param  {Object} overrideInitial     上書きする初期状態
 * @return {object}
 */

const generateInitialState = ({ isMultiple, isUseApi }, overrideInitial) => {
  let ret = {}

  if (isMultiple) {
    ret.items = []
  } else {
    ret.item = {}
  }

  if (isUseApi) {
    ret.isFetching = false
    ret.isSubmitting = false
  }

  if (overrideInitial) { ret = { ...ret, ...overrideInitial } }

  return ret
}

const generateActionNameMapping = (objectName, action) => {
  const capObjectName = capitalize(objectName)
  const capObjectsName = pluralize(capObjectName)

  const actionNames = {
    fetchObject: `fetch${capObjectName}`,
    fetchObjectDone: `fetch${capObjectName}Done`,
    fetchObjects: `fetch${capObjectsName}`,
    fetchObjectsDone: `fetch${capObjectsName}Done`,
    submitUpdateObject: `submitUpdate${capObjectName}`,
    submitUpdateObjectDone: `submitUpdate${capObjectName}Done`,
    submitCreateObject: `submitCreate${capObjectName}`,
    submitCreateObjectDone: `submitCreate${capObjectName}Done`,
    submitDeleteObject: `submitDelete${capObjectName}`,
    submitDeleteObjectDone: `submitDelete${capObjectName}Done`,
  }

  let ret = {}
  for (let key in actionNames) {
    if ({}.hasOwnProperty.call(actionNames, key) && action[actionNames[key]]) {
      ret[key] = action[actionNames[key]]
    }
  }

  return ret
}


/**
 * ハンドラーを生成
 * @param  {Array}  actionNameMapping   生成するアクションリスト
 * @param  {Boolean} isMultiple         １アカウントに対してhas_manyなプロパティか
 * @param  {Object} overrideHandler     上書きするハンドラー
 * @return {Object}
 */

const generateHandler = (actionNameMapping, isMultiple, overrideHandler) => {
  let handler = {}
  for (let key in actionNameMapping) {
    if ({}.hasOwnProperty.call(actionNameMapping, key)) {
      handler[actionNameMapping[key]] = preparedHandlers[key](isMultiple)
    }
  }
  if (overrideHandler) { handler = { ...handler, ...overrideHandler } }
  return handler
}

/**
 * 外部から呼び出されるreducerの自動生成メソッド
 * @param  {object} action           定義されているaction（ただの文字列）
 * @param  {string} objectName       storeに格納されるプロパティ名（単数型）
 * @param  {object} overrideHandler 個別処理が必要等の上書き・追加用のHandler
 * @param  {object} overrideInitial  個別に必要なInitialState
 * @return {object}                   reducerに入れるObject
 */

const generateHandlerArgs = (action, objectName, overrideHandler, overrideInitial) => {
  const actionNameMapping = generateActionNameMapping(objectName, action)

  const isMultiple = !!actionNameMapping.fetchObjects
  const isUseApi = !!actionNameMapping.fetchObject || !!actionNameMapping.fetchObjects

  const initial = generateInitialState({ isMultiple, isUseApi }, overrideInitial)
  const handler = generateHandler(actionNameMapping, isMultiple, overrideHandler)

  return { handler, initial }
}

export default generateHandlerArgs
