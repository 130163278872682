import React from 'react'
import {
  Card, CardBody, Col, Row,
} from 'reactstrap'
import ContentWrapper from 'components/layout/ContentWrapper'
import { connect } from 'react-redux'
import { useMountEffect } from 'lib/hooks'
import { adminCampaignTemplateAction } from 'actions'
import AdminCampaignTemplateForm from 'components/parts/campaignTemplate/AdminCampaignTemplateForm'

export const AdminCampaignTemplateEditPage = ({dispatch, match}) => {
  const templateId = Number(match.params.id)

  useMountEffect(() => {
    dispatch(adminCampaignTemplateAction.fetchCampaignTemplates())
  })

  return (
    <ContentWrapper>
      <div className="content-heading">
        <div>
          メールテンプレート編集
        </div>
      </div>
      <Row>
        <Col xl={12}>
          <Card className="card-default">
            <CardBody>
              <AdminCampaignTemplateForm
                templateId={templateId}
                submitAction="update"
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </ContentWrapper>
  )
}

export default connect()(AdminCampaignTemplateEditPage)
