import { createActions } from 'redux-actions'

const SEARCH_BEHAVIOR_SUMMARY = 'SEARCH_BEHAVIOR_SUMMARY'

const FETCH_BEHAVIOR_SUMMARY = 'FETCH_BEHAVIOR_SUMMARY'
const FETCH_BEHAVIOR_SUMMARY_DONE = 'FETCH_BEHAVIOR_SUMMARY_DONE'


export const behaviorSummaryAction = createActions(
  {
    [FETCH_BEHAVIOR_SUMMARY_DONE]: [
      (...args) => args[0],
      (...args) => args[1],
    ],
  },
  SEARCH_BEHAVIOR_SUMMARY,
  FETCH_BEHAVIOR_SUMMARY,
)
