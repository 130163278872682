import { handleActions } from 'redux-actions'
import generateHandlerArgs from 'modules/reducerGenerator'
import { itemAction as action } from 'actions'

const overrideHandlers = {
  [action.submitUpdateItemDone]: (state, action) => {
    if (action.error) {
      return {
        ...state,
        isSubmitting: false,
        processResult: action.payload,
      }
    }
    let replaceObjIndex = null
    const replaceObj = state.items.data.filter((obj, index) => {
      // idで識別して更新する
      if (obj.id === action.payload.id) {
        replaceObjIndex = index
        return true
      }
      return false
    })[0]
    const data = state.items.data
    if (replaceObj) data[replaceObjIndex] = { ...replaceObj, ...action.payload }
    return {
      ...state,
      isSubmitting: false,
      data,
      processResult: action.error ? action.payload : 'SUCCESS',
    }
  }
}

const { handler, initial } = generateHandlerArgs(action, 'item', overrideHandlers)

export const item = handleActions(handler, initial)
