import { handleActions } from 'redux-actions'
import generateHandlerArgs from 'modules/reducerGenerator'
import { customerImportSettingAction as action } from 'actions'

const overrideHandlers = {
  [action.fetchCustomerImportSettingDone]: (state, action) => ({
    ...state,
    item: action.error ? state.item : action.payload,
    isFetching: false,
    isAuthorized: action.meta.statusCode !== 403,
    processResult: action.error ? action.payload : 'SUCCESS',
  })
}

const overrideInitial = {
  isAuthorized: true,
}

const { handler, initial } = generateHandlerArgs(action, 'customerImportSetting', overrideHandlers, overrideInitial)

export const customerImportSetting = handleActions(handler, initial)
