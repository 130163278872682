import React from 'react'
import { connect } from 'react-redux'
import ContentWrapper from 'components/layout/ContentWrapper'
import swal from 'sweetalert'
import {
  Card, CardBody, CardHeader, Table,
  Button, FormGroup, FormText,
} from 'reactstrap'
import { Formik, Form } from 'formik'
import {
  FieldSingle, Loading,
} from 'lib/components'
import { mainUserConstant as mc } from 'config/constants'
import { mainUserAction } from 'actions'


export const MainUserSettingForm = ({
  registeredUser, dispatch, adminPermission, user,
}) => {
  const filteredOptions = registeredUser.items.filter(user => (!user.mainAccount))

  const options = filteredOptions.map(user => [user.email, user.id])

  const currentMainUser = registeredUser.items.filter(user => !!user.mainAccount)

  const currentMainUserDisplay = () => {
    if (typeof currentMainUser[0] !== 'undefined') {
      return (
        <Table responsive>
          <thead>
            <tr>
              <th>姓</th>
              <th>名</th>
              <th>メールアドレス</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{currentMainUser[0].lastName}</td>
              <td>{currentMainUser[0].firstName}</td>
              <td>{currentMainUser[0].email}</td>
            </tr>
          </tbody>
        </Table>
      )
    }
    return false
  }

  const handleDisabled = () => {
    if (options.length <= 0) {
      return true
    } if (adminPermission.item.status !== 'OK' && !user.item.mainAccount) {
      return true
    }
    return false
  }

  return (
    <Loading isLoading={registeredUser.isFetching}>
      <Formik
        initialValues={{
          id: '',
        }}
        onSubmit={(values) => {
          swal({
            ...mc.mainUserSwalOptions,
          }).then((isConfirm) => { isConfirm && dispatch(mainUserAction.submitUpdateMainUser(values)) })
        }}
      >
        <ContentWrapper>
          <div className="content-heading">
            <div>メインユーザ切替</div>
          </div>
          <Card className="card-default">
            <CardHeader>
              現在のメインユーザ
            </CardHeader>
            <CardBody>
              <div className="mb-4">
                <FormText>
                  メインユーザとは？<br />
                  ファイル連携時のエラーメールの受け取り、週次・月次レポートメールの受け取りやメインユーザ権限移譲の権限を持つユーザです
                </FormText>
              </div>
              {currentMainUserDisplay()}
            </CardBody>
          </Card>
          <Card className="card-default">
            <CardHeader>
            メインユーザ選択
            </CardHeader>
            <CardBody>
              <div className="mb-4">
                <FormText>
                  メインユーザの切替はメインユーザのみ実行できます<br />
                  何らかのご事情でメインユーザでのログインができない場合は、<a href="mailto:support@naviplus.co.jp">サポート窓口</a>までご連絡ください
                </FormText>
              </div>
              <Form noValidate className="form-inline">
                <FormGroup className="mb-2 mr-3 mb-sm-0">
                  <label className="mr-2">対象者メールアドレス</label>
                  <FieldSingle
                    type="select"
                    name="id"
                    options={options}
                    disabled={handleDisabled()}
                  />
                </FormGroup>
                <div className="text-center">
                  <Button
                    color="primary"
                    type="submit"
                    disabled={handleDisabled()}
                  >
                  切替
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
        </ContentWrapper>

      </Formik>
    </Loading>

  )
}

const select = ({ registeredUser, adminPermission, user }) => ({ registeredUser, adminPermission, user })

export default connect(select)(MainUserSettingForm)
