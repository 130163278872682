import { handleActions } from 'redux-actions'
import { behaviorHistoryAction as action } from 'actions'

const initial = {
  items: [],
  isFetching: false,
}

export const behaviorHistory = handleActions({
  [action.fetchBehaviorHistories]: (state) => ({
    ...state,
    isFetching: true,
  }),
  [action.fetchBehaviorHistoriesDone]: (state, action) => {
    return {
      ...state,
      items: action.payload,
      isFetching: false,
    }
  },
}, initial)
