export default {
  testTypes: [
    ['デザインをテスト', 'design_test'],
    ['配信タイミングをテスト', 'delivered_timing_test'],
    ['件名をテスト', 'title_test'],
  ],
  testTypeConditions: [
    ['全て', 'all'],
    ['デザインをテスト', 'design_test'],
    ['配信タイミングをテスト', 'delivered_timing_test'],
    ['件名をテスト', 'title_test'],
  ],
  deliveryStatus: [
    ['全て', 'all'],
    ['配信中', 'on'],
    ['配信中以外', 'off'],
  ],
  showFinishedTest: [
    ['表示する', 'true'],
    ['表示しない', 'false'],
  ],
  campaignTypes: [
    ['放棄系キャンペーン', 'abandoned'],
    ['完了系キャンペーン', 'completed'],
    ['商品連動キャンペーン', 'contextual'],
  ],
  modalTitle: {
    start: 'ABテストを開始します。',
    pause: 'ABテストを中断します。',
    resume: 'ABテストを再開します。',
    finish: 'ABテストを終了します。',
  },
  modalCaption: {
    start: '下記のキャンペーンでABテストを開始します。テスト中のキャンペーンは全て配信中（配信オン設定に自動切替）になります。よろしいですか？',
    pause: 'ABテストを一時停止し、テスト中のキャンペーンは全て中断（配信オフ設定に自動切替）します。よろしいですか？',
    resume: '下記のキャンペーンでABテストを再開します。テスト中のキャンペーンは全て配信中（配信オン設定に自動切替）になります。よろしいですか？',
    finish: 'ABテストを終了します。終了したABテストは再実行できません。配信を続けたいキャンペーンの「採用する」ボタンを押してください。'
    + '配信を続けたいキャンペーンがない場合は「全て不採用」ボタンを押してください。',
  },
}
