import React from 'react'

const HelpIcon = ({ icon, ...attr }) => {
  const { className, style, ...attrs } = attr
  return (
    <i
      className={[className, iconClassName(icon)].join(' ')}
      style={{ verticalAlign: '25%', ...style } }
      {...attrs}
    />
  )
}


const iconClassName = icon => `fa fa-${icon !== undefined ? icon : 'question-circle'} link-like`

export default HelpIcon
