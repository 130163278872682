import React, { useState } from 'react'
import { connect } from 'react-redux'
import { ClipboardLink, Loading, ToggleButton } from 'lib/components'
import { Button, UncontrolledTooltip } from 'reactstrap'
import { trackingSettingAction } from 'actions'

export const OtherTrackingCode = ({ company, trackingSetting, dispatch }) => {
  const otherTsJson = trackingSetting.items.find(item => item.eventType === 'other')
  const otherTs = otherTsJson ? JSON.parse(otherTsJson.setting) : {}
  const defaultTs = {
    userTag: { tags: {} },
  }
  const ts = { ...defaultTs, ...otherTs }

  const [userEmail, setUserEmail] = useState(ts.userTag.tags.userEmail)
  const [userPurchasedCount, setUserPurchasedCount] = useState(ts.userTag.tags.userPurchasedCount)
  const [userName, setUserName] = useState(ts.userTag.tags.userName)
  const [userStatus, setUserStatus] = useState(ts.userTag.tags.userStatus)
  const [userGender, setUserGender] = useState(ts.userTag.tags.userGender)
  const [userPoint, setUserPoint] = useState(ts.userTag.tags.userPoint)
  const [userCustomerType, setUserCustomerType] = useState(ts.userTag.tags.userCustomerType)
  const [userRegister, setUserRegister] = useState(ts.userTag.tags.userRegister)


  const codeText = generateCodeText({
    userEmail, userPurchasedCount, userName, userStatus, userGender, userPoint, userCustomerType, userRegister,
  })

  const saveSetting = () => {
    const updateSetting = {
      userTag: {
        tags: {
          userEmail,
          userPurchasedCount,
          userName,
          // 互換性のため、userFirstName, userLastNameも含める
          userFirstName: userName,
          userLastName: userName,
          userStatus,
          userGender,
          userPoint,
          userCustomerType,
          userRegister,
        },
        show: true,
      },
    }
    if (otherTsJson) {
      return dispatch(trackingSettingAction.submitUpdateTrackingSetting({
        ...otherTsJson,
        setting: JSON.stringify(updateSetting),
      }))
    }
    dispatch(trackingSettingAction.submitCreateTrackingSetting({
      eventType: 'other',
      setting: JSON.stringify(updateSetting),
    }))
  }
  return (
    <Loading isLoading={trackingSetting.isFetching}>
      <p>ログイン後の画面でCookieとユーザの紐付けを行いメール配信対象とするユーザを増やすためのタグです。</p>
      <div className="mb-3">
        <h5>ユーザ属性データ</h5>
        <div className="mb-3">
          <p className="mb-0">任意パラメータ</p>
          <ToggleButton
            className="mr-1 mb-1"
            defaultActive={userEmail}
            onClick={() => setUserEmail(!userEmail)}
            id="userEmail"
          >email
          </ToggleButton>
          <ToggleButton
            className="mr-1 mb-1"
            defaultActive={userPurchasedCount}
            onClick={() => setUserPurchasedCount(!userPurchasedCount)}
            id="userPurchasedCount"
          >purchasedCount
          </ToggleButton>
          <ToggleButton
            className="mr-1 mb-1"
            defaultActive={userName}
            onClick={() => setUserName(!userName)}
            id="userName"
          >firstName/lastName
          </ToggleButton>
          <ToggleButton
            className="mr-1 mb-1"
            defaultActive={userStatus}
            onClick={() => setUserStatus(!userStatus)}
            id="userStatus"
          >status
          </ToggleButton>
          <ToggleButton
            className="mr-1 mb-1"
            defaultActive={userGender}
            onClick={() => setUserGender(!userGender)}
            id="userGender"
          >gender
          </ToggleButton>
          <ToggleButton
            className="mr-1 mb-1"
            defaultActive={userPoint}
            onClick={() => setUserPoint(!userPoint)}
            id="userPoint"
          >point
          </ToggleButton>
          <ToggleButton
            className="mr-1 mb-1"
            defaultActive={userCustomerType}
            onClick={() => setUserCustomerType(!userCustomerType)}
            id="userCustomerType"
          >customerType
          </ToggleButton>
          <ToggleButton
            className="mr-1 mb-1"
            defaultActive={userRegister}
            onClick={() => setUserRegister(!userRegister)}
            id="userRegister"
          >register
          </ToggleButton>
        </div>
        <UncontrolledTooltip target="userEmail">
          ユーザのメールアドレス
        </UncontrolledTooltip>
        <UncontrolledTooltip target="userPurchasedCount">
          ユーザの購入回数
        </UncontrolledTooltip>
        <UncontrolledTooltip target="userName">
          ユーザの名前
        </UncontrolledTooltip>
        <UncontrolledTooltip target="userStatus">
          ユーザの配信対象の有効／無効ステータス
        </UncontrolledTooltip>
        <UncontrolledTooltip target="userGender">
          ユーザの性別
        </UncontrolledTooltip>
        <UncontrolledTooltip target="userPoint">
          ユーザのポイント数
        </UncontrolledTooltip>
        <UncontrolledTooltip target="userCustomerType">
          ユーザの会員タイプ
        </UncontrolledTooltip>
        <UncontrolledTooltip target="userRegister">
          新規会員登録完了フラグ
        </UncontrolledTooltip>
      </div>
      <div className="mb-3">
        <Button
          color="primary"
          onClick={saveSetting}
          id="saveTrackingSetting"
        >設定を保存
        </Button>
      </div>
      <div>
        <ClipboardLink copyText={codeText} />
      </div>
      <pre className="bg-gray-lighter p-3 code-text">
        { codeText }
      </pre>
    </Loading>
  )
}

const generateCodeText = ({
  userEmail, userPurchasedCount, userName, userStatus, userGender, userPoint, userCustomerType, userRegister,
}) => {
  const userText = [
    userEmail && "_sna_dam.push(['email', '{ユーザのメールアドレス}']);",
    userPurchasedCount && "_sna_dam.push(['purchasedCount', '{ユーザの購入回数}']);",
    userName && "_sna_dam.push(['lastName', '{ユーザの名前（姓）}']);",
    userName && "_sna_dam.push(['firstName', '{ユーザの名前（名）}']);",
    userStatus && "_sna_dam.push(['status', '{ユーザの配信対象の有効／無効ステータス}']);",
    userGender && "_sna_dam.push(['gender', '{ユーザの性別}']);",
    userPoint && "_sna_dam.push(['point', '{ユーザのポイント数}']);",
    userCustomerType && "_sna_dam.push(['customerType', '{ユーザの会員タイプ}']);",
    userRegister && "_sna_dam.push(['register', '{新規会員登録完了フラグ}']);",
  ].filter(e => !!e).join('\n  ')

  const template = `
<script type='text/javascript'>
  _sna_dam.push(['userCode', '{ユーザを識別するためのID}']);
  ${userText}
</script>`
  return template.replace(/^\s*\n/gm, '')
}

const select = ({ company, trackingSetting }) => ({ company, trackingSetting })

export default connect(select)(OtherTrackingCode)
