import { createActions } from 'redux-actions'

const FETCH_API_TOKENS = 'FETCH_API_TOKENS'
const FETCH_API_TOKENS_DONE = 'FETCH_API_TOKENS_DONE'

const SUBMIT_CREATE_API_TOKEN = 'SUBMIT_CREATE_API_TOKEN'
const SUBMIT_CREATE_API_TOKEN_DONE = 'SUBMIT_CREATE_API_TOKEN_DONE'

const SUBMIT_DELETE_API_TOKEN = 'SUBMIT_DELETE_API_TOKEN'
const SUBMIT_DELETE_API_TOKEN_DONE = 'SUBMIT_DELETE_API_TOKEN_DONE'

export const apiTokenAction = createActions(
  {
    [FETCH_API_TOKENS_DONE]: [
      (...args) => args[0],
      (...args) => args[1],
    ],
    [SUBMIT_CREATE_API_TOKEN_DONE]: [
      (...args) => args[0],
      (...args) => Object.assign({ notification: 'ALL_CASE' }, args[1]),
    ],
    [SUBMIT_DELETE_API_TOKEN_DONE]: [
      (...args) => args[0],
      (...args) => Object.assign({ notification: 'ALL_CASE', succMess: '削除しました。' }, args[1]),
    ],
  },
  FETCH_API_TOKENS,
  SUBMIT_CREATE_API_TOKEN,
  SUBMIT_DELETE_API_TOKEN,
)
