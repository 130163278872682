import { takeLatest, call, put } from 'redux-saga/effects'
import * as api from 'services/api'
import { apiTokenAction } from 'actions'

function* handleFetchApiTokens(action) {
  const { payload, meta } = yield call(api.fetchApiTokens)
  yield put(apiTokenAction.fetchApiTokensDone(payload, meta))
}

function* handleSubmitCreateApiToken(action) {
  const { payload, meta, error } = yield call(api.submitCreateApiToken, action.payload)
  yield put(apiTokenAction.submitCreateApiTokenDone(payload, meta))
  if (!error) yield put(apiTokenAction.fetchApiTokens())
}

function* handleSubmitDeleteApiToken(action) {
  let { payload, meta, error } = yield call(api.submitDeleteApiToken, action.payload)
  if (!error) { payload = action.payload }
  yield put(apiTokenAction.submitDeleteApiTokenDone(payload, meta))
  if (!error) yield put(apiTokenAction.fetchApiTokens())
}

export const apiTokenSaga = [
  takeLatest(apiTokenAction.fetchApiTokens, handleFetchApiTokens),
  takeLatest(apiTokenAction.submitCreateApiToken, handleSubmitCreateApiToken),
  takeLatest(apiTokenAction.submitDeleteApiToken, handleSubmitDeleteApiToken),
]
