import React from 'react';
import { withRouter } from 'react-router';
import Accessory from '../Accessory'
import Header from './Header'
import Sidebar from './Sidebar'
import Footer from './Footer'

import { connect } from 'react-redux'
import { useMountEffect } from 'lib/hooks'
import { adminPermissionAction } from 'actions'

const Base = ({ publicRoutes, location, children, dispatch }) => {
  const isAdmin = location.pathname.indexOf('/admin/') > -1
  useMountEffect(() => {
    dispatch(adminPermissionAction.fetchAdminPermission())
  })
  if(publicRoutes.indexOf(location.pathname) > -1){
    return (
      <div className="wrapper">
        { children }
      </div>
    )
  }else{
    return (
      <Accessory isAdmin={isAdmin}>
        <div className="wrapper">

          <Header isAdmin={isAdmin} />
          <Sidebar isAdmin={isAdmin} />

          <section className="section-container">
              { children }
          </section>

          <Footer />
        </div>
      </Accessory>
    )
  }
}

export default withRouter(connect()(Base));
