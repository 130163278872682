import { call, put, takeLatest } from 'redux-saga/effects'
import { newsReleaseAction } from 'actions'
import * as api from 'services/api'

function* handleFetchNewsReleases(action) {
  const { payload, meta } = yield call(api.fetchNewsReleases, action.payload)
  yield put(newsReleaseAction.fetchNewsReleasesDone(payload, meta))
}

export const newsReleaseSaga = [
  takeLatest(newsReleaseAction.fetchNewsReleases, handleFetchNewsReleases),
]
