import React, { Fragment } from 'react'

const RecoOnlyBadge = ({ recoOnly }) => {
  if (!recoOnly) {
    return <Fragment />
  }
  return (
    <span className="badge badge-primary">
      レコ用
    </span>
  )
}


export default RecoOnlyBadge
