import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import ContentWrapper from 'components/layout/ContentWrapper'
import { Formik, Form } from 'formik'
import { Card, CardBody, FormGroup, Label, Input, Button } from 'reactstrap'
import { abandonedCampaignAction } from 'actions'

export const AiOptimizedCampaignReportPage = ({ dispatch, abandonedCampaign }) => {
  useEffect(() => {
    dispatch(abandonedCampaignAction.searchAbandonedCampaigns({ is_delivery_time_ai_optimized: true }))
  }, [dispatch])

  return (
    <ContentWrapper>
      <div className="content-heading">
        AI配信時間最適化レポート
      </div>
      <Card className="card-default">
        <CardBody>
          <Formik>
            <Form>
              <FormGroup>
                <Label for="campaignSelect">
                  AI配信時間最適化キャンペーン
                </Label>
                <Input
                  id="campaignSelect"
                  name="select"
                  type="select"
                >
                  { abandonedCampaign.items.map((campaign) => (<option key={campaign.id}>{ campaign.name }</option>)) }
                </Input>
              </FormGroup>
              <Button color="primary" type="submit">レポートを更新</Button>
            </Form>
          </Formik>
        </CardBody>
      </Card>
    </ContentWrapper>
  )
}

const mapStateToProps = (state) => ({
  abandonedCampaign: state.abandonedCampaign,
})
export default connect(mapStateToProps)(AiOptimizedCampaignReportPage)
