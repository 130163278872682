import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Formik, Form } from 'formik'
import { Button, FormGroup, Row, Modal, ModalBody } from 'reactstrap'
import * as Yup from 'yup'
import { FieldSingle, Label, BatchStatusModal } from 'lib/components'
import { customerImportSettingAction, connectionTestAction } from 'actions'
import { FileFormatFields } from 'components/parts/fileImport/FileFormatFields'

const validationSchema = Yup.object().shape({
  host: Yup.string().required(),
  port: Yup.number().required(),
  path: Yup.string().required(),
  filename: Yup.string().required(),
  username: Yup.string().required(),
  password: Yup.string(),
  compressType: Yup.number().required(),
  fileType: Yup.number().required(),
  encoding: Yup.number().required(),
})

export const SftpSettingEditForm = ({ values, dispatch, customerImportSetting }) => {
  const item = customerImportSetting.item
  const [isOpen, setIsOpen] = useState(false)
  return (
    <Formik
      initialValues={{
        protocol: 4,
        host: item.host || '',
        port: item.port || '',
        path: item.path || '',
        filename: item.filename || '',
        username: item.username || '',
        password: item.password || '',
        compressType: item.compressType,
        fileType: item.fileType,
        encoding: item.encoding,
      }}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        dispatch(customerImportSettingAction.submitUpdateCustomerImportSetting(values))
      }}
    >
      <Form noValidate>
        <fieldset>
          <Row>
            <FormGroup className="col-sm-8">
              <Label require>ホスト名 or IPアドレス</Label>
              <FieldSingle name="host" type="text" />
            </FormGroup>
            <FormGroup className="col-sm-1">
              <Label>&nbsp;</Label>
              <div style={{ textAlign: 'center', marginTop: '5px' }}>:</div>
            </FormGroup>
            <FormGroup className="col-sm-3">
              <Label require>ポート番号</Label>
              <FieldSingle name="port" type="text" />
            </FormGroup>
          </Row>
          <Row>
            <FormGroup className="col-sm-6">
              <Label require>ディレクトリパス</Label>
              <FieldSingle name="path" type="text" />
            </FormGroup>
            <FormGroup className="col-sm-1">
              <Label>&nbsp;</Label>
              <div style={{ textAlign: 'center', marginTop: '5px' }}>/</div>
            </FormGroup>
            <FormGroup className="col-sm-5">
              <Label require>ファイル名</Label>
              <FieldSingle name="filename" type="text" />
            </FormGroup>
          </Row>
          <Row>
            <FormGroup className="col-sm-6">
              <Label require>ユーザ名</Label>
              <FieldSingle name="username" type="text" />
            </FormGroup>
            <FormGroup className="col-sm-6">
              <Label>パスワード</Label>
              <FieldSingle name="password" type="password" />
            </FormGroup>
          </Row>
        </fieldset>
        <FileFormatFields />
        <div className="text-center">
          <Button
            color="warning"
            size="lg"
            className="mr-5"
            onClick={() => {
              dispatch(connectionTestAction.submitCreateConnectionTest({ ...values, _type: 'customer' }))
              setIsOpen(true)
            }}
          >
    接続テスト
          </Button>
          <Modal isOpen={isOpen} toggle={() => setIsOpen(false)}>
            <ModalBody>
              <BatchStatusModal type="customer" />
            </ModalBody>
          </Modal>
          <Button color="primary" type="submit" size="lg">保存</Button>
        </div>
      </Form>
    </Formik>
  )
}

const select = ({ customerImportSetting }) => ({ customerImportSetting })

export default connect(select)(SftpSettingEditForm)
