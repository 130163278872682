import React, { useState } from 'react'
import { connect } from 'react-redux'
import { FormGroup, Input } from 'reactstrap'
import { arraysToObject } from 'lib/utils'
import { importSettingConstant as isc } from 'config/constants'
import HttpSettingEditForm from 'components/parts/itemImport/HttpSettingEditForm'
import SftpSettingEditForm from 'components/parts/itemImport/SftpSettingEditForm'
import S3SettingEditForm from 'components/parts/itemImport/S3SettingEditForm'
import FtpsSettingEditForm from 'components/parts/itemImport/FtpsSettingEditForm'
import ApiSettingEditForm from 'components/parts/itemImport/ApiSettingEditForm'
import ApiTokenList from 'components/parts/apiToken/ApiTokenList'
import ImportSettingNotAuthorized from 'components/parts/static/ImportSettingNotAuthorized'
import { Loading } from 'lib/components'

const BEAVER_API_ROOT = process.env.REACT_APP_BEAVER_API_ROOT

const protocols = arraysToObject(isc.protocolValues, 1, 0)

export const ItemImportSettingEditFormWrapper = ({ itemImportSetting }) => {
  const [protocol, setProtocol] = useState(protocolValueToOption(itemImportSetting.item.protocol) || 'http_https')
  return (
    <Loading isLoading={itemImportSetting.isFetching}>
      { itemImportSetting.isAuthorized &&
        <>
          <div>
            <legend className="mb-3">通信形式</legend>
            <FormGroup>
              <Input
                type= 'select'
                name='protocolOption'
                className='custom-select'
                onChange={((e) => { setProtocol(e.target.value) })}
                value={ protocol }
              >
                <option value='http_https'>HTTP or HTTPSを使用</option>
                <option value='sftp'>SFTPを使用</option>
                <option value='s3'>S3を使用</option>
                <option value='ftps'>FTPSを使用</option>
                <option value='api'>アップロードAPIを使用</option>
              </Input>
            </FormGroup>
          </div>
          <div>
            { protocol === 'http_https' &&
              <HttpSettingEditForm/>
            }
            { protocol === 'sftp' &&
              <SftpSettingEditForm/>
            }
            { protocol === 's3' &&
              <S3SettingEditForm/>
            }
            { protocol === 'ftps' &&
              <FtpsSettingEditForm/>
            }
            { protocol === 'api' &&
              <>
                <h4>アップロード先URL</h4>
                <p className="mt-3 mb-4 ml-1" style={{ fontSize: '105%' }}>
                  {BEAVER_API_ROOT}
                  {isc.itemUploadPath}
                </p>
                <ApiTokenList />
                <ApiSettingEditForm />
              </>
            }
          </div>
        </>
      }
      { !itemImportSetting.isAuthorized &&
        <ImportSettingNotAuthorized/>
      }
    </Loading>
  )
}

const protocolValueToOption = (value) => {
  if (protocols[value] === 'HTTP' || protocols[value] === 'HTTPS') { return 'http_https' }
  if (protocols[value] === 'SFTP') { return 'sftp' }
  if (protocols[value] === 'S3') { return 's3' }
  if (protocols[value] === 'FTPS') { return 'ftps' }
  if (protocols[value] === 'ファイルアップロードAPI') { return 'api' }
  return null
}

const select = ({ itemImportSetting }) => ({ itemImportSetting })

export default connect(select)(ItemImportSettingEditFormWrapper)
