import { takeLatest, put } from 'redux-saga/effects'
import { replace } from 'connected-react-router'
import { serializeToQueryString } from 'lib/utils'
import { elasticsearchAction } from 'actions'

function* handleSearchElasticsearchData(action) {
  yield put(replace(`/tag_parameter_confirmation${serializeToQueryString(action.payload)}`))
}

export const elasticsearchSaga = [
  takeLatest(elasticsearchAction.searchElasticsearchData, handleSearchElasticsearchData),
]
