export default {
  terms: [
    ['今週', 'this_week'],
    ['今月', 'this_month'],
    ['先週', 'last_week'],
    ['先月', 'last_month'],
    ['期間を指定', 'free_period'],
  ],
  transitions: [
    ['日次', 'daily'],
    ['週次', 'weekly'],
    ['月次', 'monthly'],
  ],
  campaignTypes: [
    ['全キャンペーン合計', 'all'],
    ['全キャンペーン合計（メール）', 'all_customers_mail'],
    ['全キャンペーン合計（ポップアップ）', 'all_prospects'],
    ['全キャンペーン合計（LINE）', 'all_customers_line'],
  ],
  defaultTransitionReportCondition: {
    campaign: 'all',
    term: 'this_month',
    transition: 'daily',
  },
  transitionReportDisplayIndexes: [
    ['processedCount', '配信数', '#5ab1ef'],
    ['clickCount', 'クリック数', '#f5994e'],
    ['conversionCount', '完了数', '#d87a80'],
  ],
  defaultComparisonReportConditions: {
    report: [
      {
        campaign: 'all',
        term: 'this_month',
      },
      {
        campaign: 'all_customers_mail',
        term: 'this_month',
      },
      {
        campaign: 'all_prospects',
        term: 'this_month',
      },
      {
        campaign: 'all_customers_line',
        term: 'this_month',
      },
    ],
    showDisable: false,
  },
  campaignTypeDelimiter: '_',
  mailAbTestReportSegmentCondition: [
    ['配信数', 'processedCount'],
    ['クリック数', 'clickCount'],
    ['クリック率', 'clickRate'],
    ['完了数', 'conversionCount'],
    ['完了率', 'conversionRate'],
    ['キャンペーン売上', 'priceSum'],
    ['リフトアップ', 'liftUp'],
  ],
  defaultMailAbTestSegmentCondition: 'priceSum',
}
