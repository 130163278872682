import { takeLatest, call, put } from 'redux-saga/effects'
import * as api from 'services/api'
import { customerImportSettingAction } from 'actions'

function* handleFetchCustomerImportSetting() {
  const { payload, meta } = yield call(api.fetchCustomerImportSetting)
  yield put(customerImportSettingAction.fetchCustomerImportSettingDone(payload, meta))
}

function* handleSubmitUpdateCustomerImportSetting(action) {
  const { payload, meta, error } = yield call(api.submitUpdateCustomerImportSetting, action.payload)
  yield put(customerImportSettingAction.submitUpdateCustomerImportSettingDone(payload, meta))
  if (!error) yield put(customerImportSettingAction.fetchCustomerImportSetting())
}

export const customerImportSettingSaga = [
  takeLatest(customerImportSettingAction.fetchCustomerImportSetting, handleFetchCustomerImportSetting),
  takeLatest(customerImportSettingAction.submitUpdateCustomerImportSetting, handleSubmitUpdateCustomerImportSetting),
]
