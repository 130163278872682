import { takeLatest, call, put } from 'redux-saga/effects'
import * as api from 'services/api'
import { mainUserAction, registeredUserAction, userAction } from 'actions'

function* handleSubmitUpdateMainUser(action) {
  const { payload, meta, error } = yield call(api.submitUpdateMainUser, action.payload)
  yield put(mainUserAction.submitUpdateMainUserDone(payload, meta))
  if (!error) yield put(registeredUserAction.fetchRegisteredUsers())
  yield put(userAction.fetchCurrentUser())
}

export const mainUserSaga = [
  takeLatest(mainUserAction.submitUpdateMainUser, handleSubmitUpdateMainUser),
]
