import React, { Fragment } from 'react'

const ReviewOnlyBadge = ({ reviewOnly }) => {
  if (!reviewOnly) {
    return <Fragment />
  }
  return (
    <span className="badge badge-primary">
      レビュー用
    </span>
  )
}


export default ReviewOnlyBadge
