import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import ContentWrapper from 'components/layout/ContentWrapper'
import { Card, CardBody, CardHeader } from 'reactstrap'

import { deserializeFromQueryString } from 'lib/utils'
import { elasticsearchBehaviorsAction, elasticsearchCustomersAction, elasticsearchItemsAction } from 'actions'
import TagParameterSearchConditionForm from 'components/parts/tagParameter/TagParameterSearchConditionForm'
import TagParameterBehaviorsDataDetailTable from 'components/parts/tagParameter/TagParameterBehaviorsDataDetailTable'
import TagParameterItemsDataDetailTable from 'components/parts/tagParameter/TagParameterItemsDataDetailTable'
import TagParameterCustomersDataDetailTable from 'components/parts/tagParameter/TagParameterCustomersDataDetailTable'
import { tagParameterConstant as tp } from 'config/constants'

export const TagParameterConfirmationPage = ({dispatch, location}) => {
  const queryObj = toQueryObj(location.search)

  function toQueryObj(queryString){
    let ret = deserializeFromQueryString(queryString) || tp.defaultTagParameterCondition
    if (!ret.confirmationIndexTerm) {
      ret.confirmationIndexTerm = moment().format('YYYY-MM-DD')
    }
    return ret
  }

  useEffect(() => {
    dispatch(elasticsearchBehaviorsAction.fetchElasticsearchBehaviors(queryObj))
    dispatch(elasticsearchCustomersAction.fetchElasticsearchCustomers(queryObj))
    dispatch(elasticsearchItemsAction.fetchElasticsearchItems(queryObj))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search])

  return (
    <ContentWrapper>
      <div className="content-heading">
        <div>
          タグ連携状況を確認
        </div>
      </div>
      <Card className="card-default">
        <CardBody>
          <TagParameterSearchConditionForm queryObj={queryObj} />
        </CardBody>
      </Card>
      <Card className="card-default">
        <CardBody>
          <TagParameterBehaviorsDataDetailTable queryObj={queryObj} />
        </CardBody>
      </Card>
      <Card className="card-default">
        <CardHeader>顧客情報の連携状況</CardHeader>
        <CardBody>
          <TagParameterCustomersDataDetailTable queryObj={queryObj} />
        </CardBody>
      </Card>
      <Card className="card-default">
        <CardHeader>商品情報の連携状況</CardHeader>
        <CardBody>
          <TagParameterItemsDataDetailTable queryObj={queryObj} />
        </CardBody>
      </Card>
    </ContentWrapper>
  )
}

export default connect()(TagParameterConfirmationPage)
