import { takeLatest, call, put } from 'redux-saga/effects'
import * as api from 'services/api'
import { elasticsearchItemsAction } from 'actions'

function* handleFetchElasticsearchItems(action) {
  const { payload, meta } = yield call(api.fetchElasticsearchItems, action.payload)
  yield put(elasticsearchItemsAction.fetchElasticsearchItemsDone(payload, meta))
}

export const elasticsearchItemsSaga = [
  takeLatest(elasticsearchItemsAction.fetchElasticsearchItems, handleFetchElasticsearchItems),
]
