import React from 'react';
import { ToggleState, TriggerResize } from 'lib/components';
import HeaderRun from './Header.run'

import { useMountEffect } from 'lib/hooks'
import AuthDropdown from 'components/parts/auth/AuthDropdown';

const Header = ({isAdmin}) => {
  useMountEffect(() => {
    HeaderRun();
  })

  return (
      <header className="topnavbar-wrapper">
          { /* START Top Navbar */ }
          <nav className="navbar topnavbar">
              { /* START navbar header */ }
              <div className="navbar-header">
                  <a className="navbar-brand" href="#/">
                      <div className="brand-logo">
                          <img className="img-fluid" src="/img/naviplus-logo.png" alt="App Logo" />
                      </div>
                      <div className="brand-logo-collapsed">
                          <img style={{maxWidth: '32px'}} className="img-fluid" src="/img/naviplus-logo-single.png" alt="App Logo" />
                      </div>
                  </a>
              </div>
              { /* END navbar header */ }

              { /* START Left navbar */ }
              <ul className="navbar-nav mr-auto flex-row">
                  <li className="nav-item">
                      { /* Button used to collapse the left sidebar. Only visible on tablet and desktops */ }
                      <TriggerResize>
                          <ToggleState state="aside-collapsed">
                              { /* eslint-disable-next-line */ }
                              <a href="" className="nav-link d-none d-md-block d-lg-block d-xl-block">
                                  <em className="fa fa-navicon"></em>
                              </a>
                          </ToggleState>
                      </TriggerResize>
                      { /* Button to show/hide the sidebar on mobile. Visible on mobile only. */ }
                      <ToggleState state="aside-toggled" nopersist={true}>
                          { /* eslint-disable-next-line */ }
                          <a href=""  className="nav-link sidebar-toggle d-md-none">
                              <em className="fa fa-navicon"></em>
                          </a>
                      </ToggleState>
                  </li>
              </ul>
              { /* END Left navbar */ }
              { /* START Right Navbar */ }
              <ul className="navbar-nav flex-row">
                <AuthDropdown isAdmin={isAdmin} />
              </ul>
              { /* END Right Navbar */ }
          </nav>
          { /* END Top Navbar */ }
      </header>
  );
}

export default Header
