export default {
  siteTypes: [
    ['Eコマース', 'ec'],
    ['Eコマース（平均購買単価２万円以上）', 'ec_over_20K'],
    ['デジタルコンテンツ', 'digital_contents'],
    ['ウェブサービス', 'web_service'],
    ['メディア出版', 'media_publishing'],
    ['不動産', 'real_estate'],
    ['旅行レジャー', 'travel_leisure'],
    ['人材', 'human_resources'],
    ['金融保険', 'finance_and_insurance'],
    ['製造', 'manufacture'],
    ['教育', 'education'],
    ['その他', 'other'],
  ],
  systemTypes: [
    ['株式会社コマース21（Commerce21 SELL-SIDE SOLUTION）', 'c21_sell_side_solution'],
    ['株式会社コマース21（Commerce21 S5）', 'c21_s5'],
    ['株式会社ecbeing（ecbeing）', 'ecbeing'],
    ['株式会社システムインテグレータ（SI Web Shopping）', 'si_web_shopping'],
    ['株式会社エスキュービズム・テクノロジー（EC-Orange）', 'ec_orange'],
    ['株式会社インターファクトリー（えびすマート）', 'ebisu_mart'],
    ['株式会社フューチャーショップ（FutureShop2）', 'future_shop2'],
    ['株式会社アラタナ（カゴラボ）', 'kagolab'],
    ['EC-Cube（Version 2.x）', 'ec_cube2'],
    ['EC-Cube（Version 3.x）', 'ec_cube3'],
    ['株式会社Eストアー(ShopServe)', 'shop_serve'],
    ['その他', 'other_system'],
  ],
  sitePvs: [
    ['250万PV未満', 'pv_under_2500k'],
    ['500万PV未満', 'pv_over_2500k_under_5m'],
    ['1,000万PV未満', 'pv_over_5m_under_10m'],
    ['1,000万PV以上', 'pv_over_10m'],
  ],
  customerDataCounts: [
    ['1万件未満', 'customer_under_10k'],
    ['5万件未満', 'customer_under_50k'],
    ['10万件未満', 'customer_under_100k'],
    ['25万件未満', 'customer_under_250k'],
    ['50万件未満', 'customer_under_500k'],
    ['100万件未満', 'customer_under_1000k'],
    ['150万件未満', 'customer_under_1500k'],
    ['150万件以上', 'customer_over_1500k'],
  ],
  itemDataCounts: [
    ['5万件未満', 'item_under_50k'],
    ['25万件未満', 'item_under_250k'],
    ['100万件未満', 'item_under_1m'],
    ['100万件以上', 'item_over_1m'],
  ],
}
