import { createActions } from 'redux-actions'

const SEARCH_MAIL_AB_TEST_REPORT = 'SEARCH_MAIL_AB_TEST_REPORT'

const CLEAR_MAIL_AB_TEST_REPORTS = 'CLEAR_MAIL_AB_TEST_REPORTS'

const FETCH_MAIL_AB_TEST_REPORT = 'FETCH_MAIL_AB_TEST_REPORT'
const FETCH_MAIL_AB_TEST_REPORT_DONE = 'FETCH_MAIL_AB_TEST_REPORT_DONE'

export const mailAbTestReportAction = createActions(
  {
    [FETCH_MAIL_AB_TEST_REPORT_DONE]: [
      (...args) => args[0],
      (...args) => args[1],
    ],
  },
  SEARCH_MAIL_AB_TEST_REPORT,
  CLEAR_MAIL_AB_TEST_REPORTS,
  FETCH_MAIL_AB_TEST_REPORT,
)
