import React from 'react'
import { connect } from 'react-redux'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import { Button, FormGroup, FormText, Input, Label } from 'reactstrap'
import * as Yup from 'yup'
import { FieldCode, Loading } from 'lib/components'
import { subscriptionTrackingSettingsAction } from 'actions'

const validationSchema = Yup.object().shape({
  enabled: Yup.bool().required(),
  htmlContent: Yup.string().required(),
  plainContent: Yup.string(),
  landing: Yup.string(),
  url: Yup.string().url(),
})

export const SubscriptionTrackingSettingsEditForm = ({ subscriptionTrackingSettings, dispatch }) => {
  function handleSubmit(values, { setErrors }) {
    if (values.isRedirectUrl === 'false') {
      values.url = ''
    }
    // フォームObjectから不要なisRedirectUrlだけ削除する処理
    const sliced = Object.fromEntries(Object.entries(values).filter(([key, _]) => key !== 'isRedirectUrl'))
    dispatch(subscriptionTrackingSettingsAction.updateSubscriptionTrackingSettings(sliced, setErrors))
  }

  return (
    <Loading isLoading={subscriptionTrackingSettings.isFetching}>
      <Formik
        initialValues={{
          enabled: subscriptionTrackingSettings.enabled,
          htmlContent: subscriptionTrackingSettings.htmlContent,
          plainContent: subscriptionTrackingSettings.plainContent,
          landing: subscriptionTrackingSettings.landing,
          url: subscriptionTrackingSettings.url,
          isRedirectUrl: (!!subscriptionTrackingSettings.url).toString(), // urlの値があるかないかをBooleanでキャスト後、radio inputで使用するためにString型のtrue, falseに置き換える
        }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ values }) => (
          <Form>
            <FormGroup check>
              <Field id="enabled" name="enabled" type="checkbox" as={Input} />
              <Label check for="enabled">オプトアウトリンク挿入機能を使用する</Label>
              <FormText>ポップアップメールには適用しません</FormText>
            </FormGroup>
            <FormGroup>
              <Label>オプトアウトリンクメッセージ（HTMLメール用）<span className="text-danger"> *</span></Label>
              <Field name="htmlContent" type="textarea" as={Input} />
              <ErrorMessage name="htmlContent">{msg => <div className='text-danger'>{msg}</div>}</ErrorMessage>
              <FormText>
                  HTMLのオプトアウトリンク挿入位置を自由に設定したい場合は[unsubscribe_tag]をご使用ください
                  （例：{'<a href="[unsubscribe_tag]"></a>'}）
              </FormText>
            </FormGroup>
            <FormGroup>
              <Label>オプトアウトリンクメッセージ（テキストメール用）</Label>
              <Field name="plainContent" type="textarea" as={Input} />
            </FormGroup>
            <FormGroup tag="fieldset">
              <Label>オプトアウトリンクをクリック後の遷移</Label>
              <FormGroup check className="mb-2">
                <Field
                  id="customLanding"
                  name="isRedirectUrl"
                  type="radio"
                  value="false"
                  as={Input}
                />
                <Label check for="customLanding">
                  ランディングページをリタゲメールで表示
                </Label>
              </FormGroup>
              <FormGroup check>
                <Field
                  id="redirectUrl"
                  name="isRedirectUrl"
                  type="radio"
                  value="true"
                  as={Input}
                />
                <Label check for="redirectUrl">
                  自社サイトに誘導
                </Label>
              </FormGroup>
            </FormGroup>
            { values.isRedirectUrl === 'true' ? (
              <FormGroup>
                <Label>リンククリック後にリダイレクトするURL</Label>
                <Field
                  name="url"
                  type="text"
                  placeholder="自サイトにオプトアウト完了ページを用意する場合、こちらにURLを入力してください"
                  as={Input}
                />
              </FormGroup>
            ) : (
              <FormGroup>
                <Label>リンククリック後に表示するランディングページのHTML</Label>
                <FieldCode name="landing" mode="htmlmixed" />
              </FormGroup>
            )}
            <div className="text-center">
              <Button color="primary" type="submit" size="lg" disabled={subscriptionTrackingSettings.isSubmitting}>保存</Button>
            </div>
          </Form>
        )}
      </Formik>
    </Loading>
  )
}

const select = ({ subscriptionTrackingSettings }) => ({ subscriptionTrackingSettings })

export default connect(select)(SubscriptionTrackingSettingsEditForm)
