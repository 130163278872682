import React from 'react'
import { connect } from 'react-redux'
import {
  Table, Alert, Card, UncontrolledTooltip,
} from 'reactstrap'
import { HelpIcon, Loading } from 'lib/components'
import { tagParameterConstant as gc } from 'config/constants'
import TagParameterCustomersDataCsvDownloadButton from 'components/parts/tagParameter/TagParameterCustomersDataCsvDownloadButton'

export const TagParameterCustomersDataDetailTable = ({ customersData, queryObj }) => {
  let campaignPolicy = queryObj.campaignPolicy
  let customersTableData = buildCustomersTableData(customersData.items, campaignPolicy)
  return (
    <Loading isLoading={customersData.isFetching}>
      <Alert color="dark">
            顧客情報の連携が完了すると、会員IDを用いて行動履歴の連携が可能になります。
        <br />
            また、ファイル連携で顧客情報をインポートしている場合は、会員ID（userCode）のタグ連携だけで
        <br />
            施策を実施いただけます。
      </Alert>
      <AlertIndexNotFount customersTableData={customersTableData} campaignPolicy={campaignPolicy} />
      <Card>
        <LabelCustomerDable queryObj={queryObj} />
        <Table bordered responsive>
          <thead>
            <tr style={{ wordBreak: 'keep-all' }}>
              <th>パラメータ名</th>
              <th>
必須
                <HelpIcon id="required_customer" />
              </th>
              <th>
連携状況
                <HelpIcon id="acquiredStatus_customer" />
              </th>
              <th>パラメータ概要</th>
            </tr>
          </thead>
          <tbody>
            { customersTableData !== undefined && customersTableData.length > 0 && customersTableData.map(data => (
              <tr key={`${data.name}_${data.date}`}>
                <td>{data.name}</td>
                <td>{data.required}</td>
                <td>{data.coopCondition}</td>
                <td>{data.abstract}</td>
              </tr>
            ))}
          </tbody>
          <IndicatorTooltips />
        </Table>
      </Card>
      <div className="text-right mb-3">
        <TagParameterCustomersDataCsvDownloadButton queryObj={queryObj} />
      </div>
    </Loading>
  )
}

const AlertIndexNotFount = ({ customersTableData, campaignPolicy }) => {
  if (!customersTableData && campaignPolicy) {
    return (
      <Alert color="warning">
              連携データが1件も見つかりませんでした。検索を再実行するか、タグの設置状況を確認してください。
      </Alert>
    )
  }
  return null
}

const LabelCustomerDable = ({ queryObj }) => (
  <label>
          タグ連携状況(
    {queryObj.confirmationIndexTerm}
          )
  </label>
)

/**
 * 会員登録・退会はそれぞれ独自のデータテンプレートを使用
 * それ以外は汎用テンプレートを使う
 * データテンプレート(= プロパティ名・必須・概要説明の組み合わせ)
 * @param customersData
 * @param campaignPolicy
 * @returns {*}
 */
const buildCustomersTableData = (customersData, campaignPolicy) => {
  if (customersData.length === 0) { return false }
  switch (campaignPolicy) {
  case 'firstPurchasing':
    return setAcquiredStatus(customersData, gc.customersForRegisteredParameterDataTemplate)
  case 'customerUnsubscribe':
    return setAcquiredStatus(customersData, gc.customersForUnsubscribeParameterDataTemplate)
  default:
    return setAcquiredStatus(customersData, gc.customersForGeneralParameterDataTemplate)
  }
}

/**
 * パラメータの値をチェックし連携状況をセットする
 * データ全件で値が存在する：○
 * 必須だが値が存在しない：×
 * データ全件で存在しない値がある：–
 * @param customersData 直近10件の顧客情報ログデータ
 * @param dataTemplate
 * @returns {*}
 */
const setAcquiredStatus = (customersData, dataTemplate) => {
  for (let data of dataTemplate) {
    if (dataExists(customersData, data.name)) {
      data.coopCondition = gc.cooperated
    } else if (data.required && !dataExists(customersData, data.name)) {
      data.coopCondition = gc.invalid
    }
  }
  return dataTemplate
}

/**
 * パラメータ名に対応するデータの存在確認を行う
 * 全データの存在が確認できた場合のみtrue
 * @param customersDat 直近10件の顧客情報ログデータ
 * @param parameterName
 * @returns {boolean}
 */
const dataExists = (customersData, parameterName) => {
  let notExistCount = 0
  let isCooped = false
  for (let data of customersData) {
    if (!data[parameterName]) {
      notExistCount += 1
    }
  }
  if (notExistCount === 0) { isCooped = true }
  return isCooped
}

const IndicatorTooltips = () => (
  <>
    <UncontrolledTooltip target="required_customer" placement="left">
        施策を実施するには必須となっているパラメータが
      <br />
        全て連携されている必要があります。
    </UncontrolledTooltip>
    <UncontrolledTooltip target="acquiredStatus_customer" placement="left">
      ○：最新10件のデータが正しく連携できています。
      <br />
      ×：最新10件のデータに未連携・不適切な値があるため施策を実施できません。
      <br />
      -：最新10件のデータに未連携が含まれていますが、施策は実施可能です。
    </UncontrolledTooltip>
  </>
)

const select = ({
  elasticsearchCustomers, queryObj,
}) => {
  const customers = elasticsearchCustomers.items.map((x) => { x._index_type = 'Customers'; return x })
  return {
    customersData: {
      items: [...customers],
      isFetching: elasticsearchCustomers.isFetching,
    },
    ...queryObj,
  }
}

export default connect(select)(TagParameterCustomersDataDetailTable)
