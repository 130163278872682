import { createActions } from 'redux-actions'

const FETCH_ITEM_IMPORT_SETTING = 'FETCH_ITEM_IMPORT_SETTING'
const FETCH_ITEM_IMPORT_SETTING_DONE = 'FETCH_ITEM_IMPORT_SETTING_DONE'

const SUBMIT_UPDATE_ITEM_IMPORT_SETTING = 'SUBMIT_UPDATE_ITEM_IMPORT_SETTING'
const SUBMIT_UPDATE_ITEM_IMPORT_SETTING_DONE = 'SUBMIT_UPDATE_ITEM_IMPORT_SETTING_DONE'

export const adminItemImportSettingAction = createActions(
  {
    [FETCH_ITEM_IMPORT_SETTING_DONE]: [
      (...args) => args[0],
      (...args) => args[1],
    ],
    [SUBMIT_UPDATE_ITEM_IMPORT_SETTING_DONE]: [
      (...args) => args[0],
      (...args) => args[1],
    ],
  },
  FETCH_ITEM_IMPORT_SETTING,
  SUBMIT_UPDATE_ITEM_IMPORT_SETTING,
  {
    prefix: 'ADMIN',
  },
)
