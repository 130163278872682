import campaignConstant, { bufferTime } from './campaignConstant'

export default {
  ...campaignConstant,
  targetActions: [
    ['お気に入りに追加した', 1, 'addToFavorite'],
    ['カートに追加した', 2, 'addToCart'],
    ['閲覧した（※試験段階の機能です。ご利用の際はお問い合わせください。）', 3, 'view'],
  ],
  scheduleTimes: [
    ['1日後に送信', 24 * 60 - bufferTime],
    ['2日後に送信', 2 * 24 * 60 - bufferTime],
  ],
  purchaseHistories: [
    ['全ての顧客', 'any_purchased_count'],
    ['購入履歴が１回の顧客', 'purchased_once'],
    ['購入履歴が２回以上の顧客', 'purchased_more_than_once'],
    ['購入履歴がない顧客', 'not_purchased'],
  ],
}
