import React from 'react'
import swal from 'sweetalert'
import { DropdownItem } from 'reactstrap'

const SwalDropdownItem = ({ options, callback, children }) => {
  const handleClick = (e) => {
    e.preventDefault()
    swal(options).then(callback)
  }
  return (
    <DropdownItem onClick={handleClick}>{children}</DropdownItem>
  )
}

export default SwalDropdownItem
