import React from 'react'
import { connect } from 'react-redux'
import { CSVLink } from 'react-csv'

const headers = (cart, browser) => {
  let ret = [
    { label: '年月日', key: 'date' },
  ]
  if (cart) {
    ret = [
      ...ret,
      { label: 'カートUB数', key: 'cartUbCount' },
      { label: 'カゴ落ちUB数', key: 'abandonedCartCount' },
      { label: 'カゴ落ち率', key: 'abandonedCartRate' },
    ]
  }
  if (browser) {
    ret = [
      ...ret,
      { label: 'アイテムUB数', key: 'viewUbCount' },
      { label: 'ブラウザ放棄UB数', key: 'abandonedBrowserCount' },
      { label: 'ブラウザ放棄率', key: 'abandonedBrowserRate' },
    ]
  }
  return [
    ...ret,
    { label: '配信対象ユーザ数', key: 'deliverableCount' },
    { label: '抽出率', key: 'matchingRate' },
    { label: '受注単価', key: 'averageOrderPrice' },
    { label: 'ポテンシャル売上', key: 'potentialPrice' },
    { label: 'ヒット率５％', key: 'hitRate5' },
  ]
}

export const BehaviorSummaryCsvDownloadButton = ({ behaviorSummary, cart, browser }) => {
  const details = behaviorSummary.item.detail
  if (!details) {
    return (<div />)
  }
  const summary = behaviorSummary.item.summary
  summary.date = '合計'
  const data = [
    ...details, summary,
  ]
  return (
    <CSVLink
      className="btn btn-success"
      filename="行動情報集計.csv"
      data={data}
      headers={headers(cart, browser)}
    >
CSVダウンロード
    </CSVLink>
  )
}

const select = ({ behaviorSummary }) => ({ behaviorSummary })

export default connect(select)(BehaviorSummaryCsvDownloadButton)
