import { toast } from 'react-toastify'

/**
 * 処理の成功・失敗を表示する通知の管理をするミドルウェア。
 */

const SUCCESS = 'SUCCESS'
const FAILURE = 'FAILURE'
const ALL_CASE = 'ALL_CASE'

const notificationMiddleware = store => next => (action) => {
  if (!action.meta || !action.meta.notification) return next(action)

  const notificationCondition = action.meta.notification
  const notificationType = extractNotificationType(action.payload)

  if (!isFulfillNotificationCondition(notificationCondition, notificationType)) {
    return next(action)
  }

  const successMessage = action.meta.succMess || '更新に成功しました。'
  const failureMessage = action.meta.failMess || '更新に失敗しました。'


  console.log('Notification occurred.')
  if (notificationType === SUCCESS) toast(successMessage, { type: 'success' })
  if (notificationType === FAILURE) toast(failureMessage, { type: 'error' })


  return next(action)
}

const extractNotificationType = (payload) => {
  if (payload instanceof Error) return FAILURE
  return SUCCESS
}

const isFulfillNotificationCondition = (condition, type) => {
  if (condition === ALL_CASE || condition === type) return true
  return false
}

export default notificationMiddleware
