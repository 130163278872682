import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import {
  CardHeader, CardBody,
} from 'reactstrap'
import moment from 'moment'
import { adminCompanyStatusAction } from 'actions'
import AdminStopBadge from 'components/parts/company/AdminStopBadge'
import BillingStartAtRow from 'components/parts/company/BillingStartAtRow'
import CompanyStatusBadge from 'components/parts/company/CompanyStatusBadge'

const StatusTable = ({ adminCompanyStatus, dispatch }) => {
  if (Object.keys(adminCompanyStatus.item).length === 0) {
    return <Fragment />
  }
  return (
    <Fragment>
      <CardHeader>ステータス情報</CardHeader>
      <CardBody>
        <table>
          <tbody>
            <tr>
              <th>
                <h6>メール配信管理者停止ステータス</h6>
                <AdminStopBadge
                  stopByAdmin={adminCompanyStatus.item.stopByAdmin}
                />
              </th>
              <td>
                <label className="switch switch-lg">
                  <input
                    type="checkbox"
                    defaultChecked={adminCompanyStatus.item.stopByAdmin}
                    onChange={() => {
                      dispatch(adminCompanyStatusAction.submitUpdateCompanyStatus({
                        ...adminCompanyStatus.item,
                        stopByAdmin: !adminCompanyStatus.item.stopByAdmin,
                      }))
                    }}
                  />
                  <span />
                </label>
              </td>
            </tr>
            <tr>
              <th>
                <h6>契約ステータス</h6>
                <CompanyStatusBadge
                  status={adminCompanyStatus.item.status}
                />
              </th>
              <td />
            </tr>
            <tr>
              <th>
                <h6>試用期間開始日</h6>
                {adminCompanyStatus.item.trialStartedAt && moment(adminCompanyStatus.item.trialStartedAt).format('YYYY-MM-DD')}
              </th>
              <td />
            </tr>
            <BillingStartAtRow />
          </tbody>
        </table>
      </CardBody>
    </Fragment>
  )
}

const select = ({ adminCompanyStatus }) => ({ adminCompanyStatus })

export default connect(select)(StatusTable)
