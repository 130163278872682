import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { Table, Button } from 'reactstrap'
import { Loading } from 'lib/components'

export const RecoTemplateList = ({ recoTemplate, company, dispatch }) => (
  <Loading isLoading={recoTemplate.isFetching && company.isFetching}>
    { !company.item.recoAccountDomain &&
      <div>
        <h3>現在このアカウントではNaviplusレコメンド機能は使用できません</h3>
        <p>
          NaviPlusレコメンド連携機能の使用をご希望の場合、
          お手数ですがsupport-rtg@naviplus.co.jpまでご連絡をお願い致します。
        </p>
      </div>
    }
    { company.item.recoAccountDomain &&
      <Table responsive>
        <thead>
          <tr>
            <th>テンプレート番号</th>
            <th>テンプレート名称</th>
            <th>メモ</th>
            <th>リクエスト形式</th>
            <th>キャンペーン内での使用例</th>
            <th>&nbsp;</th>
          </tr>
        </thead>
        <tbody>
          { [...recoTemplate.items].reverse().map(r => (
            <tr key={r.id}>
              <td>
                { r.recoTemplateId }
              </td>
              <td>
                { r.aliasName }
              </td>
              <td>
                { r.memo }
              </td>
              <td>
                { r.useCategory && 'categoryをリクエストに追加する' }
              </td>
              <td>
                { `{% for item in recommend_${r.aliasName}.items %}` }<br/>
                &nbsp;&nbsp;[レコメンド商品表示HTML・テキスト]<br/>
                { '{% endfor %}' }
              </td>
              <td>
                <Button
                  tag={Link}
                  to={`/reco_template_edit/${r.id}`}
                  color='secondary'
                >編集
                </Button>
              </td>
            </tr>
          )) }
        </tbody>
      </Table>
    }
  </Loading>
)

const select = ({ recoTemplate, company }) => ({ recoTemplate, company })

export default connect(select)(RecoTemplateList)
