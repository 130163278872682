import React from 'react'
import { connect } from 'react-redux'
import { Table, UncontrolledTooltip } from 'reactstrap'
import { HelpIcon, Loading } from 'lib/components'
import { shouldDisplaySummaryCost, valueOrError } from "lib/reportUtils"

export const TransitionReportKeyIndicator = ({ transitionReport, condition }) => {
  const summary = transitionReport.item.summary
  const shouldDisplaySummaryCostCol = shouldDisplaySummaryCost(condition)
  return (
    <Loading isLoading={transitionReport.isFetching}>
      <Table bordered responsive>
        <thead>
          <tr style={{ wordBreak: 'keep-all' }}>
            <th>
              配信数
              <HelpIcon id="processedCountTooltip_keyIndicator" />
            </th>
            <th>
              クリック数
              <HelpIcon id="clickCountTooltip_keyIndicator" />
            </th>
            <th>
              完了数
              <HelpIcon id="conversionCountTooltip_keyIndicator" />
            </th>
            <th>
              キャンペーン売上
              <HelpIcon id="priceSumTooltip_keyIndicator" />
            </th>
            { shouldDisplaySummaryCostCol
              && (
                <th>
                  コスト
                  <HelpIcon id="costTooltip_keyIndicator" />
                </th>
              )
            }
            { shouldDisplaySummaryCostCol
              && (
                <th>
                  ROAS
                  <HelpIcon id="roasTooltip_keyIndicator" />
                </th>
              )
            }
          </tr>
        </thead>
        <tbody>
          { summary
            && (
              <tr>
                <td>{summary.processedCount.toLocaleString()}</td>
                <td>{summary.clickCount.toLocaleString()}</td>
                <td>{summary.conversionCount.toLocaleString()}</td>
                <td>¥{summary.priceSum.toLocaleString()}</td>
                { shouldDisplaySummaryCostCol
                  && (
                    <td>{valueOrError(`¥${summary.cost.toLocaleString()}`, summary.errors)}</td>
                  )
                }
                { shouldDisplaySummaryCostCol
                  && (
                    <td>{valueOrError(`${Math.round(summary.roas * 1000) / 10}%`, summary.errors)}</td>
                  )
                }
              </tr>
            )
          }
        </tbody>
        <IndicatorTooltips shouldDisplaySummaryCostCol={shouldDisplaySummaryCostCol} />
      </Table>
    </Loading>
  )
}

const IndicatorTooltips = ({ shouldDisplaySummaryCostCol }) => (
  <>
    <UncontrolledTooltip placement="bottom" target="processedCountTooltip_keyIndicator">
      メール/ポップアップ/LINEキャンペーンを配信した件数
    </UncontrolledTooltip>
    <UncontrolledTooltip placement="left" target="clickCountTooltip_keyIndicator">
      メール/ポップアップ/LINEキャンペーン内リンクをクリックし、サイトへ送客した件数<br />
      （ポップアップ内の「メールで送る」は集計対象外）
    </UncontrolledTooltip>
    <UncontrolledTooltip placement="left" target="conversionCountTooltip_keyIndicator">
      クリックから１日以内（ポップアップの場合）または７日以内（キャンペーン配信の場合）に購入完了した件数<br />
      （タグ内パラメータbehaviorTypeの値が「3」）
    </UncontrolledTooltip>
    <UncontrolledTooltip placement="left" target="priceSumTooltip_keyIndicator">
      クリックから１日以内（ポップアップの場合）または７日以内（キャンペーン配信の場合）に購入完了した売上の合計額<br />
      （タグ内パラメータbehaviorTypeの値が「3」）
    </UncontrolledTooltip>
    { shouldDisplaySummaryCostCol
      && (
        <UncontrolledTooltip placement="left" target="costTooltip_keyIndicator">
          配信数課金の期間：配信数と料金テーブルに基づいた額<br />
          クリック課金の期間：成果報酬額（CPC✕クリック数）。ただし最低チャージ額未満にはならない
        </UncontrolledTooltip>
      )
    }
    { shouldDisplaySummaryCostCol
      && (
        <UncontrolledTooltip placement="bottom" target="roasTooltip_keyIndicator">
          コスト対効果
        </UncontrolledTooltip>
      )
    }
  </>
)

const select = ({ transitionReport }) => ({ transitionReport })

export default connect(select)(TransitionReportKeyIndicator)
