import { Form, Formik } from 'formik'
import { Button, FormGroup } from 'reactstrap'
import React from 'react'
import * as Yup from 'yup'
import { generalConstant as gc } from 'config/constants'
import { FieldSingle } from 'lib/components'

const validationSchema = Yup.object().shape({
  invoiceCsvMonth: Yup.date()
    .required(),
})

const DownloadInvoiceCsvForm = () => (
  <Formik
    initialValues={{
      invoiceCsvMonth: '',
    }}
    validationSchema={validationSchema}
    onSubmit={(values) => {
      window.open(`${gc.apiRoot}/api/admin_company_invoices.csv?month=${values.invoiceCsvMonth}`)
    }}
  >
    {() => (
      <Form noValidate className="form-inline">
        <FormGroup className="mb-2 mr-3 mb-sm-0">
          <FieldSingle name="invoiceCsvMonth" type="month" />
        </FormGroup>
        <div className="text-center"><Button color="success" type="submit">請求CSVダウンロード</Button></div>
      </Form>
    )}
  </Formik>
)

export default DownloadInvoiceCsvForm
