import { takeLatest, call, put } from 'redux-saga/effects'
import * as api from 'services/api'
import { companyCampaignTemplateValueAction } from 'actions'

function* handleFetchCompanyCampaignTemplateValue() {
  const { payload, meta } = yield call(api.fetchCompanyCampaignTemplateValue)
  yield put(companyCampaignTemplateValueAction.fetchCompanyCampaignTemplateValueDone(payload, meta))
}

function* handleSubmitUpdateCompanyCampaignTemplateValue(action) {
  const { payload, meta, error } = yield call(api.submitUpdateCompanyCampaignTemplateValue, action.payload)
  yield put(companyCampaignTemplateValueAction.submitUpdateCompanyCampaignTemplateValueDone(payload, meta))
  if (!error) yield put(companyCampaignTemplateValueAction.fetchCompanyCampaignTemplateValue())
}

export const companyCampaignTemplateValueSaga = [
  takeLatest(companyCampaignTemplateValueAction.fetchCompanyCampaignTemplateValue, handleFetchCompanyCampaignTemplateValue),
  takeLatest(companyCampaignTemplateValueAction.submitUpdateCompanyCampaignTemplateValue, handleSubmitUpdateCompanyCampaignTemplateValue),
]
