import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { Table, Button } from 'reactstrap'
import { Loading, MailPreviewThumbnail } from 'lib/components'

export const CampaignTemplateList = ({ campaignTemplate, campaignType, searchTags = [] }) => (
  <Loading isLoading={campaignTemplate.isFetching}>
    <Table responsive>
      <thead>
        <tr>
          <th>サムネイル</th>
          <th>テンプレート名</th>
          <th>概要</th>
          <th>&nbsp;</th>
        </tr>
      </thead>
      <tbody className="no-thead">
        {
          campaignTemplate.items
            .sort((a, b) => (a.displayOrder > b.displayOrder))
            .filter((x) => {
              if (searchTags.length === 0) { return true }
              if (x.templateTags.length === 0) { return false }
              return searchTags.every(searchTag => (x.templateTags.some(templateTag => templateTag.id === searchTag)))
            })
            .map(cam => (
              <tr key={`campaignTempalte_${cam.id}`}>
                <td>
                  <MailPreviewThumbnail type="campaign_templates" id={cam.id} />
                </td>
                <td className="campaign-overview">
                  <h5>{cam.title}</h5>
                  { cam.templateTags.map(tag => (
                    <span key={`tag_${tag.id}`} className="badge badge-purple">{tag.name}</span>
                  ))}
                </td>
                <td style={{ whiteSpace: 'pre-wrap' }}>
                  {cam.description}
                </td>
                <td className="text-center">
                  <Button tag={Link} to={`/campaign_new/${campaignType}?template=${cam.id}`} color="primary">このテンプレートを使う</Button>
                </td>
              </tr>
            ))
        }
      </tbody>
    </Table>
  </Loading>
)

const select = ({ campaignTemplate }) => ({ campaignTemplate })

export default connect(select)(CampaignTemplateList)
