import React from 'react'
import { connect } from 'react-redux'
import { Table } from 'reactstrap'
import { arraysToObject } from 'lib/utils'
import { reportConstant as rc } from 'config/constants'
import { Loading } from 'lib/components'

const terms = arraysToObject(rc.terms, 1, 0)

export const MailAbTestReportSummaryTable = ({ mailAbTestReport, company }) => {
  const summaries = mailAbTestReport.items.map(el => ({
    ...el.summary,
    ...el.info,
    campaignName: el.summary.name,
    termName: terms[el.info.term],
  }))

  return (
    <Loading isLoading={mailAbTestReport.isFetching}>
      <Table bordered responsive>
        <thead>
          <tr style={{ wordBreak: 'keep-all' }}>
            <th>キャンペーン名</th>
            <th>期間</th>
            <th>配信数</th>
            <th>開封数</th>
            <th>開封率</th>
            <th>クリック数</th>
            <th>クリック率</th>
            <th>完了数</th>
            <th>完了率</th>
            <th>キャンペーン売上</th>
            <th>リフトアップ</th>
            <th>メアド取得数</th>
            <th>会員化数</th>
            <th>会員化率</th>
          </tr>
        </thead>
        <tbody>
          { summaries.map((sum, index) => (
            <tr key={`${sum.campaign}_${sum.campaignId || 'noid'}_${sum.term}`}>
              <td>{sum.campaignName}</td>
              <td>{sum.termName}</td>
              <td>{sum.processedCount.toLocaleString()}</td>
              <td>{sum.openCount.toLocaleString()}</td>
              <td>{Math.round(sum.openRate * 1000) / 10}%</td>
              <td>{sum.clickCount.toLocaleString()}</td>
              <td>{Math.round(sum.clickRate * 1000) / 10}%</td>
              <td>{sum.conversionCount.toLocaleString()}</td>
              <td>{Math.round(sum.conversionRate * 1000) / 10}%</td>
              <td>¥{sum.priceSum.toLocaleString()}</td>
              <td>{Math.round(sum.liftUp * 1000) / 10}%</td>
              <td>{sum.prospectCount.toLocaleString()}</td>
              <td>{sum.registerCount.toLocaleString()}</td>
              <td>{Math.round(sum.registerRate * 1000) / 10}%</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Loading>
  )
}

const select = ({ mailAbTestReport, company }) => (
  {
    mailAbTestReport,
    company: { ...company.item },
  }
)

export default connect(select)(MailAbTestReportSummaryTable)
