export default {
  itemCodeCondition: [
    ['完全一致', 'exact'],
    ['部分一致', 'partial']
  ],
  stopDeliveryStatus: [
    ['全て', 'all'],
    ['配信可能', 'enable'],
    ['配信不可', 'disable'],
    ['強制停止', 'force_stop'],
  ],
}
