import React from 'react'
import { connect } from 'react-redux'
import { CSVLink } from 'react-csv'
import { shouldDisplayDetailCost, shouldDisplaySummaryCost } from "lib/reportUtils"

const headersAll = [
  { label: '年月日', key: 'date' },
  { label: 'キャンペーン名', key: 'name' },
  { label: '配信数', key: 'processedCount' },
  { label: '到達数', key: 'deliveredCount' },
  { label: '開封数', key: 'openCount' },
  { label: 'クリック数', key: 'clickCount' },
  { label: 'メアド取得数', key: 'prospectCount' },
  { label: '会員化数', key: 'registerCount' },
  { label: '全体会員化数', key: 'siteRegisterCount' },
  { label: '完了数', key: 'conversionCount' },
  { label: '全体完了数', key: 'siteConversionCount' },
  { label: 'キャンペーン売上', key: 'priceSum' },
  { label: '全体売上', key: 'sitePriceSum' },
  { label: 'キャンペーン受注単価', key: 'orderUnitPrice' },
  { label: 'リフトアップ', key: 'liftUp' },
  { label: '開封率', key: 'openRate' },
  { label: 'クリック率', key: 'clickRate' },
  { label: 'メアド取得率', key: 'prospectRate' },
  { label: '会員化率', key: 'registerRate' },
  { label: '完了率', key: 'conversionRate' },
  { label: 'ROAS', key: 'roas' },
  { label: 'コスト', key: 'cost' },
]

const headersWithoutCost = [
  { label: '年月日', key: 'date' },
  { label: 'キャンペーン名', key: 'name' },
  { label: '配信数', key: 'processedCount' },
  { label: '到達数', key: 'deliveredCount' },
  { label: '開封数', key: 'openCount' },
  { label: 'クリック数', key: 'clickCount' },
  { label: 'メアド取得数', key: 'prospectCount' },
  { label: '会員化数', key: 'registerCount' },
  { label: '全体会員化数', key: 'siteRegisterCount' },
  { label: '完了数', key: 'conversionCount' },
  { label: '全体完了数', key: 'siteConversionCount' },
  { label: 'キャンペーン売上', key: 'priceSum' },
  { label: '全体売上', key: 'sitePriceSum' },
  { label: 'キャンペーン受注単価', key: 'orderUnitPrice' },
  { label: 'リフトアップ', key: 'liftUp' },
  { label: '開封率', key: 'openRate' },
  { label: 'クリック率', key: 'clickRate' },
  { label: 'メアド取得率', key: 'prospectRate' },
  { label: '会員化率', key: 'registerRate' },
  { label: '完了率', key: 'conversionRate' },
]

const headers = (condition) => {
  // CSVにコスト列を出すかは「合計」にコストを表示するかのみをチェックすればいい
  // （「明細」にコストを出すときは「合計」にも必ずコストを出すので）
  if (shouldDisplaySummaryCost(condition)) {
    return headersAll
  }
  return headersWithoutCost
}

const detailsForCsv = (details, condition) => {
  if (shouldDisplayDetailCost(condition)) {
    return details
  }

  // コストを計算できない場合はコスト関連のセルを空白に
  return details.map((detail) => {
    detail.cpc = ''
    detail.cpa = ''
    detail.roas = ''
    detail.cost = ''

    return detail
  })
}

export const TransitionReportCsvDownloadButton = ({ transitionReport, condition }) => {
  const details = transitionReport.item.detail
  if (!details) {
    return (<div />)
  }
  const summary = transitionReport.item.summary
  summary.date = '合計'
  const data = [
    ...detailsForCsv(details, condition), summary,
  ]
  return (
    <CSVLink
      className="btn btn-success"
      filename="配信レポート.csv"
      data={data}
      headers={headers(condition)}
    >
CSVダウンロード
    </CSVLink>
  )
}

const select = ({ transitionReport }) => (
  {
    transitionReport,
  }
)

export default connect(select)(TransitionReportCsvDownloadButton)
