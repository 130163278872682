import React from 'react'
import { connect } from 'react-redux'
import { Formik, Form } from 'formik'
import { Button } from 'reactstrap'
import * as Yup from 'yup'
import { customerImportSettingAction } from 'actions'
import { FileFormatFields } from 'components/parts/fileImport/FileFormatFields'

const validationSchema = Yup.object().shape({
  compressType: Yup.number().required(),
  fileType: Yup.number().required(),
  encoding: Yup.number().required(),
})

export const ApiSettingEditForm = ({ customerImportSetting, dispatch }) => {
  const item = customerImportSetting.item
  return (
    <Formik
      initialValues={{
        protocol: 7,
        compressType: item.compressType,
        fileType: item.fileType,
        encoding: item.encoding,
      }}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        dispatch(customerImportSettingAction.submitUpdateCustomerImportSetting(values))
      }}
    >
      <Form noValidate>
        <FileFormatFields />
        <div className="text-center"><Button color="primary" type="submit" size="lg">保存</Button></div>
      </Form>
    </Formik>
  )
}

const select = ({ customerImportSetting }) => ({ customerImportSetting })

export default connect(select)(ApiSettingEditForm)
