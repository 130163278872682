import { call, put, takeLatest } from 'redux-saga/effects'
import { userAction } from 'actions'
import * as api from 'services/api'

export function* handleFetchCurrentUser() {
  const { payload, meta } = yield call(api.fetchCurrentUser)
  yield put(userAction.fetchCurrentUserDone(payload, meta))
}

function* handleSubmitUpdateCurrentUser(action) {
  const { payload, meta, error } = yield call(api.submitUpdateCurrentUser, action.payload)
  yield put(userAction.submitUpdateCurrentUserDone(payload, meta))
  if (!error) yield put(userAction.fetchCurrentUser())
}

function* handleSubmitUpdatePassword(action) {
  const { payload, meta, error } = yield call(api.submitUpdatePassword, action.payload)
  if (error) { meta.failMess = payload.message }
  yield put(userAction.submitUpdatePasswordDone(payload, meta))
}

export const userSaga = [
  takeLatest(userAction.fetchCurrentUser, handleFetchCurrentUser),
  takeLatest(userAction.submitUpdateCurrentUser, handleSubmitUpdateCurrentUser),
  takeLatest(userAction.submitUpdatePassword, handleSubmitUpdatePassword),
]
