import React, { useState } from 'react'
import moment from 'moment'
import { Col, Row, Modal, ModalBody } from 'reactstrap'
import ReactMarkdown from 'react-markdown'
import NewsCategoryLabel from './NewsCategoryLabel'

export const NewsRelease = ({ news }) => {
  const [isOpen, setIsOpen] = useState(false)
  return (
    <Row>
      <Col xs={2}>
        <NewsCategoryLabel
          category={news.category}
        />
      </Col>
      <Col xs={2}>
        <span className="text-muted ml-2">
          {moment(news.openedAt)
            .format('YYYY/MM/DD HH:mm')}
        </span>
      </Col>
      <Col>
        <a
          href="#/"
          onClick={() => setIsOpen(true)}
        >
          {news.title}
        </a>
        <Modal isOpen={isOpen} toggle={() => setIsOpen(false)}>
          <ModalBody>
            <ModalContent news={news} />
          </ModalBody>
        </Modal>
      </Col>
    </Row>
  )
}

const ModalContent = ({ news }) => (
  <div>
    <p>
      <NewsCategoryLabel
        category={news.category}
      />
      <span className="ml-2 text-muted">
        {moment(news.openedAt)
          .format('YYYY/MM/DD HH:mm')}
      </span>
    </p>
    <h1 className="mb-3 m-0 page-header">{news.title}</h1>
    <ReactMarkdown
      source={news.content}
      escapeHtml={false}
    />
  </div>
)

export default NewsRelease
