export default {
  protocolOptions: [
    ['HTTP or HTTPSを使用', 'http_https'],
    ['SFTPを使用', 'sftp'],
    ['AmazonS3を使用', 's3'],
    ['FTPSを使用', 'ftps'],
    ['ファイルアップロードAPIを使用', 'api'],
  ],
  protocolValues: [
    ['HTTP', 1, 'http'],
    ['HTTPS', 2, 'https'],
    ['SFTP', 4, 'sftp'],
    ['S3', 5, 's3'],
    ['FTPS', 6, 'ftps'],
    ['ファイルアップロードAPI', 7, 'api'],
  ],
  ftpsModes: [
    ['EXPLICIT', 1],
    ['IMPLICIT', 2],
  ],
  compressTypes: [
    ['圧縮なし', 0],
    ['gz圧縮', 1],
    ['zip圧縮', 2],
  ],
  encodings: [
    ['UTF-8', 1],
    ['Shift-JIS', 2],
    ['EUC-JP', 3],
  ],
  fileTypes: [
    ['CSV形式', 1],
    ['TSV形式', 2],
  ],
  authMethod: [
    ['認証を使用しない', 0],
    ['Basic認証', 1],
  ],
  s3Regions: [
    ['US East (Northern Virginia) Region', 'us-east-1'],
    ['US East (Ohio) Region', 'us-east-2'],
    ['US West (Northern California) Region', 'us-west-1'],
    ['US West (Oregon) Region', 'us-west-2'],
    ['EU (Ireland) Region', 'eu-west-1'],
    ['Asia Pacific (Singapore) Region', 'ap-southeast-1'],
    ['Asia Pacific (Sydney) Region', 'ap-southeast-2'],
    ['Asia Pacific (Tokyo) Region', 'ap-northeast-1'],
    ['South America (Sao Paulo) Region', 'sa-east-2'],
  ],
  customerUploadPath: '/api/v01/files/customers',
  itemUploadPath: '/api/v01/files/items',
}
