import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import ContentWrapper from 'components/layout/ContentWrapper'
import { Card, CardBody, CardHeader } from 'reactstrap'
import { useMountEffect } from 'lib/hooks'
import { deserializeFromQueryString } from 'lib/utils'

import {
  mailAbTestReportAction, mailAbTestAction, abandonedCampaignAction, completedCampaignAction, contextualCampaignAction,
} from 'actions'
import MailAbTestReportConditionForm from 'components/parts/report/MailAbTestReportConditionForm'
import MailAbTestReportSummaryTable from 'components/parts/report/MailAbTestReportSummaryTable'
import MailAbTestReportDetailTable from 'components/parts/report/MailAbTestReportDetailTable'
import MailAbTestReportCsvDownloadButton from 'components/parts/report/MailAbTestReportCsvDownloadButton'
import MailAbTestReportGraph from 'components/parts/report/MailAbTestReportGraph'

export const MailAbTestReportPage = ({dispatch, location}) => {
  const queryObj = toQueryObj(location.search)

  function toQueryObj(queryString){
    return deserializeFromQueryString(queryString, { isNested: true, isToArray: true }) || []
  }

  useMountEffect(() => {
    dispatch(mailAbTestAction.fetchMailAbTests())
    dispatch(abandonedCampaignAction.fetchAbandonedCampaigns({ show_finished: true }))
    dispatch(completedCampaignAction.fetchCompletedCampaigns({ show_finished: true }))
    dispatch(contextualCampaignAction.fetchContextualCampaigns({ show_finished: true }))
  })

  useEffect(() => {
    dispatch(mailAbTestReportAction.clearMailAbTestReports())
    for (let q of queryObj) {
      dispatch(mailAbTestReportAction.fetchMailAbTestReport(q))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search])

  return (
    <ContentWrapper>
      <div className="content-heading">
        <div>
          ABテストレポート
        </div>
      </div>
      <Card className="card-default">
        <CardBody>
          <MailAbTestReportConditionForm queryObj={queryObj} />
        </CardBody>
      </Card>
      <div className="text-right mb-3">
        <MailAbTestReportCsvDownloadButton />
      </div>
      <Card className="card-default">
        <CardBody>
          <MailAbTestReportGraph queryObj={queryObj} />
        </CardBody>
      </Card>
      <Card className="card-default">
        <CardHeader>集計期間合計</CardHeader>
        <MailAbTestReportSummaryTable />
      </Card>
      <Card className="card-default">
        <CardHeader>集計期間明細</CardHeader>
        <MailAbTestReportDetailTable />
      </Card>
    </ContentWrapper>
  )
}

export default connect()(MailAbTestReportPage)
