import React from 'react'
import { connect } from 'react-redux'
import ContentWrapper from 'components/layout/ContentWrapper'
import { Card, CardBody } from 'reactstrap'
import { useMountEffect } from 'lib/hooks'
import { recoTemplateAction } from 'actions'
import RecoTemplateForm from 'components/parts/recoTemplate/RecoTemplateForm'

export const RecoTemplateEditPage = ({match, dispatch}) => {
  useMountEffect(() => {
    dispatch(recoTemplateAction.fetchRecoTemplates())
  })
  return (
    <ContentWrapper>
      <div className="content-heading">
        <div>レコメンド連携設定</div>
      </div>
      <Card className="card-default">
        <CardBody>
          { console.log(match) }
          <RecoTemplateForm
            rId={Number(match.params.id)}
            submitAction='update'
          />
        </CardBody>
      </Card>
    </ContentWrapper>
  )
}

const select = ({recoTemplate}) => ({recoTemplate})

export default connect(select)(RecoTemplateEditPage)
