import React from 'react';
import PropTypes from 'prop-types';

/**
 * Wrapper element for template content
 */
const ContentWrapper = ({unwrap, children}) =>(
    <div className="content-wrapper">
        {unwrap ?
            (<div className="unwrap">{children}</div>)
            :
            (children)
        }
    </div>
)

ContentWrapper.propTypes = {
    /** add element with 'unwrap' class to expand content area */
    unwrap: PropTypes.bool
}
ContentWrapper.defaultProps = {
    unwrap: false
}

export default ContentWrapper;
