import { takeLatest, call, put } from 'redux-saga/effects'
import * as api from 'services/api'
import { customersAction } from 'actions'

function* handleSearchCustomers(action) {
  const { payload, meta } = yield call(api.searchCustomers, action.payload)
  yield put(customersAction.searchCustomersDone(payload, meta))
}

export const customersSaga = [
  takeLatest(customersAction.searchCustomers, handleSearchCustomers),
]
