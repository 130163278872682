import React from 'react'
import { Button, Card, CardBody } from 'reactstrap'

const ProspectSettingLinkCard = () => (
  <Card className="card-default">
    <CardBody>
      <legend className="mb-3">ポップアップメール設定</legend>
      <fieldset>
        <div className="mb-2">「メールアドレス取得」パートをご利用の場合、カゴ落ちポップアップメールを設定してください。</div>
      </fieldset>
      <div className="text-center"><Button color="primary" size="lg" href="/prospects_campaign_list">メール設定へ</Button></div>
    </CardBody>
  </Card>
)

export default ProspectSettingLinkCard
