import { decamelizeKeys, camelizeKeys } from 'humps'
import moment from 'moment'
import queryString from 'query-string'

/**
 * 先頭の文字を大文字化
 * @param  {string} str
 * @return {strign}
 */
export const capitalize = str => (
  str.charAt(0).toUpperCase() + str.slice(1)
)

/**
 * ２重配列をオブジェクトに変換
 * @param  {Array}    arys
 * @param  {Integer}  keyIndex オブジェクトのキーになる要素の配列番号
 * @param  {Integer}  valIndex オブジェクトの値になる要素の配列番号
 * @return {Object}
 */
export const arraysToObject = (arys, keyIndex, valIndex) => {
  let ret = {}
  arys.forEach((ele) => {
    ret[ele[keyIndex]] = ele[valIndex]
  })
  return ret
}

/**
 * ２重配列を配列に変換
 * @param  {Array} arys     [description]
 * @param  {Integer} valIndex [description]
 * @return {Array}          [description]
 */
export const arraysToArray = (arys, valIndex) => {
  let ret = []
  arys.forEach((ele) => {
    ret.push(ele[valIndex])
  })
  return ret
}


/**
 * オブジェクトからクエリストリングに変換
 * @param  {Object} data
 * @return {String}
 */
export const serializeToQueryString = (data, { isNested = false, encode = true } = {}) => {
  if (!data || Object.keys(data).length === 0) return ''
  data = decamelizeKeys(data)

  if (isNested) {
    for (let key in data) {
      if ({}.hasOwnProperty.call(data, key)) {
        data[key] = JSON.stringify(data[key])
      }
    }
  }
  const queryStringOptions = {
    encode,
  }

  return `?${queryString.stringify(data, queryStringOptions)}`
}

/**
 * クエリストリングからオブジェクトに変換
 * @param  {String} query
 * @return {Object}
 */
export const deserializeFromQueryString = (qStr, options) => {
  if (!qStr) return null

  qStr = qStr.replace(/^\?/, '')
  let data = queryString.parse(qStr)

  if (options && options.isNested) {
    for (let key in data) {
      if ({}.hasOwnProperty.call(data, key)) {
        data[key] = JSON.parse(data[key])
      }
    }
  }
  if (options && options.isToArray) {
    data = Object.keys(data).map(el => (data[el]))
  }

  return camelizeKeys(data)
}


/**
 * Cookieの値を取得するメソッド。
 * @return {object} objectに格納されたCookie情報
 */

export const getCookie = () => {
  let r = document.cookie.split(';')
  let ret = {}
  r.forEach((value) => {
    let content = value.trim().split('=')
    ret[content[0]] = content[1]
  })
  return ret
}

/**
 * rubyライクなuniqメソッド
 * @param  {Array}   ary  対象の配列
 * @param  {Function} fn  この戻り値が同じものがuniqになる
 * @return {Array}        uniqされた配列
 */

export const uniq = (ary, fn) => ary.filter((x, i, self) => {
  const base = fn(x)
  for (let j = 0; j < i; j += 1) {
    if (base === fn(self[j])) { return false }
  }
  return true
})


/**
 * 指定した範囲の数値を引数を取り、配列を返す
 * @param  {Integer} from  始端の数値
 * @param  {Integer} to    終端の数値
 * @return {Array}         操作を加えた配列
 */
export const fromToMap = (from, to, fn) => {
  let retArray = []
  for (let i = from; i <= to; i += 1) {
    retArray.push(fn(i))
  }
  return retArray
}

export const formatDateTick = (unixTimestamp, index, transition) => {
  const moObj = moment.utc(unixTimestamp)
  const month = moObj.get('month')
  const date = moObj.get('date')

  if(index === 0){
    if(transition === 'monthly') { return moObj.format('YYYY/M') }
    return moObj.format('YYYY/M/D')
  }

  if(transition === 'monthly'){
    return moObj.format('YYYY/M')
  }

  if(transition === 'weekly'){
    if(date < 7){
      if(month === 1){ return moObj.format('YYYY/M/D') }
    }
    return moObj.format('M/D')
  }

  if(date === 1){
    if(month === 1){ return moObj.format('YYYY/M/D') }
  }
  return moObj.format('M/D')
}

export const thinOutDateTicks = (dates, transition) => {
  if(dates.length < 20) { return dates }

  if(transition === 'daily'){
    if(dates.length < 100) {
      return dates.filter(
        (d) => ([1, 5, 10, 15 ,20, 25, 30].includes(moment.utc(d).get('date')))
      )
    }
    return dates.filter(
      (d) => (moment.utc(d).get('date') === 1)
    )
  }

  if(transition === 'weekly'){
    return dates.filter(
      (d) => (moment.utc(d).get('date') === 1)
    )
  }

  return dates.filter(
    (d) => ([1, 4, 7, 10].includes(moment.utc(d).get('month')))
  )
}

/**
 * 指定した値がnullもしくはundefinedかどうかを返す
 * @param  {Object} value 対象の値
 * @return {Boolean}       nullもしくはundefinedかどうか
 */
export const isNullOrUndefined = value => value === null || value === undefined

export const diffObjectAttributes = (content, comparison, excludedKeys) => {
  const diffs = []
  if (!comparison || !content) {
    return diffs
  }
  Object.keys(content).forEach((key) => {
    const val = content[key]
    if (excludedKeys.indexOf(key) !== -1 || !Object.prototype.hasOwnProperty.call(comparison, key) || typeof (val) === 'function') {
      return
    }
    if (val !== comparison[key]) {
      diffs.push(key)
    }
  })
  return diffs
}
