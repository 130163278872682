import { handleActions } from 'redux-actions'
import generateHandlerArgs from 'modules/reducerGenerator'
import { mailAbTestCampaignMapAction as action } from 'actions'

const overrideHandlers = {
  [action.clearMailAbTestCampaignMaps]: (state, action) => ({
    ...state,
    items: [],
  }),
}

const { handler, initial } = generateHandlerArgs(action, 'mailAbTestCampaignMap', overrideHandlers)

export const mailAbTestCampaignMap = handleActions(handler, initial)
