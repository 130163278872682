import { createActions } from 'redux-actions'

const FIND_CUSTOMER = 'FIND_CUSTOMER'
const FIND_CUSTOMER_DONE = 'FIND_CUSTOMER_DONE'
const UPDATE_CUSTOMER = 'UPDATE_CUSTOMER'
const UPDATE_CUSTOMER_DONE = 'UPDATE_CUSTOMER_DONE'
const ACTIVATE_CUSTOMER = 'ACTIVATE_CUSTOMER'
const ACTIVATE_CUSTOMER_SUCCESS = 'ACTIVATE_CUSTOMER_SUCCESS'
const ACTIVATE_CUSTOMER_FAILURE = 'ACTIVATE_CUSTOMER_FAILURE'

export const customerAction = createActions(
  {
    [FIND_CUSTOMER_DONE]: [
      (...args) => args[0],
      (...args) => args[1],
    ],
    [ACTIVATE_CUSTOMER]: (...args) => ({ customer: args[0], toggle: args[1], setErrors: args[2] })
  },
  FIND_CUSTOMER,
  UPDATE_CUSTOMER,
  UPDATE_CUSTOMER_DONE,
  ACTIVATE_CUSTOMER_SUCCESS,
  ACTIVATE_CUSTOMER_FAILURE
)
