import { takeLatest, call, put } from 'redux-saga/effects'
import * as api from 'services/api'
import { subscriptionTrackingSettingsAction } from 'actions'
import { toast } from 'react-toastify'

function* handleFetchSubscriptionTrackingSettings() {
  const { payload } = yield call(api.fetchSubscriptionTrackingSettings)
  yield put(subscriptionTrackingSettingsAction.fetchSubscriptionTrackingSettingsDone(payload))
}

function* handleUpdateSubscriptionTrackingSettings(action) {
  const { payload, error } = yield call(api.updateSubscriptionTrackingSettings, action.payload.settings)
  if (error) {
    yield put(subscriptionTrackingSettingsAction.updateSubscriptionTrackingSettingsFailure())
    action.payload.setErrors({ htmlContent: payload.message })
  } else {
    yield put(subscriptionTrackingSettingsAction.updateSubscriptionTrackingSettingsSuccess())
    toast.success('オプトアウトリンク設定を更新しました')
  }
}

export const subscriptionTrackingSettingsSaga = [
  takeLatest(subscriptionTrackingSettingsAction.fetchSubscriptionTrackingSettings, handleFetchSubscriptionTrackingSettings),
  takeLatest(subscriptionTrackingSettingsAction.updateSubscriptionTrackingSettings, handleUpdateSubscriptionTrackingSettings),
]
