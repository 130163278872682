import React, { Fragment } from 'react'

const UnderIntroductionBadge = ({ underIntroduction }) => {
  if (!underIntroduction) {
    return <Fragment />
  }
  return (
    <span className="badge badge-primary">
      導入中
    </span>
  )
}


export default UnderIntroductionBadge
