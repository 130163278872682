import { createActions } from 'redux-actions'

const FETCH_COMPANY_STATUS = 'FETCH_COMPANY_STATUS'
const FETCH_COMPANY_STATUS_DONE = 'FETCH_COMPANY_STATUS_DONE'

const SUBMIT_UPDATE_COMPANY_STATUS = 'SUBMIT_UPDATE_COMPANY_STATUS'
const SUBMIT_UPDATE_COMPANY_STATUS_DONE = 'SUBMIT_UPDATE_COMPANY_STATUS_DONE'

const SUBMIT_TERMINATE_COMPANY_STATUS = 'SUBMIT_TERMINATE_COMPANY_STATUS'
const SUBMIT_TERMINATE_COMPANY_STATUS_DONE = 'SUBMIT_TERMINATE_COMPANY_STATUS_DONE'

export const adminCompanyStatusAction = createActions(
  {
    [FETCH_COMPANY_STATUS_DONE]: [
      (...args) => args[0],
      (...args) => args[1],
    ],
    [SUBMIT_UPDATE_COMPANY_STATUS_DONE]: [
      (...args) => args[0],
      (...args) => Object.assign({ notification: 'ALL_CASE' }, args[1]),
    ],
    [SUBMIT_TERMINATE_COMPANY_STATUS_DONE]: [
      (...args) => args[0],
      (...args) => Object.assign({ notification: 'ALL_CASE' }, args[1]),
    ],
  },
  FETCH_COMPANY_STATUS,
  SUBMIT_UPDATE_COMPANY_STATUS,
  SUBMIT_TERMINATE_COMPANY_STATUS,
  {
    prefix: 'ADMIN',
  },
)
