import { createActions } from 'redux-actions'

const SUBMIT_UPDATE_MAIN_USER = 'SUBMIT_UPDATE_MAIN_USER'
const SUBMIT_UPDATE_MAIN_USER_DONE = 'SUBMIT_UPDATE_MAIN_USER_DONE'

export const mainUserAction = createActions(
  {
    [SUBMIT_UPDATE_MAIN_USER_DONE]: [
      (...args) => args[0],
      (...args) => Object.assign({ notification: 'ALL_CASE', succMess: '変更が完了しました。' }, args[1]),
    ],
  },
  SUBMIT_UPDATE_MAIN_USER,
)
