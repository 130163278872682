import { createActions } from 'redux-actions'

const SEARCH_CUSTOMERS = 'SEARCH_CUSTOMERS'
const SEARCH_CUSTOMERS_DONE = 'SEARCH_CUSTOMERS_DONE'

export const customersAction = createActions(
  {
    [SEARCH_CUSTOMERS_DONE]: [
      (...args) => args[0],
      (...args) => args[1],
    ],
  },
  SEARCH_CUSTOMERS,
)
