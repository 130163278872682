import { takeLatest, call, put } from 'redux-saga/effects'
import * as api from 'services/api'
import { connectionTestAction } from 'actions'

function* handleFetchConnectionTest(action) {
  const { payload, meta } = yield call(api.fetchConnectionTest, action.payload)
  yield put(connectionTestAction.fetchConnectionTestDone(payload, meta))
}

function* handleSubmitCreateConnectionTest(action) {
  const { payload, meta } = yield call(api.submitCreateConnectionTest, action.payload)
  yield put(connectionTestAction.submitCreateConnectionTestDone(payload, meta))
}

export const connectionTestSaga = [
  takeLatest(connectionTestAction.fetchConnectionTest, handleFetchConnectionTest),
  takeLatest(connectionTestAction.submitCreateConnectionTest, handleSubmitCreateConnectionTest),
]
