import React, { Fragment } from 'react'

const SearchOnlyBadge = ({ searchOnly }) => {
  if (!searchOnly) {
    return <Fragment />
  }
  return (
    <span className="badge badge-primary">
      サーチ用
    </span>
  )
}


export default SearchOnlyBadge
