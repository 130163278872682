import { call, put, takeLatest } from 'redux-saga/effects'
import * as api from 'services/api'
import { itemAction } from 'actions'

export function* handlefetchItems(action) {
  const { payload, meta } = yield call(api.fetchItems, action.payload)
  yield put(itemAction.fetchItemsDone(payload, meta))
}

function* handleSubmitUpdateItem(action) {
  const { payload, meta } = yield call(api.submitUpdateItem, action.payload)
  yield put(itemAction.submitUpdateItemDone(payload.item, meta))
}

export const itemSaga = [
  takeLatest(itemAction.fetchItems, handlefetchItems),
  takeLatest(itemAction.submitUpdateItem, handleSubmitUpdateItem),
]
