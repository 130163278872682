import { call, put, takeLatest } from 'redux-saga/effects'
import * as api from 'services/adminApi'
import { adminItemImportSettingAction } from 'actions'

export function* handleFetchItemImportSetting(action) {
  const { payload, meta } = yield call(api.fetchItemImportSetting, action.payload)
  yield put(adminItemImportSettingAction.fetchItemImportSettingDone(payload, meta))
}

function* handleSubmitUpdateItemImportSetting(action) {
  const { payload, meta } = yield call(api.submitUpdateItemImportSetting, action.payload)
  yield put(adminItemImportSettingAction.submitUpdateItemImportSettingDone(payload.itemImportSetting, meta))
}

export const adminItemImportSettingSaga = [
  takeLatest(adminItemImportSettingAction.fetchItemImportSetting, handleFetchItemImportSetting),
  takeLatest(adminItemImportSettingAction.submitUpdateItemImportSetting, handleSubmitUpdateItemImportSetting),
]
