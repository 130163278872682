import React, { Fragment } from 'react'

const SiteNameLabel = ({ company }) => {
  if (!company.siteUrl) {
    if (!company.siteName) {
      return <Fragment />
    }
    return (
      <span>
        {company.siteName}
      </span>
    )
  }
  return (
    <a href={company.siteUrl}>
      {company.siteName || company.siteUrl}
    </a>
  )
}

export default SiteNameLabel
