import { createActions } from 'redux-actions'

const FETCH_BEHAVIOR_HISTORIES = 'FETCH_BEHAVIOR_HISTORIES'
const FETCH_BEHAVIOR_HISTORIES_DONE = 'FETCH_BEHAVIOR_HISTORIES_DONE'

export const behaviorHistoryAction = createActions(
  {
    [FETCH_BEHAVIOR_HISTORIES_DONE]: [
      (...args) => args[0],
      (...args) => args[1],
    ],
  },
  FETCH_BEHAVIOR_HISTORIES,
)
