import {
  call, fork, put, takeEvery, takeLatest,
} from 'redux-saga/effects'
import { push } from 'connected-react-router'
import * as api from 'services/api'
import { completedCampaignAction } from 'actions'
import { serializeToQueryString } from 'lib/utils'
import { handleNoticeChangeCampaign, handleNoticeEnableCampaign } from './userAccessTrackingSaga'

function* handleFetchCompletedCampaigns(action) {
  const { payload, meta } = yield call(api.fetchCompletedCampaigns, action.payload)
  yield put(completedCampaignAction.fetchCompletedCampaignsDone(payload, meta))
}

function* handleSubmitUpdateCompletedCampaign(action) {
  let { payload, meta, error } = yield call(api.submitUpdateCompletedCampaign, action.payload)
  if (!error && ('completedCampaign' in payload)) { payload = payload.completedCampaign }
  yield put(completedCampaignAction.submitUpdateCompletedCampaignDone(payload, meta))
  if (!error) {
    yield fork(handleNoticeChangeCampaign, payload)
    yield put(push(`/campaign_list${window.location.search}`))
  }
}

function* handleSubmitEnableCompletedCampaign(action) {
  let { payload, meta, error } = yield call(api.submitUpdateCompletedCampaign, action.payload)
  if (!error && ('completedCampaign' in payload)) { payload = payload.completedCampaign }
  yield put(completedCampaignAction.submitEnableCompletedCampaignDone(payload, meta))
  if (!error) {
    yield fork(handleNoticeEnableCampaign, payload)
  }
}

function* handleSubmitCreateCompletedCampaign(action) {
  const { payload, meta, error } = yield call(api.submitCreateCompletedCampaign, action.payload)
  yield put(completedCampaignAction.submitCreateCompletedCampaignDone(payload, meta))
  if (!error) yield put(push(`/campaign_list${serializeToQueryString({ deliveryStatus: 'all' })}`))
}

function* handleSubmitDeleteCompletedCampaign(action) {
  let { payload, meta, error } = yield call(api.submitDeleteCompletedCampaign, {id: action.payload.completedCampaign.id})
  if (!error) {
    action.payload.completedCampaign['enable'] = false
    action.payload.completedCampaign['stage'] = 'finished'
    payload = action.payload
  }
  yield put(completedCampaignAction.submitDeleteCompletedCampaignDone(payload, meta))
}

function* handleSubmitSendCompletedCampaignTestMail(action) {
  let payload = {}
  let meta = {}
  if (action.payload.fromAddress && action.payload.testAddress) {
    ({ payload, meta } = yield call(api.submitSendCompletedCampaignTestMail, action.payload))
  } else {
    payload = new Error()
    meta.failMess = '「Reply-toアドレス」「テストメール送信先アドレス」を入力してください'
  }
  yield put(completedCampaignAction.submitSendCompletedCampaignTestMailDone(payload, meta))
}

export const completedCampaignSaga = [
  takeLatest(completedCampaignAction.fetchCompletedCampaigns, handleFetchCompletedCampaigns),
  takeLatest(completedCampaignAction.submitUpdateCompletedCampaign, handleSubmitUpdateCompletedCampaign),
  takeEvery(completedCampaignAction.submitEnableCompletedCampaign, handleSubmitEnableCompletedCampaign),
  takeLatest(completedCampaignAction.submitCreateCompletedCampaign, handleSubmitCreateCompletedCampaign),
  takeLatest(completedCampaignAction.submitDeleteCompletedCampaign, handleSubmitDeleteCompletedCampaign),
  takeLatest(completedCampaignAction.submitSendCompletedCampaignTestMail, handleSubmitSendCompletedCampaignTestMail),
]
