import React from 'react'
import { Input } from 'reactstrap'
import { FieldGroup } from 'lib/components'

const FieldSingle = props => (
  <FieldGroup>
    <Input {...props} />
  </FieldGroup>
)

export default FieldSingle
