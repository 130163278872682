import React from 'react'
import { connect } from 'react-redux'
import {
  Table, Alert, Card, UncontrolledTooltip,
} from 'reactstrap'
import { HelpIcon, Loading } from 'lib/components'
import { tagParameterConstant as gc } from 'config/constants'
import TagParameterBehaviorsDataCsvDownloadButton from 'components/parts/tagParameter/TagParameterBehaviorsDataCsvDownloadButton'

export const TagParameterBehaviorsDataDetailTable = ({ behaviorsData, queryObj }) => {
  let campaignPolicy = queryObj.campaignPolicy
  let behaviorsTableData = buildBehaviorsTableData(behaviorsData.items, campaignPolicy)
  if (campaignPolicy === 'firstPurchasing' || campaignPolicy === 'customerManage' || campaignPolicy === 'customerUnsubscribe') {
    return (
      <Loading isLoading={behaviorsData.isFetching}>
        <label>行動履歴のタグ連携状況</label>
        <Alert color="dark">
              この施策では利用しません。
        </Alert>
      </Loading>
    )
  }
  return (
    <Loading isLoading={behaviorsData.isFetching}>
      <AlertIndexNotFount behaviorsTableData={behaviorsTableData} campaignPolicy={campaignPolicy} />
      <Card>
        <label>
行動履歴のタグ連携状況(
          {queryObj.confirmationIndexTerm}
)
        </label>
        <Table bordered responsive>
          <thead>
            <tr style={{ wordBreak: 'keep-all' }}>
              <th>パラメータ名</th>
              <th>
                  必須
                <HelpIcon id="required_behavior" />
              </th>
              <th>
                  連携状況
                <HelpIcon id="acquiredStatus_behavior" />
              </th>
              <th>パラメータ概要</th>
            </tr>
          </thead>
          <tbody>
            { behaviorsTableData !== undefined && behaviorsTableData.length > 0 && behaviorsTableData.map(data => (
              <tr key={`${data.name}_${data.date}`}>
                <td>{data.name}</td>
                <td>{data.required}</td>
                <td>{data.coopCondition}</td>
                <td>{data.abstract}</td>
              </tr>
            ))}
          </tbody>
          <IndicatorTooltips />
        </Table>
      </Card>
      <div className="text-right mb-3">
        <TagParameterBehaviorsDataCsvDownloadButton queryObj={queryObj} />
      </div>
    </Loading>
  )
}

const AlertIndexNotFount = ({ behaviorsTableData, campaignPolicy }) => {
  if (!behaviorsTableData && campaignPolicy) {
    return (
      <Alert color="warning">
              連携データが1件も見つかりませんでした。検索を再実行するか、タグの設置状況を確認してください。
      </Alert>
    )
  }
  return null
}

const buildBehaviorsTableData = (behaviorsData, campaignPolicy) => {
  if (behaviorsData.length === 0) { return false }
  switch (campaignPolicy) {
  case 'view':
    return setAcquiredStatus(behaviorsData, gc.behaviorsForItemDetailParameterDataTemplate)
  case 'cart':
  case 'discountInCart':
  case 'littleStockInCart':
  case 'restockInCart':
  case 'register':
    return setAcquiredStatus(behaviorsData, gc.behaviorsForCartDataTemplate)
  case 'repeatPurchasing':
  case 'reviewRequest':
    return setAcquiredStatus(behaviorsData, gc.behaviorsForPurchasedDataTemplate)
  case 'littleStockInFav':
  case 'restockInFav':
  case 'discountInFav':
    return setAcquiredStatus(behaviorsData, gc.behaviorsForFavoriteParameterDataTemplate)
  default:
    return false
  }
}

/**
 * パラメータの値をチェックし連携状況をセットする
 * データ全件で値が存在する：○
 * 必須だが値が存在しない：×
 * データ全件で存在しない値がある：–
 * @param behaviorsData 直近10件の行動履歴ログデータ
 * @param dataTemplate
 * @returns {*}
 */
const setAcquiredStatus = (behaviorsData, dataTemplate) => {
  for (let data of dataTemplate) {
    if (dataExists(behaviorsData, data.name)) {
      data.coopCondition = gc.cooperated
    } else if (data.required && !dataExists(behaviorsData, data.name)) {
      data.coopCondition = gc.invalid
    }
  }
  return dataTemplate
}

/**
 * パラメータ名に対応するデータの存在確認を行う
 * 全データの存在が確認できた場合のみtrue
 * @param behaviorsData 直近10件の行動履歴ログデータ
 * @param parameterName
 * @returns {boolean}
 */
const dataExists = (behaviorsData, parameterName) => {
  let notExistCount = 0
  let isCooped = false
  for (let data of behaviorsData) {
    if (!data[parameterName]) {
      notExistCount += 1
    }
  }
  if (notExistCount === 0) { isCooped = true }
  return isCooped
}

const IndicatorTooltips = () => (
  <>
    <UncontrolledTooltip target="required_behavior" placement="left">
        施策を実施するには必須となっているパラメータが
      <br />
        全て連携されている必要があります。
    </UncontrolledTooltip>
    <UncontrolledTooltip target="acquiredStatus_behavior" placement="left">
            ○：最新10件のデータが正しく連携できています。
      <br />
            ×：最新10件のデータに未連携・不適切な値があるため施策を実施できません。
      <br />
            -：最新10件のデータに未連携が含まれていますが、施策は実施可能です。
    </UncontrolledTooltip>

  </>
)

const select = ({
  elasticsearchBehaviors, queryObj,
}) => {
  const behaviors = elasticsearchBehaviors.items.map((x) => { x._index_type = 'behaviors'; return x })
  return {
    behaviorsData: {
      items: [...behaviors],
      isFetching: elasticsearchBehaviors.isFetching
    },
    ...queryObj,
  }
}

export default connect(select)(TagParameterBehaviorsDataDetailTable)
