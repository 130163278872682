import { Form, Formik } from 'formik'
import { connect } from 'react-redux'
import { Alert, Button, FormGroup } from 'reactstrap'
import React from 'react'
import * as Yup from 'yup'
import {
  FieldSingle, Label, Loading,
} from 'lib/components'
import { adminCompanyInvoiceTermAction } from 'actions'

const validationSchema = Yup.object().shape({
  startMonth: Yup.date().required(),
  minimumChargeAmount: Yup.string().required(),
  maxChargeAmount: Yup.string(),
  agencyRate: Yup.number().min(0.001).max(1).required(),
  salesStaffName: Yup.string(),
  closingTiming: Yup.string(),
  invoiceTo: Yup.string(),
})

export const InvoiceTermForm = ({
  adminCompanyInvoiceTerm, companyId, item, submitAction, dispatch,
}) => (
  <Loading isLoading={adminCompanyInvoiceTerm.isFetching}>
    <Formik
      initialValues={{
        startMonth: item.startMonth || '',
        minimumChargeAmount: item.minimumChargeAmount || '',
        maxChargeAmount: item.maxChargeAmount || '',
        agencyRate: item.agencyRate || '',
        salesStaffName: item.salesStaffName || '',
        closingTiming: item.closingTiming || '',
        paymentTiming: item.paymentTiming || '',
        invoiceTo: item.invoiceTo || '',
      }}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        if (submitAction === 'update') {
          dispatch(adminCompanyInvoiceTermAction.submitUpdateCompanyInvoiceTerm({ id: item.id, companyId, ...values }))
        } else {
          dispatch(adminCompanyInvoiceTermAction.submitCreateCompanyInvoiceTerm({ companyId, ...values }))
        }
      }}
    >
      {({ values, errors }) => (
        <Form noValidate>
          { adminCompanyInvoiceTerm.error && <Alert color="danger">{adminCompanyInvoiceTerm.error}</Alert> }
          <fieldset className="mb-3">
            <FormGroup>
              <Label require>期間（from）</Label>
              <FieldSingle name="startMonth" type="month" />
            </FormGroup>
            <FormGroup>
              <Label require>最低請求額</Label>
              <FieldSingle name="minimumChargeAmount" type="text" />
            </FormGroup>
            <FormGroup>
              <Label>最高請求額</Label>
              <FieldSingle name="maxChargeAmount" type="text" />
            </FormGroup>
            <FormGroup>
              <Label require>代理店料率</Label>
              <FieldSingle name="agencyRate" type="number" step="0.01" />
            </FormGroup>
            <FormGroup>
              <Label>営業担当者名</Label>
              <FieldSingle name="salesStaffName" type="text" />
            </FormGroup>
            <FormGroup>
              <Label>支払情報</Label>
              <FieldSingle name="closingTiming" type="text" />締め
              <FieldSingle name="paymentTiming" type="text" />払い
            </FormGroup>
            <FormGroup>
              <Label>請求先 （未入力の場合、請求CSVには企業名が出力されます）</Label>
              <FieldSingle name="invoiceTo" type="text" />
            </FormGroup>
          </fieldset>
          <div className="text-center"><Button color="primary" type="submit" size="lg">保存</Button></div>
        </Form>
      )}
    </Formik>
  </Loading>
)

const select = ({
  adminCompanyInvoiceTerm,
}, { invoiceTermId }) => {
  let item = {}
  if (invoiceTermId && adminCompanyInvoiceTerm.items.length > 0) {
    item = adminCompanyInvoiceTerm.items.find(e => e.id === invoiceTermId)
  }
  return {
    adminCompanyInvoiceTerm, item,
  }
}

export default connect(select)(InvoiceTermForm)
