import React from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import { Table } from 'reactstrap'
import { generalConstant as gc } from 'config/constants'
import { Loading, Swal } from 'lib/components'
import { registeredUserAction } from 'actions'

export const RegisteredUserList = ({ registeredUser, dispatch }) => (
  <Loading isLoading={registeredUser.isFetching}>
    <Table responsive>
      <thead>
        <tr>
          <th>姓</th>
          <th>名</th>
          <th>メールアドレス</th>
          <th>登録日</th>
          <th>役割</th>
          <th>&nbsp;</th>
        </tr>
      </thead>
      <tbody>
        { registeredUser.items.map(user => (
          <tr key={user.id}>
            <td>{user.lastName}</td>
            <td>{user.firstName}</td>
            <td>{user.email}</td>
            <td>{moment(user.createdAt).format('YYYY/MM/DD')}</td>
            {user.mainAccount ? <td>メインユーザ</td> : <td>一般ユーザ</td>}
            <td>
              <Swal
                options={gc.deleteSwalOptions}
                color="danger"
                disabled={user.mainAccount}
                callback={(isConfirm) => { isConfirm && dispatch(registeredUserAction.submitDeleteRegisteredUser({ id: user.id })) }}
              >
削除
              </Swal>
            </td>
          </tr>
        )) }
      </tbody>
    </Table>
  </Loading>
)

const select = ({ registeredUser }) => ({ registeredUser })

export default connect(select)(RegisteredUserList)
