import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import {
  Table, Button, CardBody, CardFooter,
} from 'reactstrap'
import { Loading, Pagination } from 'lib/components'
import { generalConstant as gc } from 'config/constants'
import moment from 'moment'
import AdminStopBadge from 'components/parts/company/AdminStopBadge'
import BillableBadge from 'components/parts/company/BillableBadge'
import CompanyStatusBadge from 'components/parts/company/CompanyStatusBadge'
import RecoOnlyBadge from 'components/parts/company/RecoOnlyBadge'
import ReviewOnlyBadge from 'components/parts/company/ReviewOnlyBadge'
import SearchOnlyBadge from 'components/parts/company/SearchOnlyBadge'
import SiteNameLabel from 'components/parts/company/SiteNameLabel'
import TestAccountBadge from 'components/parts/company/TestAccountBadge'
import UnderIntroductionBadge from 'components/parts/company/UnderIntroductionBadge'

export const AdminCompanyList = ({
  adminCompany, limit = gc.campaignItemsLimit, offset = 0, dispatch,
}) => {
  const targetItems = [...adminCompany.items].filter((company, index) => adminCompanyFilter(company))
  return (
    <Loading isLoading={adminCompany.isFetching}>
      <CardBody>
        <Table responsive>
          <thead>
            <tr>
              <th>会社名</th>
              <th>シリアル</th>
              <th>電話番号</th>
              <th>申込ID</th>
              <th>&nbsp;</th>
            </tr>
          </thead>
          <tbody className="no-thead">
            {
              targetItems
                .sort((a, b) => templateTagSort(a, b))
              // ページネーション用filter
                .filter((company, index) => (index >= offset && index < offset + limit))
                .map(company => (
                  <tr key={`${company.id}`}>
                    <td className="campaign-overview">
                      <h6>企業名: {company.name}</h6>
                      <h6>ドメイン: {company.domain}</h6>
                      <h6>
                        <SiteNameLabel
                          company={company}
                        />
                      </h6>
                      <CompanyStatusBadge
                        status={company.companyStatus.status}
                      />
                      <AdminStopBadge
                        stopByAdmin={company.companyStatus.stopByAdmin}
                      />
                      <TestAccountBadge
                        testAccount={company.testAccount}
                      />
                      <BillableBadge
                        billable={company.billable}
                      />
                      <RecoOnlyBadge
                        recoOnly={company.recoOnly}
                      />
                      <SearchOnlyBadge
                        searchOnly={company.searchOnly}
                      />
                      <ReviewOnlyBadge
                        reviewOnly={company.reviewOnly}
                      />
                      <UnderIntroductionBadge
                        underIntroduction={company.underIntroduction}
                      />
                    </td>
                    <td className="campaign-overview">
                      {company.serial}
                    </td>
                    <td>
                      {company.phoneNumber}
                    </td>
                    <td>
                      {company.orderCode}
                    </td>
                    <td className="text-center">
                      <Button target="_blank" href={`${gc.apiRoot}/admin/delegation/login/${company.id}?from_woody=1`} color="success">
                      ログイン
                      </Button>
                      <Button tag={Link} to={`/admin/companies/${company.id}/convert_rule_list`} color="warning">
                      マスタ変換設定
                      </Button>
                      <Button tag={Link} to={`/admin/companies/${company.id}/invoice_list`} color="primary">
                      請求情報設定
                      </Button>
                      <Button tag={Link} to={`/admin/companies/${company.id}/allow_ip_address_list`} color="primary">
                      許可IP設定
                      </Button>
                      <Button tag={Link} to={`/admin/companies/${company.id}/recipient_whitelist_edit`} color="primary">
                      送信先ホワイトリスト設定
                      </Button>
                      <Button tag={Link} to={`/admin/companies/${company.id}/item_export_edit`} color="primary">
                      商品情報出力設定
                      </Button>
                      <Button tag={Link} to={`/admin/company_show/${company.id}`} color="primary">
                      詳細
                      </Button>
                    </td>
                  </tr>
                ))
            }
          </tbody>
        </Table>
      </CardBody>
      <CardFooter>
        <Pagination
          totalItemCount={targetItems.length}
          limit={limit}
        />
      </CardFooter>
    </Loading>
  )
}

const templateTagSort = (a, b, condition) => moment(b.updatedAt) - moment(a.updatedAt)

const adminCompanyFilter = company => company.companyStatus.status === 'accepted'

const select = ({ adminCompany }) => ({ adminCompany })

export default connect(select)(AdminCompanyList)
