import {
  call, fork, put, takeEvery, takeLatest,
} from 'redux-saga/effects'
import { push } from 'connected-react-router'
import * as api from 'services/api'
import { abandonedCampaignAction } from 'actions'
import { serializeToQueryString } from 'lib/utils'
import { handleNoticeChangeCampaign, handleNoticeEnableCampaign } from './userAccessTrackingSaga'

function* handleFetchAbandonedCampaigns(action) {
  const { payload, meta } = yield call(api.fetchAbandonedCampaigns, action.payload)
  yield put(abandonedCampaignAction.fetchAbandonedCampaignsDone(payload, meta))
}

function* handleSubmitUpdateAbandonedCampaign(action) {
  let { payload, meta, error } = yield call(api.submitUpdateAbandonedCampaign, action.payload)
  if (!error && ('abandonedCampaign' in payload)) { payload = payload.abandonedCampaign }
  yield put(abandonedCampaignAction.submitUpdateAbandonedCampaignDone(payload, meta))
  if (!error) {
    yield fork(handleNoticeChangeCampaign, payload)
    yield put(push(`/campaign_list${window.location.search}`))
  }
}

function* handleSubmitEnableAbandonedCampaign(action) {
  let { payload, meta, error } = yield call(api.submitUpdateAbandonedCampaign, action.payload)
  if (!error && ('abandonedCampaign' in payload)) { payload = payload.abandonedCampaign }
  yield put(abandonedCampaignAction.submitEnableAbandonedCampaignDone(payload, meta))
  if (!error) {
    yield fork(handleNoticeEnableCampaign, payload)
  }
}

function* handleSubmitCreateAbandonedCampaign(action) {
  const { payload, meta, error } = yield call(api.submitCreateAbandonedCampaign, action.payload)
  yield put(abandonedCampaignAction.submitCreateAbandonedCampaignDone(payload, meta))
  if (!error) yield put(push(`/campaign_list${serializeToQueryString({ deliveryStatus: 'all' })}`))
}

function* handleSubmitDeleteAbandonedCampaign(action) {
  let { payload, meta, error } = yield call(api.submitDeleteAbandonedCampaign, {id: action.payload.abandonedCampaign.id})
  if (!error) {
    action.payload.abandonedCampaign['enable'] = false
    action.payload.abandonedCampaign['stage'] = 'finished'
    payload = action.payload
  }
  yield put(abandonedCampaignAction.submitDeleteAbandonedCampaignDone(payload, meta))
}

function* handleSubmitSendAbandonedCampaignTestMail(action) {
  const { payload, meta } = yield call(api.submitSendAbandonedCampaignTestMail, action.payload)
  yield put(abandonedCampaignAction.submitSendAbandonedCampaignTestMailDone(payload, meta))
}

function* handleSearchAbandonedCampaigns(action) {
  const { payload, error } = yield call(api.searchAbandonedCampaigns, action.payload)
  if (error) {
    yield put(abandonedCampaignAction.searchAbandonedCampaignsFailure(payload))
  } else {
    yield put(abandonedCampaignAction.searchAbandonedCampaignsSuccess(payload))
  }
}

export const abandonedCampaignSaga = [
  takeLatest(abandonedCampaignAction.fetchAbandonedCampaigns, handleFetchAbandonedCampaigns),
  takeLatest(abandonedCampaignAction.submitUpdateAbandonedCampaign, handleSubmitUpdateAbandonedCampaign),
  takeEvery(abandonedCampaignAction.submitEnableAbandonedCampaign, handleSubmitEnableAbandonedCampaign),
  takeLatest(abandonedCampaignAction.submitCreateAbandonedCampaign, handleSubmitCreateAbandonedCampaign),
  takeLatest(abandonedCampaignAction.submitDeleteAbandonedCampaign, handleSubmitDeleteAbandonedCampaign),
  takeLatest(abandonedCampaignAction.submitSendAbandonedCampaignTestMail, handleSubmitSendAbandonedCampaignTestMail),
  takeLatest(abandonedCampaignAction.searchAbandonedCampaigns, handleSearchAbandonedCampaigns),
]
