import React from 'react'
import { connect } from 'react-redux'
import ContentWrapper from 'components/layout/ContentWrapper'
import { Card, CardBody } from 'reactstrap'
import { useMountEffect } from 'lib/hooks'
import { recoTemplateAction } from 'actions'
import RecoTemplateForm from 'components/parts/recoTemplate/RecoTemplateForm'

export const RecoTemplateNewPage = ({dispatch}) => {
  useMountEffect(() => {
    dispatch(recoTemplateAction.fetchRecoTemplates())
  })
  return (
    <ContentWrapper>
      <div className="content-heading">
        <div>レコメンド連携設定</div>
      </div>
      <Card className="card-default">
        <CardBody>
          <RecoTemplateForm submitAction='create'/>
        </CardBody>
      </Card>
    </ContentWrapper>
  )
}

export default connect()(RecoTemplateNewPage)
