import React from 'react'
import { connect } from 'react-redux'
import ContentWrapper from 'components/layout/ContentWrapper'
import { Card, CardBody, CardHeader } from 'reactstrap'
import { useMountEffect } from 'lib/hooks'
import LineSettingEditForm from 'components/parts/lineSetting/LineSettingEditForm'
import { lineSettingAction, customersAction } from 'actions'
import PageDescriptionCard from 'components/parts/common/PageDescriptionCard'
import ManualLink from 'components/parts/manual/ManualLink'

export const LineSettingPage = ({ dispatch }) => {
  useMountEffect(() => {
    dispatch(lineSettingAction.fetchLineSetting())
    dispatch(customersAction.searchCustomers({ hasLineId: true }))
  })

  return (
    <ContentWrapper>
      <div className="content-heading">LINE設定</div>
      <PageDescriptionCard>
        <ManualLink filename="mansys_line_setting">
          詳細はこちら
        </ManualLink>
      </PageDescriptionCard>
      <Card className="card-default">
        <CardHeader>LINE設定</CardHeader>
        <CardBody>
          <LineSettingEditForm />
        </CardBody>
      </Card>
    </ContentWrapper>
  )
}

export default connect()(LineSettingPage)
