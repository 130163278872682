import { createActions } from 'redux-actions'

const FETCH_CAMPAIGN_TEMPLATES = 'FETCH_CAMPAIGN_TEMPLATES'
const FETCH_CAMPAIGN_TEMPLATES_DONE = 'FETCH_CAMPAIGN_TEMPLATES_DONE'

export const campaignTemplateAction = createActions(
  {
    [FETCH_CAMPAIGN_TEMPLATES_DONE]: [
      (...args) => args[0],
      (...args) => args[1],
    ],
  },

  FETCH_CAMPAIGN_TEMPLATES,
)
