const toMsoElement = (element, styles) => {
  const resetedEl = removeChildMsoNodes(element)
  return buildTableForMso(resetedEl, styles)
}

const htmlToElement = (html) => {
  const temp = document.createElement('template')
  html = html.trim()
  temp.innerHTML = html
  return temp.content.firstChild
}

const removeChildMsoNodes = (el) => {
  const elForMso = el.cloneNode(true)

  const deleteNodes = []
  elForMso.childNodes.forEach((node) => {
    if (node.textContent.startsWith('[if mso]')) {
      deleteNodes.push(node)
    }
  })
  deleteNodes.forEach((deleteNode) => {
    elForMso.removeChild(deleteNode)
  })

  return elForMso
}

const buildTableForMso = (el, styles) => {
  const elForMso = el.cloneNode(true)

  const paddingBottom = styles['padding-bottom']
  const paddingTop = styles['padding-top']
  const maxWidth = styles['max-width']
  const backgroundColor = styles['background-color']
  const borderTop = styles['border-top']
  const borderBottom = styles['border-bottom']

  const paddingTopEl = buildPaddingForMso(paddingTop)
  const paddingBottomEl = buildPaddingForMso(paddingBottom)

  if (paddingTopEl !== null) {
    elForMso.prepend(paddingTopEl)
  }
  if (paddingBottomEl !== null) {
    elForMso.append(paddingBottomEl)
  }

  const enableMaxWidth = maxWidth !== undefined && maxWidth !== '0'
  const enableBackgroundColor = backgroundColor !== undefined
  const enableBorderTop = borderTop !== undefined
  const enableBorderBottom = borderBottom !== undefined

  if (!enableMaxWidth && !enableBackgroundColor && !enableBorderTop && !enableBorderBottom) {
    return elForMso
  }

  const maxWidthForMso = enableMaxWidth ? maxWidth.replace('px', '') : '100%'
  const backGroundColorForMso = enableBackgroundColor ? `background-color:${backgroundColor};` : ''
  const borderTopForMso = enableBorderTop ? `border-top:${borderTop};` : ''
  const borderBottomForMso = enableBorderBottom ? `border-bottom:${borderBottom};` : ''

  const tableStartTag = document.createComment(`[if mso]><table cellpadding="0" cellspacing="0" align="center" width="${maxWidthForMso}" border="0" style="text-align:center;${backGroundColorForMso}${borderTopForMso}${borderBottomForMso}"><tr><td width="100%"><![endif]`)
  elForMso.prepend(tableStartTag)

  const tableEndTag = document.createComment('[if mso]></td></tr></table><![endif]')
  elForMso.append(tableEndTag)

  return elForMso
}

function buildPaddingForMso(padding) {
  if (padding === undefined || padding === '0') {
    return null
  }
  const paddingForMso = padding.replace('px', '')
  return document.createComment(`[if mso]><table border="0" cellspacing="0" cellpadding="0" align="center"><tr><td width="100%" height="${paddingForMso}"></td></tr></table><![endif]`)
}

const generateArea = () => ({
  model: {
    defaults: {
      traits: [],
    },
    toHTML() {
      const html = this.em.get('DomComponents').Component.prototype.toHTML.apply(this, arguments)
      const el = htmlToElement(html)
      const elForMso = toMsoElement(el, this.getStyle())
      const customName = this.get('custom-name')
      elForMso.dataset.gjsType = this.get('type')
      if (customName !== undefined) {
        elForMso.dataset.gjsCustomName = customName
      }
      return elForMso.outerHTML
    },
  },
})

export default generateArea
