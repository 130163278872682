export default {
  mainUserSwalOptions: {
    title: '本当にメインユーザを変更しますか？',
    text: '変更後は現在のユーザはメインユーザを変更できなくなります。',
    icon: 'warning',
    dangerMode: true,
    closeModal: true,
    closeOnClickOutside: true,
    closeOnEsc: true,
    buttons: ['キャンセル', '実行'],
  },
}
