import React, { useState } from 'react'
import { connect } from 'react-redux'
import ContentWrapper from 'components/layout/ContentWrapper'
import {
  Card, Nav, NavItem, NavLink, TabContent, TabPane,
  UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem
} from 'reactstrap'
import { push } from 'connected-react-router'
import InvoiceTermList from 'components/parts/invoiceTerm/InvoiceTermList'
import UnitPriceList from 'components/parts/unitPrice/UnitPriceList'
import { useMountEffect } from 'lib/hooks'
import { adminCompanyInvoiceTermAction, adminCompanyUnitPriceAction } from 'actions'

export const AdminCompanyInvoiceListPage = ({match, dispatch}) => {
  const companyId = Number(match.params.companyId)

  useMountEffect(() => {
    dispatch(adminCompanyInvoiceTermAction.fetchCompanyInvoiceTerms({ companyId }))
    dispatch(adminCompanyUnitPriceAction.fetchCompanyUnitPrices({ companyId }))
  })

  const invoiceTermNewPath = `/admin/companies/${companyId}/invoice_term_new`
  const unitPriceNewPath = `/admin/companies/${companyId}/unit_price_new`

  const [tab, setTab] = useState('invoiceTerm')
  return (
    <ContentWrapper>
      <div className="content-heading">
        <div>請求情報一覧</div>
        <div className="ml-3">
          <UncontrolledDropdown>
            <DropdownToggle caret>新規作成</DropdownToggle>
            <DropdownMenu>
              <DropdownItem
                onClick={() => { dispatch(push(invoiceTermNewPath)) }}
              >請求基本情報
              </DropdownItem>
              <DropdownItem
                onClick={() => { dispatch(push(unitPriceNewPath)) }}
              >価格設定
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </div>
      </div>
      <Card className="card-transparent">
        <Nav tabs justified>
          <NavItem>
            <NavLink
              className={[tab === 'invoiceTerm' ? 'active' : ''].join(' ')}
              onClick={() => { setTab('invoiceTerm') }}
            >請求基本情報
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={[tab === 'unitPrice' ? 'active' : ''].join(' ')}
              onClick={() => { setTab('unitPrice') }}
            >価格設定
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={tab} className="bg-white">
          <TabPane tabId="invoiceTerm">
            <InvoiceTermList />
          </TabPane>
          <TabPane tabId="unitPrice">
            <UnitPriceList />
          </TabPane>
        </TabContent>
      </Card>
    </ContentWrapper>
  )
}

export default connect()(AdminCompanyInvoiceListPage)
