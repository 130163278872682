import React from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import NewsRelease from './NewsRelease'
import { Loading } from 'lib/components'

export const NewsReleaseList = ({ newsRelease }) => (
  <Loading isLoading={newsRelease.isFetching}>
    <div className="ml-3 mb-2">
      {
        newsRelease.items
          .filter(e => e.dashboardEnabled)
          .sort((a, b) => b.id - a.id)
          .sort((a, b) => moment(b.openedAt).unix() - moment(a.openedAt).unix())
          .slice(0, 3)
          .map(news => (
            <NewsRelease
              news={news}
              key={news.id}
            />
          ))
      }
    </div>
  </Loading>
)

const select = ({ newsRelease }) => ({ newsRelease })
export default connect(select)(NewsReleaseList)
