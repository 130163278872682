/*!
 *
 * Angle - Bootstrap Admin App + ReactJS
 *
 * Version: 3.8.8
 * Author: @themicon_co
 * Website: http://themicon.co
 * License: https://wrapbootstrap.com/help/licenses
 *
 */

import React from 'react'
import { Provider } from 'react-redux'
import $ from 'jquery'

import config from 'react-global-configuration'
import configuration from 'config'
import ReactGA from 'react-ga'

// App Routes
import Routes from './Routes'

// Application Styles
// import '../styles/bootstrap.scss'
import '../styles/app.scss'

config.set(configuration, { freeze: true })

// Disable warning "Synchronous XMLHttpRequest on the main thread is deprecated.."
$.ajaxPrefilter(o => o.async = true)  // eslint-disable-line
window.jQuery = $

ReactGA.initialize('UA-121554633-2')

const App = ({ store, history }) => {
  // specify base href from env varible 'WP_BASE_HREF'
  // use only if application isn't served from the root
  // for development it is forced to root only
  // /* global WP_BASE_HREF */
  // const basename = process.env.REACT_APP_ENV === 'development' ? '/' : (WP_BASE_HREF || '/')

  return (
    <Provider store={store}>
      <Routes history={history} />
    </Provider>
  )
}

export default App
