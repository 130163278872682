import { handleActions } from 'redux-actions'
import { customerAction as action } from 'actions'

const initial = {
  item: {},
  isFetching: false,
}

export const customer = handleActions({
  [action.findCustomer]: (state) => ({
    ...state,
    isFetching: true,
  }),
  [action.findCustomerDone]: (state, action) => {
    return {
      ...state,
      item: action.payload,
      isFetching: false,
    }
  },
  [action.updateCustomer]: (state) => state,
  [action.updateCustomerDone]: (state, action) => {
    return {
      ...state,
      item: {
        ...state.item,
        ...action.payload,
      },
    }
  },
  [action.activateCustomer]: (state) => state,
  [action.activateCustomerSuccess]: (state, action) => {
    return {
      ...state,
      item: {
        ...state.item,
        ...action.payload,
      },
    }
  },
  [action.activateCustomerFailure]: (state) => state
}, initial)
