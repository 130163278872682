import { createActions } from 'redux-actions'

const FETCH_BATCH_STATUS_ITEM_IMPORTS = 'FETCH_BATCH_STATUS_ITEM_IMPORTS'
const FETCH_BATCH_STATUS_ITEM_IMPORTS_DONE = 'FETCH_BATCH_STATUS_ITEM_IMPORTS_DONE'

export const batchStatusItemImportAction = createActions(
  {
    [FETCH_BATCH_STATUS_ITEM_IMPORTS_DONE]: [
      (...args) => args[0],
      (...args) => args[1],
    ],
  },
  FETCH_BATCH_STATUS_ITEM_IMPORTS,
)
