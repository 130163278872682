import React from 'react'
import { connect } from 'react-redux'
import {
  Table, Alert, Card, UncontrolledTooltip,
} from 'reactstrap'
import { HelpIcon, Loading } from 'lib/components'
import { tagParameterConstant as gc } from 'config/constants'
import TagParameterItemsDataCsvDownloadButton from 'components/parts/tagParameter/TagParameterItemsDataCsvDownloadButton'

export const TagParameterItemsDataDetailTable = ({ itemsData, queryObj }) => {
  let campaignPolicy = queryObj.campaignPolicy
  let itemsTableData = buildItemsTableData(itemsData.items, campaignPolicy)
  return (
    <Loading isLoading={itemsData.isFetching}>
      <Alert color="dark">
        ファイル連携で商品情報をインポートしている場合は、商品ID（itemCode）のタグ連携だけで
        <br />
        施策を実施いただけます。
      </Alert>
      <AlertIndexNotFount itemsTableData={itemsTableData} campaignPolicy={campaignPolicy} />
      <Card>
        <label>
タグ連携状況(
          {queryObj.confirmationIndexTerm}
)
        </label>
        <Table bordered responsive>
          <thead>
            <tr style={{ wordBreak: 'keep-all' }}>
              <th>パラメータ名</th>
              <th>
              必須
                <HelpIcon id="required_item" />
              </th>
              <th>
              連携状況
                <HelpIcon id="acquiredStatus_item" />
              </th>
              <th>パラメータ概要</th>
            </tr>
          </thead>
          <tbody>
            { itemsTableData !== undefined && itemsTableData.length > 0 && itemsTableData.map(data => (
              <tr key={`${data.name}_${data.date}`}>
                <td>{data.name}</td>
                <td>{data.required}</td>
                <td>{data.coopCondition}</td>
                <td>{data.abstract}</td>
              </tr>
            ))}
          </tbody>
          <IndicatorTooltips />
        </Table>
      </Card>
      <div className="text-right mb-3">
        <TagParameterItemsDataCsvDownloadButton queryObj={queryObj} />
      </div>
    </Loading>
  )
}

const AlertIndexNotFount = ({ itemsTableData, campaignPolicy }) => {
  if (!itemsTableData && campaignPolicy) {
    return (
      <Alert color="warning">
              連携データが1件も見つかりませんでした。検索を再実行するか、タグの設置状況を確認してください。
      </Alert>
    )
  }
  return null
}

const buildItemsTableData = (itemsData, campaignPolicy) => {
  if (itemsData.length === 0) { return false }
  switch (campaignPolicy) {
  case 'view':
  case 'cart':
  case 'register':
    return setAcquiredStatus(itemsData, gc.itemsForItemDetailParameterDataTemplate)
  case 'discountInCart':
  case 'discountInFav':
    return setAcquiredStatus(itemsData, gc.itemsForDiscountContextualParameterDataTemplate)
  case 'littleStockInCart':
  case 'restockInCart':
  case 'littleStockInFav':
  case 'restockInFav':
    return setAcquiredStatus(itemsData, gc.itemsForStockContextualParameterDataTemplate)
  case 'repeatPurchasing':
  case 'reviewRequest':
    return setAcquiredStatus(itemsData, gc.itemsForPurchasedParameterDataTemplate)
  default:
    return setAcquiredStatus(itemsData, gc.itemsParameterDataTemplateDefault)
  }
}

/**
 * パラメータの値をチェックし連携状況をセットする
 * データ全件で値が存在する：○
 * 必須だが値が存在しない：×
 * データ全件で存在しない値がある：–
 * @param itemsData 直近10件の商品情報ログデータ
 * @param dataTemplate
 * @returns {*}
 */
const setAcquiredStatus = (itemsData, dataTemplate) => {
  for (let data of dataTemplate) {
    if (dataExists(itemsData, data.name)) {
      data.coopCondition = gc.cooperated
    } else if (data.required && !dataExists(itemsData, data.name)) {
      data.coopCondition = gc.invalid
    }
  }
  return dataTemplate
}

/**
 * パラメータ名に対応するデータの存在確認を行う
 * 全データの存在が確認できた場合のみtrue
 * @param itemsData 直近10件の商品情報ログデータ
 * @param parameterName
 * @returns {boolean}
 */
const dataExists = (itemsData, parameterName) => {
  let notExistCount = 0
  let isCooped = false
  for (let data of itemsData) {
    if (!data[parameterName]) {
      notExistCount += 1
    }
  }
  if (notExistCount === 0) { isCooped = true }
  return isCooped
}

const IndicatorTooltips = () => (
  <>
    <UncontrolledTooltip target="required_item" placement="left">
        施策を実施するには必須となっているパラメータが
      <br />
        全て連携されている必要があります。
    </UncontrolledTooltip>
    <UncontrolledTooltip target="acquiredStatus_item" placement="left">
        ○：最新10件のデータが正しく連携できています。
      <br />
        ×：最新10件のデータに未連携・不適切な値があるため施策を実施できません。
      <br />
        -：最新10件のデータに未連携が含まれていますが、施策は実施可能です。
    </UncontrolledTooltip>

  </>
)

const select = ({
  elasticsearchItems, queryObj,
}) => {
  const Items = elasticsearchItems.items.map((x) => { x._index_type = 'Items'; return x })
  return {
    itemsData: {
      items: [...Items],
      isFetching: elasticsearchItems.isFetching
    },
    ...queryObj,
  }
}

export default connect(select)(TagParameterItemsDataDetailTable)
