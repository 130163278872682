import { takeLatest, put, call } from 'redux-saga/effects'
import { replace } from 'connected-react-router'
import { serializeToQueryString } from 'lib/utils'
import { transitionReportAction } from 'actions'
import * as api from 'services/api'
import { reportConstant as rc } from 'config/constants'
import { baseCampaignTypes } from 'lib/reportUtils'

function* handleSearchTransitionReport(action) {
  yield put(replace(`/transition_report${serializeToQueryString(action.payload)}`))
}

function* handleFetchTransitionReport(action) {
  const reqValues = fetchActionPayloadToRequest(action.payload)
  const { payload, meta } = yield call(api.fetchTransitionReport, reqValues)
  yield put(transitionReportAction.fetchTransitionReportDone(payload, meta))
}

const fetchActionPayloadToRequest = (actionPayload) => {
  let { campaign, ...reqValues } = actionPayload
  if (baseCampaignTypes.includes(campaign)) {
    reqValues.campaignType = campaign
  } else {
    const delimiterIndex = campaign.indexOf(rc.campaignTypeDelimiter)
    reqValues.campaignType = campaign.slice(0, delimiterIndex)
    reqValues.campaignId = campaign.slice(delimiterIndex + rc.campaignTypeDelimiter.length)
  }
  return reqValues
}

export const transitionReportSaga = [
  takeLatest(transitionReportAction.searchTransitionReport, handleSearchTransitionReport),
  takeLatest(transitionReportAction.fetchTransitionReport, handleFetchTransitionReport),
]
