import { takeLatest, call, put } from 'redux-saga/effects'
import * as api from 'services/api'
import { suppressionSettingAction } from 'actions'

function* handleSubmitUpdateSuppressionSetting(action) {
  const { payload, meta, error } = yield call(api.submitUpdateSuppressionSetting, action.payload.emails)
  yield put(suppressionSettingAction.submitUpdateSuppressionSettingDone(payload, meta))
  if (!error) yield action.payload.handleSubmit()
  yield action.payload.setSubmitting(false)
}

export const suppressionSettingSaga = [
  takeLatest(suppressionSettingAction.submitUpdateSuppressionSetting, handleSubmitUpdateSuppressionSetting),
]
