import {
  takeLatest, call, fork, put,
} from 'redux-saga/effects'
import { push } from 'connected-react-router'
import * as api from 'services/api'
import { prospectsCampaignAction } from 'actions'
import { handleNoticeChangeCampaign, handleNoticeEnableCampaign } from './userAccessTrackingSaga'

function* handleFetchProspectsCampaigns(action) {
  const { payload, meta } = yield call(api.fetchProspectsCampaigns, action.payload)
  yield put(prospectsCampaignAction.fetchProspectsCampaignsDone(payload, meta))
}

function* handleSubmitUpdateProspectsCampaign(action) {
  let { payload, meta, error } = yield call(api.submitUpdateProspectsCampaign, action.payload)
  if (!error && ('prospectsCampaign' in payload)) { payload = payload.prospectsCampaign }
  yield put(prospectsCampaignAction.submitUpdateProspectsCampaignDone(payload, meta))
  if (!error) {
    yield fork(handleNoticeChangeCampaign, payload)
    yield put(push('/prospects_campaign_list'))
  }
}

function* handleSubmitEnableProspectsCampaign(action) {
  let { payload, meta, error } = yield call(api.submitUpdateProspectsCampaign, action.payload)
  if (!error && ('prospectsCampaign' in payload)) { payload = payload.prospectsCampaign }
  yield put(prospectsCampaignAction.submitEnableProspectsCampaignDone(payload, meta))
  if (!error) {
    yield fork(handleNoticeEnableCampaign, payload)
  }
}

function* handleSubmitCreateProspectsCampaign(action) {
  let { payload, meta, error } = yield call(api.submitCreateProspectsCampaign, action.payload)
  yield put(prospectsCampaignAction.submitCreateProspectsCampaignDone(payload, meta))
  if (!error) yield put(push('/prospects_campaign_list'))
}

function* handleSubmitSendProspectsCampaignTestMail(action) {
  let payload = {}
  let meta = {}
  if (action.payload.fromAddress && action.payload.testAddress) {
    ({ payload, meta } = yield call(api.submitSendProspectsCampaignTestMail, action.payload))
  } else {
    payload = new Error()
    meta.failMess = '「Reply-toアドレス」「テストメール送信先アドレス」を入力してください'
  }
  yield put(prospectsCampaignAction.submitSendProspectsCampaignTestMailDone(payload, meta))
}

export const prospectsCampaignSaga = [
  takeLatest(prospectsCampaignAction.fetchProspectsCampaigns, handleFetchProspectsCampaigns),
  takeLatest(prospectsCampaignAction.submitUpdateProspectsCampaign, handleSubmitUpdateProspectsCampaign),
  takeLatest(prospectsCampaignAction.submitEnableProspectsCampaign, handleSubmitEnableProspectsCampaign),
  takeLatest(prospectsCampaignAction.submitCreateProspectsCampaign, handleSubmitCreateProspectsCampaign),
  takeLatest(prospectsCampaignAction.submitSendProspectsCampaignTestMail, handleSubmitSendProspectsCampaignTestMail),
]
