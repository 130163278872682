import React from 'react'
import { connect } from 'react-redux'
import ContentWrapper from 'components/layout/ContentWrapper'
import {
  Row, Col, Card,
} from 'reactstrap'
import { useMountEffect } from 'lib/hooks'
import { deserializeFromQueryString } from 'lib/utils'
import { generalConstant as gc } from 'config/constants'
import AdminCompanyList from 'components/parts/company/AdminCompanyList'
import DownloadInvoiceCsvForm from 'components/parts/company/DownloadInvoiceCsvForm'
import { adminCompanyAction } from 'actions'

const AdminCompanyListPage = ({location, dispatch}) => {
  useMountEffect(() => {
    dispatch(adminCompanyAction.fetchCompanies())
  })

  const queryObj = deserializeFromQueryString(location.search) || {}
  const page = Number(queryObj.page) || 1
  return (
    <ContentWrapper>
      <div className="content-heading">
        <div>登録企業一覧</div>
      </div>
      <Row>
        <div>
          <DownloadInvoiceCsvForm />
        </div>
        <Col xl={12}>
          <Card className="card-default">
            <AdminCompanyList
              limit={gc.adminTemplateTagItemsLimit}
              offset={(page - 1) * gc.adminTemplateTagItemsLimit}
            />
          </Card>
        </Col>
      </Row>
    </ContentWrapper>
  )
}

export default connect()(AdminCompanyListPage)
