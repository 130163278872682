import React, { Fragment } from 'react'

const BillableBadge = ({ billable }) => {
  if (!billable) {
    return <Fragment />
  }
  return (
    <span className="badge badge-primary">
      請求対象
    </span>
  )
}

export default BillableBadge
