import React from 'react'
import { connect } from 'react-redux'
import { Table } from 'reactstrap'
import { connectionTestAction } from 'actions'
import { Loading } from 'lib/components'
import { batchStatusConstant as bsc } from 'config/constants'
import { arraysToObject } from 'lib/utils'

const batchStatus = arraysToObject(bsc.batchStatuses, 1, 0)

export const BatchStatusModal = ({ type, connectionTest, dispatch }) => {
  let targetId = connectionTest.item.batchStatusId
  if (!targetId) {
    targetId = type === 'customer' ? connectionTest.item.customerImportTestSettingId : connectionTest.item.itemImportTestSettingId
  }
  const item = connectionTest.item
  const status = batchStatus[item.status]
  const errorObj = item.errorJson ? JSON.parse(item.errorJson) : {}
  if (!connectionTest.isFetching && targetId && ['processing', 'file_imported', 'data_imported'].includes(status)) {
    setTimeout(() => {
      dispatch(connectionTestAction.fetchConnectionTest({ id: targetId, _type: type }))
    }, 5000)
  }

  return (
    <Loading isLoading={connectionTest.isSubmitting}>
      { status === 'processing'
        && (
          <h4 className="mb-4">
            <i className="fa fa-info text-info mr-2" />
接続中です。
          </h4>
        )
      }
      { status === 'file_imported'
        && (
          <h4 className="mb-4">
            <i className="fa fa-info text-info mr-2" />
ファイルをダウンロードしています。
          </h4>
        )
      }
      { status === 'data_imported'
        && (
          <h4 className="mb-4">
            <i className="fa fa-info text-info mr-2" />
データを取込中です。
          </h4>
        )
      }
      { ['finished', 'file_unchanged'].includes(status)
        && (
          <h4 className="mb-4">
            <i className="fa fa-info text-info mr-2" />
完了しました。
          </h4>
        )
      }
      { status === 'error'
        && (
          <h4 className="mb-4">
            <i className="fa fa-times text-danger mr-2" />
            {errorObj.exception}
          </h4>
        )
      }
      { (errorObj.errors && errorObj.errors.length > 0)
        && (
          <>
            <Table responsive striped>
              <tbody>
                { errorObj.errors.map(err => (
                  <tr key={`error_${err[0]}`}>
                    <td>
                      {err[0]}
行目
                    </td>
                    <td>{err[1]}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <p>※全エラー中先頭100行</p>
          </>
        )
      }
    </Loading>
  )
}

const select = ({ connectionTest }, ownProps) => (
  { connectionTest, ...ownProps }
)

export default connect(select)(BatchStatusModal)
