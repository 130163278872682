import { authAction, adminAuthAction } from 'actions'

/**
 * 認証用ミドルウェア。
 * レスポンスの受け取り時に「非認証」になっているかチェックしている。
 */

const authWatcherMiddleware = store => next => (action) => {
  if (!action.meta || !action.meta.statusCode) return next(action)

  const statusCode = action.meta.statusCode

  // 非認証でも通す処理の場合はignoreUnauthをtrueにしておく
  if (statusCode !== 401 || action.meta.ignoreUnauth) return next(action)

  const _authAction = /^ADMIN\//.test(action.type) ? adminAuthAction : authAction
  store.dispatch(_authAction.setUnauthInProgress())

  return next(action)
}

export default authWatcherMiddleware
