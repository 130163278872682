import React from 'react'
import moment from 'moment'
import { Formik, Form } from 'formik'
import { connect } from 'react-redux'
import {
  Col, Row, Button, FormGroup, Alert, Input,
} from 'reactstrap'
import { FieldSingle, FieldGroup, InputGroupAddon, Loading } from 'lib/components'

import { comparisonReportAction } from 'actions'
import { reportConstant as rc } from 'config/constants'

const convertToSubmitValues = (values) => {
  const { termFrom, termTo, ...baseValues } = values
  const submitValues = values.term === 'free_period' ? values : baseValues
  return submitValues
}

export const ComparisonReportConditionForm = ({ campaign, queryObj, dispatch }) => {
  let campaigns = campaign.items
  if (!queryObj.showDisable) {
    campaigns = campaigns.filter(cam => cam.enabled)
  }
  const campaignOption = campaigns
    .map(cam => (
      [cam.name, `${cam._camGroup}${rc.campaignTypeDelimiter}${cam.id}`]
    ))
  return (
    <Loading isLoading={campaign.isFetching}>
      <Formik
        initialValues={{
          report: {
            campaign: rc.defaultTransitionReportCondition.campaignType,
            term: queryObj.report.term || rc.defaultTransitionReportCondition.term,
            termFrom: queryObj.report.dateFrom || moment().subtract(1, 'months').format('YYYY-MM-DD'),
            termTo: queryObj.report.dateTo || moment().format('YYYY-MM-DD'),
          },
          showDisable: queryObj !== null && !!queryObj.showDisable,
        }}
        onSubmit={(values) => {
          const submitValues = convertToSubmitValues(values.report)
          const report = [...queryObj.report, submitValues]
          dispatch(comparisonReportAction.searchComparisonReport({ ...queryObj, report, showDisable: values.showDisable }))
        }}
        validate={(values) => {
          // 追加済レポートの場合は警告を出す
          for (let q of queryObj.report) {
            if (values.report.campaign === q.campaign && values.report.term === q.term) {
              if (values.report.term !== 'free_period') {
                return { message: 'すでに追加済のレポートです。' }
              }
              if (
                values.report.term === 'free_period' &&
                values.report.termFrom === q.termFrom &&
                values.report.termTo === q.termTo
              ) {
                return { message: 'すでに追加済のレポートです。' }
              }
            }
          }
          return {}
        }}
      >
        {({ values, errors }) => (
          <Form noValidate>
            <Row>
              <Col xs="6" md="6">
                <FormGroup className="mb-3">
                  <FieldSingle type="select" name="report.campaign" options={rc.campaignTypes.concat(campaignOption)} />
                </FormGroup>
                <FormGroup check>
                  <FieldSingle name="showDisable" type="checkbox" option="配信中以外のキャンペーンも表示する" />
                </FormGroup>
              </Col>
              <Col xs="6" md="6">
                <FormGroup className="mb-3">
                  <FieldSingle type="select" name="report.term" options={rc.terms} />
                </FormGroup>
              </Col>
            </Row>
            { values.report.term === 'free_period'
            && (
              <FormGroup className="mb-3">
                <FieldGroup>
                  <Input name="report.termFrom" type="date" />
                  <InputGroupAddon addonType="middle">〜</InputGroupAddon>
                  <Input name="report.termTo" type="date" />
                </FieldGroup>
              </FormGroup>
            )
            }
            { errors.message
            && <Alert color="warning">{errors.message}</Alert>
            }
            <div className="text-center"><Button color="primary" type="submit">レポートを追加</Button></div>
          </Form>
        )}
      </Formik>
    </Loading>
  )
}

const select = ({
  abandonedCampaign, completedCampaign, contextualCampaign, popup, prospectsCampaign,
}) => {
  const abanItems = abandonedCampaign.items.map((x) => { x._camGroup = 'abandoned'; return x })
  const compItems = completedCampaign.items.map((x) => { x._camGroup = 'completed'; return x })
  const contItems = contextualCampaign.items.map((x) => { x._camGroup = 'contextual'; return x })
  const popItems = popup.items.map((x) => { x._camGroup = 'popup'; return x })
  const prosItems = prospectsCampaign.items.map((x) => { x._camGroup = 'prospects'; return x })

  return {
    campaign: {
      items: [...abanItems, ...compItems, ...contItems, ...popItems, ...prosItems],
      isFetching: abandonedCampaign.isFetching || completedCampaign.isFetching || contextualCampaign.isFetching || popup.isFetching || prospectsCampaign.isFetching,
    },
  }
}

export default connect(select)(ComparisonReportConditionForm)
