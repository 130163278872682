import { takeLatest, call, put } from 'redux-saga/effects'
import * as api from 'services/api'
import { pageResourceAction } from 'actions'

function* handleFetchPageResources(action) {
  const { payload, meta } = yield call(api.fetchPageResources, action.payload)
  yield put(pageResourceAction.fetchPageResourcesDone(payload, meta))
}

export const pageResourceSaga = [
  takeLatest(pageResourceAction.fetchPageResources, handleFetchPageResources),
]
