import { createActions } from 'redux-actions'

const FETCH_LINE_SETTING = 'FETCH_LINE_SETTING'
const FETCH_LINE_SETTING_DONE = 'FETCH_LINE_SETTING_DONE'

const SUBMIT_UPDATE_LINE_SETTING = 'SUBMIT_UPDATE_LINE_SETTING'
const SUBMIT_UPDATE_LINE_SETTING_DONE = 'SUBMIT_UPDATE_LINE_SETTING_DONE'

export const lineSettingAction = createActions(
  {
    [FETCH_LINE_SETTING_DONE]: [
      (...args) => args[0],
      (...args) => args[1],
    ],
    [SUBMIT_UPDATE_LINE_SETTING_DONE]: [
      (...args) => args[0],
      (...args) => Object.assign({ notification: 'ALL_CASE' }, args[1]),
    ],
  },

  FETCH_LINE_SETTING,
  SUBMIT_UPDATE_LINE_SETTING,

)
