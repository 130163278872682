import { takeLatest, call, put } from 'redux-saga/effects'
import * as api from 'services/api'
import { batchStatusItemImportAction } from 'actions'

function* handleFetchBatchStatusItemImports() {
  const { payload, meta } = yield call(api.fetchBatchStatusItemImports)
  yield put(batchStatusItemImportAction.fetchBatchStatusItemImportsDone(payload, meta))
}

export const batchStatusItemImportSaga = [
  takeLatest(batchStatusItemImportAction.fetchBatchStatusItemImports, handleFetchBatchStatusItemImports),
]
