import React from 'react'
import moment from 'moment'
import { connect } from 'react-redux'
import ContentWrapper from 'components/layout/ContentWrapper'
import { Link } from 'react-router-dom'
import { Card, CardBody, CardFooter, CardHeader, } from 'reactstrap'
import { useMountEffect } from 'lib/hooks'
import { newsReleaseAction, transitionReportAction } from 'actions'
import TransitionReportGraph from 'components/parts/report/TransitionReportGraph'
import TransitionReportKeyIndicator from 'components/parts/report/TransitionReportKeyIndicator'
import NewsReleaseList from '../parts/news/NewsReleaseList'

const DashboardPage = ({dispatch}) => {
  const reportCondition = {
    campaign: 'all',
    term: 'free_period',
    term_from: moment().subtract(7, 'days').format('YYYY-MM-DD'),
    term_to: moment().format('YYYY-MM-DD'),
    transition: 'daily',
  }
  useMountEffect(() => {
    dispatch(transitionReportAction.fetchTransitionReport(reportCondition))
    dispatch(newsReleaseAction.fetchNewsReleases())
  })

  return (
    <ContentWrapper>
      <div className="content-heading">
        <div>ダッシュボード</div>
      </div>
      <Card className="card-default">
        <CardHeader>ニュース・お知らせ</CardHeader>
        <NewsReleaseList />
      </Card>
      <Card className="card-default">
        <CardHeader>過去７日間の成果</CardHeader>
        <TransitionReportKeyIndicator condition={reportCondition} />
      </Card>
      <Card className="card-default">
        <CardHeader>過去７日間の推移</CardHeader>
        <CardBody>
          <TransitionReportGraph graphHeight={200} />
        </CardBody>
        <CardFooter className="text-right">
          <Link to="/transition_report">レポート画面へ</Link>
        </CardFooter>
      </Card>
    </ContentWrapper>
  )
}

export default connect()(DashboardPage)
