import { createActions } from 'redux-actions'

const FETCH_COMPANY_CAMPAIGN_TEMPLATE_VALUE = 'FETCH_COMPANY_CAMPAIGN_TEMPLATE_VALUE'
const FETCH_COMPANY_CAMPAIGN_TEMPLATE_VALUE_DONE = 'FETCH_COMPANY_CAMPAIGN_TEMPLATE_VALUE_DONE'

const SUBMIT_UPDATE_COMPANY_CAMPAIGN_TEMPLATE_VALUE = 'SUBMIT_UPDATE_COMPANY_CAMPAIGN_TEMPLATE_VALUE'
const SUBMIT_UPDATE_COMPANY_CAMPAIGN_TEMPLATE_VALUE_DONE = 'SUBMIT_UPDATE_COMPANY_CAMPAIGN_TEMPLATE_VALUE_DONE'

export const companyCampaignTemplateValueAction = createActions(
  {
    [FETCH_COMPANY_CAMPAIGN_TEMPLATE_VALUE_DONE]: [
      (...args) => args[0],
      (...args) => args[1],
    ],
    [SUBMIT_UPDATE_COMPANY_CAMPAIGN_TEMPLATE_VALUE_DONE]: [
      (...args) => args[0],
      (...args) => Object.assign({ notification: 'ALL_CASE' }, args[1]),
    ],
  },

  FETCH_COMPANY_CAMPAIGN_TEMPLATE_VALUE,

  SUBMIT_UPDATE_COMPANY_CAMPAIGN_TEMPLATE_VALUE,

)
