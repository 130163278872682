import { takeLatest, call, put } from 'redux-saga/effects'
import { push } from 'connected-react-router'
import * as api from 'services/api'
import { mailAbTestAction } from 'actions'

function* handleFetchMailAbTests(action) {
  const { payload, meta } = yield call(api.fetchMailAbTests, action.payload)
  yield put(mailAbTestAction.fetchMailAbTestsDone(payload, meta))
}

function* handleSubmitUpdateMailAbTest(action) {
  let { payload, meta, error } = yield call(api.submitUpdateMailAbTest, action.payload)
  if (!error && ('mailAbTest' in payload)) { payload = payload.mailAbTest }
  yield put(mailAbTestAction.submitUpdateMailAbTestDone(payload, meta))
  if (!error) yield put(push('/mail_ab_test_list'))
}

function* handleSubmitCreateMailAbTest(action) {
  const { payload, meta, error } = yield call(api.submitCreateMailAbTest, action.payload)
  yield put(mailAbTestAction.submitCreateMailAbTestDone(payload, meta))
  if (!error) yield put(push('/mail_ab_test_list'))
}

function* handleSubmitDeleteMailAbTest(action) {
  let { payload, meta, error } = yield call(api.submitDeleteMailAbTest, action.payload)
  if (!error) { payload = action.payload }
  yield put(mailAbTestAction.submitDeleteMailAbTestDone(payload, meta))
  if (!error) yield put(push('/mail_ab_test_list'))
}

export const mailAbTestSaga = [
  takeLatest(mailAbTestAction.fetchMailAbTests, handleFetchMailAbTests),
  takeLatest(mailAbTestAction.submitUpdateMailAbTest, handleSubmitUpdateMailAbTest),
  takeLatest(mailAbTestAction.submitCreateMailAbTest, handleSubmitCreateMailAbTest),
  takeLatest(mailAbTestAction.submitDeleteMailAbTest, handleSubmitDeleteMailAbTest),
]
