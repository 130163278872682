import { createActions } from 'redux-actions'

const FETCH_CURRENT_USER = 'FETCH_CURRENT_USER'
const FETCH_CURRENT_USER_DONE = 'FETCH_CURRENT_USER_DONE'

export const adminUserAction = createActions(
  {
    [FETCH_CURRENT_USER_DONE]: [
      (...args) => args[0],
      (...args) => Object.assign({ ignoreUnauth: true }, args[1]),
    ],
  },
  FETCH_CURRENT_USER,
  {
    prefix: 'ADMIN',
  },
)
