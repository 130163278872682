import { takeLatest, call, put } from 'redux-saga/effects'
import { push } from 'connected-react-router'
import * as api from 'services/api'
import { recoTemplateAction } from 'actions'

function* handleFetchRecoTemplates() {
  const { payload, meta } = yield call(api.fetchRecoTemplates)
  yield put(recoTemplateAction.fetchRecoTemplatesDone(payload, meta))
}

function* handleSubmitUpdateRecoTemplate(action) {
  let { payload, meta, error } = yield call(api.submitUpdateRecoTemplate, action.payload)
  if (!error && ('recoTemplate' in payload)) { payload = payload.recoTemplate }
  yield put(recoTemplateAction.submitUpdateRecoTemplateDone(payload, meta))
  if (!error) yield put(push('/reco_template_setting'))
}

function* handleSubmitCreateRecoTemplate(action) {
  const { payload, meta, error } = yield call(api.submitCreateRecoTemplate, action.payload)
  yield put(recoTemplateAction.submitCreateRecoTemplateDone(payload, meta))
  if (!error) yield put(push('/reco_template_setting'))
}

export const recoTemplateSaga = [
  takeLatest(recoTemplateAction.fetchRecoTemplates, handleFetchRecoTemplates),
  takeLatest(recoTemplateAction.submitUpdateRecoTemplate, handleSubmitUpdateRecoTemplate),
  takeLatest(recoTemplateAction.submitCreateRecoTemplate, handleSubmitCreateRecoTemplate),
]
