import React from 'react'
import { InputGroupAddon as Base } from 'reactstrap'

const InputGroupAddon = ({ addonType, className, ...rest }) => {
  if (addonType === 'middle') {
    addonType = 'prepend'
    className = `${className || ''} 'input-group-append'`
  }
  return (
    <Base addonType={addonType} className={className} {...rest} />
  )
}

export default InputGroupAddon
