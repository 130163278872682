import { createActions } from 'redux-actions'

const RETRIEVE_SUPPRESSION_BY_CODE = 'RETRIEVE_SUPPRESSION_BY_CODE'
const RETRIEVE_SUPPRESSION_BY_CODE_DONE = 'RETRIEVE_SUPPRESSION_BY_CODE_DONE'
const CREATE_SUPPRESSIONS = 'CREATE_SUPPRESSIONS'
const CREATE_SUPPRESSIONS_DONE = 'CREATE_SUPPRESSIONS_DONE'
const REMOVE_SUPPRESSION = 'REMOVE_SUPPRESSION'
const REMOVE_SUPPRESSION_DONE = 'REMOVE_SUPPRESSION_DONE'
const VALIDATE_DOMAIN_AUTHENTICATION = 'VALIDATE_DOMAIN_AUTHENTICATION'
const VALIDATE_DOMAIN_AUTHENTICATION_DONE = 'VALIDATE_DOMAIN_AUTHENTICATION_DONE'

export const sendgridAction = createActions(
  {
    [VALIDATE_DOMAIN_AUTHENTICATION_DONE]: [
      (...args) => args[0],
      (...args) => args[1],
    ],
  },
  RETRIEVE_SUPPRESSION_BY_CODE,
  RETRIEVE_SUPPRESSION_BY_CODE_DONE,
  CREATE_SUPPRESSIONS,
  CREATE_SUPPRESSIONS_DONE,
  REMOVE_SUPPRESSION,
  REMOVE_SUPPRESSION_DONE,
  VALIDATE_DOMAIN_AUTHENTICATION,
)
