import { arraysToObject } from 'lib/utils'
import { campaignConstant as cc } from 'config/constants'

const colNumClassMap = {
  1: 'num11b',
  2: 'num6b',
  3: 'num4b',
}

const colNumWidthMap = {
  1: 380,
  2: 290,
  3: 186,
}

const thumbnailClassMap = {
  1: 'item-thumbnail-1column',
  2: 'item-thumbnail-2column',
  3: 'item-thumbnail-3column',
}

const getLiquidItem = (props) => {
  const layout = props['layout']
  const itemTruncate = layout !== 'left-image-right-text' ? 21 : 48
  return {
    name: `{{ item.name | truncate: ${itemTruncate}, "..."}}`,
    price1: '{{ item.price1 | commify }}',
    url1: '{{ item.url1 }}',
    image_url1: '{{ item.image_url1 }}',
    description: '{{ item.description | truncate: 40, "..." }}',
    price_off_rate: '{{ item.price_off_rate }}',
    old_price: '{{ item.old_price | commify }}',
    stock_count: '{{ item.stock_count }}',
  }
}

const contextualTypes = arraysToObject(cc.contextualTypes, 2, 1)

const buildItemsContainer = (itemCells, props) => {
  const layout = props['layout']
  const colNum = Number(props['col-num'])
  const itemNum = Number(props['item-num'])
  const colClassName = colNumClassMap[colNum]
  const colWidth = colNumWidthMap[colNum]

  let emptyCell = ''
  if (layout === 'plain') {
    let i = itemNum
    while (i % colNum !== 0) {
      emptyCell += `
<!-- floatが効かないメーラーが多いので空要素を挿入し寄せて対応する -->
<!-- 最後の一つを左寄せに ここから -->
<!--[if mso]><table width="${colWidth}" align="left" bgcolor="transparent" cellpadding="0" cellspacing="0" border="0"><tr><![endif]-->
<!--[if mso]><td valign="top" align="left" width="${colWidth}" height="50"><![endif]-->
<div class="col ${colClassName}"></div>
<!--[if mso]></td><![endif]-->
<!--[if mso]></td></tr></table><![endif]-->
<!-- 最後の一つを左寄せに ここまで -->
`
      i++
    }
  }

  return `
    <div width="100%">
        <!--[if mso]><table id="outlookholder" border="0" cellspacing="0" cellpadding="0" align="center"><tr><td><![endif]-->
        <table class="container" cellpadding="0" cellspacing="0" align="center" width="100%" border="0">
            <tbody>
            <tr>
                <td width="100%">
                    <table class="block-grid two-up" cellpadding="0" cellspacing="0" width="100%" bgcolor="transparent">
                        <tbody>
                        <tr>
                            <td class="item-all center">
                                <!--[if mso]><table id="outlookholder" width="640" border="0" cellpadding="0" cellspacing="0" align="center"><tr><td><![endif]-->

                                ${itemCells}
                            
                                ${emptyCell}
                                
                                <!--[if mso]></td></tr></table><![endif]-->
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </td>
            </tr>
            </tbody>
        </table>
        <!--[if mso]></td></tr></table><![endif]-->
    </div>
`
}

const getItemCellBuilder = (triggerType) => {
  if (triggerType === undefined) {
    return buildItemCell
  }
  if (isPriceReducedTriggerType(triggerType)) {
    return buildItemCellForPriceReduced
  }
  else if (isStockReducedTriggerType(triggerType)) {
    return buildItemCellForStockReduced
  }
  else if (isRestockTriggerType(triggerType)) {
    return buildItemCellForRestock
  }
}
/* eslint-disable no-irregular-whitespace */
const buildItemCellForPriceReduced = (item, props) => {
  const itemType = props['item-type']
  if (itemType !== 'item') {
    return buildItemCell(item, props)
  }
  return `
<!--[if mso]><table width="640" align="center" bgcolor="transparent" cellpadding="0" cellspacing="0" border="0"><tr><![endif]-->
<!--[if mso]><td valign="top" align="center" width="640"><![endif]-->
<div class="col num11b">
    <table cellpadding="0" cellspacing="0" align="center" width="100%" border="0">
        <tbody>
        <tr>
            <td>

                <!-- サムネイル ここから -->
                <table cellpadding="0" cellspacing="0" width="100%" border="0">
                    <tbody>
                    <tr>
                        <td class="item-thumbnail-1column" align="center">
                            <!--[if mso]><table width="380" align="center" bgcolor="transparent" cellpadding="0" cellspacing="0" border="0"><tr><td valign="top" align="center" width="380" height="*"><![endif]-->
                            <div  class="item-border" align="center">
                                <a href="${item.url1}" target="_blank">
                                    <img class="center fullwidth" align="center" border="0" src="${item.image_url1}" width="380">
                                </a>
                            </div>
                            <!--[if mso]></td></tr></table><![endif]-->
                        </td>
                    </tr>
                    </tbody>
                </table>
                <!-- サムネイル ここまで -->

                <!-- アイテム情報 ここから -->
                <table cellpadding="0" cellspacing="0" width="100%">
                    <tbody>

                    <!-- アイテム名 ここから -->
                    <tr>
                        <td  class="item-name">
                            <!--[if mso]><table width="380" align="center" bgcolor="transparent" cellpadding="0" cellspacing="0" border="0"><tr><td valign="top" align="center" width="380" height="34"><![endif]-->
                            <!-- ↑↑↑　Outlook対策 --heightはline-height*2行になるよう設定 -->
                            <div align="center">
                                <a href="${item.url1}" target="_blank"><p>${item.name}</p></a>
                                <!-- 文字のサイズ・文字数の変更は表示環境毎に描画が異なる為、余裕をもった設定を行うこと、変更の場合は複数の実機で要確認のこと -->
                            </div>
                            <!--[if mso]></td></tr></table><![endif]-->
                        </td>
                    </tr>
                    <!-- アイテム名 ここまで -->
                    <!-- 説明テキスト ここから-->
                    <tr>
                        <td  class="item-description">
                            <!--[if mso]><table width="380" align="center" bgcolor="transparent" cellpadding="0" cellspacing="0" border="0"><tr><td valign="top" align="center" width="380" height="34"><![endif]-->
                            <!-- ↑↑↑　Outlook対策 --heightは下部pタグのline-height*2行になるよう設定 -->
                            <div align="center">
                                <a href="${item.url1}" target="_blank"><p>${item.description}</p></a>
                                <!-- 文字のサイズ、数の変更は表示環境毎に描画が異なる為、余裕をもった設定を行うこと、変更の場合は複数の実機で要確認のこと -->
                            </div>
                            <!--[if mso]></td></tr></table><![endif]-->
                        </td>
                    </tr>
                    <!-- 説明テキスト ここまで-->
                    <!-- プライス値下げ率 ここから -->
                    <tr>
                        <td class="item-cutrate-price">
                            <!--[if mso]><table width="380" align="center" bgcolor="transparent" cellpadding="0" cellspacing="0" border="0"><tr><td valign="top" align="center" width="380" height="16"><![endif]-->
                            <div align="center">
                                <a href="${item.url1}" target="_blank"><p>${item.price_off_rate} % OFF</p></a>
                            </div>
                            <!--[if mso]></td></tr></table><![endif]-->
                        </td>
                    </tr>
                    <!-- プライス値下げ率 ここまで -->
                    <!-- プライスold ここから -->
                    <tr>
                        <td  class="item-old-price">
                            <!--[if mso]><table width="380" align="center" bgcolor="transparent" cellpadding="0" cellspacing="0" border="0"><tr><td valign="top" align="center" width="380" height="16"><![endif]-->
                            <div align="center">
                                <a href="${item.url1}" target="_blank"><p>￥ ${item.old_price}（税込）</p></a>
                            </div>
                            <!--[if mso]></td></tr></table><![endif]-->
                        </td>
                    </tr>
                    <!-- プライスold ここまで -->
                    <!-- プライスnew ここから -->
                    <tr>
                        <td  class="item-new-price">
                            <!--[if mso]><table width="380" align="center" bgcolor="transparent" cellpadding="0" cellspacing="0" border="0"><tr><td valign="top" align="center" width="380" height="16"><![endif]-->
                            <div align="center">
                                <a href="${item.url1}" target="_blank"><p>￥ ${item.price1}（税込）</p></a>
                            </div>
                            <!--[if mso]></td></tr></table><![endif]-->
                        </td>
                    </tr>
                    <!-- プライス ここまで -->

                    </tbody>
                </table>
                <!-- アイテム情報 ここまで -->

            </td>
        </tr>
        </tbody>
    </table>
</div>
<!--[if mso]></td><![endif]-->
<!--[if mso]></td></tr></table><![endif]-->
  `
}

const buildItemCellForStockReduced = (item, props) => {
  const itemType = props['item-type']
  if (itemType !== 'item') {
    return buildItemCell(item, props)
  }
  return `
<!--[if mso]><table width="640" align="center" bgcolor="transparent" cellpadding="0" cellspacing="0" border="0"><tr><![endif]-->
<!--[if mso]><td valign="top" align="center" width="640"><![endif]-->
<div class="col num11b">
    <table cellpadding="0" cellspacing="0" align="center" width="100%" border="0">
        <tbody>
        <tr>
            <td>

                <!-- サムネイル ここから -->
                <table cellpadding="0" cellspacing="0" width="100%" border="0">
                    <tbody>
                    <tr>
                        <td class="item-thumbnail-1column" align="center">
                            <!--[if mso]><table width="380" align="center" bgcolor="transparent" cellpadding="0" cellspacing="0" border="0"><tr><td valign="top" align="center" width="380" height="*"><![endif]-->
                            <div  class="item-border" align="center">
                                <a href="${item.url1}" target="_blank">
                                    <img class="center fullwidth" align="center" border="0" src="${item.image_url1}" width="380">
                                </a>
                            </div>
                            <!--[if mso]></td></tr></table><![endif]-->
                        </td>
                    </tr>
                    </tbody>
                </table>
                <!-- サムネイル ここまで -->

                <!-- アイテム情報 ここから -->
                <table cellpadding="0" cellspacing="0" width="100%">
                    <tbody>

                    <!-- アイテム名 ここから -->
                    <tr>
                        <td  class="item-name">
                            <!--[if mso]><table width="380" align="center" bgcolor="transparent" cellpadding="0" cellspacing="0" border="0"><tr><td valign="top" align="center" width="380" height="34"><![endif]-->
                            <!-- ↑↑↑　Outlook対策 --heightはline-height*2行になるよう設定 -->
                            <div align="center">
                                <a href="${item.url1}" target="_blank"><p>${item.name}</p></a>
                                <!-- 文字のサイズ・文字数の変更は表示環境毎に描画が異なる為、余裕をもった設定を行うこと、変更の場合は複数の実機で要確認のこと -->
                            </div>
                            <!--[if mso]></td></tr></table><![endif]-->
                        </td>
                    </tr>
                    <!-- アイテム名 ここまで -->
                    <!-- 説明テキスト ここから-->
                    <tr>
                        <td class="item-description">
                            <!--[if mso]><table width="380" align="center" bgcolor="transparent" cellpadding="0" cellspacing="0" border="0"><tr><td valign="top" align="center" width="380" height="34"><![endif]-->
                            <!-- ↑↑↑　Outlook対策 --heightは下部pタグのline-height*2行になるよう設定 -->
                            <div align="center">
                                <a href="${item.url1}" target="_blank"><p>${item.description}</p></a>
                                <!-- 文字のサイズ、数の変更は表示環境毎に描画が異なる為、余裕をもった設定を行うこと、変更の場合は複数の実機で要確認のこと -->
                            </div>
                            <!--[if mso]></td></tr></table><![endif]-->
                        </td>
                    </tr>
                    <!-- 説明テキスト ここまで-->
                    <!-- 在庫数 ここから -->
                    <tr>
                        <td class="item-stock">
                            <!--[if mso]><table width="380" align="center" bgcolor="transparent" cellpadding="0" cellspacing="0" border="0"><tr><td valign="top" align="center" width="380" height="16"><![endif]-->
                            <div align="center">
                                <a href="${item.url1}" target="_blank"><p><span>在庫数 </span>${item.stock_count}<span> 点</span></p></a>
                            </div>
                            <!--[if mso]></td></tr></table><![endif]-->
                        </td>
                    </tr>
                    <!-- 在庫数 ここまで -->
                    <!-- プライス ここから -->
                    <tr>
                        <td class="item-new-price">
                            <!--[if mso]><table width="380" align="center" bgcolor="transparent" cellpadding="0" cellspacing="0" border="0"><tr><td valign="top" align="center" width="380" height="16"><![endif]-->
                            <div align="center">
                                <a href="${item.url1}" target="_blank"><p>￥ ${item.price1}（税込）</p></a>
                            </div>
                            <!--[if mso]></td></tr></table><![endif]-->
                        </td>
                    </tr>
                    <!-- プライス ここまで -->

                    </tbody>
                </table>
                <!-- アイテム情報 ここまで -->

            </td>
        </tr>
        </tbody>
    </table>
</div>
<!--[if mso]></td><![endif]-->
<!--[if mso]></td></tr></table><![endif]-->
  `
}

const buildItemCellForRestock = (item, props) => {
  const itemType = props['item-type']
  if (itemType !== 'item') {
    return buildItemCell(item, props)
  }
  return `
<!--[if mso]><table width="640" align="center" bgcolor="transparent" cellpadding="0" cellspacing="0" border="0"><tr><![endif]-->
<!--[if mso]><td valign="top" align="center" width="640"><![endif]-->
<div class="col num11b">
    <table cellpadding="0" cellspacing="0" align="center" width="100%" border="0">
        <tbody>
        <tr>
            <td>

                <!-- サムネイル ここから -->
                <table cellpadding="0" cellspacing="0" width="100%" border="0">
                    <tbody>
                    <tr>
                        <td class="item-thumbnail-1column" align="center">
                            <!--[if mso]><table width="380" align="center" bgcolor="transparent" cellpadding="0" cellspacing="0" border="0"><tr><td valign="top" align="center" width="380" height="*"><![endif]-->
                            <div  class="item-border" align="center">
                                <a href="${item.url1}" target="_blank">
                                    <img class="center fullwidth" align="center" border="0" src="${item.image_url1}" width="380">
                                </a>
                            </div>
                            <!--[if mso]></td></tr></table><![endif]-->
                        </td>
                    </tr>
                    </tbody>
                </table>
                <!-- サムネイル ここまで -->

                <!-- アイテム情報 ここから -->
                <table cellpadding="0" cellspacing="0" width="100%">
                    <tbody>

                    <!-- アイテム名 ここから -->
                    <tr>
                        <td  class="item-name">
                            <!--[if mso]><table width="380" align="center" bgcolor="transparent" cellpadding="0" cellspacing="0" border="0"><tr><td valign="top" align="center" width="380" height="34"><![endif]-->
                            <!-- ↑↑↑　Outlook対策 --heightはline-height*2行になるよう設定 -->
                            <div align="center">
                                <a href="${item.url1}" target="_blank"><p>${item.name}</p></a>
                                <!-- 文字のサイズ・文字数の変更は表示環境毎に描画が異なる為、余裕をもった設定を行うこと、変更の場合は複数の実機で要確認のこと -->
                            </div>
                            <!--[if mso]></td></tr></table><![endif]-->
                        </td>
                    </tr>
                    <!-- アイテム名 ここまで -->
                    <!-- 説明テキスト ここから-->
                    <tr>
                        <td  class="item-description">
                            <!--[if mso]><table width="380" align="center" bgcolor="transparent" cellpadding="0" cellspacing="0" border="0"><tr><td valign="top" align="center" width="380" height="34"><![endif]-->
                            <!-- ↑↑↑　Outlook対策 --heightは下部pタグのline-height*2行になるよう設定 -->
                            <div align="center">
                                <a href="${item.url1}" target="_blank"><p>${item.description}</p></a>
                                <!-- 文字のサイズ、数の変更は表示環境毎に描画が異なる為、余裕をもった設定を行うこと、変更の場合は複数の実機で要確認のこと -->
                            </div>
                            <!--[if mso]></td></tr></table><![endif]-->
                        </td>
                    </tr>
                    <!-- 説明テキスト ここまで-->
                    <!-- プライス ここから -->
                    <tr>
                        <td  class="item-new-price">
                            <!--[if mso]><table width="380" align="center" bgcolor="transparent" cellpadding="0" cellspacing="0" border="0"><tr><td valign="top" align="center" width="380" height="16"><![endif]-->
                            <div align="center">
                                <a href="${item.url1}" target="_blank"><p>￥ ${item.price1}（税込）</p></a>
                            </div>
                            <!--[if mso]></td></tr></table><![endif]-->
                        </td>
                    </tr>
                    <!-- プライス ここまで -->

                    </tbody>
                </table>
                <!-- アイテム情報 ここまで -->

            </td>
        </tr>
        </tbody>
    </table>
</div>
<!--[if mso]></td><![endif]-->
<!--[if mso]></td></tr></table><![endif]-->
  `
}

const buildItemCell = (item, props) => {
  const layout = props['layout']
  switch (layout) {
  case 'plain':
    return itemCellForPlainLayout(item, props)
  case 'left-image-right-text':
    return itemCellForRightTextLayout(item, props)
  case 'review-request':
    return itemCellForReviewRequestLayout(item, props)
  default:
    return itemCellForPlainLayout(item, props)
  }
}

const itemCellForPlainLayout = (item, props) => {
  const colNum = Number(props['col-num'])
  const colClassName = colNumClassMap[colNum]
  const colWidth = colNumWidthMap[colNum]
  const thumbnailClassName = thumbnailClassMap[colNum]
  const align = colNum !== 1 ? 'left' : 'center'
  return `
<!--[if mso]><table width="${colWidth}" align="${align}" bgcolor="transparent" cellpadding="0" cellspacing="0" border="0"><tr><![endif]-->
<!--[if mso]><td valign="top" align="${align}" width="${colWidth}"><![endif]-->
<div class="col ${colClassName}">
    <table cellpadding="0" cellspacing="0" align="center" width="100%" border="0">
        <tbody>
        <tr>
            <td>

                <!-- サムネイル ここから -->
                <table cellpadding="0" cellspacing="0" width="100%" border="0">
                    <tbody>
                    <tr>
                        <td class="${thumbnailClassName}" align="center">
                            <!--[if mso]><table width="${colWidth}" align="left" bgcolor="transparent" cellpadding="0" cellspacing="0" border="0"><tr><td valign="top" align="left" width="${colWidth}" height="*"><![endif]-->
                            <div  class="item-border" align="center">
                                <a href="${item.url1}" target="_blank">
                                    <img class="center fullwidth" align="center" border="0" src="${item.image_url1}" width="${colWidth}">
                                </a>
                            </div>
                            <!--[if mso]></td></tr></table><![endif]-->
                        </td>
                    </tr>
                    </tbody>
                </table>
                <!-- サムネイル ここまで -->

                <!-- アイテム情報 ここから -->
                <table cellpadding="0" cellspacing="0" width="100%">
                    <tbody>

                    <!-- アイテム名 ここから -->
                    <tr>
                        <td  class="item-name">
                            <!--[if mso]><table width="${colWidth}" align="left" bgcolor="transparent" cellpadding="0" cellspacing="0" border="0"><tr><td valign="top" align="left" width="${colWidth}" height="34"><![endif]-->
                            <!-- ↑↑↑　Outlook対策 --heightはline-height*2行になるよう設定 -->
                            <div align="center">
                                <a href="${item.url1}" target="_blank"><p>${item.name}</p></a>
                                <!-- 文字のサイズ・文字数の変更は表示環境毎に描画が異なる為、余裕をもった設定を行うこと、変更の場合は複数の実機で要確認のこと -->
                            </div>
                            <!--[if mso]></td></tr></table><![endif]-->
                        </td>
                    </tr>
                    <!-- アイテム名 ここまで -->
                    <!-- プライス ここから -->
                    <tr>
                        <td  class="item-price">
                            <!--[if mso]><table width="${colWidth}" align="left" bgcolor="transparent" cellpadding="0" cellspacing="0" border="0"><tr><td valign="top" align="left" width="${colWidth}" height="16"><![endif]-->
                            <div align="center">
                                <a href="${item.url1}" target="_blank"><p>￥ ${item.price1}（税込）</p></a>
                            </div>
                            <!--[if mso]></td></tr></table><![endif]-->
                        </td>
                    </tr>
                    <!-- プライス ここまで -->

                    </tbody>
                </table>
                <!-- アイテム情報 ここまで -->

            </td>
        </tr>
        </tbody>
    </table>
</div>
<!--[if mso]></td><![endif]-->
<!--[if mso]></td></tr></table><![endif]-->
`
}

const itemCellForRightTextLayout = (item, props) => {
  return `
<!--[if mso]><table width="640" align="left" bgcolor="transparent" cellpadding="0" cellspacing="0" border="0"><tr><![endif]-->
<!--[if mso]><td valign="top" align="left" width="640"><![endif]-->
<div class="col num12">
  <table cellpadding="0" cellspacing="0" align="center" width="100%" border="0">
      <tbody>
      <!-- サムネイル、アイテム名 ここから -->
      <tr class="boder-top">
          <!-- サムネイル ここから -->
          <td class="item-thumbnail-1column-list" align="center"  width="50%" rowspan="3">
              <!--[if mso]><table width="320" align="left" bgcolor="transparent" cellpadding="0" cellspacing="0" border="0"><tr><td valign="top" align="left" width="300" height="*"><![endif]-->
              <div  class="item-border" align="center">
                  <a href="${item.url1}" target="_blank">
                      <img class="fullwidth fullwidth-296" align="center" border="0" src="${item.image_url1}" width="296" height="*">
                  </a>
              </div>
              <!--[if mso]></td></tr></table><![endif]-->
          </td>
          <!-- サムネイル ここまで -->
          <!-- アイテム名 ここから -->
          <td  class="item-name" width="50%">
              <!--[if mso]><table width="320" align="center" bgcolor="transparent" cellpadding="0" cellspacing="0" border="0"><td><![endif]-->
              <div align="center">
                  <a href="${item.url1}" target="_blank"><p class="item-description" >${item.name}</p>
                  </a>
              </div>
              <!--[if mso]></td></table><![endif]-->
          </td>
          <!-- アイテム名 ここまで -->
      </tr>
      <!-- サムネイル、アイテム名 ここまで -->
      <!-- プライス ここから -->
      <tr>
          <td class="item-price">
              <!--[if mso]><table width="320" align="center" bgcolor="transparent" cellpadding="0" cellspacing="0" border="0"><td><![endif]-->
              <div align="center">
                  <a href="${item.url1}" target="_blank"><p class="item-description" >￥ ${item.price1}（税込）</p></a>
              </div>
              <!--[if mso]></td></table><![endif]-->
          </td>
      </tr>
      <!-- プライス ここまで -->
      <!-- ボタン ここから -->
      <tr>
          <td class="item-bouton">
              <a href="${item.url1}" target="_blank">
                  <img class="fullwidth fullwidth-btn-s" align="center" border="0" src="https://s3-ap-northeast-1.amazonaws.com/rtg-mail/mailtemplate/img/btn_5_detail_black.png" width="290">
              </a>
          </td>
      </tr>
      <!-- ボタン ここまで -->

      </tbody>
  </table>
</div>
<!--[if mso]></td><![endif]-->
<!--[if mso]></td></tr></table><![endif]-->
  `
}

const itemCellForReviewRequestLayout = (item, props) => {
  return `
<!--[if mso]><table width="640" align="left" bgcolor="transparent" cellpadding="0" cellspacing="0" border="0"><tr><![endif]-->
<!--[if mso]>><td valign="top" align="left" width="640"><![endif]-->
<div class="col num12">
  <table cellpadding="0" cellspacing="0" align="center" width="100%" border="0">
      <tbody>

      <tr>
          <td class="boder-top" colspan="3" width="100%"> <td>
      </tr>
      <tr>
          <!-- サムネイル ここから -->
          <td class="item-thumbnail-1column-list" align="center" width="84" rowspan="2">
              <!--[if mso]><table width="84" align="left" bgcolor="transparent" cellpadding="0" cellspacing="0" border="0"><tr><td valign="top" align="left" width="84" height="*"><![endif]-->
              <div  class="item-border" align="center">
                  <a href="${item.url1}" target="_blank">
                      <img class="fullwidth fullwidth-84" align="center" border="0" src="${item.image_url1}" width="84" height="*">
                  </a>
              </div>
              <!--[if mso]></td></tr></table><![endif]-->
          </td>
          <!-- サムネイル ここまで -->
          <!-- アイテム名 ここから -->
          <td  class="item-name-list">
              <!--[if mso]><table width="350" align="left" bgcolor="transparent" cellpadding="0" cellspacing="0" border="0"><tr><td valign="top" align="left" width="350" height="34"><![endif]-->
              <!-- ↑↑↑　Outlook対策 --heightはline-height*2行になるよう設定 -->
              <div align="center">
                  <a href="${item.url1}" target="_blank"><p>${item.name}</p></a>
                  <!-- 文字のサイズ・文字数の変更は表示環境毎に描画が異なる為、余裕をもった設定を行うこと、変更の場合は複数の実機で要確認のこと -->
              </div>
              <!--[if mso]></td></tr></table><![endif]-->
          </td>
          <!-- アイテム名 ここまで -->
      </tr>
      <tr>
          <!-- レビューを書く ここから-->
          <td class="item-review">
              <!--[if mso]><table width="120" align="right" bgcolor="transparent" cellpadding="0" cellspacing="0" border="0"><tr><td valign="top" align="right" width="120" height="16"><![endif]-->
              <div>
                  <a href="${item.url1}" target="_blank"><p>レビューを書く&nbsp;&#8594;</p></a>
              </div>
              <!--[if mso]></td></tr></table><![endif]-->
          </td>
      </tr>
      <!-- レビューを書く ここまで-->

      </tbody>
  </table>
</div>
<!--[if mso]></td><![endif]-->
<!--[if mso]></td></tr></table><![endif]-->
  `
}
/* eslint-enable no-irregular-whitespace */
const buildItemLiquidCell = (itemCell, itemType, itemNum) => {
  const needLoop = itemType !== 'item'
  return needLoop ? `
<!-- {% assign loop_items = ${itemType} | default: items %} -->
<!-- {% for item in loop_items | limit:${itemNum} %} -->
  ${itemCell} 
<!-- {% endfor %} -->
` : itemCell
}

const zeroPadding = (num, length) => {
  return ('0'.repeat(length) + num).slice(-length)
}

const getDefaultOptions = (campaignType, triggerType) => {
  switch (campaignType) {
  case 'browser':
    return [{ id: 'items', name: '閲覧した商品' }]
  case 'cart':
    return [{ id: 'items', name: 'カートに入れた商品' }]
  case 'register':
    return [{ id: 'items', name: '1. カート履歴 2. 閲覧履歴 3. 最新順の登録商品 の中から順に抽出した商品' }]
  case 'purchase':
    return [{ id: 'items', name: '購入した商品' }, { id: 'view_buy_items', name: '購入した商品をのぞく閲覧した商品' }]
  case 'contextual':
    if (isPriceReducedTriggerType(triggerType)) {
      return [{ id: 'item', name: '値下げした商品' }]
    }
    else if (isStockReducedTriggerType(triggerType)) {
      return [{ id: 'item', name: '在庫あとわずかの商品' }]
    }
    else if (isRestockTriggerType(triggerType)) {
      return [{ id: 'item', name: '再入荷した商品' }]
    }
    return []
  default:
    return [{ id: 'items', name: '商品' }]
  }
}

const isPriceReducedTriggerType = (triggerType) => {
  return [contextualTypes.priceReduced, contextualTypes.priceReducedStep].includes(triggerType)
}

const isStockReducedTriggerType = (triggerType) => {
  return [contextualTypes.stockReduced, contextualTypes.stockReducedStep].includes(triggerType)
}

const isRestockTriggerType = (triggerType) => {
  return [contextualTypes.restock, contextualTypes.restockStep].includes(triggerType)
}

const generateItems = ({ campaignType, triggerType, recoTemplates }) => {
  const options = getDefaultOptions(campaignType, triggerType).concat(
    recoTemplates.map((item) => {
      return { id: `recommend_${item.aliasName}.items`, name: item.memo !== null ? item.memo : item.aliasName }
    })
  )
  const itemCellBuilder = getItemCellBuilder(triggerType)

  const itemInnerHTML = (props) => {
    const itemType = props['item-type']
    const itemNum = Number(props['item-num'])
    let itemCells = ''
    for(let i = 0; i < itemNum; i++) {
      const itemNo = i + 1
      const item = {
        name: `商品${itemNo}`,
        price1: itemNo * 1000,
        url1: 'http://www.naviplus.co.jp/rtg-mail/',
        image_url1: `https://s3-ap-northeast-1.amazonaws.com/rtg-mail/item-img/images300x300_${zeroPadding(itemNo, 3)}.jpg`,
        description: '商品説明',
        old_price: itemNo * 1000 * 1.05,
        price_off_rate: 5,
        stock_count: 10,
      }
      itemCells += itemCellBuilder(item, props)
      if (itemType === 'item') {
        break
      }
    }
    return buildItemsContainer(itemCells, props)
  }

  const itemFullHTML = (props) => {
    const itemType = props['item-type']
    const layout = props['layout']
    const colNum = Number(props['col-num'])
    const itemNum = Number(props['item-num'])
    return `
<div data-gjs-type="items" data-gjs-item-type="${itemType}" data-gjs-layout="${layout}" data-gjs-col-num="${colNum}" data-gjs-item-num="${itemNum}" >
  <div data-gjs-type="unLayerable">
    ${buildItemLiquid(props)}
  </div>
</div>
`
  }

  const buildItemLiquid = (props) => {
    const itemType = props['item-type']
    const itemNum = Number(props['item-num'])
    const itemCell = itemCellBuilder(getLiquidItem(props), props)
    const itemCells = buildItemLiquidCell(itemCell, itemType, itemNum)
    return buildItemsContainer(itemCells, props)
  }

  return {
    model: {
      defaults: {
        droppable: false,
        traits: [
          {
            type: 'select',
            name: 'item-type',
            label: '商品種別',
            options,
            changeProp: true,
          },
        ],
      },

      init() {
        this.on('change', this.handleChange)

        this.updateTraits()
      },

      handleChange() {
        this.updateTraits()

        this.view.renderElement()
      },

      toHTML() {
        return itemFullHTML(this.props())
      },

      updateTraits() {
        const props = this.props()
        const itemType = props['item-type']
        const layout = props['layout']
        if (itemType === 'item') {
          this.removeTrait('layout')
          this.removeTrait('item-num')
        } else {
          if (this.getTrait('layout') === undefined) {
            this.addTrait({
              type: 'select',
              name: 'layout',
              label: 'レイアウト',
              options: [
                { id: 'plain', name: 'プレーン' },
                { id: 'left-image-right-text', name: '左イメージ＋右テキスト' },
                { id: 'review-request', name: 'レビュー依頼' },
              ],
              changeProp: true,
            }, { at: 1 })
          }
          if (this.getTrait('item-num') === undefined) {
            this.addTrait({
              type: 'number',
              name: 'item-num',
              placeholder: '1-10',
              min: 1,
              max: 10,
              step: 1,
              changeProp: true,
            }, { at: 2 })
          }
        }
        if (layout !== 'plain' || itemType === 'item') {
          this.removeTrait('col-num')
        } else {
          if (this.getTrait('col-num') === undefined) {
            this.addTrait({
              type: 'number',
              name: 'col-num',
              label: '列数',
              placeholder: '1-2',
              min: 1,
              max: 3,
              step: 1,
              changeProp: true,
            })
          }
        }
      }
    },
    view: {
      renderElement() {
        this.el.innerHTML = itemInnerHTML(this.model.props())
      },
      onRender({ el }) {
        this.renderElement()
      },
    },
  }
}

export default generateItems
