import React from 'react'
import { connect } from 'react-redux'
import ContentWrapper from 'components/layout/ContentWrapper'
import {
  Row, Col, Card, CardBody,
} from 'reactstrap'
import { useMountEffect } from 'lib/hooks'
import { deserializeFromQueryString } from 'lib/utils'

import {
  abandonedCampaignAction,
  completedCampaignAction,
  contextualCampaignAction,
  prospectsCampaignAction,
  mailSenderInfoAction,
  companyAction,
  campaignTemplateAction,
  eventAction,
  adminPermissionAction,
  companyCampaignTemplateValueAction,
} from 'actions'
import AbandonedCampaignForm from 'components/parts/campaign/AbandonedCampaignForm'
import CompletedCampaignForm from 'components/parts/campaign/CompletedCampaignForm'
import ContextualCampaignForm from 'components/parts/campaign/ContextualCampaignForm'
import ProspectsCampaignForm from 'components/parts/campaign/ProspectsCampaignForm'

const formComponentMapping = {
  abandoned: AbandonedCampaignForm,
  completed: CompletedCampaignForm,
  contextual: ContextualCampaignForm,
  prospects: ProspectsCampaignForm
}

export const CampaignNewPage = ({dispatch, match, location}) => {
  const queryObj = deserializeFromQueryString(location.search) || {}
  const campaignType = match.params.campaign_type
  const templateId = 'template' in queryObj && Number(queryObj.template)
  const originalCampaignId = 'original' in queryObj && Number(queryObj.original)
  const FormComponent = formComponentMapping[campaignType]

  useMountEffect(() => {
    dispatch(companyAction.fetchCompany())
    dispatch(companyCampaignTemplateValueAction.fetchCompanyCampaignTemplateValue())
    dispatch(mailSenderInfoAction.fetchMailSenderInfo())
    dispatch(campaignTemplateAction.fetchCampaignTemplates())
    dispatch(adminPermissionAction.fetchAdminPermission())
    switch (campaignType) {
    case 'abandoned':
      dispatch(abandonedCampaignAction.fetchAbandonedCampaigns({ type: 'all' }))
      break
    case 'completed':
      dispatch(completedCampaignAction.fetchCompletedCampaigns())
      break
    case 'contextual':
      dispatch(contextualCampaignAction.fetchContextualCampaigns())
      break
    case 'prospects':
      dispatch(prospectsCampaignAction.fetchProspectsCampaigns())
      dispatch(eventAction.fetchEvents())
      break
    default:
    }
  })

  return (
    <ContentWrapper>
      <div className="content-heading">
        <div>キャンペーン新規作成</div>
      </div>
      <Row>
        <Col xl={12}>
          <Card className="card-default">
            <CardBody>
              <FormComponent templateId={templateId} campaignId={originalCampaignId} submitAction='create'/>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </ContentWrapper>
  )
}


export default connect()(CampaignNewPage)
