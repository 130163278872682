import { createActions } from 'redux-actions'

const FETCH_ADMIN_PERMISSION = 'FETCH_ADMIN_PERMISSION'
const FETCH_ADMIN_PERMISSION_DONE = 'FETCH_ADMIN_PERMISSION_DONE'

export const adminPermissionAction = createActions(
  {
    [FETCH_ADMIN_PERMISSION_DONE]: [
      (...args) => args[0],
      (...args) => args[1],
    ],
  },
  FETCH_ADMIN_PERMISSION,
)
