import { takeLatest, call, put } from 'redux-saga/effects'
import * as api from 'services/api'
import { eventAction } from 'actions'

function* handleFetchEvents() {
  const { payload, meta } = yield call(api.fetchEvents)
  yield put(eventAction.fetchEventsDone(payload, meta))
}

export const eventSaga = [
  takeLatest(eventAction.fetchEvents, handleFetchEvents),
]
