import { takeLatest, call, put } from 'redux-saga/effects'
import * as api from 'services/api'
import { elasticsearchCustomersAction } from 'actions'

function* handleFetchElasticsearchCustomers(action) {
  const { payload, meta } = yield call(api.fetchElasticsearchCustomers, action.payload)
  yield put(elasticsearchCustomersAction.fetchElasticsearchCustomersDone(payload, meta))
}

export const elasticsearchCustomersSaga = [
  takeLatest(elasticsearchCustomersAction.fetchElasticsearchCustomers, handleFetchElasticsearchCustomers),
]
