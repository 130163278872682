import { createActions } from 'redux-actions'

const FETCH_ELASTICSEARCH_ITEMS = 'FETCH_ELASTICSEARCH_ITEMS'
const FETCH_ELASTICSEARCH_ITEMS_DONE = 'FETCH_ELASTICSEARCH_ITEMS_DONE'

export const elasticsearchItemsAction = createActions(
  {
    [FETCH_ELASTICSEARCH_ITEMS_DONE]: [
      (...args) => args[0],
      (...args) => args[1],
    ],
  },
  FETCH_ELASTICSEARCH_ITEMS,
)
