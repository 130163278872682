import React from 'react'
import { connect } from 'react-redux'
import { Swal } from 'lib/components'
import { Card, CardHeader } from 'reactstrap'
import { adminCompanyStatusAction } from 'actions'
import { generalConstant as gc } from 'config/constants'

export const TerminateCompanyCard = ({adminCompanyStatus, dispatch}) => {
  return (
    <Card className="card-default">
      <CardHeader>解約処理</CardHeader>
      { adminCompanyStatus.item.status === 'terminated' ?
        <span className="text-muted">解約済み</span>
        :
        <Swal
          options={gc.terminateCompanySwalOptions}
          color="danger"
          callback={(isConfirm) => {
            isConfirm && dispatch(adminCompanyStatusAction.submitTerminateCompanyStatus({ companyId: adminCompanyStatus.item.companyId }))
          }}
          size="lg"
        >
            解約
        </Swal>
      }
      { adminCompanyStatus.item.status !== 'terminated' }
    </Card>
  )
}

const select = ({ adminCompanyStatus }) => ({ adminCompanyStatus })

export default connect(select)(TerminateCompanyCard)
