import { createActions } from 'redux-actions'

const SEARCH_TRANSITION_REPORT = 'SEARCH_TRANSITION_REPORT'

const FETCH_TRANSITION_REPORT = 'FETCH_TRANSITION_REPORT'
const FETCH_TRANSITION_REPORT_DONE = 'FETCH_TRANSITION_REPORT_DONE'


export const transitionReportAction = createActions(
  {
    [FETCH_TRANSITION_REPORT_DONE]: [
      (...args) => args[0],
      (...args) => args[1],
    ],
  },
  SEARCH_TRANSITION_REPORT,
  FETCH_TRANSITION_REPORT,
)
