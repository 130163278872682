import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import ContentWrapper from 'components/layout/ContentWrapper'
import { Card, CardBody, CardHeader } from 'reactstrap'
import { deserializeFromQueryString } from 'lib/utils'

import { useMountEffect } from 'lib/hooks'
import { reportConstant as rc } from 'config/constants'
import {
  transitionReportAction,
  abandonedCampaignAction,
  completedCampaignAction,
  contextualCampaignAction,
  popupAction,
  prospectsCampaignAction,
} from 'actions'
import TransitionReportConditionForm from 'components/parts/report/TransitionReportConditionForm'
import TransitionReportKeyIndicator from 'components/parts/report/TransitionReportKeyIndicator'
import TransitionReportGraph from 'components/parts/report/TransitionReportGraph'
import TransitionReportSummaryTable from 'components/parts/report/TransitionReportSummaryTable'
import TransitionReportDetailTable from 'components/parts/report/TransitionReportDetailTable'
import TransitionReportCsvDownloadButton from 'components/parts/report/TransitionReportCsvDownloadButton'

export const TransitionReportPage = ({dispatch, location}) => {
  const queryObj = toQueryObj(location.search)

  function toQueryObj(queryString){
    let ret = deserializeFromQueryString(queryString) || {}
    for (let key in rc.defaultTransitionReportCondition) {
      if ({}.hasOwnProperty.call(rc.defaultTransitionReportCondition, key)) {
        ret[key] = ret[key] || rc.defaultTransitionReportCondition[key]
      }
    }
    return ret
  }

  useMountEffect(() => {
    dispatch(abandonedCampaignAction.fetchAbandonedCampaigns({ show_finished: true }))
    dispatch(completedCampaignAction.fetchCompletedCampaigns({ show_finished: true }))
    dispatch(contextualCampaignAction.fetchContextualCampaigns({ show_finished: true }))
    dispatch(popupAction.fetchPopups())
    dispatch(prospectsCampaignAction.fetchProspectsCampaigns({ show_finished: true }))
  })

  useEffect(() => {
    dispatch(transitionReportAction.fetchTransitionReport(queryObj))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search])

  return (
    <ContentWrapper>
      <div className="content-heading">
        <div>推移レポート</div>
      </div>
      <Card className="card-default">
        <CardBody>
          <TransitionReportConditionForm queryObj={queryObj} />
        </CardBody>
      </Card>
      <Card className="card-default">
        <TransitionReportKeyIndicator condition={queryObj} />
      </Card>
      <Card className="card-default">
        <CardBody>
          <TransitionReportGraph transition={queryObj['transition']}/>
        </CardBody>
      </Card>
      <div className="text-right mb-3">
        <TransitionReportCsvDownloadButton condition={queryObj} />
      </div>
      <Card className="card-default">
        <CardHeader>集計期間合計</CardHeader>
        <TransitionReportSummaryTable condition={queryObj} />
      </Card>
      <Card className="card-default">
        <CardHeader>集計期間明細</CardHeader>
        <TransitionReportDetailTable condition={queryObj} />
      </Card>
    </ContentWrapper>
  )
}


export default connect()(TransitionReportPage)
