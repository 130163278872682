import { call, put, takeLatest } from 'redux-saga/effects'
import * as api from 'services/adminApi'
import { adminCampaignTemplateAction } from 'actions'
import { push } from 'connected-react-router'

export function* handleFetchCampaignTemplates() {
  const { payload, meta } = yield call(api.fetchCampaignTemplates)
  yield put(adminCampaignTemplateAction.fetchCampaignTemplatesDone(payload, meta))
}

function* handleSubmitUpdateCampaignTemplate(action) {
  const { payload, meta, error } = yield call(api.submitUpdateCampaignTemplate, action.payload)
  yield put(adminCampaignTemplateAction.submitUpdateCampaignTemplateDone(payload, meta))
  if (!error) yield put(push('/admin/campaign_template_list'))
}

function* handleSubmitCreateCampaignTemplate(action) {
  const { payload, meta, error } = yield call(api.submitCreateCampaignTemplate, action.payload)
  yield put(adminCampaignTemplateAction.submitCreateCampaignTemplateDone(payload, meta))
  if (!error) yield put(push('/admin/campaign_template_list'))
}

function* handleSubmitDeleteCampaignTemplate(action) {
  let { payload, meta, error } = yield call(api.submitDeleteCampaignTemplate, action.payload)
  if (!error) { payload = action.payload }
  yield put(adminCampaignTemplateAction.submitDeleteCampaignTemplateDone(payload, meta))
  if (!error) yield put(push('/admin/campaign_template_list'))
}

export const adminCampaignTemplateSaga = [
  takeLatest(adminCampaignTemplateAction.fetchCampaignTemplates, handleFetchCampaignTemplates),
  takeLatest(adminCampaignTemplateAction.submitUpdateCampaignTemplate, handleSubmitUpdateCampaignTemplate),
  takeLatest(adminCampaignTemplateAction.submitCreateCampaignTemplate, handleSubmitCreateCampaignTemplate),
  takeLatest(adminCampaignTemplateAction.submitDeleteCampaignTemplate, handleSubmitDeleteCampaignTemplate),
]
