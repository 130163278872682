import React, { useState } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import { XYPlot, XAxis, YAxis, LineMarkSeries, Crosshair, makeWidthFlexible } from 'react-vis'
import { reportConstant as rc } from 'config/constants'
import { formatDateTick, thinOutDateTicks } from 'lib/utils'
import { Loading } from 'lib/components'

const FlexibleXYPlot = makeWidthFlexible(XYPlot)

const extractGraphData = (details, indicators) => {
  if (!details || details.length === 0) return null
  let ret = []
  indicators.forEach(() => { ret.push([]) })
  details.forEach((x) => {
    const unixTimestamp = new Date(x.date).getTime()
    indicators.forEach((v, i) => {
      ret[i].push({
        x: unixTimestamp,
        y: x[indicators[i]]
      })
    })
  })
  return ret
}

export const TransitionReportGraph = ({ transitionReport, graphHeight = 300 , transition }) => {
  const [crosshairValues, setCrosshairValues] = useState([])
  const indicatorsData = rc.transitionReportDisplayIndexes
  const graphData = extractGraphData(transitionReport.item.detail, indicatorsData.map(x => x[0]))
  if(!graphData) {
    return (
      <Loading isLoading={transitionReport.isFetching}>
        <div
          style={{
            height: graphHeight,
            backgroundColor: 'rgba(0, 0, 0, 0.03)',
            textAlign: 'center'
          }}
        >
          <h3 style={{lineHeight: `${graphHeight}px`}}>該当期間にデータが存在しません</h3>
        </div>
      </Loading>
    )
  }
  const max = Math.max(
    ...graphData.map(
      (data) => (data.reduce((acc, row) => (Math.max(acc, row.y)), -Infinity))
    )
  )
  return (
    <Loading isLoading={transitionReport.isFetching}>
      <FlexibleXYPlot
        height={graphHeight}
        margin={{left: 60, right: 60}}
        xType='time'
        yDomain={[0, max]}
        onMouseLeave={() => {setCrosshairValues([])}}
      >
        <XAxis
          tickFormat={(v, i) => (formatDateTick(v, i, transition))}
          tickValues={thinOutDateTicks(graphData[0].map((data) => (data.x)), transition)}
        />
        <YAxis/>
        {
          graphData.map((data, i) => (
            i === 0 ?
              <LineMarkSeries
                data={data}
                onNearestX={(value, {index}) => { setCrosshairValues(graphData.map(d => d[index])) }}
                key={`${i}`}
              /> :
              <LineMarkSeries
                data={data}
                key={`${i}`}
              />
          ))
        }
        <Crosshair
          values={crosshairValues}
          titleFormat={(d) => ({title: '日付', value: moment.utc(d[0].x).format('YYYY/MM/DD')})}
          itemsFormat={(d) => (
            d.map((v, i) => ({title: indicatorsData[i][1], value: d[i].y}))
          )}
        />
      </FlexibleXYPlot>
    </Loading>
  )
}

const select = ({ transitionReport }) => ({ transitionReport })

export default connect(select)(TransitionReportGraph)
