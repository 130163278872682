import { createActions } from 'redux-actions'

const SEARCH_COMPARISON_REPORT = 'SEARCH_COMPARISON_REPORT'

const REMOVE_COMPARISON_REPORT = 'REMOVE_COMPARISON_REPORT'

const CLEAR_COMPARISON_REPORTS = 'CLEAR_COMPARISON_REPORTS'

const FETCH_COMPARISON_REPORT = 'FETCH_COMPARISON_REPORT'
const FETCH_COMPARISON_REPORT_DONE = 'FETCH_COMPARISON_REPORT_DONE'

export const comparisonReportAction = createActions(
  {
    [FETCH_COMPARISON_REPORT_DONE]: [
      (...args) => args[0],
      (...args) => args[1],
    ],
  },
  SEARCH_COMPARISON_REPORT,
  REMOVE_COMPARISON_REPORT,
  CLEAR_COMPARISON_REPORTS,
  FETCH_COMPARISON_REPORT,
)
