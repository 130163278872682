import React from 'react'
import { connect } from 'react-redux'
import { Table, Button } from 'reactstrap'
import { arraysToObject } from 'lib/utils'
import { comparisonReportAction } from 'actions'

import { reportConstant as rc } from 'config/constants'
import { Loading } from 'lib/components'

const terms = arraysToObject(rc.terms, 1, 0)
const baseCampaignTypes = arraysToObject(rc.campaignTypes, 1, 0)

export const ComparisonReportTable = ({
  campaign, comparisonReport, company, queryObj = [], dispatch,
}) => {
  const summaries = comparisonReport.items
    .sort((a, b) => (a.order - b.order))
    .map(el => ({
      ...el.summary,
      ...el.info,
      campaignName: convertCampaignName(el.info.campaign, campaign.items),
      termName: terms[el.info.term],
    }))

  return (
    <Loading isLoading={campaign.isFetching}>
      <Table bordered responsive>
        <thead>
          <tr style={{ wordBreak: 'keep-all' }}>
            <th>&nbsp;</th>
            <th>キャンペーン名</th>
            <th>期間</th>
            <th>配信数</th>
            <th>到達数</th>
            <th>開封数</th>
            <th>開封率</th>
            <th>クリック数</th>
            <th>クリック率</th>
            <th>完了数</th>
            <th>完了率</th>
            <th>キャンペーン売上</th>
            <th>リフトアップ</th>
            <th>メアド取得数</th>
            <th>会員化数</th>
            <th>会員化率</th>
          </tr>
        </thead>
        <tbody>
          { summaries.map((sum, index) => (
            <tr key={`${sum.campaign}_${sum.campaignId || 'noid'}_${sum.term}_${index}`}>
              <td>
                <Button
                  color="warning"
                  onClick={(e) => {
                    dispatch(comparisonReportAction.removeComparisonReport({ queryObj: { ...queryObj }, index }))
                  }}
                >
                  −
                </Button>
              </td>
              <td>{sum.campaignName}</td>
              <td>{sum.termName}</td>
              <td>{sum.processedCount.toLocaleString()}</td>
              <td>{sum.deliveredCount.toLocaleString()}</td>
              <td>{sum.openCount.toLocaleString()}</td>
              <td>{Math.round(sum.openRate * 1000) / 10}%</td>
              <td>{sum.clickCount.toLocaleString()}</td>
              <td>{Math.round(sum.clickRate * 1000) / 10}%</td>
              <td>{sum.conversionCount.toLocaleString()}</td>
              <td>{Math.round(sum.conversionRate * 1000) / 10}%</td>
              <td>¥{sum.priceSum.toLocaleString()}</td>
              <td>{Math.round(sum.liftUp * 1000) / 10}%</td>
              <td>{sum.prospectCount.toLocaleString()}</td>
              <td>{sum.registerCount.toLocaleString()}</td>
              <td>{Math.round(sum.registerRate * 1000) / 10}%</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Loading>
  )
}

const convertCampaignName = (campaign, campaignItems) => {
  if (baseCampaignTypes[campaign]) {
    return baseCampaignTypes[campaign]
  }
  const delimiterIndex = campaign.indexOf(rc.campaignTypeDelimiter)
  const campaignType = campaign.slice(0, delimiterIndex)
  const campaignId = Number(campaign.slice(delimiterIndex + rc.campaignTypeDelimiter.length))
  return campaignItems.find(item => item._camGroup === campaignType && item.id === campaignId).name
}

const select = ({
  abandonedCampaign, completedCampaign, contextualCampaign, popup, prospectsCampaign, comparisonReport, company,
}) => {
  const abanItems = abandonedCampaign.items.map((x) => { x._camGroup = 'abandoned'; return x })
  const compItems = completedCampaign.items.map((x) => { x._camGroup = 'completed'; return x })
  const contItems = contextualCampaign.items.map((x) => { x._camGroup = 'contextual'; return x })
  const popItems = popup.items.map((x) => { x._camGroup = 'popup'; return x })
  const prosItems = prospectsCampaign.items.map((x) => { x._camGroup = 'prospects'; return x })
  return {
    campaign: {
      items: [...abanItems, ...compItems, ...contItems, ...popItems, ...prosItems],
      isFetching: comparisonReport.isFetching || abandonedCampaign.isFetching || completedCampaign.isFetching || contextualCampaign.isFetching || popup.isFetching || prospectsCampaign.isFetching || comparisonReport.isFetching,
    },
    comparisonReport,
    company: { ...company.item },
  }
}

export default connect(select)(ComparisonReportTable)
