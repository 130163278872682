import React from 'react'
import { Formik, Form } from 'formik'
import { connect } from 'react-redux'
import moment from 'moment'
import {
  Button, FormGroup, Row, Col,
} from 'reactstrap'
import { FieldSingle, Label, FieldGroup } from 'lib/components'
import { tagParameterConstant as tp } from 'config/constants'

import { elasticsearchAction } from 'actions'


const extraQueryBuilder = (values) => {
  initializeExtraQuery(values)
  switch (values.campaignPolicy) {
  case 'view':
    values.behaviorType = 1
    return values
  case 'cart':
  case 'littleStockInCart':
  case 'discountInCart':
  case 'restockInCart':
  case 'register':
    values.behaviorType = 2
    return values
  case 'repeatPurchasing':
  case 'reviewRequest':
    values.behaviorType = 3
    return values
  case 'discountInFav':
  case 'littleStockInFav':
  case 'restockInFav':
    values.behaviorType = 14
    return values
  case 'firstPurchasing':
    values.register = 1
    return values
  case 'customerUnsubscribe':
    values.status = true
    return values
  default:
    return values
  }
}

const initializeExtraQuery = (values) => {
  values.behaviorType = null
  values.register = null
  values.status = null
}

/**
 * クライアントとサーバでUTCの時差があるため、クライアントサイドで日時を定義する
 * @param term
 * @returns {*}
 */
const createIndexTerm = (term) => {
  let indexTerm
  if (term === 0) {
    indexTerm = moment().format('YYYY-MM-DD')
  } else {
    indexTerm = moment().subtract(term, 'days').format('YYYY-MM-DD')
  }
  return indexTerm
}

export const TagParameterSearchConditionForm = ({ queryObj, dispatch }) => (
  <Formik
    initialValues={{
      campaignPolicy: queryObj.campaignPolicy || tp.defaultTagParameterCondition.campaignPolicy,
      term: queryObj.term || tp.defaultTagParameterCondition.term,
    }}
    onSubmit={(values) => {
      values.confirmationIndexTerm = createIndexTerm(values.term)
      extraQueryBuilder(values)
      dispatch(elasticsearchAction.searchElasticsearchData(values))
    }}
  >
    <Form noValidate className="form-horizontal">
      <Row>
        <Col md={6}>
          <FormGroup className="mb-3">
            <Label>確認する施策</Label>
            <FieldSingle
              type="select"
              name="campaignPolicy"
              options={tp.campaignPolicyOptions}
            />
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup className="mb-3">
            <Label>確認対象日</Label>
            <FieldGroup>
              <FieldSingle
                type="select"
                name="term"
                options={tp.termOptions}
              />
            </FieldGroup>
          </FormGroup>
        </Col>
      </Row>
      <div className="text-center"><Button color="primary" type="submit">検索</Button></div>
    </Form>
  </Formik>
)

export default connect()(TagParameterSearchConditionForm)
