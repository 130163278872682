import React from 'react'
import { connect } from 'react-redux'
import ContentWrapper from 'components/layout/ContentWrapper'
import { Card, CardBody } from 'reactstrap'
import { useMountEffect } from 'lib/hooks'
import ItemImportSettingEditFormWrapper from 'components/parts/itemImport/ItemImportSettingEditFormWrapper'
import { itemImportSettingAction, apiTokenAction, companyAction } from 'actions'

export const ItemImportSettingEditPage = ({dispatch}) => {
  useMountEffect(() => {
    dispatch(itemImportSettingAction.fetchItemImportSetting())
    dispatch(apiTokenAction.fetchApiTokens())
    dispatch(companyAction.fetchCompany())
  })

  return (
    <ContentWrapper>
      <div className="content-heading">
        <div>商品データ取り込み設定</div>
      </div>
      <Card className="card-default">
        <CardBody>
          <ItemImportSettingEditFormWrapper />
        </CardBody>
      </Card>
    </ContentWrapper>
  )
}

export default connect()(ItemImportSettingEditPage)
