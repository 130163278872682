import React from 'react'
import { connect } from 'react-redux'
import { Formik, Form } from 'formik'
import {
  Button, FormGroup, Col, Row,
} from 'reactstrap'
import * as Yup from 'yup'
import { FieldSingle, Label, PasswordStrengthBadge } from 'lib/components'
import * as customYupValidation from 'lib/yupValidation'
import { registeredUserAction } from 'actions'

Yup.addMethod(Yup.string, 'equalTo', customYupValidation.equalTo)

const validationSchema = Yup.object().shape({
  firstName: Yup.string().required(),
  lastName: Yup.string().required(),
  email: Yup.string().email().required(),
  password: Yup.string().min(8).required(),
  passwordConfirmation: Yup.string().equalTo(Yup.ref('password'), 'パスワードが一致していません').required(),
})

export const RegisteredUserNewForm = ({ dispatch }) => (
  <Formik
    initialValues={{
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      passwordConfirmation: '',
    }}
    validationSchema={validationSchema}
    onSubmit={(values) => {
      dispatch(registeredUserAction.submitCreateRegisteredUser(values))
    }}
  >
    {({ values }) => (
      <Form noValidate>
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label require>姓</Label>
              <FieldSingle name="lastName" type="text" placeholder="例）山田"/>
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label require>名</Label>
              <FieldSingle name="firstName" type="text" placeholder="例）太郎"/>
            </FormGroup>
          </Col>
        </Row>
        <FormGroup>
          <Label require>メールアドレス</Label>
          <FieldSingle name="email" type="text" placeholder="例）example@naviplus.co.jp"/>
        </FormGroup>
        <Row>
          <Col md={5}>
            <FormGroup>
              <Label require>パスワード</Label>
              <FieldSingle name="password" type="password"/>
            </FormGroup>
          </Col>
          <Col md={5}>
            <FormGroup>
              <Label require>パスワード（確認）</Label>
              <FieldSingle name="passwordConfirmation" type="password"/>
            </FormGroup>
          </Col>
          <Col md={2}>
            <FormGroup>
              <Label>&nbsp;</Label>
              <PasswordStrengthBadge password={values.password} minLength={8} />
            </FormGroup>
          </Col>
        </Row>
        <div className="text-center"><Button color="primary" type="submit" size="lg">保存</Button></div>
      </Form>
    )}
  </Formik>
)

export default connect()(RegisteredUserNewForm)
