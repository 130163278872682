export default {
  behaviorTypes: [
    ['閲覧', 1, 'view'],
    ['カート', 2, 'cart'],
    ['コンバージョン', 3, 'conversion'],
    ['開封', 4, 'openMail'],
    ['配信内クリック', 5, 'clickMail'],
    ['キャンペーン配信', 6, 'sendMail'],
    ['cookieとの紐付け発生', 7, 'associated'],
    ['カート変更', 8, 'setCartItem'],
    ['ポップアップ表示', 9, 'openPopup'],
    ['ポップアップ閉じる', 10, 'closePopup'],
    ['ポップアップからメールアドレス取得', 11, 'prospect'],
    ['会員登録', 12, 'register'],
    ['ポップアップから再回遊', 13, 'clickPopup'],
    ['お気に入り', 14, 'favorite'],
    ['お気に入り追加', 15, 'addFavorite'],
    ['お気に入り削除', 16, 'removeFavorite'],
    ['レビュー完了', 17, 'completeReview'],
    ['LINE連携', 18, 'linkLine'],
    ['LINE連携解除', 19, 'unlinkLine'],
  ],
}
