import React, { useState } from 'react'
import { Button } from 'reactstrap'

const ToggleButton = ({
  defaultActive = false,
  children,
  onClick,
  ...rest
}) => {
  const [ active, setActive ] = useState(defaultActive)
  return (
    <Button
      color={active ? 'info' : 'secondary'}
      onClick={(e) => {
        setActive(!active)
        if(onClick) {
          onClick(e)
        }
      }}
      {...rest}
    >
      { children }
    </Button>
  )
}

export default ToggleButton
