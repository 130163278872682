import { call, put, takeLatest } from 'redux-saga/effects'
import { adminUserAction } from 'actions'
import * as api from 'services/adminApi'

export function* handleFetchCurrentUser() {
  const { payload, meta } = yield call(api.fetchCurrentUser)
  yield put(adminUserAction.fetchCurrentUserDone(payload, meta))
}

export const adminUserSaga = [
  takeLatest(adminUserAction.fetchCurrentUser, handleFetchCurrentUser),
]
