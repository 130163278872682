import React from 'react'
import { connect } from 'react-redux'
import { Modal, ModalHeader, ModalBody } from 'reactstrap'
import { ToastContainer } from 'react-toastify'

import { useMountEffect } from 'lib/hooks'
import { Loading } from 'lib/components'
import LoginForm from 'components/parts/auth/LoginForm'
import AdminLoginForm from 'components/parts/auth/AdminLoginForm'
import Authorized from 'components/Authorized'
import { authAction, adminAuthAction } from 'actions'

/**
 * メイン処理以外に関わるComponent
 *  - 初期のローディング表示
 *  - 非認証時のモーダル表示
 */

const Accessory = ({auth, adminAuth, isAdmin, dispatch, children}) => {
  const _authAction = isAdmin ? adminAuthAction : authAction
  const _auth = isAdmin ? adminAuth : auth

  useMountEffect(() => {
    dispatch(_authAction.checkLogin())
  })

  return (
    <Loading
      isLoading={!_auth.isChecked}
      style={{ height: `${window.innerHeight}px` }}
    >
      <ToastContainer
        hideProgressBar autoClose={3000}
        style={{ width: '400px', minHeight: '50px', paddingLeft: '15px' }} />
      <Modal isOpen={_auth.isUnAuthInProgress}>
        <ModalHeader>セッションが切れています</ModalHeader>
        <ModalBody>
          { isAdmin ? <AdminLoginForm/> : <LoginForm/> }
        </ModalBody>
      </Modal>
      { _auth.isLoggedIn && <Authorized isAdmin={isAdmin}/> }
      {children}
    </Loading>
  )
}

const select = ({ auth, adminAuth }) => ({ auth, adminAuth })

export default connect(select)(Accessory)
