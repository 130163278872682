// 配信スケジュールが1日以上の場合、予測活動時間として2時間マイナスする
export const bufferTime = 2 * 60

export default {
  abandonedTypes: [
    ['ブラウザ放棄', 1, 'browser'],
    ['カゴ落ち', 2, 'cart'],
    ['カゴ落ち（後続）', 5, 'notPurchasedAfterCampaign'],
    ['ブラウザ放棄（後続）', 6, 'notViewedCartAfterCampaign'],
  ],
  completedTypes: [
    ['会員登録完了', 1, 'registerCompletion'],
    ['購入完了', 2, 'purchaseCompletion'],
    ['会員登録完了（後続）', 7, 'notRegisterCompletion'],
    ['購入完了（後続）', 8, 'notPurchaseCompletion'],
  ],
  contextualTypes: [
    ['値下げ(価格が下がった時)', 0, 'priceReduced'],
    ['在庫数(在庫数がしきい値以下になった時)', 1, 'stockReduced'],
    ['再入荷(在庫切れ商品が入荷した時)', 2, 'restock'],
    ['値下げ(後続)', 3, 'priceReducedStep'],
    ['在庫数(後続)', 4, 'stockReducedStep'],
    ['再入荷(後続)', 5, 'restockStep'],
  ],
  tagMappingAbandonedTypes: [
    { name: 'ブラウザ放棄', value: 1 },
    { name: 'カート放棄', value: 2 }
  ],
  tagMappingCompletedTypes: [
    { name: '会員登録完了', value: 1 },
    { name: '購入完了', value: 2 }
  ],
  tagMappingContextualTypes: [
    { name: '値下げ', value: 0 },
    { name: '在庫数', value: 1 },
    { name: '再入荷', value: 2 }
  ],
  messageType: [
    ['メール', 'mail'],
    ['LINE（ご利用の際はお問い合わせください）', 'line'],
  ],
  gender: [
    ['性別を問わない', 'all_gender'],
    ['男性のみ対象', 'male_only'],
    ['女性のみ対象', 'female_only'],
  ],
  customerTypes: [
    ['全ての顧客', 0],
    ['種別１', 1],
    ['種別２', 2],
    ['種別３', 3],
    ['種別４', 4],
    ['種別５', 5],
    ['種別６', 6],
    ['種別７', 7],
    ['種別８', 8],
    ['種別９', 9],
    ['種別１０', 10],
  ],
  breakTimePurchasedCustomer: [
    ['停止しない', 0],
    ['1日間送信しない', 24 * 60],
    ['2日間送信しない', 2 * 24 * 60],
    ['3日間送信しない', 3 * 24 * 60],
    ['7日間送信しない', 7 * 24 * 60],
    ['14日間送信しない', 14 * 24 * 60],
    ['30日間送信しない', 30 * 24 * 60],
    ['60日間送信しない', 60 * 24 * 60],
    ['90日間送信しない', 90 * 24 * 60],
    ['180日間送信しない', 180 * 24 * 60],
  ],
  purchaseHistories: [
    ['全ての顧客', 'any_purchased_count'],
    ['購入履歴が１回の顧客', 'purchased_once'],
    ['購入履歴が２回の顧客', 'purchased_twice'],
    ['購入履歴が２回以上の顧客', 'purchased_more_than_once'],
    ['購入履歴が３回以上の顧客', 'purchased_more_than_twice'],
  ],
  campaignTypeConditions: [
    ['全て', 'all'],
    ['放棄系キャンペーン', 'abandoned'],
    ['完了系キャンペーン', 'completed'],
    ['商品連動キャンペーン', 'contextual'],
  ],
  deliveryStatus: [
    ['全て', 'all'],
    ['配信中', 'on'],
    ['配信中以外', 'off'],
  ],
  defaultHtmlMailHeader: `<!-- この内容は自動的に出力されます -->
<!DOCTYPE html>
<html>
<head>
<title>{{件名}}</title>
<meta http-equiv="Content-Type" content="text/html; charset=utf-8">`,
}
