import { handleActions } from 'redux-actions'
import generateHandlerArgs, { preparedHandlers } from 'modules/reducerGenerator'
import { prospectsCampaignAction as action } from 'actions'

const overrideHandlers = {
  [action.submitSendProspectsCampaignTestMail]: (state, action) => ({
    ...state,
    isSendingTestMail: true,
  }),
  [action.submitSendProspectsCampaignTestMailDone]: (state, action) => ({
    ...state,
    isSendingTestMail: false,
    processResult: action.error ? action.payload : 'SUCCESS',
  }),
  [action.submitEnableProspectsCampaignDone]: preparedHandlers.submitUpdateObjectDone(true),
}

const overrideInitial = {
  isSendingTestMail: false,
}

const { handler, initial } = generateHandlerArgs(action, 'prospectsCampaign', overrideHandlers, overrideInitial)

export const prospectsCampaign = handleActions(handler, initial)
