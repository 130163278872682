import React from 'react'
import { connect } from 'react-redux'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import PopupContentForm from 'components/parts/popup/PopupContentForm'

import {
  Button, FormGroup, Input, InputGroupText, Row, Col, Card, CardBody, CardHeader, InputGroup, FormText, UncontrolledTooltip,
} from 'reactstrap'
import {
  Loading, FieldSingle, Label, FieldGroup, InputGroupAddon, HelpIcon,
} from 'lib/components'
import { popupConstant as pc } from 'config/constants'
import { popupAction } from 'actions'

const validationSchema = Yup.object().shape({
  name: Yup.string().required().max(255),
  frequencyDays: Yup.number().min(0).max(30).required(),
  frequencyHours: Yup.number().min(0).lessThan(24).required(),
  frequencyMinutes: Yup.number().min(0).lessThan(60).required(),
  delayTime: Yup.number().integer().min(0).max(5000).required(),
  pageEvent: Yup.string(),
  popupContentsAttributes: Yup.array(),
})

export const PopupForm = ({
  popup, pageResource, submitAction, item, dispatch,
}) => (
  <Loading isLoading={popup.isFetching || pageResource.isFetching}>
    <Formik
      initialValues={{
        name: item.name || '',
        frequencyDays: item.frequencyDays !== undefined ? item.frequencyDays : 1,
        frequencyHours: item.frequencyHours || 0,
        frequencyMinutes: item.frequencyMinutes || 0,
        delayTime: item.delayTime || 500,
        pageEvent: item.pageEvent || pc.defaultPageEvent,
        popupContentsAttributes: item.popupContents || pc.defaultPopupContentsAttributes,
      }}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        if (submitAction === 'update') {
          dispatch(popupAction.submitUpdatePopup({ id: item.id, ...values }))
        } else {
          dispatch(popupAction.submitCreatePopup({ ...values, enabled: false }))
        }
      }}
    >
      {({ values, setFieldValue }) => (
        <Form noValidate>
          <legend className="mb-3">ポップアップ設定</legend>
          <fieldset>
            <FormGroup>
              <Label require>ポップアップ名称</Label>
              <FieldSingle name="name" type="text" />
            </FormGroup>
            <FormGroup>
              <Label require>表示制限</Label>
              <Row>
                <Col md={8}>
                  <FieldGroup>
                    <Input type="number" name="frequencyDays" min="0" max="30" />
                    <InputGroupAddon addonType="middle">
                      <InputGroupText>日</InputGroupText>
                    </InputGroupAddon>
                    <Input type="number" name="frequencyHours" min="0" max="23" />
                    <InputGroupAddon addonType="middle">
                      <InputGroupText>時間</InputGroupText>
                    </InputGroupAddon>
                    <Input type="number" name="frequencyMinutes" min="0" max="59" />
                    <InputGroupAddon addonType="middle">
                      <InputGroupText>分</InputGroupText>
                    </InputGroupAddon>
                  </FieldGroup>
                  <FormText>
                    「日」は30日まで、「時間」は23時間まで、「分」は59分まで入力することができます。
                  </FormText>
                </Col>
                <Col md={4} style={{ padding: '0.375rem 1rem' }}>
                    の間、同一のポップアップの表示を制限する。
                </Col>
              </Row>
            </FormGroup>
            <FormGroup>
              <Label require>マウスアウト判定時間</Label>
              <Row>
                <Col md={4}>
                  <FieldGroup>
                    <Input type="number" name="delayTime" min="0" max="5000" />
                    <InputGroupAddon addonType="append">
                      <InputGroupText>msec</InputGroupText>
                    </InputGroupAddon>
                  </FieldGroup>
                  <FormText>
                    5,000 msec まで入力することができます。
                  </FormText>
                </Col>
                <Col md={8} style={{ padding: '0.375rem 1rem' }}>
                    の間をポップアップ表示までの猶予時間として扱います。
                  <HelpIcon id="delayTime" className="mr-2" />
                  <UncontrolledTooltip placement="top" target="delayTime">
                    ユーザがブラウザからマウスアウトしても、この時間が経過する前にブラウザに戻ってきた場合はポップアップが表示されません。<br />
                    マウスの誤操作によるポップアップ表示を防止するための機能です。
                  </UncontrolledTooltip>
                </Col>
              </Row>
            </FormGroup>
          </fieldset>
          <legend className="mb-0">表示タイミング設定</legend>
          <FormText className="mb-0" tag="span">ポップアップの表示条件と表⽰タイミングを設定することができます。</FormText>
          <div className="mb-3">
            <span
              className="link-like"
              id="displayTooltip"
            >
              表示条件について
            </span>
            <span
              className="link-like ml-2"
              id="groupTooltip"
            >
              条件グループについて
            </span>
          </div>
          <fieldset>
            <PopupConditionForm
              pageEvent={values.pageEvent}
              pageResources={pageResource.items}
              setFormCondition={(condition) => { setFieldValue('pageEvent', condition) }}
            />
          </fieldset>
          <legend className="mb-3">ポップアップ内容編集</legend>
          <fieldset>
            <PopupContentForm
              contents={values.popupContentsAttributes}
              setFormContents={(contents) => { setFieldValue('popupContentsAttributes', contents) }}
            />
          </fieldset>
          <div className="text-center">
            <Button color="primary" type="submit" size="lg">保存</Button>
          </div>
        </Form>
      )}
    </Formik>
    <Tooltips />
  </Loading>
)


const defaultCondition = { resourceId: 3, parameter: 0, condition: 'less_than' }

const PopupConditionForm = ({
  pageEvent, pageResources, setFormCondition,
}) => {
  const condition = convertFromPageEventValue(pageEvent)

  function convertAndSetFormCondition(condition) {
    setFormCondition(convertToPageEventValue(condition))
  }

  function convertFromPageEventValue(pageEventStringValue) {
    const pageEventValue = JSON.parse(pageEventStringValue)
    let newCondition = []
    for (let andRule of pageEventValue.ruleGroups.rules) {
      let convertedAndRule = []
      for (let orRule of andRule.rules) {
        convertedAndRule.push({
          resourceId: orRule.resourceId,
          condition: orRule.condition,
          parameter: orRule.parameter[0],
        })
      }
      newCondition.push(convertedAndRule)
    }
    return newCondition
  }

  function convertToPageEventValue(condition) {
    let pageEventValue = { ruleGroups: { chainType: 'waitAll', rules: [] } }
    for (let andCon of condition) {
      let convertedAndCon = { chainType: 'any', rules: [] }
      for (let orCon of andCon) {
        convertedAndCon.rules.push({
          resourceId: Number(orCon.resourceId),
          condition: orCon.condition,
          parameter: [orCon.parameter],
        })
      }
      pageEventValue.ruleGroups.rules.push(convertedAndCon)
    }
    return JSON.stringify(pageEventValue)
  }

  function fetchPageResource(pageResourceId) {
    return pageResources.filter(pr => (Number(pr.id) === Number(pageResourceId)))[0] || {}
  }

  function changeCondition(andIndex, orIndex, key, value) {
    let cloneCondition = JSON.parse(JSON.stringify(condition))
    const beforeConditionValue = cloneCondition[andIndex][orIndex][key]
    cloneCondition[andIndex][orIndex][key] = value
    if (key === 'resourceId' && beforeConditionValue !== value) {
      const resourceType = fetchPageResource(value).resourceType
      if (resourceType === 'event_count' || resourceType === 'number') { cloneCondition[andIndex][orIndex].condition = pc.numberConditions[0][1] }
      if (resourceType === 'string') { cloneCondition[andIndex][orIndex].condition = pc.stringConditions[0][1] }
    }
    convertAndSetFormCondition(cloneCondition)
  }

  function addAndCondition() {
    let cloneCondition = JSON.parse(JSON.stringify(condition))
    cloneCondition.push([defaultCondition])
    convertAndSetFormCondition(cloneCondition)
  }

  function addOrCondition(andIndex) {
    let cloneCondition = JSON.parse(JSON.stringify(condition))
    cloneCondition[andIndex].push(defaultCondition)
    convertAndSetFormCondition(cloneCondition)
  }

  function removeAndCondition(andIndex) {
    let cloneCondition = JSON.parse(JSON.stringify(condition))
    cloneCondition = cloneCondition.filter((c, index) => (index !== andIndex))
    convertAndSetFormCondition(cloneCondition)
  }

  function removeOrCondition(andIndex, orIndex) {
    let cloneCondition = JSON.parse(JSON.stringify(condition))
    cloneCondition[andIndex] = cloneCondition[andIndex].filter((c, index) => (index !== orIndex))
    convertAndSetFormCondition(cloneCondition)
  }

  return (
    <div className="mb-5">
      { condition.map((andConditions, andIndex) => (
        <Card key={`resourceId_${andIndex}`} className="card-default">
          <CardHeader>
            イベント発生条件
            { condition.length > 1
              && (
                <div className="card-tool float-right">
                  <em onClick={() => { removeAndCondition(andIndex) }} className="fa fa-times" />
                </div>
              )
            }
          </CardHeader>
          <CardBody>
            { andConditions.map((orCoditions, orIndex) => (
              <Card key={`resourceId_${andIndex}_${orIndex}`} className="card-default">
                <CardHeader>
                  条件グループ
                  {orIndex + 1}
                  { condition[andIndex].length > 1
                    && (
                      <div className="card-tool float-right">
                        <em onClick={() => { removeOrCondition(andIndex, orIndex) }} className="fa fa-times" />
                      </div>
                    )
                  }
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col md={5}>
                      <InputGroup>
                        <Input
                          type="select"
                          name={`resourceId_${andIndex}_${orIndex}`}
                          value={condition[andIndex][orIndex].resourceId}
                          onChange={(e) => { changeCondition(andIndex, orIndex, 'resourceId', e.target.value) }}
                        >
                          { pageResources.map(pr => (
                            <option key={`pageResource_${pr.id}`} value={pr.id}>{pr.name}</option>
                          ))}
                        </Input>
                      </InputGroup>
                    </Col>
                    { fetchPageResource(condition[andIndex][orIndex].resourceId).resourceType === 'event_count'
                      && (
                        <>
                          <Col md={5}>
                            <InputGroup>
                              <Input
                                type="number"
                                name={`parameter_${andIndex}_${orIndex}`}
                                value={condition[andIndex][orIndex].parameter}
                                onChange={(e) => { changeCondition(andIndex, orIndex, 'parameter', Number(e.target.value)) }}
                              />
                              <InputGroupAddon addonType="append">
                                <InputGroupText>回</InputGroupText>
                              </InputGroupAddon>
                            </InputGroup>
                          </Col>
                          <Col md={2}>
                            <InputGroup>
                              <Input
                                type="select"
                                name={`parameter_${andIndex}_${orIndex}`}
                                value={condition[andIndex][orIndex].condition}
                                onChange={(e) => { changeCondition(andIndex, orIndex, 'condition', e.target.value) }}
                              >
                                { pc.numberConditions.map(c => (
                                  <option key={`condition_${c[1]}`} value={c[1]}>{c[0]}</option>
                                ))}
                              </Input>
                            </InputGroup>
                          </Col>
                        </>
                      )
                    }
                    { fetchPageResource(condition[andIndex][orIndex].resourceId).resourceType === 'number'
                      && (
                        <>
                          <Col md={5}>
                            <InputGroup>
                              <Input
                                type="number"
                                name={`parameter_${andIndex}_${orIndex}`}
                                value={condition[andIndex][orIndex].parameter}
                                onChange={(e) => { changeCondition(andIndex, orIndex, 'parameter', Number(e.target.value)) }}
                              />
                            </InputGroup>
                          </Col>
                          <Col md={2}>
                            <InputGroup>
                              <Input
                                type="select"
                                name={`parameter_${andIndex}_${orIndex}`}
                                value={condition[andIndex][orIndex].condition}
                                onChange={(e) => { changeCondition(andIndex, orIndex, 'condition', e.target.value) }}
                              >
                                { pc.numberConditions.map(c => (
                                  <option key={`condition_${c[1]}`} value={c[1]}>{c[0]}</option>
                                ))}
                              </Input>
                            </InputGroup>
                          </Col>
                        </>
                      )
                    }
                    { fetchPageResource(condition[andIndex][orIndex].resourceId).resourceType === 'string'
                      && (
                        <>
                          <Col md={5}>
                            <InputGroup>
                              <Input
                                type="text"
                                name={`parameter_${andIndex}_${orIndex}`}
                                value={condition[andIndex][orIndex].parameter}
                                onChange={(e) => { changeCondition(andIndex, orIndex, 'parameter', e.target.value) }}
                              />
                            </InputGroup>
                          </Col>
                          <Col md={2}>
                            <InputGroup>
                              <Input
                                type="select"
                                name={`parameter_${andIndex}_${orIndex}`}
                                value={condition[andIndex][orIndex].condition}
                                onChange={(e) => { changeCondition(andIndex, orIndex, 'condition', e.target.value) }}
                              >
                                { pc.stringConditions.map(c => (
                                  <option key={`condition_${c[1]}`} value={c[1]}>{c[0]}</option>
                                ))}
                              </Input>
                            </InputGroup>
                          </Col>
                        </>
                      )
                    }
                  </Row>
                </CardBody>
              </Card>
            ))}
            <div className="text-center"><Button onClick={() => { addOrCondition(andIndex) }}>OR条件を追加</Button></div>
          </CardBody>
        </Card>
      ))}
      <div className="text-center"><Button onClick={() => { addAndCondition() }}>AND条件を追加</Button></div>
    </div>
  )
}

const Tooltips = () => (
  <>
    <UncontrolledTooltip target="displayTooltip" placement="right">
      <img src="/img/popup_display_tooltip.png" alt="popup_tooltip" />
    </UncontrolledTooltip>
    <UncontrolledTooltip target="groupTooltip" placement="right">
      <img src="/img/popup_group_tooltip.png" alt="group_tooltip" />
    </UncontrolledTooltip>
  </>
)

const select = ({ popup, pageResource }, { popupId, submitAction }) => {
  let item
  let popupContentOverride
  if (popupId) {
    popupContentOverride = popup.items.filter(obj => (obj.id === popupId))[0] || {}
  }
  item = { ...popupContentOverride }
  return {
    popup, pageResource, item, submitAction,
  }
}

export default connect(select)(PopupForm)
