import React from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import { Table, Button } from 'reactstrap'
import { Loading, Swal } from 'lib/components'
import { generalConstant as gc } from 'config/constants'
import { apiTokenAction } from 'actions'

export const ApiTokenList = ({ apiToken, company, dispatch }) => {
  const items = apiToken.items
  return (
    <>
      <h4>ヘッダー情報</h4>
      <Loading isLoading={apiToken.isFetching || company.isFetching}>
        <Table responsive>
          <thead>
            <tr style={{ wordBreak: 'keep-all' }}>
              <th>種別</th>
              <th>Header名</th>
              <th>値</th>
              <th>作成日時</th>
              <th>&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>シリアル番号</td>
              <td>X-Rtg-Serial</td>
              <td>{company.item.serial}</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
            </tr>
            { items.map(item => (
              <tr key={item.id}>
                <td>APIトークン</td>
                <td>X-Rtg-Token</td>
                <td>{item.token}</td>
                <td>{moment(item.createdAt).format('YYYY/MM/DD HH:mm')}</td>
                <td>
                  <Swal
                    options={gc.deleteSwalOptions}
                    color="danger"
                    callback={(isConfirm) => { isConfirm && dispatch(apiTokenAction.submitDeleteApiToken({ id: item.id })) }}
                  >
    削除
                  </Swal>
                </td>
              </tr>
            )) }
          </tbody>
        </Table>
        <div className="text-center mt-3">
          <Button
            color="primary"
            size="lg"
            onClick={(e) => { dispatch(apiTokenAction.submitCreateApiToken({ enabled: true })) }}
          >
    APIトークンを追加
          </Button>
        </div>
      </Loading>
    </>
  )
}

const select = ({ apiToken, company }) => ({ apiToken, company })

export default connect(select)(ApiTokenList)
