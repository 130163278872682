import { takeLatest, call, put } from 'redux-saga/effects'
import * as api from 'services/api'
import { mailPreviewAction } from 'actions'

function* handleSubmitCreateMailPreview(action) {
  const targetApi = action.payload._type === 'template'
    ? api.submitCreateTemplatePreview : api.submitCreateMailPreview
  let { payload, meta } = yield call(targetApi, action.payload)
  yield put(mailPreviewAction.submitCreateMailPreviewDone(payload, meta))
}

export const mailPreviewSaga = [
  takeLatest(mailPreviewAction.submitCreateMailPreview, handleSubmitCreateMailPreview),
]
