import { createActions } from 'redux-actions'

const FETCH_COMPANY_SYSTEM_ORDER = 'FETCH_COMPANY_SYSTEM_ORDER'
const FETCH_COMPANY_SYSTEM_ORDER_DONE = 'FETCH_COMPANY_SYSTEM_ORDER_DONE'

export const adminCompanySystemOrderAction = createActions(
  {
    [FETCH_COMPANY_SYSTEM_ORDER_DONE]: [
      (...args) => args[0],
      (...args) => args[1],
    ],
  },
  FETCH_COMPANY_SYSTEM_ORDER,
  {
    prefix: 'ADMIN',
  },
)
