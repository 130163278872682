import { takeLatest, call, put } from 'redux-saga/effects'
import * as api from 'services/api'
import { mailAbTestCampaignMapAction } from 'actions'

function* handleFetchMailAbTestCampaignMap(action) {
  const { payload, meta } = yield call(api.fetchMailAbTestCampaignMaps, action.payload)
  yield put(mailAbTestCampaignMapAction.fetchMailAbTestCampaignMapsDone(payload, meta))
}

export const mailAbTestCampaignMapSaga = [
  takeLatest(mailAbTestCampaignMapAction.fetchMailAbTestCampaignMaps, handleFetchMailAbTestCampaignMap),
]
