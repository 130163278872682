import React from 'react'
import { connect } from 'react-redux'
import ContentWrapper from 'components/layout/ContentWrapper'
import {
  Row, Col, Card, Button,
} from 'reactstrap'
import { deserializeFromQueryString } from 'lib/utils'
import { useMountEffect } from 'lib/hooks'
import { generalConstant as gc } from 'config/constants'
import AdminCampaignTemplateList from 'components/parts/campaignTemplate/AdminCampaignTemplateList'
import { adminCampaignTemplateAction } from 'actions'
import { Link } from 'react-router-dom'

export const AdminCampaignTemplateListPage = ({location, dispatch}) => {
  useMountEffect(() => {
    dispatch(adminCampaignTemplateAction.fetchCampaignTemplates())
  })

  const queryObj = deserializeFromQueryString(location.search) || {}
  const page = Number(queryObj.page) || 1

  return (
    <ContentWrapper>
      <div className="content-heading">
        <div>メールテンプレート一覧</div>
        <div className="ml-3">
          <Button tag={Link} to="/admin/campaign_template_new" color="secondary">新規作成</Button>
        </div>
      </div>
      <Row>
        <Col xl={9}>
          <Card className="card-default">
            <AdminCampaignTemplateList
              limit={gc.adminTemplateTagItemsLimit}
              offset={(page - 1) * gc.adminTemplateTagItemsLimit}
            />
          </Card>
        </Col>
      </Row>
    </ContentWrapper>
  )
}

export default connect()(AdminCampaignTemplateListPage)
