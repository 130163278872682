import { createActions } from 'redux-actions'

const FETCH_ELASTICSEARCH_BEHAVIORS = 'FETCH_ELASTICSEARCH_BEHAVIORS'
const FETCH_ELASTICSEARCH_BEHAVIORS_DONE = 'FETCH_ELASTICSEARCH_BEHAVIORS_DONE'

export const elasticsearchBehaviorsAction = createActions(
  {
    [FETCH_ELASTICSEARCH_BEHAVIORS_DONE]: [
      (...args) => args[0],
      (...args) => args[1],
    ],
  },
  FETCH_ELASTICSEARCH_BEHAVIORS,
)
