import { takeLatest, call, put } from 'redux-saga/effects'
import * as api from 'services/api'
import { mailSenderInfoAction } from 'actions'

function* handleFetchMailSenderInfo() {
  const { payload, meta } = yield call(api.fetchMailSenderInfo)
  yield put(mailSenderInfoAction.fetchMailSenderInfoDone(payload, meta))
}

function* handleSubmitUpdateMailSenderInfo(action) {
  const { payload, meta, error } = yield call(api.submitUpdateMailSenderInfo, action.payload)
  yield put(mailSenderInfoAction.submitUpdateMailSenderInfoDone(payload, meta))
  if (!error) yield put(mailSenderInfoAction.fetchMailSenderInfo())
}

export const mailSenderInfoSaga = [
  takeLatest(mailSenderInfoAction.fetchMailSenderInfo, handleFetchMailSenderInfo),
  takeLatest(mailSenderInfoAction.submitUpdateMailSenderInfo, handleSubmitUpdateMailSenderInfo),
]
