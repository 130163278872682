import React from 'react'
import {
  Card, CardBody, Col, Row,
} from 'reactstrap'
import ContentWrapper from 'components/layout/ContentWrapper'
import { connect } from 'react-redux'
import { useMountEffect } from 'lib/hooks'
import { deserializeFromQueryString } from 'lib/utils'
import { adminCompanyUnitPriceAction } from 'actions'
import UnitPriceForm from 'components/parts/unitPrice/UnitPriceForm'

export const AdminCompanyUnitPriceNewPage = ({match, location, dispatch}) => {
  const companyId = Number(match.params.companyId)

  const queryObj = deserializeFromQueryString(location.search) || {}
  const originalUnitPriceId = 'original' in queryObj && Number(queryObj.original)

  useMountEffect(() => {
    if(originalUnitPriceId){
      dispatch(adminCompanyUnitPriceAction.fetchCompanyUnitPrice({ companyId, id: originalUnitPriceId}))
    }
  })

  return (
    <ContentWrapper>
      <div className="content-heading">
        <div>
          価格設定作成
        </div>
      </div>
      <Row>
        <Col xl={12}>
          <Card className="card-default">
            <CardBody>
              <UnitPriceForm
                submitAction="create"
                companyId={companyId}
                unitPriceId={originalUnitPriceId}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </ContentWrapper>
  )
}

export default connect()(AdminCompanyUnitPriceNewPage)
