import { createActions } from 'redux-actions'

const FETCH_MAIL_AB_TEST_CAMPAIGN_MAPS = 'FETCH_MAIL_AB_TEST_CAMPAIGN_MAPS'
const FETCH_MAIL_AB_TEST_CAMPAIGN_MAPS_DONE = 'FETCH_MAIL_AB_TEST_CAMPAIGN_MAPS_DONE'

const CLEAR_MAIL_AB_TEST_CAMPAIGN_MAPS = 'CLEAR_MAIL_AB_TEST_CAMPAIGN_MAPS'

export const mailAbTestCampaignMapAction = createActions(
  {
    [FETCH_MAIL_AB_TEST_CAMPAIGN_MAPS_DONE]: [
      (...args) => args[0],
      (...args) => args[1],
    ],
  },

  FETCH_MAIL_AB_TEST_CAMPAIGN_MAPS,
  CLEAR_MAIL_AB_TEST_CAMPAIGN_MAPS,

)
