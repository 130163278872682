import React, { Fragment } from 'react'

const TestAccountBadge = ({ testAccount }) => {
  if (!testAccount) {
    return <Fragment />
  }
  return (
    <span className="badge badge-warning">
      テスト
    </span>
  )
}


export default TestAccountBadge
