import { createActions } from 'redux-actions'

const FETCH_CONNECTION_TEST = 'FETCH_CONNECTION_TEST'
const FETCH_CONNECTION_TEST_DONE = 'FETCH_CONNECTION_TEST_DONE'

const SUBMIT_CREATE_CONNECTION_TEST = 'SUBMIT_CREATE_CONNECTION_TEST'
const SUBMIT_CREATE_CONNECTION_TEST_DONE = 'SUBMIT_CREATE_CONNECTION_TEST_DONE'

export const connectionTestAction = createActions(
  {
    [SUBMIT_CREATE_CONNECTION_TEST_DONE]: [
      (...args) => args[0],
      (...args) => Object.assign({ notification: 'FAILURE' }, args[1]),
    ],
  },
  FETCH_CONNECTION_TEST,
  FETCH_CONNECTION_TEST_DONE,
  SUBMIT_CREATE_CONNECTION_TEST,

)
