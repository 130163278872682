import { handleActions } from 'redux-actions'
import { subscriptionTrackingSettingsAction as action } from 'actions'

const initial = {
  enabled: false,
  htmlContent: '',
  plainContent: '',
  landing: '',
  url: '',
  isFetching: false,
  isSubmitting: false,
  error: undefined,
}

export const subscriptionTrackingSettings = handleActions({
  [action.fetchSubscriptionTrackingSettings]: (state) => ({
    ...state,
    isFetching: true,
  }),
  [action.fetchSubscriptionTrackingSettingsDone]: (state, action) => ({
    ...state,
    ...action.payload,
    isFetching: false,
  }),
  [action.updateSubscriptionTrackingSettings]: (state) => ({
    ...state,
    isSubmitting: true,
  }),
  [action.updateSubscriptionTrackingSettingsSuccess]: (state) => ({
    ...state,
    isSubmitting: false,
  }),
  [action.updateSubscriptionTrackingSettingsFailure]: (state) => ({
    ...state,
    isSubmitting: false,
  }),
}, initial)
