import React from 'react'
import { connect } from 'react-redux'
import { Row, Col, Button, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap'
import { Loading, SwalDropdownItem } from 'lib/components'
import { diffObjectAttributes } from 'lib/utils'
import { Link } from 'react-router-dom'
import { push } from 'connected-react-router'
import { generalConstant as gc } from 'config/constants'
import { adminCompanyUnitPriceAction } from 'actions'

export const UnitPriceList = ({ adminCompanyUnitPrice, dispatch }) => {
  const unitPrices = adminCompanyUnitPrice.items
    .filter(e => !!e)
    .map((unitPrice, i) => {
      const nextUnitPrice = adminCompanyUnitPrice.items[i + 1]
      return {
        ...unitPrice,
        diffs: diffObjectAttributes(unitPrice, nextUnitPrice, ['id', 'startDate', 'endDate'])
      }
    })
    .sort((a,b) => (a.startDate < b.startDate ? 1 : -1))

  return (
    <Loading isLoading={adminCompanyUnitPrice.isFetching}>
      { unitPrices.map((unitPrice, index) => (
        <div key={unitPrice.id} className='unit-price mb-4'>
          <div className="lead bb mb-3 clearfix"> {unitPrice.startDate} 〜
            <div style={{float: 'right'}} className='mb-1'>
              <DropdownActionButton
                unitPrice={unitPrice}
                dispatch={dispatch}
                disableDelete={unitPrices.length <= 1}
              />
            </div>
          </div>
          { unitPrice.diffs.length > 0 &&
            <h5>
              ※全設定からの変更箇所：
              { unitPrice.diffs.map(diff => gc.unitPrice[diff]).join() }
            </h5>
          }
          { unitPrice.chargeSystem === 'pay_per_click' ?
            <>
              <Row className='mb-2'>
                <Col>課金方式: クリック課金</Col>
              </Row>
              <Row className='mb-2'>
                <Col xs={3}>カゴ落ちCPC： {unitPrice.cartCampaignCpc}</Col>
                <Col xs={3}>ブラウザ放棄CPC ： {unitPrice.browserCampaignCpc}</Col>
                <Col xs={3}>会員登録完了CPC ： {unitPrice.registeredCampaignCpc}</Col>
                <Col xs={3}>購入完了CPC： {unitPrice.purchasedCampaignCpc}</Col>
              </Row>
              <Row>
                <Col xs={3}>ポップアップCPC： {unitPrice.popupCampaignCpc}</Col>
                <Col xs={3}>ポップアップメールCPC： {unitPrice.prospectCampaignCpc}</Col>
                <Col xs={3}>商品連動CPC： {unitPrice.contextualCampaignCpc}</Col>
              </Row>
            </>
            :
            <>
              <Row className='mb-2'>
                <Col>課金方式: 成果報酬課金</Col>
              </Row>
              <Row className='mb-2'>
                <Col>成果報酬率： {unitPrice.rewardRate}</Col>
              </Row>
            </>
          }
        </div>
      ))}
      { unitPrices.length === 0 &&
        <div className='m-3'>
          <h3>価格設定が存在しません</h3>
        </div>
      }
    </Loading>
  )
}

const DropdownActionButton = ({unitPrice, dispatch, disableDelete}) => {
  const unitPriceEditPath = `/admin/companies/${unitPrice.companyId}/unit_price_edit/${unitPrice.id}`
  const unitPriceNewPath = `/admin/companies/${unitPrice.companyId}/unit_price_new`
  const handlePushCopyPage = () => {
    dispatch(push(`${unitPriceNewPath}?original=${unitPrice.id}`))
  }
  const deleteCallback = (isConfirm) => {
    isConfirm && dispatch(
      adminCompanyUnitPriceAction.submitDeleteCompanyInvoiceTerm(
        { id: unitPrice.id, companyId: unitPrice.companyId }
      )
    )
  }

  return (
    <UncontrolledDropdown className="split-dropdown">
      <Button
        tag={Link}
        to={unitPriceEditPath}
        color="secondary"
      > 編集
      </Button>
      <DropdownToggle caret color="secondary" />
      <DropdownMenu>
        <DropdownItem onClick={handlePushCopyPage}>
          コピーして新規作成
        </DropdownItem>
        { !disableDelete &&
          <SwalDropdownItem
            options={gc.deleteSwalOptions}
            callback={deleteCallback}
          >削除
          </SwalDropdownItem>
        }
      </DropdownMenu>
    </UncontrolledDropdown>
  )
}

const select = ({adminCompanyUnitPrice}) => ({ adminCompanyUnitPrice })

export default connect(select)(UnitPriceList)
