import { call, put, takeLatest } from 'redux-saga/effects'
import * as api from 'services/adminApi'
import { adminCompanyAction } from 'actions'

export function* handleFetchCompanies() {
  const { payload, meta } = yield call(api.fetchCompanies)
  yield put(adminCompanyAction.fetchCompaniesDone(payload, meta))
}

export function* handleSubmitUpdateCompany(action) {
  const { payload, meta } = yield call(api.submitUpdateCompany, action.payload)
  yield put(adminCompanyAction.submitUpdateCompanyDone(payload, meta))
}

export const adminCompanySaga = [
  takeLatest(adminCompanyAction.fetchCompanies, handleFetchCompanies),
  takeLatest(adminCompanyAction.submitUpdateCompany, handleSubmitUpdateCompany),
]
