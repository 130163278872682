import React, { useState } from 'react'
import { connect } from 'react-redux'
import { UnControlled as CodeMirror } from 'react-codemirror2'
import swal from 'sweetalert'

import {
  Button, FormGroup, Input, Row, Col, Card, CardBody, CardHeader, CardFooter, InputGroup, Pagination, PaginationItem, PaginationLink,
} from 'reactstrap'
import { Label, Swal } from 'lib/components'
import { popupConstant as pc, generalConstant as gc } from 'config/constants'

function PopupContentForm({ contents, setFormContents }) {
  const [activeIndex, setActiveIndex] = useState(0)
  const existsContents = contents.filter(content => !Object.prototype.hasOwnProperty.call(content, '_destroy'))
  const deletedContents = contents.filter(content => Object.prototype.hasOwnProperty.call(content, '_destroy'))

  function addPageIndex() {
    let cloneContents = JSON.parse(JSON.stringify(contents))
    cloneContents.push({
      ...pc.defaultCenterPopupContent,
      javascript: '',
      area: 'modal',
    })
    setFormContents(cloneContents)
  }

  function removePageIndex() {
    let cloneContents = JSON.parse(JSON.stringify(existsContents))
    cloneContents = cloneContents.map((content, index) => {
      if (index === activeIndex) {
        content = {
          id: content.id,
          _destroy: true,
        }
      }
      return content
    }).concat(deletedContents)
    const afterPageIndex = activeIndex === 0 ? 0 : activeIndex - 1
    setActiveIndex(afterPageIndex)
    setFormContents(cloneContents)
  }

  function changeContent(index, key, value) {
    let cloneContents = JSON.parse(JSON.stringify(existsContents))
    cloneContents[index][key] = value
    if (key === 'area') {
      cloneContents[index].htmlBody = value === 'modal' ? pc.defaultCenterPopupContent.htmlBody : pc.defaultBottomPopupContent.htmlBody
      cloneContents[index].style = value === 'modal' ? pc.defaultCenterPopupContent.style : pc.defaultBottomPopupContent.style
    }
    cloneContents = cloneContents.concat(deletedContents)
    setFormContents(cloneContents)
  }

  function previewSource() {
    return `<html>
      <head>
        <style type="text/css">${existsContents[activeIndex].style}</style>
      </head>
      <body>
        ${existsContents[activeIndex].htmlBody}
      </body>
    </html>`
  }

  return (
    <Card className="card-default">
      <CardHeader>
        {activeIndex + 1}/{existsContents.length}ページ目
        <div className="card-tool float-right">
          <Button className="mr-3" color="primary" onClick={addPageIndex}>ページを追加する</Button>
          <Swal
            options={{
              ...gc.deleteSwalOptions,
              text: '',
            }}
            color="danger"
            disabled={existsContents.length <= 1}
            callback={(isConfirm) => { isConfirm && removePageIndex() }}
          > ページを削除する
          </Swal>
        </div>
      </CardHeader>
      <CardBody>
        { existsContents.map((content, index) => (
          <div className={activeIndex === index ? '' : 'disappearance'} key={`popupContent_${index}`}>
            <FormGroup>
              <Label>ポップアップ表示位置</Label>
              <InputGroup className="mb-3">
                <Input
                  type="select"
                  name={`area_${index}`}
                  value={content.area}
                  onChange={(e) => {
                    const value = e.target.value
                    swal({
                      ...gc.deleteSwalOptions,
                      title: '本当に変更しますか？',
                      text: '表示位置を変更すると、設定中のHTML/CSSの内容がリセットされます。よろしいですか？',
                      buttons: ['キャンセル', '変更'],
                    }).then((isConfirm) => { isConfirm && changeContent(index, 'area', value) })
                  }}
                >
                  { pc.popupPositions.map(c => (
                    <option key={`area_${c[1]}`} value={c[1]}>{c[0]}</option>
                  ))}
                </Input>
              </InputGroup>
            </FormGroup>
            <Row>
              <Col md={7}>
                <FormGroup>
                  <Label>HTML</Label>
                  <InputGroup>
                    <CodeMirror
                      options={{
                        lineWrapping: true,
                        autoRefresh: true,
                        lineNumbers: true,
                        mode: 'htmlmixed',
                        extraKeys: { Tab: 'autocomplete', 'Ctrl-Space': 'autocomplete' },
                      }}
                      autoScroll={false}
                      autoCursor={false}
                      onChange={(_, data, value) => { if (data.origin) { changeContent(index, 'htmlBody', value) } }}
                      value={content.htmlBody}
                    />
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                  <Label>CSS</Label>
                  <InputGroup>
                    <CodeMirror
                      options={{
                        lineWrapping: true,
                        autoRefresh: true,
                        lineNumbers: true,
                        mode: 'css',
                        extraKeys: { Tab: 'autocomplete', 'Ctrl-Space': 'autocomplete' },
                      }}
                      autoScroll={false}
                      autoCursor={false}
                      onChange={(_, data, value) => { if (data.origin) { changeContent(index, 'style', value) } }}
                      value={content.style}
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col md={5}>
                <div className="pc-preview">
                  <iframe
                    title="MailPreview"
                    frameBorder="0"
                    sandbox="allow-scripts"
                    srcDoc={previewSource()}
                  />
                </div>
                <p className="mt-1 mb-0">
                  <small>
                    ※配信されるポップアップとは表示が異なる場合があります。
                    <br />
                    &nbsp;&nbsp;お使いのブラウザで実際のポップアップ表示を確認することをおすすめします。
                  </small>
                </p>
              </Col>
            </Row>
          </div>
        ))}
      </CardBody>
      <CardFooter>
        <Pagination>
          { existsContents.map((_, index) => (
            <PaginationItem active={index === activeIndex} key={`popupContentPage_${index}`}>
              <PaginationLink href="#" onClick={() => { setActiveIndex(index) }}>
                {index + 1}
              </PaginationLink>
            </PaginationItem>
          ))}
        </Pagination>
      </CardFooter>
    </Card>
  )
}

export default connect()(PopupContentForm)
