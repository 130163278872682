import React, { useState } from 'react'
import { connect } from 'react-redux'
import { FormGroup, Label, Button } from 'reactstrap'
import { FieldSingle, CustomSelect, Loading } from 'lib/components'
import { Form, Formik } from 'formik'
import { adminCompanyAction } from 'actions'
import { uniq } from 'lib/utils'

const CompanyEditForm = ({ company, adminCompany, adminRecoAccount, adminCompanyTrackingOption, dispatch, _isLoading }) => {
  const parentCompanyOptions = adminCompany.items
    .filter(x => !x.testAccount && x.id !== company.id)
    .map(x => [`${x.domain}: ${x.name}`, x.id])
  const recoClusterOptions = uniq(adminRecoAccount.items, x => x.cluster)
    .map(x => [x.cluster, x.cluster])

  const initialRecoClusterVal = company.recoAccountId
    ? adminRecoAccount.items.find(x => x.id === company.recoAccountId).cluster
    : undefined
  const [recoCluster, setRecoCluster] = useState(initialRecoClusterVal)

  const recoAccountOptions = adminRecoAccount.items
    .filter(x => !recoCluster || x.cluster === recoCluster)
    .map(x => [x.domain, x.id])

  return (
    <Loading isLoading={adminCompany.isFetching || adminRecoAccount.isFetching}>
      <Formik
        initialValues={{
          testAccount: company.testAccount,
          parentCompanyId: company.parentCompanyId || undefined,
          recoAccountId: company.recoAccountId || undefined,
          writeBehaviors: company.writeBehaviors,
          positiveItemUpdate: company.positiveItemUpdate,
          billable: company.billable,
          recoOnly: company.recoOnly,
          searchOnly: company.searchOnly,
          reviewOnly: company.reviewOnly,
          underIntroduction: company.underIntroduction,
        }}
        onSubmit={((values) => {
          dispatch(adminCompanyAction.submitUpdateCompany({
            ...values,
            companyId: company.id,
          }))
        })}
      >
        { ({ values }) => (
          <Form noValidate>
            <FormGroup check>
              <FieldSingle type='checkbox' name='testAccount' option='テストアカウントに設定'/>
            </FormGroup>
            { !!values.testAccount &&
              <FieldSingle
                type='select'
                name='parentCompanyId'
                blank={true}
                options={parentCompanyOptions}
              />
            }
            <CustomSelect
              type='select'
              name='_cluster'
              value={recoCluster}
              onChange={e => setRecoCluster(e.target.value)}
              options={recoClusterOptions}
              blank={true}
            />
            <FieldSingle
              type='select'
              name='recoAccountId'
              options={recoAccountOptions}
              blank={true}
            />
            <FieldSingle type="checkbox" name="writeBehaviors" />{' '}
            行動履歴をデータベースに書き込む
            <span>行動履歴の書込を停止するとリタゲメールの機能が使えなくなります。他サービス連携でリタゲメールを使わない場合のみチェックを外して下さい。</span>
            <FieldSingle type="checkbox" name="positiveItemUpdate" />{' '}
            リタゲメールからのリクエストでのみ商品情報を更新する
            <span>外部からGETで取得できない箇所（会員制ECサイト、カートページ等）で商品情報を送信している場合はチェックを外して下さい。</span>
            <FormGroup check>
              <Label check>
                <FieldSingle type="checkbox" name="billable" />
                請求対象アカウント
              </Label>
              <Label check>
                <FieldSingle type="checkbox" name="recoOnly" />{' '}
                Recommend用アカウント
              </Label>
              <Label check>
                <FieldSingle type="checkbox" name="searchOnly" />{' '}
                Search用アカウント
              </Label>
              <Label check>
                <FieldSingle type="checkbox" name="reviewOnly" />{' '}
                Review用アカウント
              </Label>
              <Label check>
                <FieldSingle type="checkbox" name="underIntroduction" />{' '}
                導入作業中
              </Label>
            </FormGroup>
            <Button
              color="primary"
              type="submit"
              size="lg"
            >
              更新
            </Button>
          </Form>
        )}
      </Formik>
    </Loading>
  )
}

const select = ({ adminCompany, adminRecoAccount }, { companyId }) => ({
  company: adminCompany.items.find(x => x.id === companyId) || {},
  adminCompany,
  adminRecoAccount,
})

export default connect(select)(CompanyEditForm)
