import { takeLatest, call, put } from 'redux-saga/effects'
import * as api from 'services/api'
import { limitDeliverySettingAction } from 'actions'

function* handleFetchLimitDeliverySetting() {
  const { payload, meta } = yield call(api.fetchLimitDeliverySetting)
  yield put(limitDeliverySettingAction.fetchLimitDeliverySettingDone(payload, meta))
}

function* handleSubmitUpdateLimitDeliverySetting(action) {
  const { payload, meta, error } = yield call(api.submitUpdateLimitDeliverySetting, action.payload)
  if (error) { meta.failMess = payload.message }
  yield put(limitDeliverySettingAction.submitUpdateLimitDeliverySettingDone(payload, meta))

  // 画面をリフレッシュさせる
  if (!error) yield put(limitDeliverySettingAction.fetchLimitDeliverySetting())
}


export const limitDeliverySettingSaga = [
  takeLatest(limitDeliverySettingAction.fetchLimitDeliverySetting, handleFetchLimitDeliverySetting),
  takeLatest(limitDeliverySettingAction.submitUpdateLimitDeliverySetting, handleSubmitUpdateLimitDeliverySetting),
]
