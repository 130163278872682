import React from 'react'

const AdminStopBadge = ({ stopByAdmin }) => {
  return (
    <span className={`badge badge-${stopByAdmin ? 'danger' : 'success'}`}>
      {stopByAdmin ? '管理者により停止中' : '配信可能'}
    </span>
  )
}

export default AdminStopBadge
