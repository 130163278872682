import { createActions } from 'redux-actions'

const FETCH_MAIL_AB_TESTS = 'FETCH_MAIL_AB_TESTS'
const FETCH_MAIL_AB_TESTS_DONE = 'FETCH_MAIL_AB_TESTS_DONE'

const SUBMIT_UPDATE_MAIL_AB_TEST = 'SUBMIT_UPDATE_MAIL_AB_TEST'
const SUBMIT_UPDATE_MAIL_AB_TEST_DONE = 'SUBMIT_UPDATE_MAIL_AB_TEST_DONE'

const SUBMIT_CREATE_MAIL_AB_TEST = 'SUBMIT_CREATE_MAIL_AB_TEST'
const SUBMIT_CREATE_MAIL_AB_TEST_DONE = 'SUBMIT_CREATE_MAIL_AB_TEST_DONE'

const SUBMIT_DELETE_MAIL_AB_TEST = 'SUBMIT_DELETE_MAIL_AB_TEST'
const SUBMIT_DELETE_MAIL_AB_TEST_DONE = 'SUBMIT_DELETE_MAIL_AB_TEST_DONE'

export const mailAbTestAction = createActions(
  {
    [FETCH_MAIL_AB_TESTS_DONE]: [
      (...args) => args[0],
      (...args) => args[1],
    ],
    [SUBMIT_UPDATE_MAIL_AB_TEST_DONE]: [
      (...args) => args[0],
      (...args) => Object.assign({ notification: 'ALL_CASE' }, args[1]),
    ],
    [SUBMIT_CREATE_MAIL_AB_TEST_DONE]: [
      (...args) => args[0],
      (...args) => Object.assign({ notification: 'ALL_CASE' }, args[1]),
    ],
    [SUBMIT_DELETE_MAIL_AB_TEST_DONE]: [
      (...args) => args[0],
      (...args) => Object.assign({ notification: 'ALL_CASE' }, args[1]),
    ],
  },

  FETCH_MAIL_AB_TESTS,
  SUBMIT_UPDATE_MAIL_AB_TEST,
  SUBMIT_CREATE_MAIL_AB_TEST,
  SUBMIT_DELETE_MAIL_AB_TEST,

)
