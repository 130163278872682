import { takeLatest, call, put } from 'redux-saga/effects'
import * as api from 'services/api'
import { itemImportSettingAction } from 'actions'

function* handleFetchItemImportSetting() {
  const { payload, meta } = yield call(api.fetchItemImportSetting)
  yield put(itemImportSettingAction.fetchItemImportSettingDone(payload, meta))
}

function* handleSubmitUpdateItemImportSetting(action) {
  const { payload, meta, error } = yield call(api.submitUpdateItemImportSetting, action.payload)
  yield put(itemImportSettingAction.submitUpdateItemImportSettingDone(payload, meta))
  if (!error) yield put(itemImportSettingAction.fetchItemImportSetting())
}

export const itemImportSettingSaga = [
  takeLatest(itemImportSettingAction.fetchItemImportSetting, handleFetchItemImportSetting),
  takeLatest(itemImportSettingAction.submitUpdateItemImportSetting, handleSubmitUpdateItemImportSetting),
]
