import React from 'react'
import { connect } from 'react-redux'
import ContentWrapper from 'components/layout/ContentWrapper'
import { Card, CardBody, CardHeader } from 'reactstrap'
import { useMountEffect } from 'lib/hooks'
import CompanySettingForm from 'components/parts/company/CompanySettingForm'
import ReportMailSettingForm from 'components/parts/reportMail/ReportMailSettingForm'
import {
  companyAction, reportMailSettingAction,
} from 'actions'

export const CompanySettingPage = ({
  dispatch,
}) => {
  useMountEffect(() => {
    dispatch(companyAction.fetchCompany())
    dispatch(reportMailSettingAction.fetchReportMailSetting())
  })
  return (
    <ContentWrapper>
      <div className="content-heading">
        <div>企業情報設定</div>
      </div>
      <Card className="card-default">
        <CardHeader>レポートメール設定</CardHeader>
        <CardBody>
          <ReportMailSettingForm />
        </CardBody>
      </Card>
      <Card className="card-default">
        <CardHeader>企業情報</CardHeader>
        <CardBody>
          <CompanySettingForm />
        </CardBody>
      </Card>
    </ContentWrapper>
  )
}

const select = ({ company, reportMailSetting }) => ({ company, reportMailSetting })

export default connect(select)(CompanySettingPage)
