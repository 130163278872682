import campaignConstant, { bufferTime } from './campaignConstant'

export default {
  ...campaignConstant,
  scheduleTimes: [
    ['即時送信', 0],
    ['1分後に送信', 1],
    ['15分後に送信', 15],
    ['30分後に送信', 30],
    ['45分後に送信', 45],
    ['1時間後に送信', 60],
    ['2時間後に送信', 2 * 60],
    ['4時間後に送信', 4 * 60],
    ['8時間後に送信', 8 * 60],
    ['12時間後に送信', 12 * 60],
    ['1日後に送信', 24 * 60 - bufferTime],
    ['2日後に送信', 2 * 24 * 60 - bufferTime],
    ['3日後に送信', 3 * 24 * 60 - bufferTime],
    ['4日後に送信', 4 * 24 * 60 - bufferTime],
    ['5日後に送信', 5 * 24 * 60 - bufferTime],
    ['6日後に送信', 6 * 24 * 60 - bufferTime],
    ['7日後に送信', 7 * 24 * 60 - bufferTime],
    ['10日後に送信', 10 * 24 * 60 - bufferTime],
    ['14日後に送信', 14 * 24 * 60 - bufferTime],
    ['20日後に送信', 20 * 24 * 60 - bufferTime],
    ['30日後に送信', 30 * 24 * 60 - bufferTime],
    ['40日後に送信', 40 * 24 * 60 - bufferTime],
    ['60日後に送信', 60 * 24 * 60 - bufferTime],
    ['90日後に送信', 90 * 24 * 60 - bufferTime],
  ],
  scheduleTimesForTestAccount: [
    ['1分後に送信', 1],
    ['5分後に送信', 5],
    ['10分後に送信', 10],
    ['15分後に送信', 15],
    ['30分後に送信', 30],
    ['45分後に送信', 45],
    ['1時間後に送信', 60],
    ['2時間後に送信', 2 * 60],
    ['4時間後に送信', 4 * 60],
    ['8時間後に送信', 8 * 60],
    ['12時間後に送信', 12 * 60],
    ['1日後に送信', 24 * 60 - bufferTime],
    ['2日後に送信', 2 * 24 * 60 - bufferTime],
    ['3日後に送信', 3 * 24 * 60 - bufferTime],
    ['4日後に送信', 4 * 24 * 60 - bufferTime],
    ['5日後に送信', 5 * 24 * 60 - bufferTime],
    ['6日後に送信', 6 * 24 * 60 - bufferTime],
    ['7日後に送信', 7 * 24 * 60 - bufferTime],
    ['10日後に送信', 10 * 24 * 60 - bufferTime],
    ['14日後に送信', 14 * 24 * 60 - bufferTime],
    ['20日後に送信', 20 * 24 * 60 - bufferTime],
    ['30日後に送信', 30 * 24 * 60 - bufferTime],
    ['40日後に送信', 40 * 24 * 60 - bufferTime],
    ['60日後に送信', 60 * 24 * 60 - bufferTime],
    ['90日後に送信', 90 * 24 * 60 - bufferTime],
  ],
  breakTimeEachCustomer: [
    ['停止しない', 0],
    ['1日間送信しない', 24 * 60],
    ['2日間送信しない', 2 * 24 * 60],
    ['3日間送信しない', 3 * 24 * 60],
    ['7日間送信しない', 7 * 24 * 60],
    ['14日間送信しない', 14 * 24 * 60],
    ['20日間送信しない', 20 * 24 * 60],
    ['30日間送信しない', 30 * 24 * 60],
    ['40日間送信しない', 40 * 24 * 60],
    ['60日間送信しない', 60 * 24 * 60],
    ['90日間送信しない', 90 * 24 * 60],
  ],
  purchaseHistories: [
    ['全ての顧客', 'any_purchased_count'],
    ['購入履歴が１回の顧客', 'purchased_once'],
    ['購入履歴が２回の顧客', 'purchased_twice'],
    ['購入履歴が３回以上の顧客', 'purchased_more_than_twice'],
  ],
}
