import React from 'react'
import { Label as RSLabel } from 'reactstrap'

const Label = ({
  children, require, className, ...rest
}) => (
  <RSLabel className={`${className || ''} input-label`} {...rest}>
    { children }
    { require && <span className="text-danger"> *</span>}
  </RSLabel>
)

export default Label
