import { Form, Formik } from 'formik'
import { connect } from 'react-redux'
import { Alert, Button, FormGroup } from 'reactstrap'
import React from 'react'
import * as Yup from 'yup'
import {
  FieldSingle, Label, Loading,
} from 'lib/components'
import { adminCompanyAllowIpAddressAction } from 'actions'
import { generalConstant as gc } from 'config/constants'

const validationSchema = Yup.object().shape({
  ipAddress: Yup.string().matches(gc.ipv4RegEx).required(),
})

export const AdminCompanyAllowIpAddressForm = ({
  adminCompanyAllowIpAddress, companyId, item, submitAction, dispatch,
}) => (
  <Loading isLoading={adminCompanyAllowIpAddress.isFetching}>
    <Formik
      initialValues={{
        ipAddress: item.ipAddress || '',
      }}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        if (submitAction === 'update') {
          dispatch(adminCompanyAllowIpAddressAction.submitUpdateCompanyAllowIpAddress({ id: item.id, companyId, ...values }))
        } else {
          dispatch(adminCompanyAllowIpAddressAction.submitCreateCompanyAllowIpAddress({ companyId, ...values }))
        }
      }}
    >
      {({ values, errors }) => (
        <Form noValidate>
          { adminCompanyAllowIpAddress.error && <Alert color="danger">{adminCompanyAllowIpAddress.error}</Alert> }
          <fieldset className="mb-3">
            <FormGroup>
              <Label require>IPアドレス</Label>
              <FieldSingle name="ipAddress" type="text" />
            </FormGroup>
          </fieldset>
          <div className="text-center"><Button color="primary" type="submit" size="lg">更新</Button></div>
        </Form>
      )}
    </Formik>
  </Loading>
)

const select = ({
  adminCompanyAllowIpAddress,
}, { allowIpAddressId }) => {
  let item = {}
  if (allowIpAddressId) {
    item = adminCompanyAllowIpAddress.items.filter(obj => (obj.id === allowIpAddressId))[0] || {}
  }
  return {
    adminCompanyAllowIpAddress, item,
  }
}

export default connect(select)(AdminCompanyAllowIpAddressForm)
