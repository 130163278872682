import { createActions } from 'redux-actions'

const FETCH_MAIL_SENDER_INFO = 'FETCH_MAIL_SENDER_INFO'
const FETCH_MAIL_SENDER_INFO_DONE = 'FETCH_MAIL_SENDER_INFO_DONE'

const SUBMIT_UPDATE_MAIL_SENDER_INFO = 'SUBMIT_UPDATE_MAIL_SENDER_INFO'
const SUBMIT_UPDATE_MAIL_SENDER_INFO_DONE = 'SUBMIT_UPDATE_MAIL_SENDER_INFO_DONE'

export const mailSenderInfoAction = createActions(
  {
    [FETCH_MAIL_SENDER_INFO_DONE]: [
      (...args) => args[0],
      (...args) => args[1],
    ],
    [SUBMIT_UPDATE_MAIL_SENDER_INFO_DONE]: [
      (...args) => args[0],
      (...args) => Object.assign({ notification: 'ALL_CASE' }, args[1]),
    ],
  },

  FETCH_MAIL_SENDER_INFO,
  SUBMIT_UPDATE_MAIL_SENDER_INFO,

)
