import {
  takeLatest, call, fork, put,
} from 'redux-saga/effects'
import { push } from 'connected-react-router'
import * as api from 'services/api'
import { popupAction } from 'actions'
import { handleNoticeChangeCampaign, handleNoticeEnableCampaign } from './userAccessTrackingSaga'

function* handleFetchPopups(action) {
  const { payload, meta } = yield call(api.fetchPopups, action.payload)
  yield put(popupAction.fetchPopupsDone(payload, meta))
}

function* handleSubmitUpdatePopup(action) {
  let { payload, meta, error } = yield call(api.submitUpdatePopup, action.payload)
  if (!error && ('popup' in payload)) { payload = payload.popup }
  yield put(popupAction.submitUpdatePopupDone(payload, meta))
  if (!error) {
    yield fork(handleNoticeChangeCampaign, payload)
    yield put(push('/popup_list'))
  }
}

function* handleSubmitEnablePopup(action) {
  let { payload, meta, error } = yield call(api.submitUpdatePopup, action.payload)
  if (!error && ('popup' in payload)) { payload = payload.popup }
  yield put(popupAction.submitEnablePopupDone(payload, meta))
  if (!error) {
    yield fork(handleNoticeEnableCampaign, payload)
  }
}

function* handleSubmitCreatePopup(action) {
  const { payload, meta, error } = yield call(api.submitCreatePopup, action.payload)
  yield put(popupAction.submitCreatePopupDone(payload, meta))
  if (!error) yield put(push('/popup_list'))
}

export const popupSaga = [
  takeLatest(popupAction.fetchPopups, handleFetchPopups),
  takeLatest(popupAction.submitUpdatePopup, handleSubmitUpdatePopup),
  takeLatest(popupAction.submitEnablePopup, handleSubmitEnablePopup),
  takeLatest(popupAction.submitCreatePopup, handleSubmitCreatePopup),
]
