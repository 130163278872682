import { call, put, takeLatest } from 'redux-saga/effects'
import * as api from 'services/adminApi'
import { adminCompanyUnitPriceAction } from 'actions'
import { push } from 'connected-react-router'

export function* handleFetchCompanyUnitPrice(action) {
  const { payload, meta } = yield call(api.fetchCompanyUnitPrice, action.payload)
  yield put(adminCompanyUnitPriceAction.fetchCompanyUnitPriceDone(payload, meta))
}

export function* handleFetchCompanyUnitPrices(action) {
  const { payload, meta } = yield call(api.fetchCompanyUnitPrices, action.payload)
  yield put(adminCompanyUnitPriceAction.fetchCompanyUnitPricesDone(payload, meta))
}

function* handleSubmitUpdateCompanyUnitPrice(action) {
  const { payload, meta, error } = yield call(api.submitUpdateCompanyUnitPrice, action.payload)
  yield put(adminCompanyUnitPriceAction.submitUpdateCompanyUnitPriceDone(payload, meta))
  if (!error) yield put(push(`/admin/companies/${action.payload.companyId}/invoice_list`))
}

function* handleSubmitCreateCompanyUnitPrice(action) {
  const { payload, meta, error } = yield call(api.submitCreateCompanyUnitPrice, action.payload)
  yield put(adminCompanyUnitPriceAction.submitCreateCompanyUnitPriceDone(payload, meta))
  if (!error) yield put(push(`/admin/companies/${action.payload.companyId}/invoice_list`))
}

function* handleSubmitDeleteCompanyUnitPrice(action) {
  let { payload, meta, error } = yield call(api.submitDeleteCompanyUnitPrice, action.payload)
  if (!error) { payload = action.payload }
  yield put(adminCompanyUnitPriceAction.submitDeleteCompanyUnitPriceDone(payload, meta))
}

export const adminCompanyUnitPriceSaga = [
  takeLatest(adminCompanyUnitPriceAction.fetchCompanyUnitPrice, handleFetchCompanyUnitPrice),
  takeLatest(adminCompanyUnitPriceAction.fetchCompanyUnitPrices, handleFetchCompanyUnitPrices),
  takeLatest(adminCompanyUnitPriceAction.submitUpdateCompanyUnitPrice, handleSubmitUpdateCompanyUnitPrice),
  takeLatest(adminCompanyUnitPriceAction.submitCreateCompanyUnitPrice, handleSubmitCreateCompanyUnitPrice),
  takeLatest(adminCompanyUnitPriceAction.submitDeleteCompanyUnitPrice, handleSubmitDeleteCompanyUnitPrice),
]
