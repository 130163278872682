import React from 'react'
import { connect } from 'react-redux'
import {
  CardHeader, CardBody,
} from 'reactstrap'
import moment from 'moment'
import { Loading } from 'lib/components'

const CompanyTable = ({ _isLoading, company }) => (
  <Loading isLoading={_isLoading}>
    <CardHeader>会社情報</CardHeader>
    <CardBody>
      <table>
        <tbody>
          <tr>
            <th>申込ID</th>
            <td>
              {company.orderCode}
            </td>
          </tr>
          <tr>
            <th>シリアル</th>
            <td>
              {company.serial}
            </td>
          </tr>
          <tr>
            <th>会社名</th>
            <td>
              {company.name}
            </td>
          </tr>
          <tr>
            <th>導入サイト名</th>
            <td>
              {company.siteName}
            </td>
          </tr>
          <tr>
            <th>サイトURL</th>
            <td>
              {company.siteUrl}
            </td>
          </tr>
          <tr>
            <th>ドメイン</th>
            <td>
              {company.domain}
            </td>
          </tr>
          <tr>
            <th>電話番号</th>
            <td>
              {company.phoneNumber}
            </td>
          </tr>
          <tr>
            <th>郵便番号</th>
            <td>
              {company.postalCode}
            </td>
          </tr>
          <tr>
            <th>住所1</th>
            <td>
              {company.address1}
            </td>
          </tr>
          <tr>
            <th>住所2</th>
            <td>
              {company.address2}
            </td>
          </tr>
          <tr>
            <th>アカウント申請日</th>
            <td>
              {moment(company.createdAt).format('YYYY-MM-DD HH:mm:ss')}
            </td>
          </tr>
          <tr>
            <th>アカウント開設日</th>
            <td>
              {company.companyStatus && moment(company.companyStatus.examinedAt).format('YYYY-MM-DD HH:mm:ss')}
            </td>
          </tr>
        </tbody>
      </table>
    </CardBody>
  </Loading>
)

const select = ({adminCompany}, {companyId}) => ({
  company: adminCompany.items.find(x => x.id === companyId) || {},
  _isLoading: adminCompany.isFetching
})

export default connect(select)(CompanyTable)
