import React from 'react'

const NewsCategoryLabel = ({ category }) => {
  switch (category) {
  case 'info':
    return <span className="badge badge-info">お知らせ</span>
  case 'release':
    return <span className="badge badge-success">新機能リリース</span>
  case 'maintenance':
    return <span className="badge badge-danger">メンテナンス</span>
  default:
    return null
  }
}

export default NewsCategoryLabel
