import React from 'react'
import { connect } from 'react-redux'
import ContentWrapper from 'components/layout/ContentWrapper'
import { Card, CardBody, CardHeader } from 'reactstrap'
import { useMountEffect } from 'lib/hooks'
import UserNameEditForm from 'components/parts/auth/UserNameEditForm'
import PasswordSettingForm from 'components/parts/auth/PasswordSettingForm'
import { userAction } from 'actions'

export const UserSettingPage = ({ dispatch }) => {
  useMountEffect(() => {
    dispatch(userAction.fetchCurrentUser())
  })

  return (
    <ContentWrapper>
      <div className="content-heading">
        <div>ユーザ情報</div>
      </div>
      <Card className="card-default">
        <CardHeader>ユーザ名</CardHeader>
        <CardBody>
          <UserNameEditForm />
        </CardBody>
      </Card>
      <Card className="card-default">
        <CardHeader>パスワード再設定</CardHeader>
        <CardBody>
          <PasswordSettingForm />
        </CardBody>
      </Card>
    </ContentWrapper>
  )
}

const select = ({ user }) => ({ user })

export default connect(select)(UserSettingPage)
