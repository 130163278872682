import React from 'react'
import { connect } from 'react-redux'
import { Table } from 'reactstrap'
import { Loading } from 'lib/components'

export const MailAbTestReportDetailTable = ({ mailAbTestReport, company }) => {
  const details = flattenedMultipleMailAbTestReport(mailAbTestReport.items)
  return (
    <Loading isLoading={mailAbTestReport.isFetching}>
      <Table bordered responsive>
        <thead>
          <tr style={{ wordBreak: 'keep-all' }}>
            <th>年月日</th>
            <th>キャンペーン名</th>
            <th>配信数</th>
            <th>開封数</th>
            <th>開封率</th>
            <th>クリック数</th>
            <th>クリック率</th>
            <th>完了数</th>
            <th>完了率</th>
            <th>キャンペーン売上</th>
            <th>リフトアップ</th>
            <th>メアド取得数</th>
            <th>会員化数</th>
            <th>会員化率</th>
          </tr>
        </thead>
        <tbody>
          { details.map(detail => (
            <tr key={`${detail.name}_${detail.date}`}>
              <td>{detail.date}</td>
              <td>{detail.name}</td>
              <td>{detail.processedCount.toLocaleString()}</td>
              <td>{detail.openCount.toLocaleString()}</td>
              <td>{Math.round(detail.openRate * 1000) / 10}%</td>
              <td>{detail.clickCount.toLocaleString()}</td>
              <td>{Math.round(detail.clickRate * 1000) / 10}%</td>
              <td>{detail.conversionCount.toLocaleString()}</td>
              <td>{Math.round(detail.conversionRate * 1000) / 10}%</td>
              <td>¥{detail.priceSum.toLocaleString()}</td>
              <td>{Math.round(detail.liftUp * 1000) / 10}%</td>
              <td>{detail.prospectCount.toLocaleString()}</td>
              <td>{detail.registerCount.toLocaleString()}</td>
              <td>{Math.round(detail.registerRate * 1000) / 10}%</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Loading>
  )
}

const flattenedMultipleMailAbTestReport = (mailAbTestReport) => {
  let details = []
  for (let report of mailAbTestReport) {
    for (let detail of report.detail) {
      details.push(detail)
    }
  }
  return details
}

const select = ({ mailAbTestReport, company }) => ({
  mailAbTestReport,
  company: { ...company.item },
})

export default connect(select)(MailAbTestReportDetailTable)
