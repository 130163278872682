import React, { useState } from 'react'
import { Input } from 'reactstrap'

const CustomSelect = ({name, value, options=['', ''], onChange, className, blank, ...props}) => {
  const [storedValue, setStoredValue] = useState(value)
  const inputOnChange = (e) => { onChange(e); setStoredValue(e.target.value) }
  const selfElement = document.querySelector(`select[name="${name}"]`)
  if(selfElement && `${selfElement.value}` !== `${storedValue}`){
    inputOnChange({ target: { value: selfElement.value, name: name } })
  }
  return (
    <Input
      type='select'
      name={name}
      value={storedValue}
      className={`${className || ''} custom-select`}
      onChange={inputOnChange}
      {...props}
    >
      { (blank ? [[' --- 選択する --- ', ''], ...options] : options).map((x) => (
        <option
          key={`form-field-select-${name}-${x[1]}`}
          value={x[1]}
        >
          {x[0]}
        </option>
      )) }
    </Input>
  )
}

export default CustomSelect
