import { takeLatest, call, put } from 'redux-saga/effects'
import * as api from 'services/api'
import { campaignCopyAction } from 'actions'

function* handleFetchCampaignCopy(action) {
  const { payload, meta } = yield call(api.fetchCampaignCopy, action.payload)
  yield put(campaignCopyAction.fetchCampaignCopyDone(payload, meta))
}

export const campaignCopySaga = [
  takeLatest(campaignCopyAction.fetchCampaignCopy, handleFetchCampaignCopy),
]
