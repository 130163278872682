import { handleActions } from 'redux-actions'
import { connectionTestAction as action } from 'actions'

const initial = {
  isFetching: false,
  isSubmitting: false,
  item: {},
  processResult: null,
}

export const connectionTest = handleActions({
  [action.fetchConnectionTest]: (state, action) => ({
    ...state,
    isFetching: true,
  }),
  [action.fetchConnectionTestDone]: (state, action) => ({
    ...state,
    isFetching: false,
    item: action.error ? state.item : action.payload,
    processResult: action.error ? action.payload : 'SUCCESS',
  }),
  [action.submitCreateConnectionTest]: (state, action) => ({
    ...state,
    isSubmitting: true,
  }),
  [action.submitCreateConnectionTestDone]: (state, action) => ({
    ...state,
    isSubmitting: false,
    item: action.error ? {} : action.payload,
    processResult: action.error ? action.payload : 'SUCCESS',
  }),
}, initial)
