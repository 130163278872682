import { takeLatest, put, call } from 'redux-saga/effects'
import { adminPermissionAction } from 'actions'
import * as api from 'services/api'

function* handleFetchAdminPermission() {
  let { payload, meta } = yield call(api.fetchAdminPermission)
  if (meta.statusCode === 403) {
    payload = { status: false }
  }
  yield put(adminPermissionAction.fetchAdminPermissionDone(payload, meta))
}

export const adminPermissionSaga = [
  takeLatest(adminPermissionAction.fetchAdminPermission, handleFetchAdminPermission),
]
