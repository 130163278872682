import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { Button } from 'reactstrap'
import { Loading } from 'lib/components'
import { arraysToObject, arraysToArray } from 'lib/utils'
import { importSettingConstant as isc } from 'config/constants'

const protocols = arraysToObject(isc.protocolValues, 1, 0)

export const CustomerImportSetting = ({ customerImportSetting }) => {
  const item = customerImportSetting.item
  return (
    <Loading isLoading={customerImportSetting.isFetching}>
      <h4 className="mb-3">通信形式</h4>
      <dl className="row">
        <dt className="col-sm-4">プロトコル</dt>
        <dd className="col-sm-8">{protocols[item.protocol]}</dd>
        { (protocols[item.protocol] === 'HTTP' || protocols[item.protocol] === 'HTTPS')
          && (
            <>
              <dt className="col-sm-4">連携先URL</dt>
              <dd className="col-sm-8">
                { (() => {
                  const ptl = protocols[item.protocol]
                  const port = item.port
                      && ((ptl === 'HTTP' && item.port !== 80)
                      || (ptl === 'HTTPS' && item.port !== 443))
                    ? `:${item.port}` : ''
                  return `${protocols[item.protocol].toLowerCase()}://${[`${item.host}${port}`, item.path, item.filename].filter(e => !!e).join('/')}`
                })() }
              </dd>
              <dt className="col-sm-4">認証</dt>
              <dd className="col-sm-8">{arraysToObject(isc.authMethod, 1, 0)[item.authMethod]}</dd>
              { item.authMethod !== 0
              && (
                <>
                  <dt className="col-sm-4">ユーザ名</dt>
                  <dd className="col-sm-8">{item.username}</dd>
                </>
              )
              }
            </>
          )
        }
        { (protocols[item.protocol] === 'SFTP')
          && (
            <>
              <dt className="col-sm-4">ホスト</dt>
              <dd className="col-sm-8">
                {item.host}:{item.port}
              </dd>
              <dt className="col-sm-4">パス</dt>
              <dd className="col-sm-8">
                {item.path}/{item.filename}
              </dd>
              <dt className="col-sm-4">ユーザ名</dt>
              <dd className="col-sm-8">{item.username}</dd>
            </>
          )
        }
        { protocols[item.protocol] === 'S3'
          && (
            <>
              { arraysToArray(isc.s3Regions, 1).includes(item.host)
                ? (
                  <>
                    <dt className="col-sm-4">リージョン</dt>
                    <dd className="col-sm-8">{arraysToObject(isc.s3Regions, 1, 0)[item.host]}</dd>
                  </>
                )
                : (
                  <>
                    <dt className="col-sm-4">エンドポイント</dt>
                    <dd className="col-sm-8">{[item.host]}</dd>
                  </>
                )
              }
              <>
                <dt className="col-sm-4">パス</dt>
                <dd className="col-sm-8">
                  {item.path}/{item.filename}
                </dd>
                <dt className="col-sm-4">ユーザ名</dt>
                <dd className="col-sm-8">{item.username}</dd>
              </>
            </>
          )
        }
        { protocols[item.protocol] === 'FTPS'
          && (
            <>
              <dt className="col-sm-4">モード</dt>
              <dd className="col-sm-8">{arraysToObject(isc.ftpsModes, 1, 0)[item.ftpsMode]}</dd>
              <dt className="col-sm-4">ホスト</dt>
              <dd className="col-sm-8">
                {item.host}:{item.port}
              </dd>
              <dt className="col-sm-4">パス</dt>
              <dd className="col-sm-8">
                {item.path}/{item.filename}
              </dd>
              <dt className="col-sm-4">ユーザ名</dt>
              <dd className="col-sm-8">{item.username}</dd>
            </>
          )
        }
      </dl>
      <h4 className="mb-3">ファイル形式</h4>
      <dl className="row">
        <dt className="col-sm-4">圧縮</dt>
        <dd className="col-sm-8">{arraysToObject(isc.compressTypes, 1, 0)[item.compressType]}</dd>
        <dt className="col-sm-4">ファイル</dt>
        <dd className="col-sm-8">{arraysToObject(isc.fileTypes, 1, 0)[item.fileType]}</dd>
        <dt className="col-sm-4">エンコード</dt>
        <dd className="col-sm-8">{arraysToObject(isc.encodings, 1, 0)[item.encoding]}</dd>
      </dl>
      <div className="text-center"><Button tag={Link} to="/customer_import_setting_edit" color="secondary">編集</Button></div>
    </Loading>
  )
}

const select = ({ customerImportSetting }) => ({ customerImportSetting })

export default connect(select)(CustomerImportSetting)
