import { Form, Formik } from 'formik'
import { connect } from 'react-redux'
import {
  Alert, Button, FormGroup, FormText, UncontrolledCollapse,
} from 'reactstrap'
import React, { Component } from 'react'
import * as Yup from 'yup'
import { campaignConstant as cc } from 'config/constants'
import {
  FieldCode, FieldSingle, Label, Loading, MailPreviewButton,
} from 'lib/components'
import { adminCampaignTemplateAction, adminTemplateTagAction, mailPreviewAction } from 'actions'
import { UnControlled as CodeMirror } from 'react-codemirror2'
import CampaignMailCodeHelp from 'components/parts/campaign/CampaignMailCodeHelp'
import Select from 'react-select'

const validationSchema = Yup.object().shape({
  displayOrder: Yup.number().required(),
  title: Yup.string().required(),
  description: Yup.string().required(),
  subject: Yup.string(),
  previewText: Yup.string(),
  htmlHeader: Yup.string(),
  htmlBody: Yup.string(),
  style: Yup.string(),
  textBody: Yup.string(),
})

export class AdminCampaignTemplateForm extends Component {
  componentWillMount() {
    this.props.dispatch(adminTemplateTagAction.fetchTemplateTags())
  }

  render() {
    const {
      adminCampaignTemplate, adminTemplateTag, item, submitAction, dispatch,
    } = this.props
    const tags = adminTemplateTag.items.map(tag => ({ value: tag.id, label: tag.name }))
    return (
      <Loading isLoading={adminCampaignTemplate.isFetching}>
        <Formik
          initialValues={{
            displayOrder: item.displayOrder || '',
            title: item.title || '',
            description: item.description || '',
            subject: item.subject || '',
            previewText: item.previewText || '',
            htmlHeader: item.htmlHeader || '',
            htmlBody: item.htmlBody || '',
            style: item.style || '',
            textBody: item.textBody || '',
            enabled: item.enabled || false,
          }}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            if (submitAction === 'update') {
              dispatch(adminCampaignTemplateAction.submitUpdateCampaignTemplate({ id: item.id, ...values }))
            } else {
              dispatch(adminCampaignTemplateAction.submitCreateCampaignTemplate({ ...values }))
            }
          }}
        >
          {({
            values, errors, setFieldValue, setFieldTouched,
          }) => (
            <Form noValidate>
              { adminCampaignTemplate.error && <Alert color="danger">{adminCampaignTemplate.error}</Alert> }
              <fieldset className="mb-3">
                <FormGroup>
                  <Label require>表示順(昇順で表示されます)</Label>
                  <FieldSingle name="displayOrder" type="text" />
                </FormGroup>
                <FormGroup>
                  <Label require>テンプレート名</Label>
                  <FieldSingle name="title" type="text" />
                </FormGroup>
                <FormGroup>
                  <Label require>説明文</Label>
                  <FieldSingle name="description" type="text" />
                </FormGroup>
                <FormGroup>
                  <Label>件名</Label>
                  <FieldSingle name="subject" type="text" />
                </FormGroup>
                <FormGroup>
                  <Label>プレビューテキスト</Label>
                  <FieldSingle name="previewText" type="text" />
                </FormGroup>
                <FormGroup>
                  <Label>タグ</Label>
                  <TagSelectInput
                    name="templateTagsAttributes"
                    tags={tags}
                    defaultValue={(item.templateTags && item.templateTags.map(tag => ({ value: tag.id, label: tag.name })))}
                    onChange={setFieldValue}
                    onBlur={setFieldTouched}
                  />
                </FormGroup>
                <FormGroup>
                  <Label>HTMLヘッダ</Label>
                  <CodeMirror
                    options={{
                      lineWrapping: true, autoRefresh: true, lineNumbers: true, mode: 'htmlmixed', readOnly: true,
                    }}
                    value={cc.defaultHtmlMailHeader}
                    className="default-height transparent-linenumbers"
                  />
                  <FieldCode name="htmlHeader" mode="htmlmixed" />
                </FormGroup>
                <FormGroup>
                  <Label require>HTML本文</Label>
                  <FieldCode name="htmlBody" mode="htmlmixed" />
                  <FormText>bodyタグの内側を記入してください</FormText>
                </FormGroup>
                <div className="mb-5">
                  <Button
                    color="secondary"
                    id="codeHelpToggler1"
                    onClick={() => {}}
                  >
                    {' '}
                    コードのヘルプを表示する
                  </Button>
                </div>
                <UncontrolledCollapse toggler="#codeHelpToggler1" className="mb-5">
                  <CampaignMailCodeHelp />
                </UncontrolledCollapse>
                <FormGroup>
                  <Label>HTML用スタイルシート</Label>
                  <FieldCode name="style" mode="css" />
                  <FormText>メール送信時にキャンペーンメール本文の要素に自動的にインライン展開されます</FormText>
                </FormGroup>
                <div className="mb-5">
                  <MailPreviewButton
                    color="info"
                    onClickCallback={() => { dispatch(mailPreviewAction.submitCreateMailPreview({ ...values, _type: 'template' })) }}
                  >
                    メールのプレビュー
                  </MailPreviewButton>
                </div>
                <FormGroup>
                  <Label>テキストメール用本文</Label>
                  <FieldSingle name="textBody" type="textarea" style={{ minHeight: 200 }} />
                </FormGroup>
                <div className="mb-5">
                  <Button
                    color="secondary"
                    id="codeHelpToggler2"
                    onClick={() => {}}
                  >
                    {' '}
                    コードのヘルプを表示する
                  </Button>
                </div>
                <UncontrolledCollapse toggler="#codeHelpToggler2" className="mb-5">
                  <CampaignMailCodeHelp />
                </UncontrolledCollapse>
                <div className="mb-5">
                  <MailPreviewButton
                    color="info"
                    onClickCallback={() => { dispatch(mailPreviewAction.submitCreateMailPreview({ ...values, _type: 'template' })) }}
                  >
                    メールのプレビュー
                  </MailPreviewButton>
                </div>
              </fieldset>
              <div className="text-center"><Button color="primary" type="submit" size="lg">保存</Button></div>
            </Form>
          )}
        </Formik>
      </Loading>
    )
  }
}

class TagSelectInput extends Component {
  handleChange = (value) => {
    this.props.onChange(this.props.name, value.map(tag => ({ id: tag.value, name: tag.label })))
  };

  handleBlur = () => {
    this.props.onBlur(this.props.name, true)
  };

  render() {
    return (
      <Select
        defaultValue={this.props.defaultValue}
        name={this.props.name}
        isMulti
        options={this.props.tags}
        placeholder="タグを追加できます"
        onChange={this.handleChange}
        onBlur={this.handleBlur}
        value={this.props.value}
      />
    )
  }
}

const select = ({
  adminCampaignTemplate,
  adminTemplateTag,
}, { templateId }) => {
  let item = {}
  if (templateId) {
    item = adminCampaignTemplate.items.filter(obj => (obj.id === templateId))[0] || {}
  }
  return {
    adminCampaignTemplate,
    adminTemplateTag,
    item,
  }
}

export default connect(select)(AdminCampaignTemplateForm)
