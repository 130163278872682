import React from 'react'
import moment from 'moment'
import * as Yup from 'yup'
import { Formik, Form } from 'formik'
import { connect } from 'react-redux'
import { Button, FormGroup, Input } from 'reactstrap'
import {
  FieldSingle, Label, InputGroupAddon, FieldGroup,
} from 'lib/components'
import { behaviorHistoryAction, sendgridAction, customerAction } from 'actions'

const validationSchema = Yup.object().shape({
  code: Yup.string().required(),
  searchFrom: Yup.string().required('誤った日付が指定されています'),
  searchTo: Yup.string().required('誤った日付が指定されています'),
})

export const BehaviorHistorySearchConditionForm = ({ dispatch, behaviorHistory, sendgrid, customer }) => {
  const disabled = behaviorHistory.isFetching || sendgrid.isFetching || customer.isFetching

  return(
    <Formik
      initialValues={{
        code: '',
        searchFrom: moment().subtract(3, 'days').format('YYYY-MM-DD'),
        searchTo: moment().format('YYYY-MM-DD'),
      }}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        dispatch(behaviorHistoryAction.fetchBehaviorHistories(values))
        dispatch(sendgridAction.retrieveSuppressionByCode(values.code))
        dispatch(customerAction.findCustomer(values.code))
      }}
    >
      <Form noValidate className="form-horizontal">
        <FormGroup>
          <Label>ユーザコードまたはメールアドレス</Label>
          <FieldSingle name="code" type="text" />
        </FormGroup>
        <FormGroup className="mb-3">
          <Label>対象期間</Label>
          <FieldGroup>
            <Input name="searchFrom" type="date" />
            <InputGroupAddon addonType="middle">〜</InputGroupAddon>
            <Input name="searchTo" type="date" />
          </FieldGroup>
        </FormGroup>
        <div className="text-center">
          <Button color="primary" type="submit" disabled={disabled}>検索</Button>
        </div>
      </Form>
    </Formik>
  )
}

const select = ({ behaviorHistory, sendgrid, customer }) => ({ behaviorHistory, sendgrid, customer })

export default connect(select)(BehaviorHistorySearchConditionForm)
