import { handleActions } from 'redux-actions'
import { customersAction as action } from 'actions'

const initial = {
  items: [],
  error: undefined,
  isFetching: false,
}

export const customers = handleActions({
  [action.searchCustomers]: (state) => ({
    ...state,
    isFetching: true,
  }),
  [action.searchCustomersDone]: (state, action) => ({
    ...state,
    items: action.payload,
    isFetching: false,
  })
}, initial)
