import { createActions } from 'redux-actions'

const FETCH_RECO_ACCOUNTS = 'FETCH_RECO_ACCOUNTS'
const FETCH_RECO_ACCOUNTS_DONE = 'FETCH_RECO_ACCOUNTS_DONE'

export const adminRecoAccountAction = createActions(
  {
    [FETCH_RECO_ACCOUNTS_DONE]: [
      (...args) => args[0],
      (...args) => args[1],
    ],
  },
  FETCH_RECO_ACCOUNTS,
  {
    prefix: 'ADMIN',
  },
)
