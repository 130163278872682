import { createActions } from 'redux-actions'

const FETCH_REVIEW_ACCOUNTS = 'FETCH_REVIEW_ACCOUNTS'
const FETCH_REVIEW_ACCOUNTS_DONE = 'FETCH_REVIEW_ACCOUNTS_DONE'

export const adminReviewAccountAction = createActions(
  {
    [FETCH_REVIEW_ACCOUNTS_DONE]: [
      (...args) => args[0],
      (...args) => args[1],
    ],
  },
  FETCH_REVIEW_ACCOUNTS,
  {
    prefix: 'ADMIN',
  },
)
