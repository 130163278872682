import * as Yup from 'yup'

export const equalTo = (ref, msg) => Yup.mixed().test({
  name: 'equalTo',
  exclusive: false,
  message: msg,
  params: {
    reference: ref.path,
  },
  test(value) {
    return value === this.resolve(ref)
  },
})
