import React from 'react';

const Footer = () => {
  const year = new Date().getFullYear()
  return (
      <footer className="footer-container">
          <span>&copy; {year} - Naviplus</span>
      </footer>
  );
}

export default Footer;
