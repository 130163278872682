import React, { useState } from 'react'
import { connect } from 'react-redux'
import { ClipboardLink, Loading, ToggleButton } from 'lib/components'
import { Button, UncontrolledTooltip } from 'reactstrap'
import { trackingSettingAction } from 'actions'

export const ItemTrackingCode = ({ company, trackingSetting, dispatch }) => {
  const basicTsJson = trackingSetting.items.find(item => item.eventType === 'basic')
  const basicTs = basicTsJson ? JSON.parse(basicTsJson.setting) : {}
  const defaultTs = {
    itemTag: { params: {} }, userTag: { tags: {} }, updateItemTag: { params: {} },
  }
  const ts = { ...defaultTs, ...basicTs }

  const [itemTagShow, setItemTagShow] = useState(ts.itemTag.show)

  const [itemStatus, setItemStatus] = useState(ts.itemTag.params.status)
  const [itemCategory1, setItemCategory1] = useState(ts.itemTag.params.category1)
  const [itemDescription, setItemDescription] = useState(ts.itemTag.params.description)
  const [itemSaleStage, setItemSaleStage] = useState(ts.itemTag.params.saleStage)
  const [itemStockStatus, setItemStockStatus] = useState(ts.itemTag.params.stockStatus)
  const [itemStockCount, setItemStockCount] = useState(ts.itemTag.params.stockCount)
  const [itemContentDate, setItemContentDate] = useState(ts.itemTag.params.contentDate)
  const [itemPrice2, setItemPrice2] = useState(ts.itemTag.params.price2)
  const [itemCurrency, setItemCurrency] = useState(ts.itemTag.params.currency)
  const [itemDiscount, setItemDiscount] = useState(ts.itemTag.params.discount)


  const [userTagShow, setUserTagShow] = useState(ts.userTag.show)

  const [userEmail, setUserEmail] = useState(ts.userTag.tags.userEmail)
  const [userPurchasedCount, setUserPurchasedCount] = useState(ts.userTag.tags.userPurchasedCount)
  const [userName, setUserName] = useState(ts.userTag.tags.userName)
  const [userStatus, setUserStatus] = useState(ts.userTag.tags.userStatus)
  const [userGender, setUserGender] = useState(ts.userTag.tags.userGender)
  const [userPoint, setUserPoint] = useState(ts.userTag.tags.userPoint)
  const [userCustomerType, setUserCustomerType] = useState(ts.userTag.tags.userCustomerType)


  const [updateItemTagShow, setUpdateItemTagShow] = useState(ts.updateItemTag.show)

  const [updateItemName, setUpdateItemName] = useState(ts.updateItemTag.params.name)
  const [updateItemUrl1, setUpdateItemUrl1] = useState(ts.updateItemTag.params.url1)
  const [updateItemStatus, setUpdateItemStatus] = useState(ts.updateItemTag.params.status)
  const [updateItemImageUrl1, setUpdateItemImageUrl1] = useState(ts.updateItemTag.params.imageUrl1)
  const [updateItemCategory1, setUpdateItemCategory1] = useState(ts.updateItemTag.params.category1)
  const [updateItemDescription, setUpdateItemDescription] = useState(ts.updateItemTag.params.description)
  const [updateItemSaleStage, setUpdateItemSaleStage] = useState(ts.updateItemTag.params.saleStage)
  const [updateItemStockStatus, setUpdateItemStockStatus] = useState(ts.updateItemTag.params.stockStatus)
  const [updateItemStockCount, setUpdateItemStockCount] = useState(ts.updateItemTag.params.stockCount)
  const [updateItemContentDate, setUpdateItemContentDate] = useState(ts.updateItemTag.params.contentDate)
  const [updateItemPrice1, setUpdateItemPrice1] = useState(ts.updateItemTag.params.price1)
  const [updateItemPrice2, setUpdateItemPrice2] = useState(ts.updateItemTag.params.price2)
  const [updateItemCurrency, setUpdateItemCurrency] = useState(ts.updateItemTag.params.currency)
  const [updateItemDiscount, setUpdateItemDiscount] = useState(ts.updateItemTag.params.discount)


  const codeText = generateCodeText({
    itemTagShow,
    userTagShow,
    updateItemTagShow,
    itemStatus,
    itemCategory1,
    itemDescription,
    itemSaleStage,
    itemStockCount,
    itemStockStatus,
    itemContentDate,
    itemPrice2,
    itemCurrency,
    itemDiscount,
    userEmail,
    userPurchasedCount,
    userName,
    userStatus,
    userGender,
    userPoint,
    userCustomerType,
    updateItemName,
    updateItemUrl1,
    updateItemStatus,
    updateItemImageUrl1,
    updateItemCategory1,
    updateItemDescription,
    updateItemSaleStage,
    updateItemStockCount,
    updateItemStockStatus,
    updateItemContentDate,
    updateItemPrice1,
    updateItemPrice2,
    updateItemCurrency,
    updateItemDiscount,
  })

  const saveSetting = () => {
    const updateSetting = {
      itemTag: {
        params: {
          name: true,
          url1: true,
          status: itemStatus,
          imageUrl1: true,
          category1: itemCategory1,
          description: itemDescription,
          saleStage: itemSaleStage,
          stockCount: itemStockCount,
          stockStatus: itemStockStatus,
          contentDate: itemContentDate,
          price1: true,
          price2: itemPrice2,
          currency: itemCurrency,
          discount: itemDiscount,
        },
        show: itemTagShow,
      },
      userTag: {
        tags: {
          userEmail,
          userPurchasedCount,
          userName,
          // 互換性のため、userFirstName, userLastNameも含める
          userFirstName: userName,
          userLastName: userName,
          userStatus,
          userGender,
          userPoint,
          userCustomerType,
          userRegister: !!ts.userTag.tags.userRegister,
        },
        show: userTagShow,
      },
      updateItemTag: {
        params: {
          name: updateItemName,
          url1: updateItemUrl1,
          status: updateItemStatus,
          imageUrl1: updateItemImageUrl1,
          category1: updateItemCategory1,
          description: updateItemDescription,
          saleStage: updateItemSaleStage,
          stockCount: updateItemStockCount,
          stockStatus: updateItemStockStatus,
          contentDate: updateItemContentDate,
          price1: updateItemPrice1,
          price2: updateItemPrice2,
          currency: updateItemCurrency,
          discount: updateItemDiscount,
        },
        show: updateItemTagShow,
      },
    }
    if (basicTsJson) {
      return dispatch(trackingSettingAction.submitUpdateTrackingSetting({
        ...basicTsJson,
        setting: JSON.stringify(updateSetting),
      }))
    }
    dispatch(trackingSettingAction.submitCreateTrackingSetting({
      eventType: 'basic',
      setting: JSON.stringify(updateSetting),
    }))
  }
  return (
    <Loading isLoading={trackingSetting.isFethinking}>
      <p>どの商品を閲覧したというユーザの行動履歴を収集します。商品詳細ページや記事ページに配置します。</p>
      <div className="mb-3">
        <ToggleButton
          className="mr-1 mb-1"
          defaultActive={itemTagShow}
          onClick={() => setItemTagShow(!itemTagShow)}
        >商品情報をあわせて更新
        </ToggleButton>
        <ToggleButton
          className="mr-1 mb-1"
          defaultActive={userTagShow}
          onClick={() => setUserTagShow(!userTagShow)}
        >ユーザ情報をあわせて更新
        </ToggleButton>
        <ToggleButton
          className="mr-1 mb-1"
          defaultActive={updateItemTagShow}
          onClick={() => setUpdateItemTagShow(!updateItemTagShow)}
        >枝番情報をあわせて更新
        </ToggleButton>
      </div>
      { itemTagShow
        && (
          <>
            <div className="mb-3">
              <h5>商品属性データ</h5>
              <div className="mb-3">
                <p className="mb-0">必須パラメータ</p>
                <Button
                  color="info"
                  className="mr-1 mb-1"
                  id="itemName"
                >name
                </Button>
                <Button
                  color="info"
                  className="mr-1 mb-1"
                  id="itemUrl1"
                >url1
                </Button>
                <Button
                  color="info"
                  className="mr-1 mb-1"
                  id="itemImageUrl1"
                >imageUrl1
                </Button>
                <Button
                  color="info"
                  className="mr-1 mb-1"
                  id="itemPrice1"
                >price1
                </Button>
              </div>
              <div className="mb-0">
                <p className="mb-0">任意パラメータ</p>
                <ToggleButton
                  className="mr-1 mb-1"
                  defaultActive={itemStatus}
                  onClick={() => setItemStatus(!itemStatus)}
                  id="itemStatus"
                >status
                </ToggleButton>
                <ToggleButton
                  className="mr-1 mb-1"
                  defaultActive={itemCategory1}
                  onClick={() => setItemCategory1(!itemCategory1)}
                  id="itemCategory1"
                >category1
                </ToggleButton>
                <ToggleButton
                  className="mr-1 mb-1"
                  defaultActive={itemDescription}
                  onClick={() => setItemDescription(!itemDescription)}
                  id="itemDescription"
                >description
                </ToggleButton>
                <ToggleButton
                  className="mr-1 mb-1"
                  defaultActive={itemPrice2}
                  onClick={() => setItemPrice2(!itemPrice2)}
                  id="itemPrice2"
                >price2
                </ToggleButton>
                <ToggleButton
                  className="mr-1 mb-1"
                  defaultActive={itemCurrency}
                  onClick={() => setItemCurrency(!itemCurrency)}
                  id="itemCurrency"
                >currency
                </ToggleButton>
                <ToggleButton
                  className="mr-1 mb-1"
                  defaultActive={itemDiscount}
                  onClick={() => setItemDiscount(!itemDiscount)}
                  id="itemDiscount"
                >discount
                </ToggleButton>
                <ToggleButton
                  className="mr-1 mb-1"
                  defaultActive={itemSaleStage}
                  onClick={() => setItemSaleStage(!itemSaleStage)}
                  id="itemSaleStage"
                >saleStage
                </ToggleButton>
                <ToggleButton
                  className="mr-1 mb-1"
                  defaultActive={itemStockStatus}
                  onClick={() => setItemStockStatus(!itemStockStatus)}
                  id="itemStockStatus"
                >stockStatus
                </ToggleButton>
                <ToggleButton
                  className="mr-1 mb-1"
                  defaultActive={itemStockCount}
                  onClick={() => setItemStockCount(!itemStockCount)}
                  id="itemStockCount"
                >stockCount
                </ToggleButton>
                <ToggleButton
                  className="mr-1 mb-1"
                  defaultActive={itemContentDate}
                  onClick={() => setItemContentDate(!itemContentDate)}
                  id="itemContentDate"
                >contentDate
                </ToggleButton>
              </div>
              <ul className="list-unstyled">
                <li><span className="text-danger"> *「stockCount」は商品連動キャンペーン（再入荷・在庫わずか）実施時は必須</span></li>
                <li><span className="text-danger"> *「contentDate」は商品連動キャンペーン実施時は必須</span></li>
              </ul>
              <UncontrolledTooltip target="itemName">
              商品名
              </UncontrolledTooltip>
              <UncontrolledTooltip target="itemUrl1">
              商品URL
              </UncontrolledTooltip>
              <UncontrolledTooltip target="itemStatus">
              商品の有効／無効ステータス
              </UncontrolledTooltip>
              <UncontrolledTooltip target="itemImageUrl1">
              商品画像URL
              </UncontrolledTooltip>
              <UncontrolledTooltip target="itemCategory1">
              商品の属する第一階層のカテゴリ
              </UncontrolledTooltip>
              <UncontrolledTooltip target="itemDescription">
              商品の説明文
              </UncontrolledTooltip>
              <UncontrolledTooltip target="itemSaleStage">
              商品の販売上の取扱状態
              </UncontrolledTooltip>
              <UncontrolledTooltip target="itemStockStatus">
              商品の在庫状態
              </UncontrolledTooltip>
              <UncontrolledTooltip target="itemStockCount">
              商品の在庫数
              </UncontrolledTooltip>
              <UncontrolledTooltip target="itemContentDate">
              商品情報の更新日時。ブラウザキャッシュなどにより古い商品情報で更新されるのを防ぎます。
              </UncontrolledTooltip>
              <UncontrolledTooltip target="itemPrice1">
              商品の価格
              </UncontrolledTooltip>
              <UncontrolledTooltip target="itemPrice2">
              商品の推奨販売価格（値下げ前）
              </UncontrolledTooltip>
              <UncontrolledTooltip target="itemCurrency">
              商品の価格に関する記述する通貨
              </UncontrolledTooltip>
              <UncontrolledTooltip target="itemDiscount">
              商品の割引率
              </UncontrolledTooltip>
            </div>
          </>
        )
      }
      { userTagShow
        && (
          <>
            <div className="mb-3">
              <h5>ユーザ属性データ</h5>
              <div className="mb-3">
                <p className="mb-0">任意パラメータ</p>
                <ToggleButton
                  className="mr-1 mb-1"
                  defaultActive={userEmail}
                  onClick={() => setUserEmail(!userEmail)}
                  id="userEmail"
                >email
                </ToggleButton>
                <ToggleButton
                  className="mr-1 mb-1"
                  defaultActive={userPurchasedCount}
                  onClick={() => setUserPurchasedCount(!userPurchasedCount)}
                  id="userPurchasedCount"
                >purchasedCount
                </ToggleButton>
                <ToggleButton
                  className="mr-1 mb-1"
                  defaultActive={userName}
                  onClick={() => setUserName(!userName)}
                  id="userName"
                >firstName/lastName
                </ToggleButton>
                <ToggleButton
                  className="mr-1 mb-1"
                  defaultActive={userStatus}
                  onClick={() => setUserStatus(!userStatus)}
                  id="userStatus"
                >status
                </ToggleButton>
                <ToggleButton
                  className="mr-1 mb-1"
                  defaultActive={userGender}
                  onClick={() => setUserGender(!userGender)}
                  id="userGender"
                >gender
                </ToggleButton>
                <ToggleButton
                  className="mr-1 mb-1"
                  defaultActive={userPoint}
                  onClick={() => setUserPoint(!userPoint)}
                  id="userPoint"
                >point
                </ToggleButton>
                <ToggleButton
                  className="mr-1 mb-1"
                  defaultActive={userCustomerType}
                  onClick={() => setUserCustomerType(!userCustomerType)}
                  id="userCustomerType"
                >customerType
                </ToggleButton>
              </div>
              <UncontrolledTooltip target="userEmail">
              ユーザのメールアドレス
              </UncontrolledTooltip>
              <UncontrolledTooltip target="userPurchasedCount">
              ユーザの購入回数
              </UncontrolledTooltip>
              <UncontrolledTooltip target="userName">
              ユーザの名前
              </UncontrolledTooltip>
              <UncontrolledTooltip target="userStatus">
              ユーザの配信対象の有効／無効ステータス
              </UncontrolledTooltip>
              <UncontrolledTooltip target="userGender">
              ユーザの性別
              </UncontrolledTooltip>
              <UncontrolledTooltip target="userPoint">
              ユーザのポイント数
              </UncontrolledTooltip>
              <UncontrolledTooltip target="userCustomerType">
              ユーザの会員タイプ
              </UncontrolledTooltip>
            </div>
          </>
        )
      }
      { updateItemTagShow
        && (
          <>
            <div className="mb-3">
              <h5>枝番商品属性データ</h5>
              <div className="mb-3">
                <p className="mb-0">任意パラメータ</p>
                <ToggleButton
                  className="mr-1 mb-1"
                  defaultActive={updateItemName}
                  onClick={() => setUpdateItemName(!updateItemName)}
                  id="updateItemName"
                >name
                </ToggleButton>
                <ToggleButton
                  className="mr-1 mb-1"
                  defaultActive={updateItemUrl1}
                  onClick={() => setUpdateItemUrl1(!updateItemUrl1)}
                  id="updateItemUrl1"
                >url1
                </ToggleButton>
                <ToggleButton
                  className="mr-1 mb-1"
                  defaultActive={updateItemStatus}
                  onClick={() => setUpdateItemStatus(!updateItemStatus)}
                  id="updateItemStatus"
                >status
                </ToggleButton>
                <ToggleButton
                  className="mr-1 mb-1"
                  defaultActive={updateItemImageUrl1}
                  onClick={() => setUpdateItemImageUrl1(!updateItemImageUrl1)}
                  id="updateItemImageUrl1"
                >imageUrl1
                </ToggleButton>
                <ToggleButton
                  className="mr-1 mb-1"
                  defaultActive={updateItemCategory1}
                  onClick={() => setUpdateItemCategory1(!updateItemCategory1)}
                  id="updateItemCategory1"
                >category1
                </ToggleButton>
                <ToggleButton
                  className="mr-1 mb-1"
                  defaultActive={updateItemDescription}
                  onClick={() => setUpdateItemDescription(!updateItemDescription)}
                  id="updateItemDescription"
                >description
                </ToggleButton>
                <ToggleButton
                  className="mr-1 mb-1"
                  defaultActive={updateItemSaleStage}
                  onClick={() => setUpdateItemSaleStage(!updateItemSaleStage)}
                  id="updateItemSaleStage"
                >saleStage
                </ToggleButton>
                <ToggleButton
                  className="mr-1 mb-1"
                  defaultActive={updateItemStockStatus}
                  onClick={() => setUpdateItemStockStatus(!updateItemStockStatus)}
                  id="updateItemStockStatus"
                >stockStatus
                </ToggleButton>
                <ToggleButton
                  className="mr-1 mb-1"
                  defaultActive={updateItemStockCount}
                  onClick={() => setUpdateItemStockCount(!updateItemStockCount)}
                  id="updateItemStockCount"
                >stockCount
                </ToggleButton>
                <ToggleButton
                  className="mr-1 mb-1"
                  defaultActive={updateItemContentDate}
                  onClick={() => setUpdateItemContentDate(!updateItemContentDate)}
                  id="updateItemContentDate"
                >contentDate
                </ToggleButton>
                <ToggleButton
                  className="mr-1 mb-1"
                  defaultActive={updateItemPrice1}
                  onClick={() => setUpdateItemPrice1(!updateItemPrice1)}
                  id="updateItemPrice1"
                >price1
                </ToggleButton>
                <ToggleButton
                  className="mr-1 mb-1"
                  defaultActive={updateItemPrice2}
                  onClick={() => setUpdateItemPrice2(!updateItemPrice2)}
                  id="updateItemPrice2"
                >price2
                </ToggleButton>
                <ToggleButton
                  className="mr-1 mb-1"
                  defaultActive={updateItemCurrency}
                  onClick={() => setUpdateItemCurrency(!updateItemCurrency)}
                  id="updateItemCurrency"
                >currency
                </ToggleButton>
                <ToggleButton
                  className="mr-1 mb-1"
                  defaultActive={updateItemDiscount}
                  onClick={() => setUpdateItemDiscount(!updateItemDiscount)}
                  id="updateItemDiscount"
                >discount
                </ToggleButton>
              </div>
              <UncontrolledTooltip target="updateItemName">
              枝番商品名
              </UncontrolledTooltip>
              <UncontrolledTooltip target="updateItemUrl1">
              枝番商品URL
              </UncontrolledTooltip>
              <UncontrolledTooltip target="updateItemStatus">
              枝番商品の有効／無効ステータス
              </UncontrolledTooltip>
              <UncontrolledTooltip target="updateItemImageUrl1">
              枝番商品画像URL
              </UncontrolledTooltip>
              <UncontrolledTooltip target="updateItemCategory1">
              枝番商品の属する第一階層のカテゴリ
              </UncontrolledTooltip>
              <UncontrolledTooltip target="updateItemDescription">
              枝番商品の説明文
              </UncontrolledTooltip>
              <UncontrolledTooltip target="updateItemSaleStage">
              枝番商品の販売上の取扱状態
              </UncontrolledTooltip>
              <UncontrolledTooltip target="updateItemStockStatus">
              枝番商品の在庫状態
              </UncontrolledTooltip>
              <UncontrolledTooltip target="updateItemStockCount">
              枝番商品の在庫数
              </UncontrolledTooltip>
              <UncontrolledTooltip target="updateItemContentDate">
              枝番商品情報の更新日時。ブラウザキャッシュなどにより古い商品情報で更新されるのを防ぎます。
              </UncontrolledTooltip>
              <UncontrolledTooltip target="updateItemPrice1">
              枝番商品の価格
              </UncontrolledTooltip>
              <UncontrolledTooltip target="updateItemPrice2">
              枝番商品の推奨販売価格（値下げ前）
              </UncontrolledTooltip>
              <UncontrolledTooltip target="updateItemCurrency">
              枝番商品の価格に関する記述する通貨
              </UncontrolledTooltip>
              <UncontrolledTooltip target="updateItemDiscount">
              枝番商品の割引率
              </UncontrolledTooltip>
            </div>
          </>
        )
      }
      <div className="mb-3">
        <Button
          color="primary"
          onClick={saveSetting}
          id="saveTrackingSetting"
        >設定を保存
        </Button>
      </div>
      <div>
        <ClipboardLink copyText={codeText} />
      </div>
      <pre className="bg-gray-lighter p-3 code-text">
        { codeText }
      </pre>
    </Loading>
  )
}

const generateCodeText = ({
  itemTagShow, userTagShow, updateItemTagShow,
  itemStatus, itemCategory1, itemDescription, itemSaleStage,
  itemStockCount, itemStockStatus, itemContentDate, itemPrice2, itemCurrency, itemDiscount,
  userEmail, userPurchasedCount, userName, userStatus, userGender, userPoint, userCustomerType,
  updateItemName, updateItemUrl1, updateItemStatus, updateItemImageUrl1, updateItemCategory1,
  updateItemDescription, updateItemSaleStage, updateItemStockCount, updateItemStockStatus,
  updateItemContentDate, updateItemPrice1, updateItemPrice2, updateItemCurrency, updateItemDiscount,
}) => {
  const itemAttributeText = [
    "'name': '{商品名}'",
    "'url1': '{商品URL}'",
    itemStatus && "'status': '{商品の有効／無効ステータス}'",
    "'imageUrl1': '{商品画像URL}'",
    itemCategory1 && "'category1': '{商品が属する第一階層カテゴリ}'",
    itemDescription && "'description': '{商品の説明文}'",
    itemSaleStage && "'saleStage': '{商品の販売上の取扱状態}'",
    itemStockStatus && "'stockStatus': '{商品の在庫状態}'",
    itemStockCount && "'stockCount': '{商品の在庫数}'",
    itemContentDate && "'contentDate': '{商品情報更新日時}'",
    "'price1': '{商品の価格}'",
    itemPrice2 && "'price2': '{商品の推奨販売価格（値下げ前）}'",
    itemCurrency && "'currency': '{商品の価格に記述する通貨}'",
    itemDiscount && "'discount': '{商品の割引率}'",
  ].filter(e => !!e).join(',\n      ')

  const userText = [
    userEmail && "_sna_dam.push(['email', '{ユーザのメールアドレス}']);",
    userPurchasedCount && "_sna_dam.push(['purchasedCount', '{ユーザの購入回数}']);",
    userName && "_sna_dam.push(['lastName', '{ユーザの名前（姓）}']);",
    userName && "_sna_dam.push(['firstName', '{ユーザの名前（名）}']);",
    userStatus && "_sna_dam.push(['status', '{ユーザの配信対象の有効／無効ステータス}']);",
    userGender && "_sna_dam.push(['gender', '{ユーザの性別}']);",
    userPoint && "_sna_dam.push(['point', '{ユーザのポイント数}']);",
    userCustomerType && "_sna_dam.push(['customerType', '{ユーザの会員タイプ}']);",
  ].filter(e => !!e).join('\n  ')

  const updateItemAttributeText = [
    updateItemName && "'name': '{枝番商品名}'",
    updateItemUrl1 && "'url1': '{枝番商品URL}'",
    updateItemStatus && "'status': '{枝番商品の有効／無効ステータス}'",
    updateItemImageUrl1 && "'imageUrl1': '{枝番商品画像URL}'",
    updateItemCategory1 && "'category1': '{枝番商品が属する第一階層カテゴリ}'",
    updateItemDescription && "'description': '{枝番商品の説明文}'",
    updateItemSaleStage && "'saleStage': '{枝番商品の販売上の取扱状態}'",
    updateItemStockStatus && "'stockStatus': '{枝番商品の在庫状態}'",
    updateItemStockCount && "'stockCount': '{枝番商品の在庫数}'",
    updateItemPrice1 && "'price1': '{枝番商品の価格}'",
    updateItemPrice2 && "'price2': '{枝番商品の推奨販売価格（値下げ前）}'",
    updateItemCurrency && "'currency': '{枝番商品の価格に記述する通貨}'",
    updateItemDiscount && "'discount': '{枝番商品の割引率}'",
    updateItemContentDate && "'contentDate': '{枝番商品情報更新日時}'",
  ].filter(e => !!e).join(',\n      ')

  const template = `
<script type='text/javascript'>
  _sna_dam.push(['behaviorType', 1]);
  _sna_dam.push(['userCode', '{お客様（一般ユーザ）を識別するためのID}']);
  _sna_dam.push(['item', {
    ${!itemAttributeText || !itemTagShow ? '' : `
    'attributes': {
      ${itemAttributeText}
    },`
}
    'code': '{アイテムコード}'
  }]);
  ${userTagShow ? userText : ''}
  ${!updateItemAttributeText || !updateItemTagShow ? '' : `
  // 枝番種類だけ繰り返し
  _sna_dam.push(['updateItem', {
    'code': '{枝番アイテムコード}',
    'attributes': {
      ${updateItemAttributeText}
    }
  }])
  `}
</script>`
  return template.replace(/^\s*\n/gm, '')
}

const select = ({ company, trackingSetting }) => ({ company, trackingSetting })

export default connect(select)(ItemTrackingCode)
