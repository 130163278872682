/* eslint no-template-curly-in-string: 0 */
export default {
  mixed: {
    required: '必須入力項目です',
  },
  string: {
    email: 'メールアドレスの形式で入力してください',
    min: '${min}文字以上入力してください',
    matches: '正しい形式で入力してください',
    url: 'URLの形式で入力してください',
  },
  number: {
    min: '${min}以上の値を入力してください',
    max: '${max}以下の値を入力してください',
    positive: '正の値を入力してください',
  },
}
