import React from 'react'
import { compose } from 'redux'
import { withRouter } from 'react-router'
import { push } from 'connected-react-router'
import { Formik, Form } from 'formik'
import { connect } from 'react-redux'
import { Button, FormGroup, CardBody } from 'reactstrap'
import { deserializeFromQueryString, serializeToQueryString } from 'lib/utils'
import { FieldSingle, Label } from 'lib/components'

import { mailAbTestConstant as ab } from 'config/constants'

export const MailAbTestRefineSearchForm = ({ location, dispatch }) => {
  const queryObj = deserializeFromQueryString(location.search) || {}
  return (
    <Formik
      initialValues={{
        testType: queryObj.testType || 'all',
        deliveryStatus: queryObj.deliveryStatus || 'all',
        showFinishedTest: queryObj.showFinishedTest || 'false',
      }}
      onSubmit={(values) => {
        dispatch(push(`${location.pathname}${serializeToQueryString({ ...queryObj, ...values })}`))
      }}
    >
      <CardBody>
        <Form noValidate>
          <FormGroup className="mb-3">
            <Label>テスト内容</Label>
            <FieldSingle type="select" name="testType" options={ab.testTypeConditions} />
          </FormGroup>
          <FormGroup className="mb-3">
            <Label>配信状態</Label>
            <FieldSingle type="select" name="deliveryStatus" options={ab.deliveryStatus} />
          </FormGroup>
          <FormGroup className="mb-3">
            <Label>終了したABテスト</Label>
            <FieldSingle type="select" name="showFinishedTest" options={ab.showFinishedTest} />
          </FormGroup>
          <div className="text-center mt-4"><Button color="primary" type="submit">絞り込み</Button></div>
        </Form>
      </CardBody>
    </Formik>
  )
}


export default compose(
  connect(),
  withRouter,
)(MailAbTestRefineSearchForm)
