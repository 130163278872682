import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import ContentWrapper from 'components/layout/ContentWrapper'
import { Card, CardBody, CardHeader } from 'reactstrap'
import SubscriptionTrackingSettingsEditForm from 'components/parts/subscriptionTrackingSettings/SubscriptionTrackingSettingsEditForm'
import { subscriptionTrackingSettingsAction } from 'actions'

export const SubscriptionTrackingSettingsPage = ({dispatch}) => {
  useEffect(() => {
    dispatch(subscriptionTrackingSettingsAction.fetchSubscriptionTrackingSettings())
  }, [dispatch])

  return (
    <ContentWrapper>
      <div className="content-heading">
        <div>オプトアウトリンク設定</div>
      </div>
      <Card className="card-default">
        <CardHeader>オプトアウトリンク設定</CardHeader>
        <CardBody>
          <SubscriptionTrackingSettingsEditForm />
        </CardBody>
      </Card>
    </ContentWrapper>
  )
}

export default connect()(SubscriptionTrackingSettingsPage)
