import React from 'react'
import { connect } from 'react-redux'
import ContentWrapper from 'components/layout/ContentWrapper'
import {
  Row, Col, Card, Button,
} from 'reactstrap'
import { deserializeFromQueryString } from 'lib/utils'
import { useMountEffect } from 'lib/hooks'
import AdminCompanyAllowIpAddressList from 'components/parts/allowIpAddress/AdminCompanyAllowIpAddressList'
import { adminCompanyAllowIpAddressAction } from 'actions'
import { Link } from 'react-router-dom'
import { generalConstant as gc } from 'config/constants'

export const AdminCompanyAllowIpAddressListPage = ({match, location, dispatch}) => {
  const companyId = match.params.id
  useMountEffect(() => {
    this.props.dispatch(adminCompanyAllowIpAddressAction.fetchCompanyAllowIpAddresses({ companyId }))
  })

  const queryObj = deserializeFromQueryString(location.search) || {}
  const page = Number(queryObj.page) || 1
  return (
    <ContentWrapper>
      <div className="content-heading">
        <div>許可IPアドレス一覧</div>
        <div className="ml-3">
          <Button tag={Link} to={`/admin/companies/${companyId}/allow_ip_address_new`} color="secondary">登録</Button>
        </div>
      </div>
      <Row>
        <Col xl={9}>
          <Card className="card-default">
            <AdminCompanyAllowIpAddressList
              limit={gc.adminCompanyAllowIpAddressItemsLimit}
              offset={(page - 1) * gc.adminCompanyAllowIpAddressItemsLimit}
            />
          </Card>
        </Col>
      </Row>
    </ContentWrapper>
  )
}

export default connect()(AdminCompanyAllowIpAddressListPage)
