import React from 'react'
import {
  Card, CardBody, Col, Row,
} from 'reactstrap'
import ContentWrapper from 'components/layout/ContentWrapper'
import { useMountEffect } from 'lib/hooks'
import { connect } from 'react-redux'
import { deserializeFromQueryString } from 'lib/utils'
import { adminCompanyInvoiceTermAction } from 'actions'
import InvoiceTermForm from 'components/parts/invoiceTerm/InvoiceTermForm'

export const AdminCompanyInvoiceTermNewPage = ({match, location, dispatch}) => {
  const companyId = Number(match.params.companyId)

  const queryObj = deserializeFromQueryString(location.search) || {}
  const originalInvoiceTermId = 'original' in queryObj && Number(queryObj.original)

  useMountEffect(() => {
    if(originalInvoiceTermId){
      dispatch(adminCompanyInvoiceTermAction.fetchCompanyInvoiceTerm({ companyId, id: originalInvoiceTermId }))
    }
  })

  return (
    <ContentWrapper>
      <div className="content-heading">
        <div>
          請求基本情報作成
        </div>
      </div>
      <Row>
        <Col xl={12}>
          <Card className="card-default">
            <CardBody>
              <InvoiceTermForm
                submitAction="create"
                companyId={companyId}
                invoiceTermId={originalInvoiceTermId}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </ContentWrapper>
  )
}

export default connect()(AdminCompanyInvoiceTermNewPage)
