import { setLocale, addMethod, string } from 'yup'
import MENU from './menu'
import STYLE from './style'

import validationMessage from './validationMessage'

setLocale(validationMessage)

// 「... <...>」形式の差出人名だと<>内の文字列をfrom_addressと誤認識するため<>記号の使用NG。ex) テストECショップ <test@example.com>
addMethod(string, 'notIncludeAngleBrackets', function notIncludeAngleBrackets() {
  return this.test(
    'not-include-angle-brackets',
    '>（大なり）と<（小なり）記号は使用できません',
    (value) => !/[<>]/.test(value),
  )
})

const configuration = {
  MENU,
  STYLE,
}

export default configuration
