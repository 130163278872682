import React from 'react'
import { connect } from 'react-redux'
import ContentWrapper from 'components/layout/ContentWrapper'
import {
  Row, Col, Card, CardBody,
} from 'reactstrap'

import { useMountEffect } from 'lib/hooks'
import {
  abandonedCampaignAction,
  completedCampaignAction,
  contextualCampaignAction,
  prospectsCampaignAction,
  mailSenderInfoAction,
  companyAction,
  eventAction,
} from 'actions'
import AbandonedCampaignForm from 'components/parts/campaign/AbandonedCampaignForm'
import CompletedCampaignForm from 'components/parts/campaign/CompletedCampaignForm'
import ContextualCampaignForm from 'components/parts/campaign/ContextualCampaignForm'
import ProspectsCampaignForm from 'components/parts/campaign/ProspectsCampaignForm'

const formComponentMapping = {
  abandoned: AbandonedCampaignForm,
  completed: CompletedCampaignForm,
  contextual: ContextualCampaignForm,
  prospects: ProspectsCampaignForm
}

export const CampaignEditPage = ({dispatch, match}) => {
  const campaignType = match.params.campaign_type
  const campaignId = Number(match.params.id)
  const FormComponent = formComponentMapping[campaignType]

  useMountEffect(() => {
    dispatch(companyAction.fetchCompany())
    dispatch(mailSenderInfoAction.fetchMailSenderInfo())
    switch (campaignType) {
    case 'abandoned':
      dispatch(abandonedCampaignAction.fetchAbandonedCampaigns({ type: 'all' }))
      break
    case 'completed':
      dispatch(completedCampaignAction.fetchCompletedCampaigns())
      break
    case 'contextual':
      dispatch(contextualCampaignAction.fetchContextualCampaigns())
      break
    case 'prospects':
      dispatch(prospectsCampaignAction.fetchProspectsCampaigns())
      dispatch(eventAction.fetchEvents())
      break
    default:
    }
  })

  return (
    <ContentWrapper>
      <div className="content-heading">
        <div>キャンペーン編集</div>
      </div>
      <Row>
        <Col xl={12}>
          <Card className="card-default">
            <CardBody>
              <FormComponent
                campaignId={campaignId}
                submitAction="update"
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </ContentWrapper>
  )
}


export default connect()(CampaignEditPage)
