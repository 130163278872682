import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import {
  Table, Button, CardBody, CardFooter,
} from 'reactstrap'
import { Loading, Swal, Pagination } from 'lib/components'
import { generalConstant as gc } from 'config/constants'
import { adminTemplateTagAction } from 'actions'
import moment from 'moment'

export const AdminTemplateTagList = ({
  adminTemplateTag, limit = gc.campaignItemsLimit, offset = 0, dispatch,
}) => {
  const targetItems = [...adminTemplateTag.items].filter((tag, index) => templateTagFilter(tag))
  return (
    <Loading isLoading={adminTemplateTag.isFetching}>
      <CardBody>
        <Table responsive>
          <tbody className="no-thead">
            {
              targetItems
                .sort((a, b) => templateTagSort(a, b))
                // ページネーション用filter
                .filter((cam, index) => (index >= offset && index < offset + limit))
                .map(tag => (
                  <tr key={`${tag.id}`}>
                    <td className="campaign-overview">
                      <h5>{tag.name}</h5>
                    </td>
                    <td className="text-center">
                      <Button tag={Link} to={`/admin/template_tag_edit/${tag.id}`} color="secondary">編集</Button>
                    </td>
                    <td>
                      <Swal
                        options={gc.deleteSwalOptions}
                        color="danger"
                        callback={(isConfirm) => { isConfirm && dispatch(adminTemplateTagAction.submitDeleteTemplateTag({ id: tag.id })) }}
                      >
                        削除
                      </Swal>
                    </td>
                  </tr>
                ))
            }
          </tbody>
        </Table>
      </CardBody>
      <CardFooter>
        <Pagination
          totalItemCount={targetItems.length}
          limit={limit}
        />
      </CardFooter>
    </Loading>
  )
}

const templateTagSort = (a, b, condition) => moment(b.updatedAt) - moment(a.updatedAt)

// 削除された要素(undefined)をスキップ
const templateTagFilter = tag => !!tag

const select = ({ adminTemplateTag }) => ({ adminTemplateTag })

export default connect(select)(AdminTemplateTagList)
