import { handleActions } from 'redux-actions'
import { comparisonReportAction as action } from 'actions'

const initial = {
  isFetching: false,
  items: [],
  processResult: null,
}

export const comparisonReport = handleActions({
  [action.fetchComparisonReport]: (state, action) => ({
    ...state,
    isFetching: true,
  }),
  [action.fetchComparisonReportDone]: (state, action) => ({
    ...state,
    isFetching: false,
    items: action.error ? state.items : [
      ...state.items,
      action.payload,
    ],
    processResult: action.error ? action.payload : 'SUCCESS',
  }),
  [action.removeComparisonReport]: (state, action) => ({
    ...state,
    items: [
      ...state.items.slice(0, action.payload.index),
      ...state.items.slice(action.payload.index + 1),
    ],
  }),
  [action.clearComparisonReports]: (state, action) => ({
    ...state,
    items: [],
  }),
}, initial)
