import React from 'react'
import { connect } from 'react-redux'
import { Table } from 'reactstrap'
import { shouldDisplayDetailCost, valueOrError } from "lib/reportUtils"
import { Loading } from 'lib/components'

export const TransitionReportDetailTable = ({ transitionReport, condition }) => {
  const details = transitionReport.item.detail ? [...transitionReport.item.detail] : []
  const shouldDisplayDetailCostCol = shouldDisplayDetailCost(condition)
  return (
    <Loading isLoading={transitionReport.isFetching}>
      <Table bordered responsive>
        <thead>
          <tr>
            <th colSpan="2" />
            <th colSpan="2" className="text-center">配信数</th>
            <th colSpan="10" className="text-center">成果指標</th>
            <th colSpan="3" className="text-center">リタゲ経由売上</th>
            <th colSpan="3" className="text-center">サイト状況</th>
            { shouldDisplayDetailCostCol
              && (
                <th colSpan="2" className="text-center">コスト</th>
              )
            }
          </tr>
          <tr style={{ wordBreak: 'keep-all' }}>
            <th>年月日</th>
            <th>キャンペーン名</th>
            <th>配信数</th>
            <th>到達数</th>
            <th>開封数</th>
            <th>開封率</th>
            <th>クリック数</th>
            <th>クリック率</th>
            <th>完了数</th>
            <th>完了率</th>
            <th>メアド取得数</th>
            <th>メアド取得率</th>
            <th>会員化数</th>
            <th>会員化率</th>
            <th>キャンペーン受注単価</th>
            <th>キャンペーン売上</th>
            <th>リフトアップ</th>
            <th>全体完了数</th>
            <th>全体売上</th>
            <th>全体会員化数</th>
            { shouldDisplayDetailCostCol
              && (
                <th>コスト</th>
              )
            }
            { shouldDisplayDetailCostCol
              && (
                <th>ROAS</th>
              )
            }
          </tr>
        </thead>
        <tbody>
          { details.reverse().map(detail => (
            <tr key={`${detail.name}_${detail.date}`}>
              <td>{detail.date}</td>
              <td>{detail.name}</td>
              <td>{detail.processedCount.toLocaleString()}</td>
              <td>{detail.deliveredCount.toLocaleString()}</td>
              <td>{detail.openCount.toLocaleString()}</td>
              <td>{Math.round(detail.openRate * 1000) / 10}%</td>
              <td>{detail.clickCount.toLocaleString()}</td>
              <td>{Math.round(detail.clickRate * 1000) / 10}%</td>
              <td>{detail.conversionCount.toLocaleString()}</td>
              <td>{Math.round(detail.conversionRate * 1000) / 10}%</td>
              <td>{detail.prospectCount.toLocaleString()}</td>
              <td>{Math.round(detail.prospectRate * 1000) / 10}%</td>
              <td>{detail.registerCount.toLocaleString()}</td>
              <td>{Math.round(detail.registerRate * 1000) / 10}%</td>
              <td>¥{Math.round(detail.orderUnitPrice).toLocaleString()}</td>
              <td>¥{detail.priceSum.toLocaleString()}</td>
              <td>{Math.round(detail.liftUp * 1000) / 10}%</td>
              <td>{detail.siteConversionCount.toLocaleString()}</td>
              <td>¥{detail.sitePriceSum.toLocaleString()}</td>
              <td>{detail.siteRegisterCount.toLocaleString()}</td>
              { shouldDisplayDetailCostCol
                && (
                  <td>{valueOrError(`¥${detail.cost.toLocaleString()}`, detail.errors)}</td>
                )
              }
              { shouldDisplayDetailCostCol
                && (
                  <td>{valueOrError(`${Math.round(detail.roas * 1000) / 10}%`, detail.errors)}</td>
                )
              }
            </tr>
          ))}
        </tbody>
      </Table>
    </Loading>
  )
}

const select = ({ transitionReport }) => ({
  transitionReport,
})

export default connect(select)(TransitionReportDetailTable)
