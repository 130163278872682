/*!
 *
 * Angle - Bootstrap Admin App + ReactJS
 *
 * Version: @version@
 * Author: @author@
 * Website: @url@
 * License: @license@
 *
 */

import React from 'react'
import ReactDOM from 'react-dom'

import { ErrorBoundary } from 'lib/components'
import App from './components/App'
import configureStore, { history } from './store/configureStore'

import './i18n'

// Vendor dependencies
import './vendor'

if (process.env.REACT_APP_ENV === 'development') {
  document.title = `(dev) ${document.title}`
} else if (process.env.REACT_APP_ENV === 'staging') {
  document.title = `(staging) ${document.title}`
}

const store = configureStore()

ReactDOM.render(
  <ErrorBoundary>
    <App store={store} history={history} />
  </ErrorBoundary>,
  document.getElementById('app'),
)
