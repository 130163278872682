import { Form, Formik } from 'formik'
import { connect } from 'react-redux'
import { Alert, Button, FormGroup } from 'reactstrap'
import React from 'react'
import * as Yup from 'yup'
import {
  FieldSingle, Label, Loading,
} from 'lib/components'
import { adminCompanyUnitPriceAction } from 'actions'
import { isNullOrUndefined } from 'lib/utils'

const requiredWhenPayPerClick = (other, schema) => (other === 'pay_per_click' ? schema.required() : schema.notRequired())

const validationSchema = Yup.object().shape({
  startDate: Yup.date().required(),
  endDate: Yup.date(),
  chargeSystem: Yup.string().required(),
  cartCampaignCpc: Yup.number().when('chargeSystem', requiredWhenPayPerClick),
  browserCampaignCpc: Yup.number().when('chargeSystem', requiredWhenPayPerClick),
  registeredCampaignCpc: Yup.number().when('chargeSystem', requiredWhenPayPerClick),
  purchasedCampaignCpc: Yup.number().when('chargeSystem', requiredWhenPayPerClick),
  popupCampaignCpc: Yup.number().when('chargeSystem', requiredWhenPayPerClick),
  prospectCampaignCpc: Yup.number().when('chargeSystem', requiredWhenPayPerClick),
  contextualCampaignCpc: Yup.number().when('chargeSystem', requiredWhenPayPerClick),
  rewardRate: Yup.number().min(0.001).max(1)
    .when('chargeSystem', (other, schema) => (other === 'pay_per_performance' ? schema.required() : schema.notRequired())),
})

export const UnitPriceForm = ({
  adminCompanyUnitPrice, companyId, item, submitAction, dispatch,
}) => (
  <Loading isLoading={adminCompanyUnitPrice.isFetching}>
    <Formik
      initialValues={{
        startDate: item.startDate || '',
        endDate: item.endDate || '',
        chargeSystem: item.chargeSystem || '',
        cartCampaignCpc: !isNullOrUndefined(item.cartCampaignCpc) ? item.cartCampaignCpc : '',
        browserCampaignCpc: !isNullOrUndefined(item.browserCampaignCpc) ? item.browserCampaignCpc : '',
        registeredCampaignCpc: !isNullOrUndefined(item.registeredCampaignCpc) ? item.registeredCampaignCpc : '',
        purchasedCampaignCpc: !isNullOrUndefined(item.purchasedCampaignCpc) ? item.purchasedCampaignCpc : '',
        popupCampaignCpc: !isNullOrUndefined(item.popupCampaignCpc) ? item.popupCampaignCpc : '',
        prospectCampaignCpc: !isNullOrUndefined(item.prospectCampaignCpc) ? item.prospectCampaignCpc : '',
        contextualCampaignCpc: !isNullOrUndefined(item.contextualCampaignCpc) ? item.contextualCampaignCpc : '',
        rewardRate: !isNullOrUndefined(item.rewardRate) ? item.rewardRate : '',
      }}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        if (submitAction === 'update') {
          dispatch(adminCompanyUnitPriceAction.submitUpdateCompanyUnitPrice({ id: item.id, companyId, ...values }))
        } else {
          dispatch(adminCompanyUnitPriceAction.submitCreateCompanyUnitPrice({ companyId, ...values }))
        }
      }}
    >
      {({ values, errors }) => (
        <Form noValidate>
          { adminCompanyUnitPrice.error && <Alert color="danger">{adminCompanyUnitPrice.error}</Alert> }
          <fieldset className="mb-3">
            <FormGroup>
              <Label require>期間（from）</Label>
              <FieldSingle name="startDate" type="date" />
            </FormGroup>
            <FormGroup>
              <Label>期間（to）</Label>
              <FieldSingle name="endDate" type="date" />
            </FormGroup>
            <FormGroup>
              <Label require>課金方式</Label>
              <FieldSingle name="chargeSystem" type="radio" option="クリック課金" value="pay_per_click" />
              <FieldSingle name="chargeSystem" type="radio" option="成果報酬課金" value="pay_per_performance" />
            </FormGroup>
            { values.chargeSystem === 'pay_per_click' &&
              <FormGroup>
                <Label require>カゴ落ちCPC</Label>
                <FieldSingle name="cartCampaignCpc" type="input" />
                <Label require>ブラウザ放棄CPC</Label>
                <FieldSingle name="browserCampaignCpc" type="input" />
                <Label require>会員登録完了CPC</Label>
                <FieldSingle name="registeredCampaignCpc" type="input" />
                <Label require>購入完了CPC</Label>
                <FieldSingle name="purchasedCampaignCpc" type="input" />
                <Label require>ポップアップCPC</Label>
                <FieldSingle name="popupCampaignCpc" type="input" />
                <Label require>ポップアップメールCPC</Label>
                <FieldSingle name="prospectCampaignCpc" type="input" />
                <Label require>商品連動CPC</Label>
                <FieldSingle name="contextualCampaignCpc" type="input" />
              </FormGroup>
            }
            { values.chargeSystem === 'pay_per_performance' &&
              <FormGroup>
                <Label require>成果報酬課金</Label>
                <FieldSingle name="rewardRate" type="input" />
              </FormGroup>
            }
          </fieldset>
          <div className="text-center">
            <Button color="primary" type="submit" size="lg">保存</Button>
          </div>
        </Form>
      )}
    </Formik>
  </Loading>
)

const select = ({
  adminCompanyUnitPrice,
}, { unitPriceId }) => {
  let item = {}
  if (unitPriceId && adminCompanyUnitPrice.items.length > 0) {
    item = adminCompanyUnitPrice.items.find(e => e.id === unitPriceId)
  }
  return {
    adminCompanyUnitPrice, item,
  }
}

export default connect(select)(UnitPriceForm)
