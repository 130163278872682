export const permissions = { ALL: 'all', ADMIN: 'admin', PUBLISH_LIMIT_DELIVERY_FEATURE: 'publish_limit_delivery_feature' }
export default {
  user: [
    {
      name: 'dashboard',
      path: '/dashboard',
      icon: 'icon-speedometer',
      displayName: 'ダッシュボード',
    },
    {
      name: 'campaign',
      icon: 'icon-target',
      displayName: 'キャンペーン（会員）',
      submenu: [
        {
          name: 'campagin_list',
          path: '/campaign_list',
          displayName: 'キャンペーン一覧',
          permission: permissions.ALL,
        },
        {
          name: 'mail_ab_test_list',
          path: '/mail_ab_test_list',
          displayName: 'ABテスト一覧',
          permission: permissions.ALL,
        },
        {
          name: 'campaign_common_setting',
          path: '/campaign_common_setting',
          displayName: 'キャンペーン全体設定',
          permission: permissions.ALL,
        },
        {
          name: 'subscription_tracking_settings',
          path: '/subscription_tracking_settings',
          displayName: 'オプトアウトリンク設定',
          permission: permissions.ALL,
        },
        {
          name: 'suppression_new',
          path: '/suppression_new',
          displayName: '配信停止設定',
          permission: permissions.ALL,
        },
        {
          name: 'campaign_template_value',
          path: '/campaign_template_value',
          displayName: 'テンプレートデフォルト値',
          permission: permissions.ALL,
        },
        {
          name: 'limit_delivery_setting',
          path: '/limit_delivery_setting',
          displayName: 'メール配信数制限',
          permission: permissions.PUBLISH_LIMIT_DELIVERY_FEATURE,
        },
      ],
    },
    {
      name: 'popup',
      icon: 'icon-bubble',
      displayName: 'キャンペーン（新規）',
      submenu: [
        {
          name: 'popup_list',
          path: '/popup_list',
          displayName: 'ポップアップ設定一覧',
          permission: permissions.ALL,
        },
        {
          name: 'prospects_campaign_list',
          path: '/prospects_campaign_list',
          displayName: 'メール設定一覧',
          permission: permissions.ALL,
        },
      ],
    },
    {
      name: 'report',
      icon: 'icon-graph',
      displayName: 'キャンペーンレポート',
      submenu: [
        {
          name: 'transition_report',
          path: '/transition_report',
          displayName: '推移レポート',
          permission: permissions.ALL,
        },
        {
          name: 'comparison_report',
          path: '/comparison_report',
          displayName: '比較レポート',
          permission: permissions.ALL,
        },
        {
          name: 'mail_ab_test_report',
          path: '/mail_ab_test_report',
          displayName: 'ABテストレポート',
          permission: permissions.ALL,
        },
        {
          name: 'ai_optimized_campaign_report',
          path: '/ai_optimized_campaign_report',
          displayName: 'AI配信時間最適化レポート',
          permission: permissions.ADMIN,
        },
      ],
    },
    {
      name: 'data',
      icon: 'icon-magnifier',
      displayName: 'サイトデータ',
      submenu: [
        {
          name: 'behavior_history',
          path: '/behavior_history',
          displayName: '行動履歴検索',
          permission: permissions.ALL,
        },
        {
          name: 'behavior_summary',
          path: '/behavior_summary',
          displayName: '行動情報集計',
          permission: permissions.ALL,
        },
        {
          name: 'item_list',
          path: '/item_list',
          displayName: '商品一覧',
          permission: permissions.ALL,
        },
        {
          name: 'tag_parameter_confirmation',
          path: '/tag_parameter_confirmation',
          displayName: 'タグ連携状況（内部のみ）',
          permission: permissions.ADMIN,
        },
      ],
    },
    {
      name: 'account',
      icon: 'icon-user',
      displayName: 'アカウント',
      submenu: [
        {
          name: 'user_setting',
          path: '/user_setting',
          displayName: 'ユーザ情報',
          permission: permissions.ALL,
        },
        {
          name: 'company_setting',
          path: '/company_setting',
          displayName: '企業情報',
          permission: permissions.ALL,
        },
        {
          name: 'registered_user_management',
          path: '/registered_user_management',
          displayName: '登録ユーザ管理',
          permission: permissions.ALL,
        },
        {
          name: 'main_user_setting',
          path: '/main_user_setting',
          displayName: 'メインユーザ切替',
          permission: permissions.ALL,
        },
        {
          name: 'reco_template_setting',
          path: '/reco_template_setting',
          displayName: 'レコ連携設定（内部のみ）',
          permission: permissions.ADMIN,
        },
      ],
    },
    {
      name: 'system',
      icon: 'icon-wrench',
      displayName: 'システム設定',
      submenu: [
        {
          name: 'customer_import',
          path: '/customer_import',
          displayName: '宛先データ取り込み',
          permission: permissions.ALL,
        },
        {
          name: 'item_import',
          path: '/item_import',
          displayName: '商品データ取り込み',
          permission: permissions.ALL,
        },
        {
          name: 'tracking_code',
          path: '/tracking_code',
          displayName: '動的タグ',
          permission: permissions.ALL,
        },
        {
          name: 'line_setting',
          path: '/line_setting',
          displayName: 'LINE設定',
          permission: permissions.ALL,
        },
      ],
    },
    {
      name: 'manual',
      icon: 'icon-book-open',
      displayName: 'マニュアル',
      submenu: [
        {
          name: 'infomation',
          path: `${process.env.REACT_APP_API_ROOT}/api/docs/`,
          displayName: '問い合わせ先',
          permission: permissions.ALL,
        },
        {
          name: 'manual_introduction',
          path: '/manual_introduction',
          displayName: '導入時資料',
          permission: permissions.ALL,
        },
        {
          name: 'manual_mailoperation',
          path: '/manual_mailoperation',
          displayName: 'メール担当者向け資料',
          permission: permissions.ALL,
        },
        {
          name: 'manual_term',
          path: `${process.env.REACT_APP_API_ROOT}/api/docs/manual_term/`,
          displayName: '規約に関する資料',
          permission: permissions.ALL,
        },
      ],
    },
    {
      name: 'user_support',
      icon: 'icon-question',
      displayName: 'ユーザサポート',
      path: 'https://naviplus.my.site.com/customer/s/login/',
      permission: permissions.ALL,
    },
  ],
  admin: [
    {
      name: 'dashboard',
      path: '/admin/dashboard',
      icon: 'icon-speedometer',
      displayName: 'ダッシュボード',
    },
    {
      name: 'campaign',
      icon: 'icon-target',
      displayName: 'キャンペーンテンプレート',
      submenu: [
        {
          name: 'campaign_template_list',
          path: '/admin/campaign_template_list',
          displayName: 'メールテンプレート一覧',
        },
        {
          name: 'template_tag_list',
          path: '/admin/template_tag_list',
          displayName: 'テンプレートのタグ一覧',
        },
      ],
    },
    {
      name: 'user',
      icon: 'icon-user',
      displayName: 'ユーザ管理',
      submenu: [
        {
          name: 'company',
          path: '/admin/company_list',
          displayName: '登録企業一覧',
        },
      ],
    },
  ],
}
