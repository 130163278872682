import React from 'react'
import { connect as formikConnect, useField, useFormikContext } from 'formik'
import { Input, FormFeedback, InputGroup } from 'reactstrap'
import CustomSelect from './CustomSelect'


const FieldGroup = ({ formik, children, ...props }) => {
  const childrenArray = children instanceof Array ? children : [children]
  const { errors } = useFormikContext()
  // nameがuser[email]の形式の場合は['user', email]に変換する
  const childrenPropsNamesArray = childrenArray.map((x) => (
    (x.props.name || '').replace(/\]$/, '').split(/\]?\[/)
  ))
  return (
    <InputGroup {...props}>
      { childrenArray.map((child, index) => {
        if (child.type !== Input) { return child }
        const type = Object.keys(typeMap).includes(child.props.type) ? typeMap[child.props.type] : FieldText
        return React.createElement(
          type,
          {
            key: `form-field-${child.props.name}[${index}]`,
            ...child.props
          },
        )
      }) }
      { childrenPropsNamesArray.map((nameArray, index) => (
        <FormFeedback key={`form-error-${nameArray.join('_')}[${index}]`}>{dig(errors, nameArray)}</FormFeedback>
      )) }
    </InputGroup>
  )
}

const FieldText = ({name, ...props}) => {
  const [field, meta] = useField(name)
  return (
    <Input invalid={meta.touched && !!meta.error} {...field} {...props}/>
  )
}

const FieldSelect = ({name,  ...props}) => {
  const [field, meta] = useField(name)
  return (
    <CustomSelect name={name} invalid={meta.touched && !!meta.error} {...field} {...props}/>
  )
}

const FieldCheckbox = ({name, option, onChange, ...props}) => {
  const [field, meta] = useField({name: name, type: 'checkbox'})
  const replacedOnChange = onChange ? (e) => { onChange(e); field.onChange(e) } : field.onChange
  return (
    <label className='checkbox-label'>
      <Input invalid={!!meta.error} {...field} {...props} onChange={replacedOnChange}/>
      <span>{option}</span>
    </label>
  )
}

const FieldRadio = ({name, value, option, ...props}) => {
  const [field, meta] = useField({name: name, type: 'radio', value: value})
  return (
    <label>
      <Input invalid={!!meta.error} {...field} {...props}/>
      <span>{option}</span>
    </label>
  )
}

const FieldMultiCheckbox = ({name, option, value, ...props}) => {
  const [field, meta] = useField({name: name})
  return (
    <label style={{ marginRight: '10px' }}>
      <Input
        style={{ position: 'relative', marginRight: '5px', marginLeft: '0' }}
        invalid={!!meta.error}
        {...field}
        {...props}
        value={value}
        checked={field.value.some((x) => (`${x}` === `${value}`))}
        type='checkbox'
      />
      <span>{option}</span>
    </label>
  )
}

const dig = (target, keys) => {
  let digged = target
  for (const key of keys) {
    if (typeof digged === 'undefined' || digged === null) {
      return undefined
    }
    if (typeof key === 'function') {
      digged = key(digged)
    } else {
      digged = digged[key]
    }
  }
  return digged
}

const typeMap = {
  select: FieldSelect,
  checkbox: FieldCheckbox,
  radio: FieldRadio,
  multicheckbox: FieldMultiCheckbox,
}

export default formikConnect(FieldGroup)
