import React, { useState } from 'react'
import { Button, Modal, ModalBody } from 'reactstrap'
import { MailPreview } from 'lib/components'

export const MailPreviewButton = ({ onClickCallback, color = 'info', children }) => {
  const [isOpen, setIsOpen] = useState(false)
  return (
    <>
      <Button
        color={color}
        onClick={() => {
          onClickCallback()
          setIsOpen(true)
        }}
      >
        {children}
      </Button>
      <Modal modalClassName="preview-modal" isOpen={isOpen} toggle={() => setIsOpen(false)} >
        <ModalBody>
          <MailPreview source="doc" />
        </ModalBody>
      </Modal>
    </>
  )
}

export default MailPreviewButton
