import React from 'react'
import * as Sentry from '@sentry/browser'
import ContentWrapper from 'components/layout/ContentWrapper'
import { Card, CardBody, CardHeader } from 'reactstrap'

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment: process.env.REACT_APP_ENV,
  enabled: !['development', 'test'].includes(process.env.REACT_APP_ENV),
})

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { error: null }
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ error })
    Sentry.withScope((scope) => {
      Object.keys(errorInfo).forEach((key) => {
        scope.setExtra(key, errorInfo[key])
      })
      console.log('send error!')
      Sentry.captureException(error)
    })
  }

  render() {
    if (this.state.error) { return <ErrorPage /> }
    return this.props.children
  }
}

const ErrorPage = () => (
  (
    <ContentWrapper>
      <div className="content-heading">
        <div>エラー</div>
      </div>
      <Card className="card-default">
        <CardHeader>システムエラーが発生しました。</CardHeader>
        <CardBody>
          <p>大変お手数ですが、サイト管理者までご連絡ください。</p>
          <p>連絡先: &nbsp;support@naviplus.co.jp</p>
        </CardBody>
      </Card>
    </ContentWrapper>
  )
)

export default ErrorBoundary
