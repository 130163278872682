import React from 'react'
import moment from 'moment'
import { connect } from 'react-redux'
import { Table, Button, UncontrolledTooltip } from 'reactstrap'
import { campaignConstant as cc, behaviorConstant as bc } from 'config/constants'
import { arraysToObject } from 'lib/utils'
import { Loading } from 'lib/components'

const abandonedTypes = arraysToObject(cc.abandonedTypes, 1, 0)
const compltedTypes = arraysToObject(cc.completedTypes, 1, 0)
const behaviorTypes = arraysToObject(bc.behaviorTypes, 1, 0)

export const BehaviorHistoryList = ({ behaviorHistory, offset = 0, limit = 20 }) => {
  const histories = [...behaviorHistory.items]

  return (
    <Loading isLoading={behaviorHistory.isFetching}>
      <Table bordered striped responsive>
        <thead>
          <tr style={{ wordBreak: 'keep-all' }}>
            <th>イベント種別</th>
            <th>発生日時</th>
            <th>端末</th>
            <th>詳細</th>
            <th>送信内容表示</th>
          </tr>
        </thead>
        <tbody>
          {
            histories.reverse()
              .filter((his, index) => (index >= offset && index < offset + limit))
              .map(his => {
                const params = new URLSearchParams({
                  code: his.userCode,
                  mail_code: his.mailCode,
                  branch_code: his.branchCode,
                  campaign_type: his.campaignType,
                }).toString()
                const apiPath = `${process.env.REACT_APP_API_ROOT}/api/behaviors/back_number`
                const htmlUrl = new URL(`${apiPath}.html?${params}`).href
                const textUrl = new URL(`${apiPath}.txt?${params}`).href

                return (
                  <tr key={`${his.eventTimeUuid}`}>
                    <td>{behaviorTypes[his.behaviorType]}</td>
                    <td>{moment(his.eventTime).format('YYYY/MM/DD HH:mm:ss')}</td>
                    <td>
                      { (his.isPc || his.isMobile || his.isTablet)
                      && (
                        <>
                          <span className="link-like" id={`userAgentTooltip_${his.eventTimeUuid}`}>
                            {converToDisplaydDevise(his.isPc, his.isMobile, his.isTablet)}
                          </span>
                          <UncontrolledTooltip placement="top" target={`userAgentTooltip_${his.eventTimeUuid}`}>
                            {his.userAgent}
                          </UncontrolledTooltip>
                        </>
                      )
                      }
                    </td>
                    <td>
                      { ([1, 2, 3, 8, 14, 15, 16].includes(his.behaviorType) && his.item)
                      && (
                        <ul className="mb-0">
                          <li>商品コード：{his.itemCode}</li>
                          <li>商品名：{his.item.name}</li>
                          { ([2, 3, 8].includes(his.behaviorType) && his.itemQty)
                          && (
                            <li>数量：{his.itemQty}</li>
                          )
                          }
                        </ul>
                      )
                      }
                      { ([4, 5, 6].includes(his.behaviorType) && his.campaign)
                      && (
                        <ul className="mb-0">
                          <li>キャンペーン名：{his.campaign.name}</li>
                          { his.campaignType === 'abandoned'
                          && (
                            <li>キャンペーン種別：{abandonedTypes[his.campaign.abandonedType]}</li>
                          )
                          }
                          { his.campaignType === 'completed'
                          && (
                            <li>キャンペーン種別：{compltedTypes[his.campaign.triggerType]}</li>
                          )
                          }
                          { his.campaignType === 'contextual'
                          && <li>キャンペーン種別：商品連動</li>
                          }
                          { his.campaignType === 'prospects'
                          && <li>キャンペーン種別：ポップアップ</li>
                          }
                          { (his.behaviorType === 5 && his.campaignLink)
                          && (
                            <li>リンクURL：{his.campaignLink.url}</li>
                          )
                          }
                        </ul>
                      )
                      }
                    </td>
                    <td>
                      { ([4, 5, 6].includes(his.behaviorType) && his.campaign && his.campaign.messageType !== 'line')
                      && (
                        <>
                          <p>
                            <Button onClick={() => handleClick(htmlUrl)} color="secondary">HTMLパート</Button>
                          </p>
                          <p className="mb-0">
                            <Button onClick={() => handleClick(textUrl)} color="secondary">テキストパート</Button>
                          </p>
                        </>
                      )
                      }
                    </td>
                  </tr>
                )
              })
          }
        </tbody>
      </Table>
    </Loading>
  )
}

const handleClick = url => {
  global.window.open(url, 'view_mail', 'width=800,height=600,menubar=no,toolbar=no,scrollbars=yes,resizable=yes')
}

const converToDisplaydDevise = (isPc, isMobile, isTablet) => {
  if (isPc) { return 'パソコン' }
  if (isMobile) { return 'スマホ' }
  if (isTablet) { return 'タブレット' }
  return null
}

const select = ({ behaviorHistory }) => ({ behaviorHistory })

export default connect(select)(BehaviorHistoryList)
