import { handleActions } from 'redux-actions'
import generateHandlerArgs from 'modules/reducerGenerator'
import { adminCompanyStatusAction as action } from 'actions'

const overrideHandlers = {
  [action.submitTerminateCompanyStatus]: (state, action) => ({
    ...state,
    isSubmitting: true,
  }),
  [action.submitTerminateCompanyStatusDone]: (state, action) => {
    const item = state.item
    item.status = 'terminated'
    return (
      {
        ...state,
        isSubmitting: false,
        item,
        processResult: action.error ? action.payload : 'SUCCESS',
      }
    )
  },
}

const { handler, initial } = generateHandlerArgs(action, 'companyStatus', overrideHandlers)

export const adminCompanyStatus = handleActions(handler, initial)
