import React from 'react'
import { connect } from 'react-redux'
import ContentWrapper from 'components/layout/ContentWrapper'
import { Link } from 'react-router-dom'
import {
  Card, CardBody, CardFooter, CardHeader,
} from 'reactstrap'
import TransitionReportGraph from 'components/parts/report/TransitionReportGraph'
import TransitionReportKeyIndicator from 'components/parts/report/TransitionReportKeyIndicator'
import NewsReleaseList from 'components/parts/news/NewsReleaseList'

class DashboardPage extends React.Component {
  render() {
    return (
      <ContentWrapper>
        <div className="content-heading">
          <div>ダッシュボード(仮)</div>
        </div>
        <Card className="card-default">
          <CardHeader>ニュース・お知らせ</CardHeader>
          <NewsReleaseList />
        </Card>
        <Card className="card-default">
          <CardHeader>過去７日間の成果</CardHeader>
          <TransitionReportKeyIndicator />
        </Card>
        <Card className="card-default">
          <CardHeader>過去７日間の推移</CardHeader>
          <CardBody>
            <TransitionReportGraph graphHeight="200px" />
          </CardBody>
          <CardFooter className="text-right">
            <Link to="/transition_report">レポート画面へ</Link>
          </CardFooter>
        </Card>
      </ContentWrapper>
    )
  }
}

export default connect()(DashboardPage)
