import React from 'react'
import {
  Card, CardBody, Col, Row,
} from 'reactstrap'
import ContentWrapper from 'components/layout/ContentWrapper'
import { connect } from 'react-redux'
import { adminCompanyInvoiceTermAction } from 'actions'
import { useMountEffect } from 'lib/hooks'
import InvoiceTermForm from 'components/parts/invoiceTerm/InvoiceTermForm'

export const AdminCompanyInvoiceTermEditPage = ({match, dispatch}) => {
  const companyId = Number(match.params.companyId)
  const invoiceTermId = Number(match.params.id)

  useMountEffect(() => {
    dispatch(adminCompanyInvoiceTermAction.fetchCompanyInvoiceTerm({ companyId, id: invoiceTermId }))
  })

  return (
    <ContentWrapper>
      <div className="content-heading">
        <div>
          請求基本情報編集
        </div>
      </div>
      <Row>
        <Col xl={12}>
          <Card className="card-default">
            <CardBody>
              <InvoiceTermForm
                submitAction="update"
                invoiceTermId={invoiceTermId}
                companyId={companyId}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </ContentWrapper>
  )
}

export default connect()(AdminCompanyInvoiceTermEditPage)
