import { createActions } from 'redux-actions'

const FETCH_RECO_TEMPLATES = 'FETCH_RECO_TEMPLATES'
const FETCH_RECO_TEMPLATES_DONE = 'FETCH_RECO_TEMPLATES_DONE'

const SUBMIT_UPDATE_RECO_TEMPLATE = 'SUBMIT_UPDATE_RECO_TEMPLATE'
const SUBMIT_UPDATE_RECO_TEMPLATE_DONE = 'SUBMIT_UPDATE_RECO_TEMPLATE_DONE'

const SUBMIT_CREATE_RECO_TEMPLATE = 'SUBMIT_CREATE_RECO_TEMPLATE'
const SUBMIT_CREATE_RECO_TEMPLATE_DONE = 'SUBMIT_CREATE_RECO_TEMPLATE_DONE'

export const recoTemplateAction = createActions(
  {
    [FETCH_RECO_TEMPLATES_DONE]: [
      (...args) => args[0],
      (...args) => args[1],
    ],
    [SUBMIT_UPDATE_RECO_TEMPLATE_DONE]: [
      (...args) => args[0],
      (...args) => Object.assign({ notification: 'ALL_CASE' }, args[1]),
    ],
    [SUBMIT_CREATE_RECO_TEMPLATE_DONE]: [
      (...args) => args[0],
      (...args) => Object.assign({ notification: 'ALL_CASE' }, args[1]),
    ],
  },

  FETCH_RECO_TEMPLATES,
  SUBMIT_UPDATE_RECO_TEMPLATE,
  SUBMIT_CREATE_RECO_TEMPLATE,
)
