import React from 'react'
import { connect } from 'react-redux'
import ContentWrapper from 'components/layout/ContentWrapper'
import {
  Card, CardBody, CardHeader, FormText,
} from 'reactstrap'
import { useMountEffect } from 'lib/hooks'
import { companyCampaignTemplateValueAction } from 'actions'
import CompanyCampaignTemplateValueForm from 'components/parts/company/CompanyCampaignTemplateValueForm'


export const CampaignTemplateValuePage = ({ dispatch }) => {
  useMountEffect(() => {
    dispatch(companyCampaignTemplateValueAction.fetchCompanyCampaignTemplateValue())
  })

  return (
    <ContentWrapper>
      <div className="content-heading">
        <div>テンプレートデフォルト値</div>
      </div>
      <Card className="card-default">
        <CardHeader>テンプレートデフォルト値</CardHeader>
        <FormText className="ml-3" tag="span">
          キャンペーン新規作成時、テンプレート内にデフォルト値として埋め込まれます。<br />
          ※HTMLエディタ用テンプレートにのみ対応しております。
        </FormText>
        <CardBody>
          <CompanyCampaignTemplateValueForm />
        </CardBody>
      </Card>
    </ContentWrapper>
  )
}

export default connect()(CampaignTemplateValuePage)
