import React from 'react'
import { connect } from 'react-redux'
import { CSVLink } from 'react-csv'
import { tagParameterConstant as gc } from 'config/constants'

export const TagParameterCustomersDataCsvDownloadButton = ({ customersData, queryObj }) => {
  if (customersData.items.length === 0) { return null }
  const headers = gc.customersHeaders
  const data = [...customersData.items]
  return (
    <CSVLink
      className="btn btn-success"
      filename={`顧客情報ログ_${queryObj.confirmationIndexTerm}.csv`}
      data={data}
      headers={headers}
    >
      {queryObj.confirmationIndexTerm}
の顧客情報ログをダウンロードする（10件）
    </CSVLink>
  )
}


const select = ({
  elasticsearchCustomers, queryObj,
}) => {
  const customers = elasticsearchCustomers.items
  return {
    customersData: {
      items: [...customers],
    },
    _isLoading: elasticsearchCustomers.isFetching,
    ...queryObj,
  }
}

export default connect(select)(TagParameterCustomersDataCsvDownloadButton)
