import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'

import {
  Alert, FormGroup, Input, InputGroupAddon,
} from 'reactstrap'
import { FieldGroup, Label } from 'lib/components'
import { adminAuthAction }  from 'actions'

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email()
    .required(),
  password: Yup.string()
    .required(),
})

export const AdminLoginForm = ({ auth, dispatch }) => (
  <Formik
    initialValues={{
      email: '',
      password: '',
    }}
    validationSchema={validationSchema}
    onSubmit={(values) => {
      dispatch(adminAuthAction.fetchLogin(values.email, values.password))
    }}
  >
    {() => (
      <Form noValidate>
        { auth.error && <Alert color="danger">{auth.error}</Alert> }
        <FormGroup>
          <Label>メールアドレス</Label>
          <FieldGroup>
            <Input name="email" type="email" className="border-right-0" />
            <InputGroupAddon addonType="append">
              <span className="input-group-text text-muted bg-transparent border-left-0">
                <em className="fa fa-envelope" />
              </span>
            </InputGroupAddon>
          </FieldGroup>
        </FormGroup>
        <FormGroup>
          <Label>パスワード</Label>
          <FieldGroup>
            <Input name="password" type="password" className="border-right-0" />
            <InputGroupAddon addonType="append">
              <span className="input-group-text text-muted bg-transparent border-left-0">
                <em className="fa fa-lock" />
              </span>
            </InputGroupAddon>
          </FieldGroup>
        </FormGroup>
        <button className="btn btn-block btn-primary mt-3" type="submit">ログイン</button>
      </Form>
    )}
  </Formik>
)

AdminLoginForm.propTypes = {
  dispatch: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
}

function select({ auth }) {
  return { auth }
}

export default connect(select)(AdminLoginForm)
