import { createActions } from 'redux-actions'

const FETCH_CURRENT_USER = 'FETCH_CURRENT_USER'
const FETCH_CURRENT_USER_DONE = 'FETCH_CURRENT_USER_DONE'

const SUBMIT_UPDATE_CURRENT_USER = 'SUBMIT_UPDATE_CURRENT_USER'
const SUBMIT_UPDATE_CURRENT_USER_DONE = 'SUBMIT_UPDATE_CURRENT_USER_DONE'

const SUBMIT_UPDATE_PASSWORD = 'SUBMIT_UPDATE_PASSWORD'
const SUBMIT_UPDATE_PASSWORD_DONE = 'SUBMIT_UPDATE_PASSWORD_DONE'

export const userAction = createActions(
  {
    [FETCH_CURRENT_USER_DONE]: [
      (...args) => args[0],
      (...args) => Object.assign({ ignoreUnauth: true }, args[1]),
    ],
    [SUBMIT_UPDATE_CURRENT_USER_DONE]: [
      (...args) => args[0],
      (...args) => Object.assign({ notification: 'ALL_CASE' }, args[1]),
    ],
    [SUBMIT_UPDATE_PASSWORD_DONE]: [
      (...args) => args[0],
      (...args) => Object.assign({ notification: 'ALL_CASE' }, args[1]),
    ],
  },
  FETCH_CURRENT_USER,
  SUBMIT_UPDATE_CURRENT_USER,
  SUBMIT_UPDATE_PASSWORD,
)
