import React from 'react'
import { Formik, Form } from 'formik'
import moment from 'moment'
import { connect } from 'react-redux'
import * as Yup from 'yup'
import {
  Col, Row, Button, FormGroup, Input,
} from 'reactstrap'
import { FieldSingle, FieldGroup, InputGroupAddon, Loading } from 'lib/components'

import { transitionReportAction } from 'actions'
import { reportConstant as rc } from 'config/constants'

const validationSchema = Yup.object().shape({
  campaign: Yup.string(),
  term: Yup.string(),
  transition: Yup.string(),
  termFrom: Yup.string(),
  termTo: Yup.string(),
})

const convertToSubmitValues = (values) => {
  const { termFrom, termTo, ...baseValues } = values
  const submitValues = values.term === 'free_period' ? values : baseValues
  return submitValues
}

export const TransitionReportConditionForm = ({ campaign, queryObj, dispatch }) => {
  let campaigns = campaign.items
  if (!queryObj.showDisable) {
    campaigns = campaigns.filter(cam => cam.enabled)
  }
  const campaignOption = campaigns.map(cam => (
    [cam.name, `${cam._camGroup}${rc.campaignTypeDelimiter}${cam.id}`]
  ))
  const transitions = rc.transitions.filter((transition) => {
    if (['this_week', 'last_week'].includes(queryObj.term)) {
      return transition[1] !== 'monthly'
    } else {
      return true
    }
  })
  return (
    <Loading isLoading={campaign.isFetching}>
      <Formik
        initialValues={{
          campaign: queryObj.campaignType || rc.defaultTransitionReportCondition.campaignType,
          term: queryObj.term || rc.defaultTransitionReportCondition.term,
          transition: queryObj.transition || rc.defaultTransitionReportCondition.transition,
          termFrom: queryObj.termFrom || moment().subtract(1, 'months').format('YYYY-MM-DD'),
          termTo: queryObj.termTo || moment().format('YYYY-MM-DD'),
          showDisable: !!queryObj.showDisable,
        }}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          const submitValues = convertToSubmitValues(values)
          dispatch(transitionReportAction.searchTransitionReport(submitValues))
        }}
      >
        {({ values }) => (
          <Form noValidate>
            <Row>
              <Col>
                <FormGroup className="mb-3">
                  <FieldSingle type="select" name="campaign" options={rc.campaignTypes.concat(campaignOption)} />
                </FormGroup>
                <FormGroup check>
                  <FieldSingle name="showDisable" type="checkbox" option="配信中以外のキャンペーンも表示する" />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col xs="8" md="10">
                <FormGroup className="mb-3">
                  <FieldSingle type="select" name="term" options={rc.terms} />
                </FormGroup>
              </Col>
              <Col xs="4" md="2">
                <FormGroup className="mb-3">
                  <FieldSingle type="select" name="transition" options={transitions} />
                </FormGroup>
              </Col>
            </Row>
            { values.term === 'free_period'
            && (
              <FormGroup className="mb-3">
                <FieldGroup>
                  <Input name="termFrom" type="date" />
                  <InputGroupAddon addonType="middle">〜</InputGroupAddon>
                  <Input name="termTo" type="date" />
                </FieldGroup>
              </FormGroup>
            )
            }
            <div className="text-center"><Button color="primary" type="submit">レポートを更新</Button></div>
          </Form>
        )}
      </Formik>
    </Loading>
  )
}

const select = ({
  abandonedCampaign, completedCampaign, contextualCampaign, popup, prospectsCampaign,
}) => {
  const abanItems = abandonedCampaign.items.map((x) => { x._camGroup = 'abandoned'; return x })
  const compItems = completedCampaign.items.map((x) => { x._camGroup = 'completed'; return x })
  const contItems = contextualCampaign.items.map((x) => { x._camGroup = 'contextual'; return x })
  const popItems = popup.items.map((x) => { x._camGroup = 'popup'; return x })
  const prosItems = prospectsCampaign.items.map((x) => { x._camGroup = 'prospects'; return x })
  return {
    campaign: {
      items: [...abanItems, ...compItems, ...contItems, ...popItems, ...prosItems],
      isFetching: abandonedCampaign.isFetching || completedCampaign.isFetching || contextualCampaign.isFetching || popup.isFetching || prospectsCampaign.isFetching,
    },
  }
}

export default connect(select)(TransitionReportConditionForm)
