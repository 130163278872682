const linkButtonInnerHTML = (props = {}) => {
  const href = props.href
  const buttonType = props['button-type']
  return `
<a href="${href}" target="_blank">
<img class="fullwidth fullwidth-btn" align="center" border="0"
     src="https://s3-ap-northeast-1.amazonaws.com/rtg-mail/mailtemplate/img/${buttonType}.png"
     width="290">
</a>
`
}

const linkButtonFullHTML = (props = {}) => {
  const href = props.href
  const buttonType = props['button-type']
  return `
<div data-gjs-type="linkButton" data-gjs-href="${href}" data-gjs-button-type="${buttonType}">
  <div data-gjs-type="unLayerable">
    ${linkButtonInnerHTML(props)}
  </div>
</div>
`
}

const generateLinkButton = () => ({
  model: {
    defaults: {
      droppable: false,
      traits: [
        {
          type: 'select',
          name: 'button-type',
          label: 'ボタン種別',
          options: [
            { id: 'btn_2_site_black', name: 'サイトを見る' },
            { id: 'btn_3_cart_black', name: 'カートに戻る' },
            { id: 'btn_4_shopping_black', name: 'ショッピングを続ける' },
            { id: 'btn_5_detail_black', name: '詳細を見る' },
            { id: 'btn_6_review_black', name: 'レビューを書く' },
            { id: 'btn_9_register_black', name: '会員登録する' },
          ],
          changeProp: true,
        },
        {
          type: 'text',
          name: 'href',
          label: 'リンク先',
          changeProp: true,
        },
      ],
    },
    init() {
      this.on('change', this.handleChange)
    },
    handleChange() {
      this.view.renderElement()
    },
    toHTML() {
      return linkButtonFullHTML(this.props())
    },
  },
  view: {
    events: {
      dblclick: 'onActive',
    },

    renderElement() {
      this.el.innerHTML = linkButtonInnerHTML(this.model.props())
    },
    onRender({ el }) {
      this.renderElement()
    },
  },
})

export default generateLinkButton
