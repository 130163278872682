import { camelizeKeys, decamelizeKeys } from 'humps'
import { serializeToQueryString, getCookie } from 'lib/utils'
import { generalConstant as gc } from 'config/constants'

/**
 * APIの呼び出し用メソッド
 * @param  {string} fullUrl プロトコル、ドメイン、パスを含んだURL（queryStringは含まない）
 * @param  {string} method  GET・POST等
 * @param  {object} data    送信データ
 * @return {function}       fetchを使用
 */

const call = (fullUrl, method, data, { encode = true } = {}) => {
  const reqObj = Object.assign({}, { method, credentials: 'include', mode: 'cors' })

  if (method === 'GET') {
    const queryString = serializeToQueryString(data, { encode })
    fullUrl = `${fullUrl}${queryString}`
  } else {
    reqObj.headers = reqObj.headers || {}
    reqObj.headers.Accept = 'application/json'
    reqObj.headers['Content-Type'] = 'application/json'

    const cookie = getCookie()
    if (cookie[gc.csrfCookieName]) reqObj.headers[gc.csrfHeaderName] = unescape(cookie[gc.csrfCookieName])

    reqObj.body = JSON.stringify(decamelizeKeys(data))
  }

  console.log('RequestBody', reqObj)
  return fetch(fullUrl, reqObj)
    .then(async res => (handleResponse(res, data)))
    .catch(error => ({ payload: error, meta: null, error: true }))
}


/**
 * レスポンスの処理用メソッド（非同期処理）
 * @param  {object}  res  fetchのレスポンスデータ
 * @param  {object}  data 送信データ
 *  ※更新に成功した場合でも「204 No Content」が返ってきて、
 *    更新されたデータがわからない場合に使用
 * @return {Promise}      更新データ
 */

const handleResponse = async (res, data) => {
  console.log(res)
  let meta = { statusCode: res.status, headers: res.headers }
  if (!res.ok) {
    return res.json()
      .then((err) => {
        const errObj = new Error(err.error || err.error_message)
        return { payload: errObj, meta, error: true }
      })
      .catch(() => ({ payload: new Error(), meta, error: true }))
  }
  let json
  try {
    json = await res.json()
  } catch (e) {
    json = data
  }
  return { payload: camelizeKeys(json), meta }
}

export default call
