import { createActions } from 'redux-actions'

const FETCH_SUBSCRIPTION_TRACKING_SETTINGS = 'FETCH_SUBSCRIPTION_TRACKING_SETTINGS'
const FETCH_SUBSCRIPTION_TRACKING_SETTINGS_DONE = 'FETCH_SUBSCRIPTION_TRACKING_SETTINGS_DONE'
const UPDATE_SUBSCRIPTION_TRACKING_SETTINGS = 'UPDATE_SUBSCRIPTION_TRACKING_SETTINGS'
const UPDATE_SUBSCRIPTION_TRACKING_SETTINGS_SUCCESS = 'UPDATE_SUBSCRIPTION_TRACKING_SETTINGS_SUCCESS'
const UPDATE_SUBSCRIPTION_TRACKING_SETTINGS_FAILURE = 'UPDATE_SUBSCRIPTION_TRACKING_SETTINGS_FAILURE'

export const subscriptionTrackingSettingsAction = createActions(
  {
    [UPDATE_SUBSCRIPTION_TRACKING_SETTINGS]: (...args) => ({ settings: args[0], setErrors: args[1] }),
  },
  FETCH_SUBSCRIPTION_TRACKING_SETTINGS,
  FETCH_SUBSCRIPTION_TRACKING_SETTINGS_DONE,
  UPDATE_SUBSCRIPTION_TRACKING_SETTINGS_SUCCESS,
  UPDATE_SUBSCRIPTION_TRACKING_SETTINGS_FAILURE,
)
