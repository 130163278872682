import { createActions } from 'redux-actions'

const FETCH_COMPANY = 'FETCH_COMPANY'
const FETCH_COMPANY_DONE = 'FETCH_COMPANY_DONE'

const SUBMIT_UPDATE_COMPANY = 'SUBMIT_UPDATE_COMPANY'
const SUBMIT_UPDATE_COMPANY_DONE = 'SUBMIT_UPDATE_COMPANY_DONE'

export const companyAction = createActions(
  {
    [FETCH_COMPANY_DONE]: [
      (...args) => args[0],
      (...args) => args[1],
    ],
    [SUBMIT_UPDATE_COMPANY_DONE]: [
      (...args) => args[0],
      (...args) => Object.assign({ notification: 'ALL_CASE' }, args[1]),
    ],
  },

  FETCH_COMPANY,

  SUBMIT_UPDATE_COMPANY,

)
