import React from 'react'
import { connect } from 'react-redux'
import { Table, UncontrolledTooltip } from 'reactstrap'
import { HelpIcon, Loading } from 'lib/components'

export const BehaviorSummaryTable = ({ behaviorSummary, cart, browser }) => {
  const summary = behaviorSummary.item.summary
  return (
    <Loading isLoading={behaviorSummary.isFetching}>
      <Table bordered responsive>
        <thead>
          <tr style={{ wordBreak: 'keep-all' }}>
            { cart &&
              <>
                <th>
                  カートUB数
                  <HelpIcon id="cartUbCountTooltip" />
                </th>
                <th>
                  カゴ落ちUB数
                  <HelpIcon id="abandonedCartCountTooltip" />
                </th>
                <th>
                  カゴ落ち率
                  <HelpIcon id="abandonedCartRateTooltip" />
                </th>
              </>
            }
            { browser &&
              <>
                <th>
                  アイテムUB数
                  <HelpIcon id="viewUbCountTooltip" />
                </th>
                <th>
                  ブラウザ放棄UB数
                  <HelpIcon id="abandonedBrowserCountTooltip" />
                </th>
                <th>
                  ブラウザ放棄率
                  <HelpIcon id="abandonedBrowserRateTooltip" />
                </th>
              </>
            }
            <th>
              配信対象ユーザ数
              <HelpIcon id="deliverableCountTooltip" />
            </th>
            <th>
              抽出率
              <HelpIcon id="matchingRateTooltip" />
            </th>
            <th>
              受注単価
              <HelpIcon id="averageOrderPriceTooltip" />
            </th>
            <th>
              ポテンシャル売上
              <HelpIcon id="potentialPriceTooltip" />
            </th>
            <th>
              ヒット率５％
              <HelpIcon id="hitRate5Tooltip" />
            </th>
          </tr>
        </thead>
        <tbody>
          { summary
            && (
              <tr>
                { cart &&
                  <>
                    <td>{summary.cartUbCount.toLocaleString()}</td>
                    <td>{summary.abandonedCartCount.toLocaleString()}</td>
                    <td>{Math.round(summary.abandonedCartRate * 1000) / 10}%</td>
                  </>
                }
                { browser &&
                  <>
                    <td>{summary.viewUbCount.toLocaleString()}</td>
                    <td>{summary.abandonedBrowserCount.toLocaleString()}</td>
                    <td>{Math.round(summary.abandonedBrowserRate * 1000) / 10}%</td>
                  </>
                }
                <td>{summary.deliverableCount.toLocaleString()}</td>
                <td>{Math.round(summary.matchingRate * 1000) / 10}%</td>
                <td>¥{summary.averageOrderPrice.toLocaleString()}</td>
                <td>¥{summary.potentialPrice.toLocaleString()}</td>
                <td>¥{Math.round(summary.hitRate5).toLocaleString()}</td>
              </tr>
            )
          }
        </tbody>
        <IndicatorTooltips cart={cart} browser={browser}/>
      </Table>
    </Loading>
  )
}

const IndicatorTooltips = ({cart, browser}) => (
  <>
    { cart &&
      <>
        <UncontrolledTooltip target="cartUbCountTooltip" placement="bottom">
          カート画面へのアクセス件数のうちユニークブラウザ（Cookie値）の件数
        </UncontrolledTooltip>
        <UncontrolledTooltip target="abandonedCartCountTooltip" placement="bottom">
          カートUB数(日次)ー完了UB数(日次)の合計値（集計期間内）
        </UncontrolledTooltip>
        <UncontrolledTooltip target="abandonedCartRateTooltip" placement="bottom">
          カゴ落ちUB数÷カートUB数
        </UncontrolledTooltip>
      </>
    }
    { browser &&
      <>
        <UncontrolledTooltip target="viewUbCountTooltip" placement="bottom">
          商品詳細画面へのアクセス件数のうちユニークブラウザ（Cookie値）の件数
        </UncontrolledTooltip>
        <UncontrolledTooltip target="abandonedBrowserCountTooltip" placement="bottom">
          アイテムUB数(日次)ーカートUB数(日次)の合計値（集計期間内）
        </UncontrolledTooltip>
        <UncontrolledTooltip target="abandonedBrowserRateTooltip" placement="bottom">
          ブラウザ放棄UB数÷アイテムUB数
        </UncontrolledTooltip>
      </>
    }
    <UncontrolledTooltip target="deliverableCountTooltip" placement="bottom">
      離脱UB数のうちメール配信可能なユーザ数、ただしオプトアウトした数は含む
    </UncontrolledTooltip>
    <UncontrolledTooltip target="matchingRateTooltip" placement="bottom">
      配信対象ユーザ数÷離脱UB数
    </UncontrolledTooltip>
    <UncontrolledTooltip target="averageOrderPriceTooltip" placement="bottom">
      購入完了画面アクセスのうちユニークな受注コード（orderCode）に絞り受注額（totalPrice）を合計した値÷ユニークなorderCodeの件数
    </UncontrolledTooltip>
    <UncontrolledTooltip target="potentialPriceTooltip" placement="bottom">
      配信対象ユーザ数(日次)✕受注単価(日次)の合計値（集計期間内）
    </UncontrolledTooltip>
    <UncontrolledTooltip target="hitRate5Tooltip" placement="bottom">
      ポテンシャル売上の5%相当額、放棄メールを実施した際に機会損失5%取り返した想定
    </UncontrolledTooltip>
  </>
)

const select = ({ behaviorSummary }) => ({ behaviorSummary, _isLoading: behaviorSummary.isFetching })

export default connect(select)(BehaviorSummaryTable)
