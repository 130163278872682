import React, { useEffect } from 'react'
import ReactDOM from 'react-dom'
import { connect } from 'react-redux'
import { useMountEffect } from 'lib/hooks'
import grapesjs from 'grapesjs'
import generateHtmlEditorComponentTypes from 'lib/grapesjs/plugins/htmlEditorComponentTypes'
import generateHtmlEditorMessages from 'lib/grapesjs/plugins/htmlEditorMessages'
import 'grapesjs/dist/css/grapes.min.css'
import { recoTemplateAction } from 'actions'
import parserPostCSS from 'grapesjs-parser-postcss'
import { companyCampaignTemplateValueConstant as cctvc } from 'config/constants'
import swal from 'sweetalert'

const isExclamation = (model) => {
  if (isExclamationText(model)) {
    return true
  }
  if (isExclamationLink(model)) {
    return true
  }
  if (isExclamationImage(model)) {
    return true
  }
  return false
}

const isExclamationText = (model) => {
  switch (model.get('type')) {
  case 'link':
  case 'text':
    if (model.get('components').length === 0 || !model.get('layerable')) {
      return false
    }
    return model.get('components').some((node) => {
      return isExclamationText(node)
    })
  case 'textnode':
    return [cctvc.siteName, cctvc.companyName, cctvc.copyright].some((text) => {
      return model.get('content').indexOf(text) !== -1
    })
  default:
    return false
  }
}

const isExclamationLink = (model) => {
  switch (model.get('type')) {
  case 'link':
    return model.getAttributes()['href'].startsWith(cctvc.baseUrl)
  case 'linkButton':
    return model.get('href').startsWith(cctvc.baseUrl)
  case 'snsIcons':
    return ['facebook-href', 'twitter-href', 'line-href', 'instagram-href', 'pinterest-href', 'youtube-href'].some((href) => {
      return model.get(href).startsWith(cctvc.baseUrl)
    })
  default:
    return false
  }
}

const isExclamationImage = (model) => {
  switch (model.get('type')) {
  case 'image':
    return [
      cctvc.shopLogoImageUrl,
      cctvc.shopMainImageUrl,
      cctvc.couponImageUrl,
    ].includes(model.getAttributes()['src'])
  default:
    return false
  }
}

const hasExclamationChild = (models) => {
  return models.some((model) => {
    if (isExclamation(model)) {
      return true
    }
    if (model.get('components').length === 0 || !model.get('layerable')) {
      return false
    }
    return hasExclamationChild(model.get('components'))
  })
}

const getAllChild = (model, isRoot = false) => {
  if (model.get('components').length === 0 || !model.get('layerable')) {
    return [model]
  }

  const children = model.get('components').map((child) => getAllChild(child)).flat()
  return isRoot ? children : [model].concat(children)
}

const DialogContent = () => {
  return (
    <div>
      変更の必要なパーツが残っているため反映できません。 <br />
      <span className="fa fa-exclamation-circle"/>の付いたパーツをご確認ください。<br />
      <br />
      <small className="form-text text-muted">
        <a href="./campaign_template_value" target="_blank">こちら</a>から各キャンペーン共通のデフォルト値を設定可能です。<br />
        ※変更した設定を反映するには新規作成からやり直してください。
      </small>
    </div>
  )
}

// 自身の配下に変更が必要な点があることを示すマークを削除
const removeHasExclamationMark = (component) => {
  if (hasExclamationChild(component.get('components'))) {
    return
  }
  component.viewLayer.$el
    .find('.gjs-layer-has-exclamation').remove()
}

// 自身に変更が必要な点があることを示すマークを削除
const removeIsExclamationMark = (component) => {
  if (isExclamation(component)) {
    return
  }
  component.viewLayer.$el
    .children(`.${component.viewLayer.clsTitleC}`)
    .find('.gjs-layer-exclamation').remove()
}


export const CampaignHtmlEditorPage = ({ dispatch, recoTemplate }) => {
  useMountEffect(() => {
    dispatch(recoTemplateAction.fetchRecoTemplates())
  })

  useEffect(() => {
    if (recoTemplate.processResult === 'SUCCESS') {
      const campaign = window.opener !== null && window.opener.campaign !== undefined ? window.opener.campaign : null
      const campaignType = window.opener !== null && window.opener.campaignType !== undefined ? window.opener.campaignType : null
      const triggerType = campaign.triggerType !== undefined ? Number(campaign.triggerType) : campaign.triggerType

      const editor = grapesjs.init({
        container: '#gjs',
        fromElement: false,
        height: '100%',
        width: 'auto',
        keepUnusedStyles: true,
        storageManager: {
          id: 'gjs-', // Prefix identifier that will be used on parameters
          type: 'local', // Type of the storage
          autosave: false, // Store data automatically
          autoload: false, // Autoload stored data on init
          stepsBeforeSave: 1, // If autosave enabled, indicates how many changes are necessary before store method is triggered
        },
        components: campaign !== null ? campaign.htmlBody : '',
        style: campaign !== null ? campaign.style : '',
        styleManager: {
          appendTo: '.styles-container',
        },
        traitManager: {
          appendTo: '.traits-container',
        },
        layerManager: {
          appendTo: '.layers-container',
          hidable: false,
          showWrapper: false,
          sortable: false,
          onInit({ component, render, listenTo }){
            // 配下の変更を監視し、自身の配下に変更が必要な点があることを示すマークの表示を更新
            const children = getAllChild(component, true)
            children.forEach((child) => {
              listenTo(child, 'change:attributes change:_innertext change:src change:href change:facebook-href change:twitter-href change:line-href change:instagram-href change:pinterest-href change:youtube-href', () => {
                // textnodeの場合は親のイベントが発火してくれないため、textnodeから親を辿って処理する必要がある
                if (component.get('type') === 'textnode') {
                  let target = component.parent()
                  while(target !== undefined) {
                    removeHasExclamationMark(target)
                    target = target.parent()
                  }
                }
                else {
                  removeHasExclamationMark(component)
                }
              })
            })

            // 自身の変更を監視し、自身に変更が必要な点があることを示すマークの表示を更新
            listenTo(component, 'change:attributes change:_innertext change:src change:href change:facebook-href change:twitter-href change:line-href change:instagram-href change:pinterest-href change:youtube-href', () => {
              // textnodeの場合は親のイベントが発火してくれないため、textnodeから親を辿って処理する必要がある
              if (component.get('type') === 'textnode') {
                removeIsExclamationMark(component.parent())
              }
              else {
                removeIsExclamationMark(component)
              }
            })
          },
          // isExclamationとhasExclamationChildのチェックを入れるためにextend
          extend: {
            template(model) {
              const { pfx, ppfx, config, clsNoEdit } = this
              const { hidable } = config
              const count = this.countChildren(model)
              const addClass = !count ? this.clsNoChild : ''
              const clsTitle = `${this.clsTitle} ${addClass}`
              const clsTitleC = `${this.clsTitleC} ${ppfx}one-bg`
              const clsCaret = `${this.clsCaret} fa fa-chevron-right`
              const clsInput = `${this.inputNameCls} ${clsNoEdit} ${ppfx}no-app`
              const level = this.level
              const gut = `${10 + level * 10}px`
              const name = model.getName()
              const icon = model.getIcon()
              const clsBase = `${pfx}layer`

              return `
                ${
                  hidable
                    ? `<i class="${pfx}layer-vis fa fa-eye ${
                      this.isVisible() ? '' : 'fa-eye-slash'
                    }" data-toggle-visible></i>`
                    : ''
                }
                <div class="${clsTitleC}">
                  <div class="${clsTitle}" style="padding-left: ${gut}" data-toggle-select>
                    <div class="${pfx}layer-title-inn" title="${name}">
                      <i class="${clsCaret}" data-toggle-open></i>
                      ${isExclamation(model) ? '<span class="fa fa-exclamation-circle gjs-layer-exclamation"></span>' : (icon ? `<span class="${clsBase}__icon">${icon}</span>` : '')}
                      <span class="${clsInput}" data-name>${name}</span>
                    </div>
                  </div>
                </div>
                ${count !== 0 && hasExclamationChild(model.get('components')) ? `<div class="gjs-layer-has-exclamation"><span class="fa fa-exclamation-circle" data-toggle-open></span></div>` : ''}
                <div class="${this.clsChildren}"></div>`
            },
          },
        },
        deviceManager: {
          devices: [{
            name: 'Desktop',
            width: '', // default size
          }, {
            name: 'Mobile',
            width: '290px', // this value will be used on canvas width
            widthMedia: '', // this value will be used in CSS @media
          }],
        },
        selectorManager: {
          appendTo: '.selector-container',
          render: ({ el, labelHead, labelStates, labelInfo, }) => {
            return `
<div class="gjs-clm-sels-info">
  <div class="gjs-clm-label-sel">${labelInfo}:</div>
  <div class="gjs-clm-sels" data-selected></div>
</div>
 `
          }
        },

        panels: {
          defaults: [
            {
              id: 'panel__right',
              el: '.panel__right',
            },
            {
              id: 'panel-devices',
              el: '.panel__devices',
              buttons: [
                {
                  id: 'device-desktop',
                  className: 'fa fa-desktop',
                  label: '',
                  command: 'set-device-desktop',
                  active: true,
                  togglable: false,
                },
                {
                  id: 'device-mobile',
                  className: 'fa fa-mobile',
                  label: '',
                  command: 'set-device-mobile',
                  togglable: false,
                },
              ],
            },
          ],
        },

        i18n: {
          locale: 'ja',
        },

        baseCss: '',
        protectedCss: '',

        showToolbar: true,

        plugins: [
          generateHtmlEditorMessages(),
          generateHtmlEditorComponentTypes({ campaignType, triggerType, recoTemplates: recoTemplate.items }),
          parserPostCSS,
        ],
      })

      editor.Panels.addPanel({
        id: 'panel-top',
        el: '.panel__top',
        buttons: [
          {
            id: 'reflect',
            className: 'btn btn-primary',
            label: '反映',
            context: 'reflect',
            command(editor) {
              if (hasExclamationChild(editor.getComponents())) {
                const wrapper = document.createElement('div')
                ReactDOM.render(<DialogContent />, wrapper)
                const el = wrapper.firstChild
                swal({
                  title: '変更の必要なパーツがあります',
                  content: el,
                  icon: 'warning',
                  closeModal: true,
                  closeOnClickOutside: false,
                  closeOnEsc: true,
                  button: {
                    text: "OK",
                  },
                }).then((isConfirm) => {})
                return
              }

              window.opener.campaign.htmlBody = editor.getHtml()
              window.opener.campaign.style = editor.getCss()

              // 閉じる際の警告をオフ
              window.onbeforeunload = null

              window.close()
            },
          },
        ],
      })

      editor.Commands.add('set-device-desktop', {
        run: editor => editor.setDevice('Desktop'),
        stop: () => {},
      })
      editor.Commands.add('set-device-mobile', {
        run: editor => editor.setDevice('Mobile'),
        stop: () => {},
      })

      // コンポーネントの移動を行うとデザイン崩れ（主にOutlookで表示時のデザイン崩れ）が発生する可能性が高いため、コンポーネントのドラッグを不可にする
      editor.on('component:drag:start', (props) => {
        const { target } = props
        target.set({
          draggable: false,
          propagate: ['draggable'],
        })
      })

      editor.on('component:selected', (model, event) => {
        // ルートコンポーネントのみ削除可能に
        if (model.parent().get('type') === 'wrapper') {
          model.set('toolbar', [{ attributes: {class: 'fa fa-trash-o'}, command: 'tlb-delete' }])
        } else {
          model.set('toolbar', [])
        }

        // テキストとエリアでのみ特定のスタイルを設定可能にする。
        // component:deselectedの処理と対になっている
        if (model.get('type') === 'text') {
          editor.StyleManager.addSector('textSector', {
            name: 'テキスト',
            open: true,
            buildProps: [
              'text-align',
            ],
            properties: [
              {
                property: 'text-align',
                list: [
                  { value: 'left', className: 'fa fa-align-left' },
                  { value: 'center', className: 'fa fa-align-center' },
                  { value: 'right', className: 'fa fa-align-right' },
                ],
              },
            ],
          })
        } else if (model.get('type') === 'area') {
          editor.StyleManager.addSector('areaSector', {
            name: 'エリア',
            open: true,
            properties: [
              {
                type: 'integer',
                name: 'padding-top',
                property: 'padding-top',
                units: ['px'],
                defaults: 0,
                min: 0,
              },
              {
                type: 'integer',
                name: 'padding-bottom',
                property: 'padding-bottom',
                units: ['px'],
                defaults: 0,
                min: 0,
              },
              {
                type: 'color',
                name: 'background-color',
                property: 'background-color',
                defaults: '',
              },
            ],
          })
        }
      })

      editor.on('component:deselected', (model, event) => {
        if (model.get('type') === 'text') {
          editor.StyleManager.removeSector('textSector')
        } else if (model.get('type') === 'area') {
          editor.StyleManager.removeSector('areaSector')
        }
      })

      // 意図不明。設定しているプロパティは以下。
      // https://github.com/GrapesJS/grapesjs/blob/9314b57ac91e370bc0adb9ea958e9201dd0a468a/src/selector_manager/model/Selector.ts#L23C1-L23C1
      editor.SelectorManager.getAll().each(selector => selector.set('private', 1))
      editor.on('selector:add', selector => selector.set('private', 1))

      editor.on('component:remove:before', (model, callback, options) => {
        if (options.skipDialog) {
          return
        }

        swal({
          title: 'パーツを削除します',
          text: 'パーツを削除しますか？削除したパーツは後から追加できません。',
          icon: 'warning',
          dangerMode: true,
          closeModal: true,
          closeOnClickOutside: false,
          closeOnEsc: true,
          buttons: ['キャンセル', '削除'],
        }).then((isConfirm) => {
          if (!isConfirm) {
            return
          }
          model.remove({ skipDialog: true })
        })

        // Backbone.jsのtriggerで呼ばれるため、ダイアログのコールバックではキャンセルできない。
        // 一度目は強制キャンセルして、削除続行の場合は再びremoveを呼ぶ
        options.abort = true
      })

      // アセットマネージャーを無効化
      editor.Commands.add('open-assets', () => {})

      // キーボードショートカットを無効化（削除やコピペが行えてしまうため）
      editor.Keymaps.removeAll()
    }
  }, [recoTemplate.processResult, recoTemplate.items])

  return (
    <>
      <div className="panel__top">
        <div className="panel__devices" />
      </div>
      <div className="editor-row">
        <div className="editor-canvas">
          <div id="gjs" />
        </div>
        <div className="panel__right">
          <div className="layers-container" />
          <div className="styles-container" />
          <div className="traits-container" />
          <div className="selector-container" />
        </div>
      </div>
    </>
  )
}

const select = ({ recoTemplate }) => ({ recoTemplate })

export default connect(select)(CampaignHtmlEditorPage)
